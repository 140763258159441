import { createAction, props } from '@ngrx/store';
import { IArchiveOrder } from '../models/order.models';

const hybrisOrdersAPIName = '[Hybris orders API]';

export const retrieveHybrisOrders = createAction(
  `${hybrisOrdersAPIName} Load hybris orders`
);

export const retrieveHybrisOrdersSuccess = createAction(
  `${hybrisOrdersAPIName} Load hybris orders success`,
  props<{hybrisOrders: IArchiveOrder[]}>(),
);
