import { createAction, props } from '@ngrx/store';
import {
  IOrdersResponse,
  IPurchaseActivityItem,
} from '../models/order.models';

export const INIT_PURCHASE_ACTIVITY = '[Purchase Activity] Init Purchase Activity';
export const PREVIOUS_ORDERS_INIT_ORDERS_LOADING = '[Purchase History] Previous Orders - Init Orders Loading';
export const PREVIOUS_ORDERS_LOAD_ORDERS_WITH_SAP_DETAILS = '[Purchase History] Previous SAP Orders - Load Orders';
export const PREVIOUS_ORDERS_LOAD_ORDERS_WITH_SAP_DETAILS_SUCCESS = '[Purchase History] Previous SAP Orders - Load Orders SUCCESS';
export const PREVIOUS_ORDERS_LOAD_ORDERS_WITH__SAP_DETAILS_FAILURE = '[Purchase History] Previous SAP Orders - Load Orders FAILURE';
export const PREVIOUS_ORDERS_LOAD_ORDERS = '[Purchase History] Previous Orders - Load Orders';
export const PREVIOUS_ORDERS_LOAD_ORDERS_SUCCESS = '[Purchase History] Previous Orders - Load Orders SUCCESS';
export const PREVIOUS_ORDERS_LOAD_ORDERS_FAILURE = '[Purchase History] Previous Orders - Load Orders FAILURE';
export const PREVIOUS_ORDERS_LOAD_ARCHIVE = '[Purchase History] Previous Orders - Load Archive';
export const PREVIOUS_ORDERS_LOAD_ARCHIVE_SUCCESS = '[Purchase History] Previous Orders - Load Archive SUCCESS';
export const PREVIOUS_ORDERS_LOAD_ARCHIVE_FAILURE = '[Purchase History] Previous Orders - Load Archive FAILURE';
export const PENDING_ORDERS_LOAD_ORDERS = '[Purchase History] Pending Orders - Load Orders';
export const PENDING_ORDERS_LOAD_ORDERS_SUCCESS = '[Purchase History] Pending Orders - Load Orders SUCCESS';
export const PENDING_ORDERS_LOAD_ORDERS_FAILURE = '[Purchase History] Pending Orders - Load Orders FAILURE';
export const PREVIOUS_QUOTES_AND_REQUESTS_LOAD = '[Purchase History] Previous Quotes and Requests - Load';
export const PREVIOUS_QUOTES_AND_REQUESTS_LOAD_SUCCESS = '[Purchase History] Previous Quotes and Requests - Load SUCCESS';
export const PREVIOUS_QUOTES_AND_REQUESTS_LOAD_FAILURE = '[Purchase History] Previous Quotes and Requests - Load FAILURE';
export const CHANGE_PAGINATION = '[Purchase History] Change Pagination';
export const MOVE_PENDING_ORDER_TO_CANCELED = '[Purchase Activity] Move Pending Order To Canceled';

// *** Init Purchase Activity ***
export const initPurchaseActivity = createAction(
  INIT_PURCHASE_ACTIVITY,
);

// *** Previous Orders - Orders ***
export const initOrdersLoadingInPreviousOrders = createAction(
  PREVIOUS_ORDERS_INIT_ORDERS_LOADING,
  (offset: number = undefined) => ({offset}),
);

// *** Previous Orders - Orders nonSAP store ***
export const loadOrdersInPreviousOrders = createAction(
  PREVIOUS_ORDERS_LOAD_ORDERS,
  (offset: number = undefined, limit: number = undefined) => ({offset, limit}),
);
export const loadOrdersInPreviousOrdersSuccess = createAction(
  PREVIOUS_ORDERS_LOAD_ORDERS_SUCCESS,
  props<{orders: IOrdersResponse}>(),
);
export const loadOrdersInPreviousOrdersFailure = createAction(
  PREVIOUS_ORDERS_LOAD_ORDERS_FAILURE,
  props<{resError: string}>(),
);

// *** Previous Orders - Orders SAP store ***

export const loadSapOrdersInPreviousOrders = createAction(
  PREVIOUS_ORDERS_LOAD_ORDERS_WITH_SAP_DETAILS,
  (offset: number = undefined, limit: number = undefined) => ({offset, limit}),
);

export const loadSapOrdersInPreviousOrdersSuccess = createAction(
  PREVIOUS_ORDERS_LOAD_ORDERS_WITH_SAP_DETAILS_SUCCESS,
  props<{ordersWithSapDetails: IOrdersResponse}>(),
);

export const loadSapOrdersInPreviousOrdersFailure = createAction(
  PREVIOUS_ORDERS_LOAD_ORDERS_WITH__SAP_DETAILS_FAILURE,
  props<{resError: string}>(),
);

// *** Hybris Orders - Archive ***
export const loadArchiveInPreviousOrders = createAction(
  PREVIOUS_ORDERS_LOAD_ARCHIVE,
);
export const loadArchiveInPreviousOrdersSuccess = createAction(
  PREVIOUS_ORDERS_LOAD_ARCHIVE_SUCCESS,
  props<{archiveOrders: IOrdersResponse}>(),
);
export const loadArchiveInPreviousOrdersFailure = createAction(
  PREVIOUS_ORDERS_LOAD_ARCHIVE_FAILURE,
  props<{resError: string}>(),
);

// *** Pending Orders ***
export const loadOrdersInPendingOrders = createAction(
  PENDING_ORDERS_LOAD_ORDERS,
);
export const loadOrdersInPendingOrdersSuccess = createAction(
  PENDING_ORDERS_LOAD_ORDERS_SUCCESS,
  props<{pendingOrders: IOrdersResponse, canceledOrders: IOrdersResponse}>(),
);
export const loadOrdersInPendingOrdersFailure = createAction(
  PENDING_ORDERS_LOAD_ORDERS_FAILURE,
  props<{resError: string}>(),
);

// *** Previous Quotes and Requests ***
export const loadQuotesAndRequestsInPreviousQuotesAndRequests = createAction(
  PREVIOUS_QUOTES_AND_REQUESTS_LOAD,
);
export const loadQuotesAndRequestsInPreviousQuotesAndRequestsSuccess = createAction(
  PREVIOUS_QUOTES_AND_REQUESTS_LOAD_SUCCESS,
  props<{previousQuotes: IPurchaseActivityItem[], previousRequests: IPurchaseActivityItem[]}>(),
);
export const loadQuotesAndRequestsInPreviousQuotesAndRequestsFailure = createAction(
  PREVIOUS_QUOTES_AND_REQUESTS_LOAD_FAILURE,
  props<{resError: string}>(),
);

// *** Change pagination ***
export const changePagination = createAction(
  CHANGE_PAGINATION,
  props<{tab: string, page: number}>(),
);

// *** Cancel or Decline Pending order ***
export const movePendingOrderToCanceled = createAction(
  MOVE_PENDING_ORDER_TO_CANCELED
);
