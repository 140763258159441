import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { EFeatureToggles } from '../configurations/common';
import { map } from 'rxjs/operators';

export const externalQuoteCheckoutRouteGuard: CanActivateFn = (route, _) => {
  const configurationFacade = inject(ConfigurationFacade);
  const router = inject(Router);

  return configurationFacade.isFeatureEnabled(EFeatureToggles.EXTERNAL_QUOTE_CHECKOUT).pipe(
    map(isEnabled => {
      if (isEnabled) {
        router.navigate(['/']);
        return false;
      }
      return true;
    })
  );
};
