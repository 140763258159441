import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IconUtils } from '../../../../utils/icon.utils';
import {
  EFeatureToggles,
  EStoreTypes, FEATURE_CONFIG,
  PurchaseActivityPreviousOrdersTabs,
  PurchaseActivityTabs,
} from '../../../../configurations/common';
import { StringUtils } from '../../../../utils/string.utils';
import { AddressUtils } from '../../../../utils/address.utils';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { ConfigurationFacade } from '../../../../facades/configuration.facade';
import { IOrderItem, IPurchaseActivityItem } from '../../../../models/order.models';
import { FileType, FileUtils } from '../../../../utils/file.utils';
import { OrdersFacade } from '../../../../facades/orders.facade';
import { I18nService } from '../../../../services';
import { Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CheckoutFacade } from '../../../../facades/checkout.facade';
import { MarketingFacade } from '../../../../facades/marketing.facade';
import { ObjectUtils } from '../../../../utils/object.utils';
import { AppUtils } from '../../../../utils/app.utils';
import { environment } from '../../../../../environments/environment';
import { CustomerFacade } from '../../../../facades/customer.facade';
import { PurchaseActivityActions } from '../../../../actions';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import { AnalyticsService } from '../../../../analytics/analytics.service';
import { HttpResponse } from '@angular/common/http';
import { EOrderStatus } from '../../../../configurations/order-status';
import { PriceUtils } from '../../../../utils/price.utils';

@Component({
  selector: 'app-history-item-detail',
  templateUrl: './history-item-detail.component.html',
  styleUrl: './history-item-detail.component.scss',
})
export class HistoryItemDetailComponent implements OnInit, OnDestroy {
  protected readonly PurchaseActivityTabs = PurchaseActivityTabs;
  protected readonly StringUtils = StringUtils;
  protected readonly AddressUtils = AddressUtils;
  protected readonly AppUtils = AppUtils;
  protected readonly EStoreTypes = EStoreTypes;
  protected readonly PurchaseActivityPreviousOrdersTabs = PurchaseActivityPreviousOrdersTabs;
  protected readonly EOrderStatus = EOrderStatus;
  protected readonly priceUtils = PriceUtils;

  @Input() order: IPurchaseActivityItem;
  @Input() currentTab: string;
  @Input() currentSubTab: string;
  @Input() isOpen: boolean;

  @Output() openAddItemToCartModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() setItemsForAddingToCart: EventEmitter<{data: IOrderItem, isConcrete: boolean, quantity: number}[]>
              = new EventEmitter<{data: IOrderItem, isConcrete: boolean, quantity: number}[]>();
  @Output() setFlForReorder: EventEmitter<string> = new EventEmitter<string>();
  @Output() setOrderId: EventEmitter<string> = new EventEmitter<string>();

  // Configuration
  addresses: any[] = [
    {
      type: 'shippingAddress',
      arakhKey: 'ship-to',
      translationKey: 'ship-to-address',
    },
    {
      type: 'soldToAddress',
      arakhKey: 'sold-to',
      translationKey: 'sold-to-address',
    },
    {
      type: 'billingAddress',
      arakhKey: 'bill-to',
      translationKey: 'bill-to-address',
    },
  ];
  checkoutAddresses: string[];

  // View logic
  tabsOpened: string[] = [] as Array<string>;
  isExcludeTaxActive: boolean = environment.features.excludeTax.includes(AppUtils.getCurrentStore().storeId);
  isQuotePdfGenerationInProgress: boolean = false;
  isExportAsPdfInProgress: boolean = false;
  isEligibleForReturn: boolean = false;
  isPendingOrderLoaded: boolean = false;
  isDeclinePendingOrderModalVisible: boolean = false;
  isDeclinePendingOrderInProgress: boolean = false;
  isConfirmRemoveItemModalVisible: boolean;

  // Customer data
  isBusinessPartner$: Observable<boolean> = this.customerFacade.isBusinessPartner();
  isApprover$: Observable<boolean> = this.customerFacade.isApprover();

  // Feature toggles
  isExportAsPdfEnabled$: Observable<boolean> =
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.PURCHASE_ACTIVITY_EXPORT_AS_PDF);
  isReorderAllItemsEnabled$: Observable<boolean> =
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.PURCHASE_ACTIVITY_REORDER_ALL_ITEMS);
  isShowOrderDetailsEnabled$: Observable<boolean> =
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.PURCHASE_ACTIVITY_SHOW_ORDER_DETAILS);

  // Order logic
  itemsToAdd: Array<{data: IOrderItem, isConcrete: boolean, quantity: number}>;
  itemsList: any[] = []; // TODO: Any type will be removed after the BE will provide the correct data (#119265)
  itemsDetails: any; // TODO: getCartForApprovalData in orders.facade.ts is returning any type (#119265)
  cartId: string;

  // Remove item from cart
  clickedItemId: string;
  clickedItemName: string;
  isLastItemInPendingOrder: boolean;
  poNumberVisibilityByTabs$: Observable<string[]> = new Observable<string[]>();

  // Other variables
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private configurationFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade,
    private ordersFacade: OrdersFacade,
    private i18nService: I18nService,
    private translate: TranslateService,
    private router: Router,
    private checkoutFacade: CheckoutFacade,
    private marketingFacade: MarketingFacade,
    private analyticsService: AnalyticsService,
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {
    this.getCheckoutAddressesFromArakh();
    this.setDownloadReturnDocumentVisibility();
    this.poNumberVisibilityByTabs$ = this.configurationFacade.getArrayValues(FEATURE_CONFIG, 'purchase_activity_purchase_order_number_visibility');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // *** Initialization ***

  /**
   * Retrieves the addresses that are enabled in checkout from Arakh.
   */
  getCheckoutAddressesFromArakh(): void {
    this.configurationFacade.getDynamicCheckoutAddresses()
      .pipe(
        take(1),
        catchError(() => {
          this.checkoutAddresses = [];
          return of([]);
        }),
      ).subscribe((addresses) => {
      this.checkoutAddresses = addresses;
    });
  }

  /**
   * Return value for visibility of the download return document button based on the order details.
   * If there is at least one item which has a return authorization number, the button will be visible.
   * @private
   */
  private setDownloadReturnDocumentVisibility() {
    this.isEligibleForReturn = this.order?.attributes?.items?.some(item => item.sapDetails?.exchangeableItem) ?? false;
  }

  // *** Accordions operations ***

  /**
   * Toggles the visibility of a details of the specific order/request/quote.
   * If the tab is already open, it will be closed. Otherwise, it will be opened.
   * If the tab is related to pending orders or canceled pending orders and they are not loaded yet, it will retrieve
   * the items for the order to approve.
   * @param {string} id - The identifier of the tab to toggle.
   */
  toggleItemDetailsVisibility(id: string): void {
    const exists: number = this.tabsOpened.findIndex(tab => tab === id);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
    } else {
      if (!this.isPendingOrderLoaded && (
        this.currentTab === PurchaseActivityTabs.PENDING_ORDERS
      )) {
        this.retrieveItemsForOrderToApprove(this.order.id);
      }
      this.tabsOpened.push(id);
    }
  }

  // *** Modal: Cancel or decline Pending order ***

  openDeclinePendingOrderModal(): void {
    this.isDeclinePendingOrderModalVisible = true;
  }

  closeDeclinePendingOrderModal(): void {
    this.isDeclinePendingOrderModalVisible = false;
    this.isDeclinePendingOrderInProgress = false;
  }

  // *** Modal: Remove item from order ***

  restartShowModal(): void {
    this.isConfirmRemoveItemModalVisible = false;
    this.isLastItemInPendingOrder = false;
  }

  /**
   * Opens the modal to confirm the removal of the item from the cart.
   * @param itemId
   * @param cartId
   */
  openRemoveItemFromCartModal(itemId: string, cartId: string): void {
    this.cartId = cartId;
    this.clickedItemId = itemId;
    this.clickedItemName = this.getItemName(itemId);
    this.isConfirmRemoveItemModalVisible = true;
    this.isLastItemInPendingOrder = this.itemsList.length <= 1;
  }

  // *** Template view logic and data formatting ***

  /**
   * If attributeValue is falsy or 'empty' string, then return '', otherwise return attributeValue.
   * @param {string} attributeValue
   * @returns {string}
   */
  formatIfMissingValue(attributeValue: string): string {
    return !attributeValue || attributeValue === 'empty' ? '' : attributeValue;
  }

  /**
   * If the field is empty or has 'empty' string as its value, return true, otherwise return false.
   * @param {string} field
   * @returns {boolean}
   */
  isFieldEmpty(field: string): boolean {
    return field === 'empty' || field === '' || !field;
  }

  /**
   * Returns the icon class name for the specific status.
   * @param {string} status
   * @returns {string}
   */
  getIcon(status: string): string {
    return IconUtils.getStatusIcon(status);
  }

  /**
   * Returns the translated status for the specific status key.
   * @param {string} status
   * @returns {string}
   */
  mappedStatus(status: string): string {
    return this.translate.instant(
      `purchase-activity.order-status-${StringUtils.replaceSpaces(status, '-').toLowerCase()}`,
    );
  }

  /**
   * Get user initials to show it as the user avatar in approver details section.
   * @param {string} firstName
   * @param {string} lastName
   * @returns {string}
   */
  getUserInitials(firstName: string, lastName: string): string {
    return firstName.substring(0, 1) + lastName.substring(0, 1);
  }

  /**
   * Returns addition to translation "purchase-activity." based on the amount.
   * If 1 then "item-singular", otherwise "item-plural".
   * @param {number} amount
   * @returns {string}
   */
  getQuantityTranslationByAmount(amount: number): string {
    let translated: string = '';
    this.i18nService
      .getTranslationByKey(`purchase-activity.${StringUtils.getItemTranslationBasedOnAmount(amount)}`, {number: amount})
      .pipe(take(1)).subscribe((translation: string) => {
      translated = translation;
    });
    return translated;
  }

  /**
   * Get item name from included from 'concrete-products'.
   * @param {string} id
   * @returns {string}
   */
  getItemName(id: string): string {
    return this.itemsDetails.included?.filter(tmp => tmp.type === 'concrete-products' && tmp.id === id)[0].attributes.name;
  }

  /**
   * Get item picture url from included from 'concrete-product-image-sets'.
   * @param {string} id
   * @returns {string}
   */
  getItemPicture(id: string): string {
    return this.itemsDetails.included?.filter(tmp => tmp.type === 'concrete-product-image-sets' && tmp.id === id)[0]
      .attributes.imageSets.find(
        img => img.name === 'default')
      .images.find(smallImage => smallImage.externalUrlSmall)
      .externalUrlSmall;
  }

  /**
   * PDF generation is available only for orders in previous orders, previous quotes and previous requests.
   * @returns {boolean}
   */
  isPdfGenerationAvailable(): boolean {
    return this.currentSubTab === this.PurchaseActivityPreviousOrdersTabs.ORDERS
      || this.currentTab === this.PurchaseActivityTabs.PREVIOUS_QUOTES;
  }

  /**
   * Returns the total quantity of the items in the order/quote/request. Needs to be calculated because quotes and
   * requests don't contain itemsTotalNumber attribute.
   * @param items
   */
  getTotalQuantity(items: IOrderItem[]): number {
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }

  // *** Document downloading operations ***

  /**
   * Downloads the return document by opening the return document URL in a new tab.
   */
  downloadReturnDocument(): void {
    window.open(this._getDownloadReturnDocumentUrl(), '_blank');
  }

  /**
   * Returns the download return document URL.
   * @returns {string}
   * @private
   */
  private _getDownloadReturnDocumentUrl(): string {
    return this.translate.instant(environment.sparePartsReturnDocumentUrlKey);
  }

  /**
   * Generates and downloads a PDF file for quote.
   */
  generateQuotePdf(): void {
    if (AppUtils.isStoreActive(EStoreTypes.JP) && this.order?.id) {
      this.isQuotePdfGenerationInProgress = true;
      this.checkoutFacade.getCartGenerateQuotePdfFile(this.order.id, false).pipe(
        take(1),
      ).subscribe({
        next: (response: HttpResponse<Blob>) => {
          const filename = FileUtils.extractFilenameFromContentDisposition(
            response.headers.get('Content-Disposition'),
            `Generated_quote_${this.order.id}`,
          );
          FileUtils.saveAndOpenFile(response.body, FileType.PDF, filename);
        },
        complete: () => this.isQuotePdfGenerationInProgress = false,
        error: () => this.isQuotePdfGenerationInProgress = false,
      });
    }
  }

  /**
   * Generates and downloads a PDF file for order.
   */
  generateOrderDetailPdf(): void {
    switch (this.currentTab) {
      case PurchaseActivityTabs.PREVIOUS_ORDERS : {
        this.isExportAsPdfInProgress = true;
        this.ordersFacade.getOrderDetailPdfFile(this.order.id).pipe(
          take(1),
        ).subscribe({
          next: (response: HttpResponse<Blob>) => {
            const filename = FileUtils.extractFilenameFromContentDisposition(
              response.headers.get('Content-Disposition'),
              `OrdersDetails_${this.order.id}`,
            );
            FileUtils.saveAndOpenFile(response.body, FileType.PDF, filename);
          },
          complete: () => this.isExportAsPdfInProgress = false,
          error: () => this.isExportAsPdfInProgress = false,
        });
        break;
      }
      case PurchaseActivityTabs.PREVIOUS_REQUESTS:
      case PurchaseActivityTabs.PREVIOUS_QUOTES : {
        this.isExportAsPdfInProgress = true;
        this.checkoutFacade.getQuotePdfFile(this.order.id).pipe(
          take(1),
        ).subscribe({
          next: (response: HttpResponse<Blob>) => {
            const filename = FileUtils.extractFilenameFromContentDisposition(
              response.headers.get('Content-Disposition'),
              `Quote_details_${this.order.id}`,
            );
            FileUtils.saveAndOpenFile(response.body, FileType.PDF, filename);
          },
          complete: () => this.isExportAsPdfInProgress = false,
          error: () => this.isExportAsPdfInProgress = false,
        });
        break;
      }
    }
  }

  // *** Order operations ***

  /**
   * Redirects to the order details page.
   * TODO: Change the route to /order-details after the harmonization of the order details page (#118923) will be done.
   * @param {string} id
   */
  redirectToOrderDetails(id: string): void {
    let route: string;
    if (this.currentTab === PurchaseActivityTabs.PREVIOUS_ORDERS) {
      route = this.currentSubTab === PurchaseActivityPreviousOrdersTabs.ARCHIVE ? 'hybris-order-details' : 'my-orders';
    } else {
      route = 'my-request';
    }
    this.router.navigate([`/${route}/${id}`]);
  }

  /**
   * Approves the order and navigates to the harmonized order review page.
   */
  approveOrder(): void {
    this.checkoutFacade.actionPutCartIdOrderApprove(this.order.id);
    this.router.navigate(['/order-review/', this.order.id]).then();
  }

  /**
   * Declines pending order, track rejecting of the order and close the modal for declining the order.
   * @param {IPurchaseActivityItem} order
   */
  declinePendingOrder(order: IPurchaseActivityItem): void {
    const data = this.prepareDataForPendingOrderDeclineRequest(order);
    this.isDeclinePendingOrderInProgress = true;
    this.ordersFacade.postDeclinePendingOrder(data)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => {
          this.closeDeclinePendingOrderModal();
          return of();
        }),
      ).subscribe({
      next: () => {
        this.analyticsService.setProducts(this.itemsDetails);
        this.analyticsService.trackOrderRejected();
        this.store.dispatch(PurchaseActivityActions.initPurchaseActivity());
        this.closeDeclinePendingOrderModal();
      },
      error: () => {
        this.closeDeclinePendingOrderModal();
      },
    });
  }

  /**
   * Prepares the data for a pending order decline request.
   * Extracts the necessary details from the order object and formats them into the required structure.
   * @param {IPurchaseActivityItem} order - The order object containing the details of the pending order.
   * @returns {object} The formatted data object for the decline request.
   */
  prepareDataForPendingOrderDeclineRequest(order: IPurchaseActivityItem): object {
    const { approverDetails, customer } = order.attributes;
    const { approverId, firstName: approverFirstName, lastName: approverLastName, dueDate } = approverDetails;
    const { email, salutation, firstName, lastName } = customer;

    return {
      data: {
        type: 'quote-decline',
        attributes: {
          idCart: order.id,
          approverDetails: { approverId, approverFirstName, approverLastName, dueDate },
          customer: { email, salutation, firstName, lastName },
        },
      },
    };
  }

  /**
   * If there is last item in the cart, then decline the order and navigate to the homepage.
   */
  lastItemDeclineOrder(): void {
    this.router.navigate([this.i18nService.getCurrentLocale()]).then(
      _ => {
        this.marketingFacade.deleteCart(this.cartId);
      },
    );
  }

  addItemsToCart(items: IOrderItem[]): void {
    this.itemsToAdd = [];
    items.forEach((item: IOrderItem) => {
      this.itemsToAdd.push({
        data: item,
        isConcrete: true,
        quantity: item.quantity,
      });
    });

    this.setFlForReorder.emit(this.order.attributes?.systemDetails?.siemensEquipmentId);
    this.setItemsForAddingToCart.emit(this.itemsToAdd);
    this.openAddItemToCartModal.emit();
    this.setOrderId.emit(this.order.id);
  }

  /**
   * Removes the item from the cart and retrieves the items for the order to approve. Then closes the modal for
   * removing item.
   */
  removeItemFromCart(): void {
    this.store.dispatch(
      PurchaseActivityActions.deleteItemFromPendingOrder({orderId: this.cartId, orderItemId: this.clickedItemId}),
    );
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.isConfirmRemoveItemModalVisible = false;
  }

  /**
   * Retrieves the items for the order to approve.
   * TODO: Will be removed after the BE will provide the correct data via mapping on BE side (#119265).
   * @param {string} id
   */
  retrieveItemsForOrderToApprove(id: string): void {
    this.ordersFacade.getCartForApprovalData(id)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => {
          this.isPendingOrderLoaded = true;
          return of();
        }),
      ).subscribe(data => {
      this.isPendingOrderLoaded = true;
      if (data) {
        this.itemsDetails = data;
        if (!ObjectUtils.isEmpty(this.itemsDetails.data.relationships)) {
          this.itemsList = this.mapItems(this.itemsDetails.data.relationships.items.data);
          this.order.attributes.items = this.itemsList;
        }
      }
    });
  }

  /**
   * TODO: Will be removed after the BE will provide the correct data via mapping on BE side (#119265).
   * @param {any[]} itemsData
   * @returns {any[]}
   * @private
   */
  private mapItems(itemsData: any[]): any[] {
    const itemsList: any[] = [];
    itemsData.forEach((item) => {
      if (itemsList.indexOf(item.id) === -1) {
        itemsList.push({
          sku: item.id,
          name: this.getItemName(item.id),
          unitPrice: this.getItemAttributeValue(item.id, 'unitPrice'),
          quantity: this.getItemAttributeValue(item.id, 'quantity'),
          sumPrice: this.getItemAttributeValue(item.id, 'sumPrice'),
          materialNumber: item.id,
          metadata: {
            image: this.getItemPicture(item.id),
          },
        });
      }
    });
    return itemsList;
  }

  /**
   * TODO: Will be removed after the BE will provide the correct data via mapping on BE side (#119265).
   * @param {string} id
   * @param {string} attribute
   * @returns {any}
   */
  getItemAttributeValue(id: string, attribute: string): any {
    if (attribute === 'unitPrice' || attribute === 'sumPrice') {
      return this.itemsDetails.included?.filter(tmp => tmp.type === 'items' && tmp.id === id)[0].attributes.calculations[attribute];
    }
    return this.itemsDetails.included?.filter(tmp => tmp.type === 'items' && tmp.id === id)[0].attributes[attribute];
  }
}
