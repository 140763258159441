import { Injectable, Injector } from '@angular/core';
import { OrdersService } from '../services/orders.service';
import { Observable } from 'rxjs';
import {
  IArchiveOrder,
  IArchiveOrderResponse,
  IOrderById,
  IOrderData,
  IOrderSearchFilter, ICartsApprovalResponse, IOrdersResponse, IPurchaseActivityItem,
} from '../models/order.models';
import {
  IOrdersHistoryPage,
  IOrdersWithSapDetails,
  selectCpqOrdersFulfilledPagination,
  selectCpqOrdersUnderReviewPagination,
  selectFulfilledCpqOrdersHistoryAtPage,
  selectLoadedSapOrders,
  selectOrdersHistoryAtPage,
  selectOrdersPendingCarts,
  selectSapOrdersAtPage,
  selectSapOrdersPagination,
  selectUnderReviewCpqOrdersHistoryAtPage,
} from '../reducers/orders.reducer';
import { Store } from '@ngrx/store';
import * as fromState from '../reducers';
import * as OrdersActions from '../actions/orders.actions';
import { selectHybrisOrderById, selectHybrisOrders } from '../reducers/hybris-orders.reducer';
import { AppUtils } from '../utils/app.utils';
import { IPaginationCurrent } from '../models/settings.model';
import { IRfqHistory } from '../models/rfq.models';
import { RfqService } from '../services';
import { PurchaseActivityActions } from '../actions';
import { selectArchiveOrderById } from '../reducers/purchase-activity.reducer';


@Injectable({providedIn: 'root'})
export class OrdersFacade {

  private pOrdersService: OrdersService;
  private pRfqService: RfqService;

  private getOrdersService(): OrdersService {
    if (!this.pOrdersService) {
      this.pOrdersService = this.injector.get(OrdersService);
    }
    return this.pOrdersService;
  }

  public getRfqService(): RfqService {
    if (!this.pRfqService) {
      this.pRfqService = this.injector.get(RfqService);
    }
    return this.pRfqService;
  }

  constructor(
    private injector: Injector,
    private store: Store<fromState.State>) {
  }

  getCarts(): Observable<any> {
    return this.getOrdersService().getCarts();
  }

  loadCarts() {
    this.store.dispatch(OrdersActions.OrdersWithCartsStartDataAction());
  }

  getCartsApproval(): Observable<ICartsApprovalResponse> {
    return this.getOrdersService().getCartsApproval();
  }

  loadCartsApproval() {
    // TODO: remove first dispatch after harmonization
    this.store.dispatch(OrdersActions.OrdersWithCartApprovalStartDataAction());
    this.store.dispatch(PurchaseActivityActions.loadOrdersInPendingOrders());
  }

  //calling selector for pending carts from application store
  selectPendingCarts(): Observable<any> {
    return this.store.select(selectOrdersPendingCarts);
  }

  getCartForApprovalData(id: string): Observable<any> {
    return this.getOrdersService().getCartForApprovalData(id);
  }

  postDeclinePendingOrder(data: any): Observable<any> {
    return this.getOrdersService().postDeclinePendingOrder(data);
  }

  deleteCart(cartId: string): Observable<any> {
    return this.getOrdersService().deleteCart(cartId);
  }

  deleteItemFromOrder(cartId: string, itemId: string): Observable<any> {
    return this.getOrdersService().deleteItemFromOrder(cartId, itemId);
  }

  postCancelOrder(orderId: string, data: any): Observable<any> {
    return this.getOrdersService().postCancelOrder(orderId, data);
  }

  getOrders(
    offset: number = null,
    limit: number = null,
    orderFilter: Partial<IOrderSearchFilter> = {},
  ): Observable<IOrdersResponse> {
    return this.getOrdersService().getOrders(offset, limit, orderFilter);
  }

  loadOrdersHistory(
    offset: number = undefined,
    limit: number = undefined,
    recallOnClearData: boolean = false,
  ): void {
    if (!offset || !limit) {
      // user must set both if they wish to override the default store-specific settings
      const paginationSettings = AppUtils.getCurrentStore().paginationSettingsPerFeature.orders;
      offset = 0;
      limit = paginationSettings.limitPerPage;
    }
    if (recallOnClearData) {
      this.store.dispatch(OrdersActions.OrdersHistoryClearDataAction());
    }
    // TODO: remove dispatch after harmonization
    this.store.dispatch(OrdersActions.OrdersHistoryStartDataAction(offset, limit));
  }

  selectOrdersHistoryAtPage(pageNumber: number): Observable<IOrdersHistoryPage> {
    return this.store.select(selectOrdersHistoryAtPage(pageNumber));
  }

  getArchiveOrders(): Observable<IArchiveOrderResponse> {
    return this.getOrdersService().getArchiveOrders();
  }

  getPreviousAndRequestQuotes(): Observable<IRfqHistory> {
    return this.getRfqService().getRfqHistory();
  }

  loadPreviousQuotesAndRequests() {
    this.store.dispatch(PurchaseActivityActions.loadQuotesAndRequestsInPreviousQuotesAndRequests());
  }

  selectHybrisOrders(): Observable<IArchiveOrder[]> {
    return this.store.select(selectHybrisOrders);
  }

  getOrderById(id: string): Observable<IOrderById> {
    return this.getOrdersService().getOrderById(id);
  }

  getHybrisOrderById(id: string): Observable<IArchiveOrderResponse> {
    return this.getOrdersService().getHybrisOrderById(id);
  }

  selectHybrisOrderById(id: string): Observable<IArchiveOrder> {
    return this.store.select(selectHybrisOrderById(id));
  }

  selectArchiveOrderById(id: string): Observable<IPurchaseActivityItem> {
    return this.store.select(selectArchiveOrderById(id));
  }

  getOrderDetailPdfFile(orderReference: string): Observable<any> {
    return this.getOrdersService().getOrderDetailPdfFile(orderReference);
  }

  postOrdersHistoryExcelFile(activeOrdersReferences: string[]): Observable<any> {
    return this.getOrdersService().postOrdersHistoryExcelFile(activeOrdersReferences);
  }

  postQuoteHistoryExcelFile(activeQuoteReferences: string[]): Observable<Blob> {
    return this.getOrdersService().getQuoteHistoryExcelFile(activeQuoteReferences);
  }

  postHybrisOrdersHistoryExcelFile(activeOrdersReferences: string[]): Observable<any> {
    return this.getOrdersService().postHybrisOrdersHistoryExcelFile(activeOrdersReferences);
  }

  selectLoadedOrdersWithSapDetails(): Observable<IOrdersWithSapDetails> {
    return this.store.select(selectLoadedSapOrders);
  }

  selectOrdersWithSapDetailsAtPage(pageNumber: number): Observable<IOrdersWithSapDetails> {
    return this.store.select(selectSapOrdersAtPage(pageNumber));
  }

  selectOrdersWithSapDetailsPagination(): Observable<IPaginationCurrent> {
    return this.store.select(selectSapOrdersPagination);
  }

  recallAndClearOrdersWithDetailsDataList(
    offset: number = undefined,
    limit: number  = undefined,
  ) {
    if (!offset || !limit) {
      // user must set both if they wish to override the default store-specific settings
      const paginationSettings = AppUtils.getCurrentStore().paginationSettingsPerFeature.sparePartOrders;
      offset = 0;
      limit = paginationSettings.limitPerPage;
    }
    this.store.dispatch(OrdersActions.OrdersWithSapDetailsClearData());
    this.store.dispatch(OrdersActions.OrdersWithSapDetailsStartDataAction(offset, limit));
  }

  loadOrdersWithSapDetails(
    offset: number = undefined,
    limit: number  = undefined,
  ): void {
    if (!offset || !limit) {
      // user must set both if they wish to override the default store-specific settings
      const paginationSettings = AppUtils.getCurrentStore().paginationSettingsPerFeature.serviceOrders;
      offset = 0;
      limit = paginationSettings.limitPerPage;
    }
    this.store.dispatch(OrdersActions.OrdersWithSapDetailsStartDataAction(offset, limit));
  }

  loadCpqOrdersUnderReviewData(
    offset: number = undefined,
    limit: number  = undefined,
  ): void {
    if (!offset || !limit) {
      // user must set both if they wish to override the default store-specific settings
      const paginationSettings = AppUtils.getCurrentStore().paginationSettingsPerFeature.serviceOrders;
      offset = 0;
      limit = paginationSettings.limitPerPage;
    }
    this.store.dispatch(OrdersActions.CpqOrdersUnderReviewStartDataAction(offset, limit));
  }

  loadCpqOrdersFulfilledData(
    offset: number = undefined,
    limit: number  = undefined,
  ): void {
    if (!offset || !limit) {
      // user must set both if they wish to override the default store-specific settings
      const paginationSettings = AppUtils.getCurrentStore().paginationSettingsPerFeature.serviceOrders;
      offset = 0;
      limit = paginationSettings.limitPerPage;
    }
    this.store.dispatch(OrdersActions.CpqOrdersFulfilledStartDataAction(offset, limit));
  }

  selectCpqUnderReviewOrdersAtPage(pageNumber: number): Observable<IOrderData[]> {
    return this.store.select(selectUnderReviewCpqOrdersHistoryAtPage(pageNumber));
  }

  selectCpqFulFilledOrdersAtPage(pageNumber: number): Observable<IOrderData[]> {
    return this.store.select(selectFulfilledCpqOrdersHistoryAtPage(pageNumber));
  }

  selectCpqFulFilledOrdersPagination(): Observable<IPaginationCurrent> {
    return this.store.select(selectCpqOrdersFulfilledPagination);
  }

  selectCpqUnderReviewOrdersPagination(): Observable<IPaginationCurrent> {
    return this.store.select(selectCpqOrdersUnderReviewPagination);
  }
}
