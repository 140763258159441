<div class="row">
  <div class="col-12">
    <app-content-banner [bannerKey]="homePageBannerKey"></app-content-banner>
    <ng-container *ngIf="isLoggedIn">
      <app-install-base
          *ngIf="((homeInstallBase$ | async) && (isBusinessPartnerRole$ | async) === false)">
      </app-install-base>
      <app-shop-categories *ngIf="isShopByCategoryActive && (isAnyTileAvailable$ | async)">
      </app-shop-categories>
    </ng-container>
    <app-featured-product [featuredProductsKey]="featuredProductsKey"></app-featured-product>
  </div>
</div>
