<div class="delivery-address">
  <h5 class="delivery-address__title" [attr.data-test]="'title-address'">{{ 'orders-page.delivery-address' | translate }}</h5>
  <ng-container *ngIf="!isAddressFieldEmpty(request.attributes.shippingAddress.firstName)">
    <p [attr.data-test]="'desc-firstName'">
      {{ renderEmptyAddressField(request.attributes.shippingAddress.firstName) }}
    </p>
  </ng-container>
  <p [attr.data-test]="'desc-address1'">
    {{ renderEmptyAddressField(request.attributes.shippingAddress.address1) }}
  </p>
  <div class="delivery-address--inline">
    <ng-container *ngIf="!isAddressFieldEmpty(request.attributes.shippingAddress.zipCode)">
      <p [attr.data-test]="'desc-zipCode'">{{ renderEmptyAddressField(request.attributes.shippingAddress.zipCode) }}&nbsp;</p>
    </ng-container>
    <ng-container *ngIf="!isAddressFieldEmpty(request.attributes.shippingAddress.city)">
      <p [attr.data-test]="'desc-city'">{{ renderEmptyAddressField(request.attributes.shippingAddress.city) }}</p>
    </ng-container>
    <ng-container *ngIf="!isAddressFieldEmpty(request.attributes.shippingAddress.address2)">
      <p [attr.data-test]="'desc-address2'">, {{ renderEmptyAddressField(request.attributes.shippingAddress.address2) }}</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!isAddressFieldEmpty(request.attributes.shippingAddress.country)">
    <p [attr.data-test]="'desc-country'">{{ renderEmptyAddressField(request.attributes.shippingAddress.country) }}</p>
  </ng-container>
</div>
<div *ngIf="taxNumber" class="cart-tax-number">
    <h5 class="cart-tax-number__title">{{ 'request-details.tax-number' | translate }}</h5>
      <p [attr.data-test]="'desc-taxNumber'">{{ taxNumber }}</p>
</div>

<div class="cart-system-details">
  <ng-container *ngIf="request.attributes.systemDetails">
    <h5 class="cart-system-details__title" [attr.data-test]="'title-systemDetails'">
      {{ 'orders-page.system-details' | translate }}
    </h5>
    <div class="padding-styling">
      <div>
        <p [attr.data-test]="'label-selectedSystem'">{{ "orders-page.selected-system" | translate }}</p>
        <div class="cart-system-details-styling">
          <p *ngIf="request.attributes.systemDetails" [attr.data-test]="'desc-selectedSystem'">
            {{ request.attributes.systemDetails | selectedSystem | async }}
          </p>
        </div>
      </div>
      <div>
        <p [attr.data-test]="'label-companyDetails'">{{ "orders-page.company" | translate }}</p>
        <div class="cart-system-details-styling">
          <p *ngIf="request.attributes.systemDetails" [attr.data-test]="'desc-companyDetails'">
            {{ request.attributes.systemDetails | companyDetails }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="row">
  <div class="col-12 col-lg-6">
    <div
        class="product__wrapper"
        [ngClass]="{'in-progress': isItemInProgress(item.sku)}"
        *ngFor="let item of requestItems" [attr.data-test]="'request-item-' + item.name">
      <div class="product__image">
        <img width="130" [src]="item.metadata.image" [alt]="item.name"
             [attr.data-test]="'img-productImage'">
      </div>
      <div class="product__details">
        <h5 class="product__name" [attr.data-test]="'title-productName'">{{ item.name }}</h5>
        <div class="item-level-details">
          <ng-container *ngIf="item.systemDetails">
          <div>
            <span [attr.data-test]="'label-selectedSystem'">
              {{ 'orders-page.selected-system' | translate }}
            </span>
            <div class="item-system-details-styling">
              <span *ngIf="item.systemDetails" [attr.data-test]="'desc-selectedSystem'">
                {{ item.systemDetails | selectedSystem |async }}
              </span>
            </div>
          </div>
          <div>
            <span [attr.data-test]="'label-companyDetails'">
              {{ 'orders-page.company' | translate }}
            </span>
            <div class="item-system-details-styling">
              <span *ngIf="item.systemDetails" [attr.data-test]="'desc-companyDetails'">
                {{ item.systemDetails | companyDetails }}
              </span>
            </div>
          </div>
          </ng-container>
          <div class="product__qty">
            <span [attr.data-test]="'label-quantity'">{{ 'shop-cart.quantity' | translate }}: </span>
            <span [attr.data-test]="'desc-quantity'">{{ item.quantity }}</span>
          </div>
        </div>

        <div class="product__btn">
          <button
              class="button button--small button--primary buy-again-button"
              appDebounceClick
              [debounceTime]="300"
              [disabled]="isItemInProgress(item.sku)"
              (debounceClick)="addProductToCart(item.sku, item.name)"
              [attr.data-test]="'button-buyAgain'">
            <span class="buy-again__icon icon-replay"></span>
            {{ 'orders-page.request-again' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6 tracking-text">
    <a class="button button--link" [routerLink]="['/my-request/', request.id]">
      <span class="icon-arrow-right-small"></span> {{ 'orders-page.tracking-details' | translate }}
    </a>
  </div>
</div>
