import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ISelectBoxOption, ISelectEvent } from '../../../../models/common.models';
import { AdvancedFilter } from '../../../../models/advanced-filter';
import { purchaseActivityFilters } from '../../purchase-activity.filters';
import { AddressUtils } from '../../../../utils/address.utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigurationFacade } from '../../../../facades/configuration.facade';
import { StringUtils } from '../../../../utils/string.utils';
import { AppUtils } from '../../../../utils/app.utils';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrl: './advanced-search.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AdvancedSearchComponent implements OnInit {
  @Input() availableOrders: any[] = [];
  @Input() triggerClearAllFilters$: Subject<any>;
  @Output() closeAdvancedFiltersSection: EventEmitter<void> = new EventEmitter<void>();
  @Output() applyAdvancedFilters: EventEmitter<AdvancedFilter[]> = new EventEmitter<AdvancedFilter[]>();

  advancedFiltersForm: UntypedFormGroup;
  advancedFilters: AdvancedFilter[] = purchaseActivityFilters;
  selectBoxOptions: Array<{name: string, options: ISelectBoxOption[]}> = [
    {
      name: 'soldToAddress',
      options: [],
    },
    {
      name: 'shipToAddress',
      options: [],
    },
    {
      name: 'flNumber',
      options: [],
    },
    {
      name: 'orderStatus',
      options: [],
    },
  ];
  datepickerRange: any = {
    'orderDateFrom': '',
    'orderDateTo': '',
  };

  equipmentIdentification$: Observable<string> = this.configurationFacade.getEquipmentIdentification$();
  private unsubscribe$: Subject<void> = new Subject<void>();
  isSapStore: boolean = AppUtils.isSapStore();

  constructor(private formBuilder: FormBuilder, private configurationFacade: ConfigurationFacade) {}

  ngOnInit(): void {
    this._initializeForm();
    this._loadAdvancedFilter();
    this.triggerClearAllFilters$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this._loadAdvancedFilter();
      this.clearAndCloseAdvancedFilter();
    });
  }

  // *** Form operations ***

  /**
   * Initialize empty form when component is initialized and when filters are cleared.
   */
  private _initializeForm(): void {
    this.advancedFiltersForm = this.formBuilder.group({
      soldToAddress: '',
      shipToAddress: '',
      orderDateFrom: '',
      orderDateTo: '',
      orderStatus: '',
      flNumber: '',
      materialNumber: '',
      orderNumber: '',
      purchaseOrder: '',
      rmaNumber: '',
    });
  }

  /**
   * Updates the form with the selected filter value. Used for SelectBox and DatePicker components.
   * @param {ISelectEvent} event
   */
  setFormValue(event: ISelectEvent): void {
    this.advancedFiltersForm.patchValue({
      [event.key]: event.value,
    });
  }

  // *** SelectBox Options ***

  /**
   * Iterates over the options of the specified filter and formats them into an array of objects with name and value
   * properties.
   * Condition for orderStatus is necessary because key must be mapped with translation.
   * @param {string} filterName - The name of the filter for which to create select box options.
   * @returns {ISelectBoxOption[]} - An array of objects representing the select box options.
   */
  private _createSelectBoxOptions(filterName: string): ISelectBoxOption[] {
    const optionsForSelectBox: ISelectBoxOption[] = [];
    const filter = this.advancedFilters.find(item => item.name === filterName);

    if (filter) {
      filter.options.forEach(option => {
        const name = filter.name === 'orderStatus'
          ? `purchase-activity.order-status-${StringUtils.replaceSpaces(option, '-').toLowerCase()}`
          : option;
        optionsForSelectBox.push({ name, value: option });
      });
    }

    return optionsForSelectBox;
  }

  /**
   * Updates the select box options for each filter which is using SelectBox component.
   */
  private _loadSelectBoxOptions(): void {
    this.selectBoxOptions.forEach(selectBox => {
      selectBox.options = this._createSelectBoxOptions(selectBox.name);
    });
  }

  /**
   * Retrieves the options for a select box based on the provided select box name in HTML template.
   * @param {string} selectBoxName - The name of the select box for which to retrieve options.
   * @returns {ISelectBoxOption[]} - An array of objects representing the select box options.
   */
  getOptionsForSelectBox(selectBoxName: string): ISelectBoxOption[] {
    return this.selectBoxOptions.find(selectBox =>
      selectBox.name === selectBoxName,
    ).options;
  }

  /**
   * Checks if the select box options are empty so it will be disabled.
   * @param {string} selectBoxName
   * @returns {boolean}
   */
  isSelectBoxEmpty(selectBoxName: string): boolean {
    return this.getOptionsForSelectBox(selectBoxName).length === 0;
  }

  // *** Advanced filter operations ***

  /**
   * Iterates over each advanced filter and extracts relevant filter options from available orders/quotes/requests.
   * Removes duplicate and undefined values from the filter options.
   * Updates the select box options with the extracted filter options.
   * NOTE: setTimeout is used as a "zero-delay timeout/deferred function" technique, because filters were loaded
   * according previous content of the list while changing tabs.
   */
  private _loadAdvancedFilter(): void {
    setTimeout(() => {
      if (!this.availableOrders) {
        return;
      }
      this.advancedFilters.forEach((advancedFilter: AdvancedFilter) => {
        const filterOptions = this.availableOrders.map(order => {
          let filter: string[];
          if (advancedFilter.name === 'shipToAddress' || advancedFilter.name === 'soldToAddress') {
            filter = [AddressUtils.formatAddressToString(order?.attributes?.[advancedFilter.attribute])];
          } else if (advancedFilter.name === 'flNumber') {
            filter =  order?.attributes?.[advancedFilter.attribute]?.[advancedFilter.firstAdditionalAttribute] ??
              order?.attributes?.['equipment'];
            filter = [filter?.toString()];
          } else {
            filter = advancedFilter.firstAdditionalAttribute ?
              order?.attributes?.[advancedFilter.attribute]?.[advancedFilter.firstAdditionalAttribute] :
              order?.attributes?.[advancedFilter.attribute];
            filter = [filter?.toString()];
          }
          return filter && filter[0] !== '' && filter[0] !== 'empty' ? filter : undefined;
        }).reduce((acc: string[], val: string[]) => acc.concat(val), []);
        advancedFilter.options = [...new Set(filterOptions)].filter(item => item !== undefined);
      });
      this._loadSelectBoxOptions();
    });
  }

  /**
   * Clear all Advanced filter fields, reset filtered options to default state when user clicks on "Clear all" button.
   */
  resetFilters(): void {
    this._initializeForm();
    this.applySelectedFiltersOptions();
  }

  /**
   * Close Advanced filter when user clicks on "Cancel" button.
   */
  clearAndCloseAdvancedFilter(): void {
    this.closeAdvancedFiltersSection.emit();
  }

  /**
   * Applies the selected filter options from the advanced filters form.
   * Iterates over each advanced filter, updates the selected values based on the form input,
   * and emits the updated filters to parent.
   * Also handles date range filters by calculating the options from the date picker.
   */
  applySelectedFiltersOptions(): void {
    this.advancedFilters = this.advancedFilters.map((filter: AdvancedFilter) => {
      if (filter.name.includes('orderDate')) {
        this.datepickerRange[filter.name] = this.advancedFiltersForm.value[filter.name];
      }

      if (this.advancedFiltersForm.value[filter.name]) {
        filter.selected = filter.name.includes('orderDate')
          ? this._calculateOptionsFromDatepicker(filter) : [this.advancedFiltersForm.value[filter.name]];
      } else {
        filter.selected = [];
      }

      this.advancedFiltersForm.patchValue({
        [filter.name]: filter.name.includes('orderDate') ?
          this.datepickerRange[filter.name] : (filter.selected[0] || ''),
      });

      return filter;
    });
    this.applyAdvancedFilters.emit(this.advancedFilters);
    this.closeAdvancedFiltersSection.emit();
  }

  /**
   * Filters the options of a date picker based on the selected date range.
   * @param {AdvancedFilter} filter - The filter object containing the options and the selected date range.
   * @returns {string[]} - An array of filtered date options or ['no-match'] if no options match the criteria.
   */
  private _calculateOptionsFromDatepicker(filter: AdvancedFilter): string[] {
    const dateValue = this.advancedFiltersForm.value[filter.name];
    const isOrderDateFrom = filter.name === 'orderDateFrom';

    const dateOptions: string[] = filter.options.filter(option => {
      const optionDate = new Date(option);
      if (isOrderDateFrom) {
        return new Date(dateValue) <= optionDate;
      } else {
        const endOfDay = new Date(dateValue);
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay >= optionDate;
      }
    });

    return dateOptions.length !== 0 ? dateOptions : ['no-match'];
  }
}
