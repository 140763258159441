import { ActionReducerMap } from '@ngrx/store';
import { CatalogReducer, CatalogState } from './catalog.reducer';
import { ShopCartReducer, ShopCartState } from './shop-cart.reducer';
import { CheckoutReducers, CheckoutState } from './checkout.reducer';
import { AppReducer, AppState } from './app.reducer';
import { OrderApproveReducer, OrderApproveState } from './order-approve.reducer';
import { CustomerReducer, CustomerState } from './customer.reducer';
import { IInstallBaseState, InstallBaseReducer } from './install-base.reducer';
import { WishlistReducer, WishlistState } from './wishlist.reducer';
import { StoreConfigurationReducer, StoreConfigurationState } from './store-configuration.reducer';
import { CpqReducer, CpqState } from './cpq.reducer';
import { SparePartReducer, SparePartState } from './spare-part.reducer';
import {
  CpqOrdersReducer,
  ICpqOrdersState,
  IOrdersCartsState,
  IOrdersWithSapDetailsState, OrdersCartReducer,
  OrdersReducer,
} from './orders.reducer';
import { HybrisOrdersReducer, HybrisOrdersState } from './hybris-orders.reducer';
import { HomepageShopCategoryReducer, IHomepageShopCategoryState } from './homepage.reducer';
import { IPurchaseActivityState, PurchaseActivityReducer } from './purchase-activity.reducer';

export const checkoutFeatureKey = 'checkout';

export interface State {
  catalog: CatalogState;
  shopCart: ShopCartState;
  checkout: CheckoutState;
  orderApprove: OrderApproveState;
  customer: CustomerState;
  app: AppState;
  wishlist: WishlistState;
  storeConfiguration: StoreConfigurationState;
  cpq: CpqState;
  cpqOrders: ICpqOrdersState;
  sparePart: SparePartState;
  installBase: IInstallBaseState;
  ordersWithDetails: IOrdersWithSapDetailsState;
  hybrisOrders: HybrisOrdersState;
  ordersCarts: IOrdersCartsState;
  homepageShopCategory: IHomepageShopCategoryState;
  purchaseActivity: IPurchaseActivityState;
}

export const reducers: ActionReducerMap<State> = {
  catalog: CatalogReducer,
  shopCart: ShopCartReducer,
  checkout: CheckoutReducers,
  app: AppReducer,
  orderApprove: OrderApproveReducer,
  customer: CustomerReducer,
  wishlist: WishlistReducer,
  storeConfiguration: StoreConfigurationReducer,
  cpq: CpqReducer,
  cpqOrders: CpqOrdersReducer,
  sparePart: SparePartReducer,
  installBase: InstallBaseReducer,
  ordersWithDetails: OrdersReducer,
  hybrisOrders: HybrisOrdersReducer,
  ordersCarts: OrdersCartReducer,
  homepageShopCategory: HomepageShopCategoryReducer,
  purchaseActivity: PurchaseActivityReducer,
};
