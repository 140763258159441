@if (orderDataLoading) {
  <div class="loading-animation"></div>
} @else {
  <div class="page-top">
    <div class="page-top__left">
      <div class="page-top__left--header">
        <h4 [attr.data-test]="'title-thankYou'">{{ 'order-thank-you-page.title' | translate }}</h4>
        <p class="description" [attr.data-test]="'title-yourOrderHasBeenReceived'">
          @if (isPersonalOrder()) {
            {{
                'order-thank-you-page.description-personal-success' | translate: {
                  orderNumber: orderReference | removeLeadingZero
                }
            }}
          } @else {
            {{
                'order-thank-you-page.description-approval-success' | translate:{
                  name: getBuyerFullName(),
                  orderNumber: orderReference | removeLeadingZero
                }
            }}
          }
        </p>
      </div>
      <app-sap-message-controller *ngIf="isSapP40Enabled" [messages]="orderData?.sapMessages"/>
    </div>
    <div class="page-top__right">
      <div class="page-top__right--header">
        <h4 [attr.data-test]="'title-haveQuestions'">
          {{ 'order-thank-you-page.question.title' | translate }}
        </h4>
        <p class="description" [attr.data-test]="'desc-haveQuestions'">
          {{ 'order-thank-you-page.question.description' | translate }}
        </p>
      </div>
      <div class="page-top__right--footer">
        <div *ngIf="'order-thank-you-page.question.phone' | translate" class="contact">
          <span class="icon-phone"></span>
          <a href="tel:{{ 'order-thank-you-page.question.phone' | translate }}"
             [attr.data-test]="'desc-contactPhone'">
            {{ 'order-thank-you-page.question.phone' | translate }}
          </a>
        </div>
        <div *ngIf="'order-thank-you-page.question.email' | translate" class="contact">
          <span class="icon-email"></span>
          <a href="mailto:{{ 'order-thank-you-page.question.email' | translate }}"
             [attr.data-test]="'desc-contactEmail'">
            {{ 'order-thank-you-page.question.email' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="page-middle">
    <h5>{{ 'order-thank-you-page.ordered-items' | translate }}</h5>
    <div class="page-middle__container">
      <app-accordion [manualOpen]="isAccordionExpanded">
        <ng-container ngProjectAs="[slot][header]">
          <div class="accordion__header accordion__title icon-arrow-down"
               (click)="isAccordionExpanded = !isAccordionExpanded"
               [attr.data-test]="'desc-toggleOrderedItemsAccordion'">
            <div class="column">
              <span class="column__label" [attr.data-test]="'label-orderId'">
                {{ 'order-thank-you-page.order' | translate }}
              </span>
              <span [attr.data-test]="'desc-orderId'">{{ orderReference | removeLeadingZero }}</span>
            </div>
            <div class="column">
              <span class="column__label" [attr.data-test]="'label-orderedOn'">
                {{ 'order-thank-you-page.ordered-on' | translate }}
              </span>
              <span [attr.data-test]="'desc-orderedOn'">
                {{ orderData?.createdAt | formatDate }}
              </span>
            </div>
            <div class="column">
              <span class="column__label" [attr.data-test]="'label-totalQuantity'">
                {{ 'order-thank-you-page.total-quantity' | translate }}
              </span>
              <span [attr.data-test]="'desc-totalQuantity'">
                {{ orderData?.itemsTotalNumber }} {{ getTotalItemTranslation(orderData?.itemsTotalNumber) }}
              </span>
            </div>
            <div class="column column__right">
              <span class="column__label" [attr.data-test]="'label-shippingAndHandlingCost'">
                {{ 'order-thank-you-page.shipping-handling-cost' | translate }}
              </span>
              <span [attr.data-test]="'desc-shippingAndHandlingCost'">
                {{ orderData?.shipments?.[0]?.defaultGrossPrice | currency: orderData?.currencyIsoCode }}
              </span>
            </div>
            <div class="column column__right">
              <span class="column__label" [attr.data-test]="'label-totalSum'">
                {{ 'order-thank-you-page.total-sum' | translate }}
              </span>
              <span [attr.data-test]="'desc-totalSum'">
                {{ orderData?.totals?.grandTotal | currency: orderData?.currencyIsoCode }}
              </span>
            </div>
          </div>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <div class="container accordion__content">
            <div class="items">
              @for (item of orderData?.items; track item) {
                <div class="item" [attr.data-test]="'order-item-' + item?.name">
                  <img class="item__image" src="{{ item?.metadata?.image }}" alt="n/a"
                       [attr.data-test]="'image-item-img'">
                  <div class="item__content">
                    <div class="item__header">
                      <div class="item__title">
                        <div class="item__tags">
                            <span *ngIf="isDiscontinuedStatusEnabled && item?.isDiscontinued"
                                  class="label discontinued-item"
                                  [attr.data-test]="'desc-flag-discontinued'">
                                    {{ 'order-thank-you-page.discontinued-item' | translate }}
                                  </span>
                          <span *ngIf="item?.sapDetails?.exchangeableItem"
                                class="label exchangeable-item"
                                [attr.data-test]="'desc-flag-exchangeable'">
                                    {{ 'order-thank-you-page.exchangeable-item' | translate }}
                                  </span>
                          <span *ngIf="item?.heavyweight"
                                class="label heavyweight-item"
                                [attr.data-test]="'desc-flag-heavyweight'">
                                    {{ 'order-thank-you-page.heavyweight-item' | translate }}
                            </span>
                        </div>
                        <h6 class="item--name" [attr.data-test]="'desc-item-name'">{{ item?.name }}</h6>
                      </div>
                      <p [attr.data-test]="'desc-item-sku'">{{ item?.sku }}</p>
                    </div>
                    <div class="item__details">
                      <div>
                        <p [attr.data-test]="'label-item-quantity'">
                          {{ 'order-thank-you-page.quantity' | translate }}
                        </p>
                        <p [attr.data-test]="'label-item-quantity'">
                          {{ 'order-thank-you-page.unit-price' | translate }}
                        </p>
                        <p class="item__details--price" [attr.data-test]="'label-item-totalPrice'">
                          {{ 'order-thank-you-page.total-price' | translate }}
                        </p>
                      </div>
                      <div class="text-align-right">
                        <p [attr.data-test]="'desc-item-quantity'">{{ item?.quantity }}</p>
                        <p [attr.data-test]="'desc-item-unitPrice'">
                          {{ item?.unitPrice | currency: orderData?.currencyIsoCode }}
                        </p>
                        <p class="item__details--price" [attr.data-test]="'desc-item-totalPrice'">
                          {{ item?.sumPrice | currency: orderData?.currencyIsoCode }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <app-advanced-product-information
                      *ngIf="item?.materialMasterNumbers"
                      [orderItem]="item"
                      [isEditingEnabled]="false">
                  </app-advanced-product-information>
                </div>
              }
            </div>
            <div class="fields">
              @for (field of pageFields; track field) {
                @switch (field) {
                  @case (EThankYouPageFieldNames.FUNCTIONAL_LOCATION) {
                    <div class="field">
                      <p class="field__title" [attr.data-test]="'label-functionalLocation'">
                        {{ 'order-thank-you-page.functional-location' | translate }}
                      </p>
                      <p [attr.data-test]="'desc-functionalLocation'">
                        {{ orderData?.systemDetails?.siemensEquipmentId || '-' }}
                      </p>
                    </div>
                  }
                  @case (getAddressFieldOnly(field)) {
                    <div class="field">
                      <p class="field__title" [attr.data-test]="'label-' + field">
                        {{ 'order-thank-you-page.' + StringUtils.camelToKebab(field) | translate }}
                      </p>
                      <app-address-block
                          [address]="orderData?.[AddressUtils.getAddressFieldName(field)]"
                          [attentionTo]="field === EThankYouPageFieldNames.SHIP_TO_ADDRESS? orderData?.pointOfContact?.attentionTo : ''"
                          [attr.data-test]="'desc-' + field">
                      </app-address-block>
                    </div>
                  }
                  @case (EThankYouPageFieldNames.SHIPMENT_METHOD) {
                    <div class="field">
                      <p class="field__title" [attr.data-test]="'label-shipmentMethod'">
                        {{ 'order-thank-you-page.shipment-method' | translate }}
                      </p>
                      <p [attr.data-test]="'desc-shipmentMethod'">
                        {{ orderData?.shipments?.[0]?.shipmentMethodName || '-' }}
                      </p>
                    </div>
                  }
                  @case (EThankYouPageFieldNames.PAYMENT_METHOD) {
                    <div class="field">
                      <p class="field__title" [attr.data-test]="'label-paymentMethod'">
                        {{ 'order-thank-you-page.payment-method' | translate }}
                      </p>
                      <p [attr.data-test]="'desc-paymentMethod'">
                        {{ OrdersUtils.getPaymentTranslationKey(orderData?.payments?.[0]?.paymentProvider) | translate }}
                      </p>
                    </div>
                  }
                  @case (EThankYouPageFieldNames.PO_NUMBER) {
                    <div class="field">
                      <p class="field__title" [attr.data-test]="'label-purchaseOrder'">
                        {{ 'order-thank-you-page.purchase-order' | translate }}
                      </p>
                      <p [attr.data-test]="'desc-purchaseOrder'">{{ orderData?.sapPoNumber || '-' }}</p>
                    </div>
                  }
                  @case (EThankYouPageFieldNames.RETURN_ORDER_NUMBER) {
                    @if (isEligibleForReturn) {
                      <div class="field">
                        <p class="field__title" [attr.data-test]="'label-returnOrderNumber'">
                          {{ 'order-thank-you-page.return-order-no' | translate }}
                        </p>
                        <p [attr.data-test]="'desc-returnOrderNumber'">
                          {{ orderData?.sapDetails?.returnAuthorizationNumber }}
                        </p>
                        <div class="return-document-buttons">
                          <div *ngIf="isDownloadReturnDocumentEnabled" class="return-document-button">
                            <a (click)="downloadReturnDocument()" [attr.data-test]="'link-downloadReturnDocument'">
                              <span class="icon-arrow-right"></span>
                              <span>{{ 'order-thank-you-page.download-return-document' | translate }}</span>
                            </a>
                          </div>
                          <div *ngIf="isSubmitReturnDocumentEnabled" class="return-document-button">
                            <a [attr.data-test]="'link-submitReturnDocument'"
                               href="mailto: {{ getSubmitReturnDocumentEmailLink()}}">
                              <span class="icon-arrow-right"></span>
                              <span>{{ 'order-thank-you-page.submit-return-document' | translate }}</span>
                            </a>
                            <p class="return-document-button__label" [attr.data-test]="'desc-submitReturnDocument'">
                              {{ 'order-thank-you-page.submit-return-document-msg' | translate }}
                            </p>
                          </div>
                        </div>
                      </div>
                    }
                  }
                }
              }
            </div>
          </div>
        </ng-container>
      </app-accordion>
    </div>
  </div>
  <div class="page-bottom">
    <button type="button"
            class="button button--secondary"
            [routerLink]="['/catalog/parts']"
            [attr.data-test]="'button-continueShopping'">
      {{ 'order-thank-you-page.continue-shopping' | translate }}
    </button>
    <button type="submit"
            class="button button--primary "
            [routerLink]="['/purchase-activity']"
            [attr.data-test]="'button-viewPurchaseActivity'">
      {{ 'order-thank-you-page.view-purchase-activity' | translate }}
    </button>
  </div>
}
