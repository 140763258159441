import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { FileType, FileUtils } from '../../utils/file.utils';

import { State } from '../../reducers';
import { I18nService } from '../../services';
import { AnalyticsService } from '../../analytics/analytics.service';
import * as OrderApproveActions from '../../actions/order-approve.actions';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { OrdersFacade } from '../../facades/orders.facade';
import { ImageUtils } from '../../utils/image.utils';
import { TermConstants } from '../../shared/terms/TermConstants';
import { LocalStorageUtils } from '../../utils/localStorage.utils';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { ICart, ICartItemWithDetail, ICartRule } from '../../models/cart.models';
import {
  ICheckoutInput,
  ICustomerCheckout,
  IPaymentMethodName,
  IPaymentProviderName,
  IPaymentSelection,
  IShipmentMethod,
} from '../../models/checkout.models';
import { ISapMessage } from '../../models/sap.model';
import { IAddress, IMessage, IPointOfContact, IPriceDisputingPerItem, ISelectEvent } from '../../models/common.models';
import { CartUtils } from '../../utils/cart.utils';
import { DeliveryInstructionsModalComponent } from '../../shared/delivery-instructions-modal/delivery-instructions-modal.component';
import * as ValidationPatterns from '../../configurations/validations';
import { AddressUtils } from '../../utils/address.utils';
import { EAddressType, EFeatureToggles, EMessageType, EStoreTypes, EUserRoles } from '../../configurations/common';
import { AppUtils } from '../../utils/app.utils';
import { CheckoutActions, CustomerActions, ShopCartActions } from '../../actions';
import { IconType } from '../../models/settings.model';
import { environment } from '../../../environments/environment.defaults';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-spare-part-order-review',
  templateUrl: './spare-part-order-review.component.html',
  styleUrls: ['./spare-part-order-review.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SparePartOrderReviewComponent implements OnInit, OnDestroy {
  @ViewChild(DeliveryInstructionsModalComponent) pocModal: DeliveryInstructionsModalComponent;

  loadingDataInProgress: boolean;
  checkoutDataCallInProgress: boolean = false;
  addressAndPocDataLoaded: boolean = false;
  showModalDeliveryInstructions: boolean = false;
  showModalReportWrongAddress: boolean = false;
  hasErrorMessages: boolean = true;
  cartDataLoaded: boolean = false;
  cartItemsDataLoaded: boolean = false;
  isExportCartActive$: Observable<boolean> = new Observable<boolean>();

  cartSapMessages: ISapMessage[] = [];
  cartId: string;
  currentCart: ICart;
  cartItemsWithDetails: Array<ICartItemWithDetail>;
  cartRules: Array<ICartRule>;
  taxDisclaimer: number;
  selectedShipmentMethod: IShipmentMethod;

  EAddressType = EAddressType;
  addressType: string;
  addressLabelName: string;
  addressesToReport: IAddress[];
  shipToAddress: IAddress;
  billToAddress: IAddress;
  billToAddressString: string;
  isBillToAddressValid: boolean;
  soldToAddress: IAddress;
  soldToAddressString: string;
  isSoldToAddressValid: boolean;
  emptyPointOfContact: IPointOfContact;
  fileGenerationInProgress: boolean = false;
  orderReviewForm: UntypedFormGroup;
  loggedUserRoles: EUserRoles[];
  pointOfContact: IPointOfContact;
  isUnavailableShipmentMethodModalOpen: boolean = false;
  cacheSapPoNumberAndCopyEmail: boolean = true;
  iconType = IconType;
  isBusinessPartnerRole: boolean = false;
  itemDisputedPricingMessage: IMessage = {
    type: EMessageType.WARNING,
    title: 'cart.disputed-pricing.title',
    description: 'cart.disputed-pricing.description',
  };

  termsAndConditionsUrl: {value: string, type: string} = {
    value: '', type: 'internal',
  };
  isPriceDisputingActive: boolean = false;
  priceDisputingPerItem: IPriceDisputingPerItem[] = [];
  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();

  private unsubscribe$ = new Subject<void>();

  constructor(
    private checkoutFacade: CheckoutFacade,
    private store: Store<State>,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private ordersFacade: OrdersFacade,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
    private i18nService: I18nService,
  ) {
  }

  ngOnInit(): void {
    this.isExportCartActive$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.CHECKOUT_SIDEBAR_CART_EXPORT_AS_PDF);
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
    this.route.params.subscribe(params => {
      if (params.orderId) {
        this.cartId = params.orderId;
      }
    });
    this.initializeForm();
    this.loadSapMessages();
    this.setupCachedData();
    this.selectCartItemsSubscription();
    this.selectCartItemsLoadFailSubscription();
    this.getCurrentCartData();
    this.selectCustomerCompanyRoles();
    this.selectIsBPRole();
    this.selectIsPriceDisputingActive();
    this.selectPriceDisputingPerItem();

    this.termsAndConditionsUrl = this.configurationFacade.getTermsUrl(
      TermConstants.termKeys.TERMS_AND_CONDITIONS.termsKey,
    );
  }

  ngOnDestroy(): void {
    if (this.cacheSapPoNumberAndCopyEmail) {
      this.checkoutFacade.setSapPoNumber(this.orderReviewForm.value.purchaseOrderNumber);
      this.checkoutFacade.setOrderEmailCopy(this.orderReviewForm.value.orderEmailCopy);
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @returns {boolean}
   */
  isSaturdayShipmentSelected(): boolean {
    return this.selectedShipmentMethod?.name === environment.sparePartSaturdayDeliveryKey;
  }

  showUnavailableShipmentMethodModal(): void {
    this.isUnavailableShipmentMethodModalOpen = true;
  }

  closeUnavailableShipmentMethodModal(): void {
    this.isUnavailableShipmentMethodModalOpen = false;
    this.redirectToDeliveryDetails();
  }

  /**
   * Checks if sapMessages array contains message with type 'E'.
   *
   * @param {ISapMessage[]} sapMessages
   */
  checkHasNoErrorMessages(sapMessages: ISapMessage[]): void {
    this.hasErrorMessages = false;
    sapMessages.forEach(message => {
      if (message.type === 'E') {
        this.hasErrorMessages = true;
      }
    });
  }

  /**
   * @returns {boolean}
   */
  areAllDataLoaded(): boolean {
    return this.cartDataLoaded && this.cartItemsDataLoaded && this.addressAndPocDataLoaded && !this.checkoutDataCallInProgress;
  }

  /**
   * Set addressesToReport according to addressType value.
   *
   * @param {string} labelName
   * @param {string} addressType
   */
  showReportWrongAddressModal(labelName: string, addressType: string): void {
    this.showModalReportWrongAddress = true;
    this.addressLabelName = labelName;
    this.addressType = addressType;

    if (addressType === EAddressType.SOLD_TO) {
      this.addressesToReport = [this.soldToAddress as IAddress];
    } else if (addressType === EAddressType.BILL_TO) {
      this.addressesToReport = [this.billToAddress as IAddress];
    }
  }

  redirectToDeliveryDetails(): void {
    let params: {};
    if (this.shipToAddress.id) {
      params = {
        'addressId': this.shipToAddress.id,
      };
    } else {
      params = {
        'addressId': this.shipToAddress.sapId,
      };
    }

    this.router.navigate(['/sap-delivery-details'],
      {
        queryParams: params,
      }).then();
  }

  showModalToDeliveryInstructions(): void {
    this.pocModal.initializeForm();
    this.showModalDeliveryInstructions = !this.showModalDeliveryInstructions;
  }

  getProductImageUrl(item: any): string {
    return ImageUtils.getProductImageUrl(item);
  }

  /**
   * @returns {string}
   */
  getTaxDisclaimerLabel(): string {
    return this.taxDisclaimer ? `order-approve.tax-disclaimer-${this.taxDisclaimer}` : '';
  }

  /**
   * @param {boolean} isAvailable
   * @returns {string}
   */
  getAvailabilityIcon(isAvailable: boolean): string {
    return isAvailable ? 'icon-check icon-check__circle' : 'icon-exclamationmark icon-exclamationmark__circle';
  }

  /**
   *
   * @param {ICheckoutInput} checkoutInputRequest
   * @private
   */
  private postCheckout(checkoutInputRequest: ICheckoutInput): void {
    this.checkoutFacade.postCheckout({data: checkoutInputRequest})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: checkout => {
          if (checkout.data.id !== this.cartId) {
            this.analyticsService.setProducts(this.cartItemsWithDetails);
            this.analyticsService.trackCart('cart.order');
            this.marketingFacade.createEmptyCart();
            this.customerFacade.setPointOfContact(this.emptyPointOfContact);
            this.router.navigate(['/order-thank-you-page/', checkout.data.id]);
          } else {
            this.marketingFacade.createEmptyCart();
            this.customerFacade.setPointOfContact(this.emptyPointOfContact);
            this.showNotificationAndRedirectToHomePage();
          }
        },
        error: error => {
          this.checkoutDataCallInProgress = false;
          if (error instanceof HttpErrorResponse && error.status === 504) {
            this.marketingFacade.deleteCart(this.cartId);
            this.showNotificationAndRedirectToHomePage();
          }
          if ((error?.error?.errors as any[]).find(error => error.detail === 'shipment.validation.expired')) {
            this.showUnavailableShipmentMethodModal();
          } else {
            this.showNotificationAndRedirectToHomePage();
          }
        },
      });
  }


  /**
   * Called after submitting order. After receiving price of shipment method via getShipmentMethodPrice() function of
   * CheckoutFacade, checkoutInputRequest is created and passed to postCheckout() function of CheckoutFacade.
   */
  proceedToSummaryPage(): void {
    this.checkoutDataCallInProgress = true;

    let customerData: ICustomerCheckout = {
      'attentionTo': null,
      'email': null,
      'salutation': null,
      'firstName': null,
      'lastName': null,
      'idCustomer': 0,
      'customerReference': null,
      'uuidCompanyUser': null,
    };

    this.checkoutFacade.getShipmentMethodPrice(this.cartId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(shipmentData => {
        const checkoutInputRequest: ICheckoutInput = {
          type: 'checkout',
          attributes: {
            idCart: this.cartId,
            isAddressSavingSkipped: true,
            payments: [
              {
                paymentSelection: IPaymentSelection.INVOICE,
                paymentMethodName: IPaymentMethodName.INVOICE,
                paymentProviderName: IPaymentProviderName.INVOICE,
              },
            ],
            shipment: {
              idShipmentMethod: this.selectedShipmentMethod.id,
            },
            termsAccessTime: new Date(),
            consentedTerms: [
              'privacy_policy',
              'tax_disclaimer',
              'terms_and_conditions',
              'signed_contract_sent',
              'purchase_order_not_required',
            ],
            customer: shipmentData?.data?.attributes?.customer as ICustomerCheckout ? shipmentData?.data?.attributes?.customer : customerData,
            sapPoNumber: this.orderReviewForm.value.purchaseOrderNumber,
            orderEmailCopy: this.orderReviewForm.value.orderEmailCopy || undefined,
            shippingAddress: this.shipToAddress,
          },
        };
        this.postCheckout(checkoutInputRequest);
        this.clearDeliveryPagesData();
        LocalStorageUtils.clearKey('opal');
      });
  }

  clearDeliveryPagesData(): void {
    this.store.dispatch(CustomerActions.clearCustomShipToAddress());
    this.store.dispatch(CustomerActions.clearPointOfContact());
    this.store.dispatch(ShopCartActions.clearPriceDisputing());
    this.store.dispatch(ShopCartActions.clearMaterialMasterNumbersForCart({cartId: this.cartId}));
    this.store.dispatch(CheckoutActions.clearPurchaseOrderNumber());
    this.store.dispatch(CheckoutActions.clearOrderEmailCopy());
    this.cacheSapPoNumberAndCopyEmail = false;
  }

  showNotificationAndRedirectToHomePage() {
    this.configurationFacade.appendNotification({
      type: 'warning',
      title: 'error-404.title-something-is-wrong',
      messages: [{
        key: 'error-404.something-is-wrong',
      }],
    });

    this.router.navigate([this.i18nService.getCurrentLocale()]);
  }

  getCurrentCartData(): void {
    this.loadingDataInProgress = true;

    this.marketingFacade.getCartShipments(this.cartId).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartData => {
      if (cartData?.data) {
        this.shipToAddress = cartData.data.attributes?.shippingAddress;
        this.store.dispatch(OrderApproveActions.setShippingAddress({address: this.shipToAddress}));
        if (cartData.data?.attributes?.pointOfContact !== null) {
          this.pointOfContact = cartData.data?.attributes?.pointOfContact;
          this.pocModal.pocData = this.pointOfContact;
          this.pocModal.initializeForm();
        }
        if (cartData?.included) {
          const shipmentMethod = cartData?.included?.filter(include => include.type === 'shipments')[0];
          this.selectedShipmentMethod = shipmentMethod.attributes?.selectedShipmentMethod;
        }

        this.addressAndPocDataLoaded = true;
        this.loadingDataInProgress = false;
      }
    });
  }

  setQueryParams(): Params {
    if (this.shipToAddress.id) {
      return {
        'addressId': this.shipToAddress.id,
      };
    }
    return {
      'addressId': this.shipToAddress.sapId,
    };
  }

  /**
   * @param {ISelectEvent} event
   */
  setFormValue(event: ISelectEvent): void {
    this.orderReviewForm.patchValue({
      [event.key]: event.value,
    });
  }

  /**
   * @returns {boolean}
   */
  formIsValid(): boolean {
    return !this.hasErrorMessages && this.isBillToAddressValid && this.isSoldToAddressValid && this.orderReviewForm.status === 'VALID';
  }

  generatePdfFile(): void {
    this.fileGenerationInProgress = true;
    this.checkoutFacade.getCartDetailPdfFile(this.currentCart.id).pipe(take(1))
      .subscribe((response: HttpResponse<Blob>) => {
        const contentDispositionHeader = response.headers.get('Content-Disposition');
        const filename = FileUtils.extractFilenameFromContentDisposition(contentDispositionHeader, 'Cart_details_' + this.currentCart.id);
        FileUtils.saveAndOpenFile(response.body, FileType.PDF, filename);
        this.fileGenerationInProgress = false;
      });
  }

  /**
   * Determine if price disputing is set for given item.
   *
   * @param {string} itemId
   * @return {boolean}
   */
  isPriceDisputingSetForItem(itemId: string): boolean {
    return this.priceDisputingPerItem?.find(itemPriceDisputing => {
      return itemPriceDisputing.itemId === itemId;
    })?.priceDisputing?.isSet;
  }

  private loadSapMessages(): void {
    this.marketingFacade.selectSapMessages().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(sapMessages => {
      this.cartSapMessages = [...sapMessages];
      this.checkHasNoErrorMessages(this.cartSapMessages);
    });
  }

  private initializeForm(): void {
    this.orderReviewForm = this.formBuilder.group({
      paymentMethod: ['Invoice to Hospital', [Validators.required]],
      purchaseOrderNumber: ['', [Validators.required, ValidationPatterns.noEmptySpaceOnTheBeginning]],
      termsAndConditionsConsent: [false, [Validators.requiredTrue]],
      taxConsent: [false, [Validators.requiredTrue]],
      orderEmailCopy: [undefined, [Validators.email]],
    });
    if (this.isCanadaStore()) {
      this.orderReviewForm.addControl(
        'poConsent',
        new UntypedFormControl(false, Validators.requiredTrue),
      );
    }
  }

  /**
   * Checks if total price to pay of all items including shipping is zero.
   *
   * @returns {boolean}
   */
  isTotalPriceToPayZero(): boolean {
    return this.currentCart?.attributes.totals.priceToPay === 0;
  }

  /**
   * Get cart, items with details and cart rules. When user has business partner role - adjust soldToAddressString and
   * billToAddressString values with sapId. When total price of cart items including shipping is 0, then set
   * purchaseOrderNumber as order-approve.default-purchase-order-number. In the end, get checkout data based on
   * cartId.
   */
  selectCartItemsSubscription(): void {
    combineLatest([
      this.marketingFacade.selectCart(),
      this.marketingFacade.selectCartItemsWithDetails(),
      this.marketingFacade.selectCartRules(),
      this.marketingFacade.getCurrentCartItems(),
    ]).pipe(takeUntil(this.unsubscribe$),
      map(([cart, cartItemsWithDetails, cartRules, cartItems]) => (
        {cart, cartItemsWithDetails, cartRules, cartItems}),
      )).pipe(debounceTime(0)).subscribe(cartData => {
      if (cartData) {
        this.currentCart = cartData.cart;
        this.cartRules = cartData.cartRules;
        this.taxDisclaimer = this.currentCart?.attributes?.taxDisclaimer;
        this.cartItemsWithDetails = CartUtils.mapAvailabilitiesToCartItems(
          cartData.cartItemsWithDetails, cartData.cartItems?.included,
        );

        this.soldToAddress = this.currentCart?.attributes?.soldToAddress;
        this.soldToAddressString =
          (this.isBusinessPartnerRole ? (this.soldToAddress?.sapId + ' - ') : '') +
          AddressUtils.formatAddressToString(this.soldToAddress);
        this.isSoldToAddressValid = AddressUtils.isAddressValid(this.soldToAddress);

        this.billToAddress = this.currentCart?.attributes?.billingAddress;
        this.billToAddressString =
          (this.isBusinessPartnerRole ? (this.billToAddress?.sapId + ' - ') : '') +
          AddressUtils.formatAddressToString(this.billToAddress);
        this.isBillToAddressValid = AddressUtils.isAddressValid(this.billToAddress);

        this.cartDataLoaded = true;
        this.cartItemsDataLoaded = true;
        if (this.isTotalPriceToPayZero()) {
          this.configurationFacade.getTranslationByKey('order-approve.default-purchase-order-number')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(translation => {
              this.orderReviewForm.patchValue({'purchaseOrderNumber': translation});
            });
        }
      }
    });

    this.marketingFacade.refreshCart(this.cartId);

    this.analyticsService.setCartId(this.cartId);
    this.analyticsService.setProducts(this.currentCart);
    this.analyticsService.trackPageReady('spare part - order review', PageTypes.ORDER_REVIEW, 'concrete-products');
  }

  private selectCartItemsLoadFailSubscription(): void {
    this.checkoutFacade.selectCartItemsLoadFail().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartItemsLoadFail => {
      if (cartItemsLoadFail) {
        this.router.navigate([this.i18nService.getCurrentLocale()]).then();
      }
    });
  }

  private selectCustomerCompanyRoles(): void {
    this.customerFacade.selectCustomerCompanyRoles().pipe(
      take(1),
    ).subscribe(userRoles => {
      this.loggedUserRoles = userRoles;
    });
  }

  /**
   * Select if price disputing functionality is active (based on the company roles).
   *
   * @private
   */
  private selectIsPriceDisputingActive(): void {
    this.customerFacade.isPriceDisputingActive().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isPriceDisputingActive => {
      this.isPriceDisputingActive = isPriceDisputingActive;
    });
  }

  /**
   * Select price disputing as array of all items
   * which had price disputing set (from ShopCart state).
   *
   * @private
   *
   */
  private selectPriceDisputingPerItem(): void {
    this.marketingFacade.selectPriceDisputing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(priceDisputingPerItem => {
        this.priceDisputingPerItem = priceDisputingPerItem;
      });
  }

  getCurrentStore(): string {
    return AppUtils.getCurrentStore().storeId;
  }

  isCanadaStore(): boolean {
    return this.getCurrentStore() === EStoreTypes.CA;
  }

  /**
   * this method is used for getting information about the current user has a BP role
   * @private
   */
  private selectIsBPRole(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBpRole => {
      this.isBusinessPartnerRole = isBpRole;
    });
  }

  private setupCachedData(): void {
    this.checkoutFacade.selectSapPoNumber().pipe(take(1))
      .subscribe(state => {
        this.orderReviewForm.patchValue({
          purchaseOrderNumber: state,
        });
      });
    this.checkoutFacade.selectOrderCopyEmail().pipe(take(1))
      .subscribe(state => {
        this.orderReviewForm.patchValue({
          orderEmailCopy: state,
        });
      });
  }
}
