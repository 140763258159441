import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';

import { OrdersFacade } from '../../../facades/orders.facade';
import { IOrderById, IOrderDataAttributes } from '../../../models/order.models';
import { OrdersUtils } from '../../../utils/orders.utils';
import { PageTypes } from '../../../analytics/enums/pageTypes';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { EFeatureToggles, EThankYouPageFieldNames } from '../../../configurations/common';
import { StringUtils } from '../../../utils/string.utils';
import { CustomerFacade } from '../../../facades/customer.facade';
import { AddressUtils } from '../../../utils/address.utils';
import { environment } from '../../../../environments/environment';
import { AppUtils } from '../../../utils/app.utils';

@Component({
  selector: 'app-harmonized-page-order-thank-you',
  templateUrl: './harmonized-page-order-thank-you.component.html',
  styleUrl: './harmonized-page-order-thank-you.component.scss',
})
export class HarmonizedPageOrderThankYouComponent implements OnInit {

  protected readonly OrdersUtils = OrdersUtils;
  protected readonly StringUtils = StringUtils;
  protected readonly AddressUtils = AddressUtils;
  protected readonly EThankYouPageFieldNames = EThankYouPageFieldNames;

  isSapP40Enabled: boolean = false;
  isEligibleForReturn: boolean = false;
  isDownloadReturnDocumentEnabled: boolean = false;
  isSubmitReturnDocumentEnabled: boolean = false;
  isDiscontinuedStatusEnabled: boolean = false;

  pageFields: string[] = [];

  companyUserId: string;
  orderReference: string;
  orderData: IOrderDataAttributes;
  orderDataLoading: boolean = true;
  isAccordionExpanded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configurationFacade: ConfigurationFacade,
    private ordersFacade: OrdersFacade,
    private analyticsService: AnalyticsService,
    private customerFacade: CustomerFacade,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.initPageData();
  }

  /**
   * Initializes the necessary page data.
   * If "orderId" is not present in the route params, the user is redirected to the error page.
   * After initialization is successfully completed, it begins to get the order related data.
   */
  initPageData(): void {
    combineLatest([
      this.route.params,
      this.configurationFacade.getDynamicOrderThankYouPageFields(),
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40),
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.DOWNLOAD_RETURN_DOCUMENT),
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.SUBMIT_RETURN_DOCUMENT),
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS),
      this.customerFacade.selectUserId(),
    ]).pipe(
      take(1),
    ).subscribe(
      ([
         params,
         pageFields,
         isSapP40Enabled,
         isDownloadReturnDocumentEnabled,
         isSubmitReturnDocumentEnabled,
         isDiscontinuedStatusEnabled,
         companyUserId,
       ]) => {
        if (!params?.orderId) {
          this._redirectToErrorPage();
          return;
        }
        this._reloadOrders();
        this.orderReference = params.orderId;
        this.isSapP40Enabled = isSapP40Enabled;
        this.isDownloadReturnDocumentEnabled = isDownloadReturnDocumentEnabled;
        this.isSubmitReturnDocumentEnabled = isSubmitReturnDocumentEnabled;
        this.isDiscontinuedStatusEnabled = isDiscontinuedStatusEnabled;
        this.pageFields = pageFields;
        this.companyUserId = companyUserId;
        this.getOrderData();
      },
    );
  }

  /**
   * Fetches the order related data by order ID.
   * If any error occurs, the user is redirected to the error page.
   */
  getOrderData(): void {
    this.ordersFacade.getOrderById(this.orderReference).pipe(
      take(1),
    ).subscribe({
      next: (orderResponseWithMessages: IOrderById) => {
        this.orderData = orderResponseWithMessages.data?.attributes;
        this.orderDataLoading = false;
        this._triggerPageLoadedAnalyticsEvent();
        if (this.isSapP40Enabled) {
          this.ordersFacade.recallAndClearOrdersWithDetailsDataList();
          this.isEligibleForReturn = OrdersUtils.isSapOrderEligibleForReturn(this.orderData);
        }
      },
      error: () => {
        this.orderDataLoading = false;
        this._redirectToErrorPage();
      },
    });
  }

  /**
   * Triggers the page loaded analytics event.
   *
   * @private
   */
  private _triggerPageLoadedAnalyticsEvent(): void {
    this.analyticsService.setProducts(this.orderData?.items);
    this.analyticsService.trackPageReady('Order Thank You', PageTypes.ORDER_THANK_YOU_PAGE, 'thank-you-page');
  }

  /**
   * Redirects the user to the error page.
   *
   * @private
   */
  private _redirectToErrorPage(): void {
    this.router.navigate(['page-not-found']).then();
  }

  /**
   * Checks if the order is a personal order (an order created by the current user).
   *
   * @returns {boolean}
   */
  isPersonalOrder(): boolean {
    if (!this.orderData?.customer?.uuidCompanyUser) {
      return true;
    }
    return this.companyUserId === this.orderData.customer.uuidCompanyUser;
  }

  /**
   * Gets the full name of the buyer.
   *
   * @returns {string}
   */
  getBuyerFullName(): string {
    return `${this.orderData?.customer?.firstName} ${this.orderData?.customer?.lastName}`;
  }

  /**
   * Gets the translation for the total item count label.
   *
   * @param {number} itemsCount
   * @returns {string}
   */
  getTotalItemTranslation(itemsCount: number): string {
    return itemsCount <= 1
      ? this.translateService.instant('order-thank-you-page.total-item-number-singular')
      : this.translateService.instant('order-thank-you-page.total-item-number-plural');
  }

  /**
   * Downloads the return document by opening the return document URL in a new tab.
   */
  downloadReturnDocument(): void {
    window.open(this._getDownloadReturnDocumentUrl(), '_blank');
  }

  /**
   * Gets the URL for the download return document action.
   *
   * @private
   *
   * @returns {string}
   */
  private _getDownloadReturnDocumentUrl(): string {
    return this.translateService.instant(environment.sparePartsReturnDocumentUrlKey);
  }

  /**
   * Gets the email link for the submit return document action.
   *
   * @returns {string}
   */
  getSubmitReturnDocumentEmailLink(): string {
    const emailRecipient = this.translateService.instant('order-thank-you-page.return-document-recipient-email');
    return `${emailRecipient}?Subject=Return Document ${this.orderData?.sapDetails?.returnAuthorizationNumber}`;
  }

  /**
   * Method used in template to dynamically render the address fields as a generic address blocks.
   *
   * @param {string} field
   * @returns {string}
   */
  getAddressFieldOnly(field: string): string {
    return ([
      EThankYouPageFieldNames.SOLD_TO_ADDRESS,
      EThankYouPageFieldNames.SHIP_TO_ADDRESS,
      EThankYouPageFieldNames.BILL_TO_ADDRESS,
      EThankYouPageFieldNames.PAYER_ADDRESS,
    ] as string[]).includes(field)
      ? field
      : '';
  }

  /**
   * Method for reloading orders in state after new order is created.
   *
   * @private
   */
  private _reloadOrders(): void {
    if (AppUtils.isSapStore()) {
      this.ordersFacade.recallAndClearOrdersWithDetailsDataList();
    } else {
      this.ordersFacade.loadOrdersHistory(undefined, undefined, true);
    }
  }
}
