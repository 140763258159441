<section class="totals-and-discounts-container" *ngIf="totalsAndDiscounts$ | async as data">
  @if (data.totals) {
    <div class="cart-pricelist">
      <div class="cart--subtotal-price">
      <span [attr.data-test]="'label-subtotalPrice'">
        {{ 'order-review.subtotal' | translate }}
      </span>
        <span [attr.data-test]="'desc-subtotalPrice'">
        {{ data.totals.subtotal | currency: currency }}
      </span>
      </div>
      <div class="cart--shipping-price">
      <span [attr.data-test]="'label-shippingAndHandlingPrice'">
        {{ 'order-review.shipping-and-handling' | translate }}
      </span>
        <span [attr.data-test]="'desc-shippingAndHandlingPrice'">
        {{ data.totals.expenseTotal | currency: currency }}
      </span>
      </div>
      @if (TaxUtils.isExcludeTaxActive()) {
        <div class="cart--shipping-price">
        <span [attr.data-test]="'label-gstPrice'">
          {{ 'order-review.gst-price' | translate: {tax: taxPercentage} }}
        </span>
          <span [attr.data-test]="'desc-shippingAndHandlingPrice'">
          {{ data.totals.taxTotal | currency: currency }}
        </span>
        </div>
      }
    </div>
    @if (data.discounts && data.discounts.length > 0) {
      <div class="discounts-section">
        @for (discount of data.discounts; track discount.displayName) {
          <div class="discount-container">
            <label>
              @if (!isCheckoutQuoteApprovalMandatory) {
                <span
                    class="icon-close remove-discount_btn"
                    title="{{ 'order-review.remove-discount' | translate }}"
                    (click)="removeDiscount(discount)"></span>
              }
              {{ orderReviewService.getDiscountTitle(discount.amount, data.totals.subtotal) }}{{ 'order-review.discount' | translate }}
            </label>
            <label class="price-2"
                   [attr.data-test]="'desc-discountAmount'">-{{ discount.amount | currency: currency }}</label>
          </div>
        }
      </div>
    }
    <hr class="bottom-line">
    <div class="final-price-section">
      <strong [attr.data-test]="'label-orderTotalPrice'">
        {{ 'order-review.order-total' | translate }}
      </strong>
      <label class="price">
        <strong [attr.data-test]="'desc-orderTotalPrice'">
          {{ data.totals.priceToPay | currency: currency }}
        </strong>
      </label>
    </div>
    <div class="additional-details-section">
      <span [attr.data-test]="'desc-priceInfo'">
        {{ 'order-review.price-info' | translate }}
      </span>
      <br>
      <span *ngIf="data.totals.expenseTotal === 0 && data.totals.shipmentTotal === 0"
            [attr.data-test]="'desc-shipping'">
          {{ 'order-review.free-shipping' | translate }}
      </span>
    </div>
  }
</section>
