import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import {
  EAppBarAlertType,
  EFeatureToggles,
  EStoreFeatures,
  EUserRoles,
  REGISTER_URL_KEY,
} from '../../configurations/common';
import { AuthorizationFacade } from '../../facades/authorization.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { StoreConfigurationFacade } from '../../facades/store-configuration.facade';
import { StoreSelectionFacade } from '../../facades/store-selection.facade';
import { IStore } from '../../models/settings.model';
import { I18nService } from '../../services';
import { AppUtils } from '../../utils/app.utils';
import { shopName } from '../../utils/localStorage.utils';
import { TermConstants } from '../terms/TermConstants';
import { UtilsService } from '../../services/utils.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { User } from '@auth0/auth0-spa-js';
import { AuthActions } from '../../auth/actions';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';

@Component({
  selector: 'app-application-bar',
  templateUrl: './application-bar.component.html',
  styleUrls: ['./application-bar.component.scss'],
})
export class ApplicationBarComponent implements OnInit, OnDestroy, AfterViewChecked {
  isMiniCartOpen: boolean;
  isAuthenticated: boolean;
  isCustomerDataLoaded: boolean = false;
  userLoggedIn$: Observable<boolean>;
  user$: Observable<User>;
  itemsInCart: string;
  hasContractInCart: boolean;
  alertDialogActive: boolean = false;
  alertDialogWarning: boolean;
  isGuest: boolean;
  companyRoles: EUserRoles[];
  currentLangParam: string;
  areToUAccepted: boolean;
  restrictedAccess: boolean = false;
  registerUrl: string;
  currentStore: IStore;
  onlineServiceUrl: string = environment.onlineServiceUrl;
  isMyProfileActive: boolean = false;
  rfqActive: boolean = false;
  isRfqOnly: boolean = false;
  isSapP40Enable: boolean = false;
  currentBusinessUnitName: string;
  isAddItemOperationInProgress: boolean = false;
  isCartOperationInProgress: boolean = false;
  isLoadingCartDataInProgress: boolean = false;
  isExternalQuoteCheckoutEnabled$: Observable<boolean> = new Observable<boolean>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private authorizationFacade: AuthorizationFacade,
    private marketingFacade: MarketingFacade,
    private router: Router,
    private customerFacade: CustomerFacade,
    private i18nService: I18nService,
    private storeConfigurationFacade: StoreConfigurationFacade,
    private storeSelectionFacade: StoreSelectionFacade,
    private changeDetector: ChangeDetectorRef,
    private configurationFacade: ConfigurationFacade,
    private utils: UtilsService,
    private analyticsService: AnalyticsService,
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {
    this.isExternalQuoteCheckoutEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.EXTERNAL_QUOTE_CHECKOUT);
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSapP40Enable = value;
    });

    this.selectRfqActive();
    this.selectIsRfqOnly();
    this.selectStoreSubscription();
    this.selectCompanyRole();
    this.selectIsGuest();

    this.userLoggedIn$ = this.authorizationFacade.isUserLoggedIn$;
    this.userLoggedIn$.subscribe({
      next: res => this.isAuthenticated = res,
      error: () => this.isAuthenticated = false,
    });
    this.user$ = this.authorizationFacade.loggedUser$;

    this.selectMiniCartState();
    this.selectItemsCountFromCart();
    this.selectAreToUAccepted();
    this.toggleAlertDialogForRestrictedAccess();

    this.selectIsAddToCartOperationInProgress();
    this.selectCartOperationInProgress();
    this.selectLoadingCartDataInProgress();

    this.currentStore = AppUtils.getCurrentStore();

    this.configurationFacade.getTranslationByKey(REGISTER_URL_KEY)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(translation => {
        this.registerUrl = translation;
      });

    this.isMyProfileActive = this.configurationFacade.isFeatureAvailable(EStoreFeatures.MY_PROFILE);
    this.utils.getCustomRouteData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  selectStoreSubscription(): void {
    this.customerFacade.selectStore().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(store => {
      if (store) {
        this.currentLangParam = this.i18nService.getCurrentParam();
      }
    });
  }

  selectIsGuest(): void {
    this.customerFacade.selectIsGuest().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isGuest => {
      this.isGuest = isGuest;
    });
  }

  selectCompanyRole(): void {
    this.customerFacade.getCustomerCompanyRoles().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(companyRoles => {
      this.companyRoles = companyRoles;
    });
  }

  selectAreToUAccepted(): void {
    this.customerFacade.getCustomerConsentedTerms().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(consentedTerms => {
      this.areToUAccepted = consentedTerms?.includes(TermConstants.termKeys.TERMS_OF_USE.termsKey);
    });
  }

  login(): void {
    this.analyticsService.trackUserLoggedIn();
    this.authorizationFacade.authorization();
  }

  logout(): void {
    this.store.dispatch(AuthActions.setIsLoginInProcess({isLoginInProcess: true}));
    this.authorizationFacade.logout();
    this.clearCartStorage();
    localStorage.removeItem('opal');
    this.alertDialogWarning = false;
  }

  register(): void {
    this.analyticsService.trackUserRegistered();
    window.open(this.registerUrl, '_blank');
  }

  selectItemsCountFromCart(): Subscription {
    return this.marketingFacade.getCurrentCartItems().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      data => {
        if (data) {
          const count = data.included?.filter(tmp => tmp.type === 'items').reduce((a, b) => a + (b.attributes.quantity || 0), 0);

          this.hasContractInCart = data.data?.attributes?.hasContractInCart;
          this.itemsInCart = count > 99 ? '99+' : count;
        } else {
          this.itemsInCart = null;
        }
      },
    );
  }

  clearCartStorage(): void {
    localStorage.removeItem(shopName);
  }

  isCartStorageEmpty(): boolean {
    const shopCart = localStorage.getItem(shopName);
    if (shopCart) {
      const shopCartObj = JSON.parse(shopCart);
      return !(shopCartObj.hasOwnProperty('cartId') && shopCartObj.cartId !== null);
    }
    return true;
  }

  toggleMiniCart(): void {
    if (this.isCartLoading()) {
      return;
    }

    if (this.router.url.includes('/shop-cart')) {
      return;
    }

    this.isMiniCartOpen = !this.isMiniCartOpen;
    this.marketingFacade.toggleMiniCart(this.isMiniCartOpen);
  }

  isApprover(): boolean {
    return this.companyRoles.includes(EUserRoles.Approver) || this.companyRoles.includes(EUserRoles.Admin);
  }

  isViewer(): boolean {
    return this.companyRoles.includes(EUserRoles.Viewer);
  }

  isOnStoreSelection(): boolean {
    return this.storeSelectionFacade.isOnStoreSelectionPage();
  }

  hideSearchAndActions(): boolean {
    return !!this.utils.customData['hideSearchAndActions'] || this.isOnStoreSelection();
  }

  toggleAlertDialogForRestrictedAccess(): void {
    this.customerFacade.selectAccessControlData().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      if (state.loaded) {
        this.isCustomerDataLoaded = true;
        this.restrictedAccess = false;
        this.currentBusinessUnitName = state.businessUnitName;
        if (!state.businessUnitActive || state.businessUnitBlockUsers) {
          this.restrictedAccess = true;
          this.alertDialogActive = true;
          this.isCustomerDataLoaded = true;
        }
      }
    });
  }

  getDialogType(): EAppBarAlertType {
    this.alertDialogActive = true;


    if (!this.isAuthenticated) {
      return EAppBarAlertType.NOT_LOGGED_USER;
    }

    if (this.restrictedAccess) {
      return EAppBarAlertType.RESTRICTED_ACCESS;
    }

    if (this.isAuthenticated && this.isGuest) {
      return EAppBarAlertType.AUTHENTICATED_GUEST;
    }

    this.alertDialogActive = false;
    return undefined;
  }

  getRestrictedMessage(): string {
    return 'app-bar.alert-restricted-access-text';
  }

  isMenuItemVisible(menuItem: string): boolean {
    return !this.currentStore.hiddenStoreUserMenu.find(item => item === menuItem);
  }

  private selectRfqActive(): void {
    this.marketingFacade.selectRfqActive().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.rfqActive = state;
    });
  }

  private selectIsRfqOnly(): void {
    this.storeConfigurationFacade.selectIsRfqOnly().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isRfqOnly = state;
    });
  }

  private selectMiniCartState(): void {
    this.marketingFacade.selectMiniCartState().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isMiniCartOpen = state.isMiniCartOpen;
    });
  }

  isCartLoading(): boolean {
    return this.isAddItemOperationInProgress || this.isCartOperationInProgress || this.isLoadingCartDataInProgress;
  }

  private selectIsAddToCartOperationInProgress(): void {
    this.marketingFacade.isAddItemOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isAddItemOperationInProgress = state;
    });
  }

  private selectCartOperationInProgress(): void {
    this.marketingFacade.selectCartOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isCartOperationInProgress => {
      this.isCartOperationInProgress = isCartOperationInProgress;
    });
  }

  private selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isLoadingCartDataInProgress => {
      this.isLoadingCartDataInProgress = isLoadingCartDataInProgress;
    });
  }
}
