import { Component, OnInit } from '@angular/core';
import { EFeatureToggles, EStoreFeatures } from '../../configurations/common';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page-shop-cart',
  templateUrl: './page-shop-cart.component.html',
  styleUrls: [],
})
export class PageShopCartComponent implements OnInit {
  isMultiCartActive: boolean = false;
  isExportCartActive$: Observable<boolean> = new Observable<boolean>;

  constructor(
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isMultiCartActive = this.configurationFacade.isFeatureAvailable(EStoreFeatures.MULTI_CART);
    this.isExportCartActive$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.CART_EXPORT_AS_PDF);
  }
}
