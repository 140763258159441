import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EUserRoles } from '../../../../configurations/common';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ISelectEvent } from '../../../../models/common.models';
import { OrdersFacade } from '../../../../facades/orders.facade';
import { sparePartOrderHistoryFilters } from './spare-part-order-history.filters';
import { AdvancedFilter } from '../../../../models/advanced-filter';
import { CustomerFacade } from '../../../../facades/customer.facade';
import { FileType, FileUtils } from '../../../../utils/file.utils';
import { FilterByAttributesPipe } from '../../../../shared/pipes/filter-by-attributes.pipe';
import { SearchFieldPipe } from '../../../../shared/pipes/search-field.pipe';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-spare-part-order-history',
  templateUrl: './spare-part-order-history.component.html',
  styleUrls: ['./spare-part-order-history.component.scss'],
})
export class SparePartOrderHistoryComponent implements OnInit, OnChanges {
  currentUser: any = {
    email: '',
    isApprover: false,
  };

  excelGenerationInProgress: boolean = false;

  originalOrders: any[]; // current page of orders (unfiltered)
  @Input() allLoadedOrders: any[]; // self-explanatory
  @Input() orders: any[]; // current page of orders (after filtering)
  @Input() ordersLoading: boolean = true;

  searchOrders: string;
  onlyCurrentUserOrders: boolean = false;

  advancedSearchDropdownIsOpened: boolean = false;
  advancedFiltersForm: UntypedFormGroup;
  advancedFilters: AdvancedFilter[] = sparePartOrderHistoryFilters;

  selectBoxOptions: any[] = [
    {
      name: 'soldToAddress',
      options: [],
    },
    {
      name: 'shipToAddress',
      options: [],
    },
    {
      name: 'flNumber',
      options: [],
    },
    {
      name: 'orderStatus',
      options: [],
    },
  ];

  datepickerRange: any = {
    'orderDateFrom': '',
    'orderDateTo': '',
  };

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private ordersFacade: OrdersFacade,
    private formBuilder: UntypedFormBuilder,
    private customerFacade: CustomerFacade,
    private filterPipe: FilterByAttributesPipe,
    private searchPipe: SearchFieldPipe,
  ) {
  }

  ngOnInit(): void {
    this.selectCurrentUserData();
    this.initializeForm();
    this.initializeAdvancedFilter();
  }

  ngOnChanges(): void {
    if (!this.ordersLoading) {
      this.originalOrders = this.orders;
      this.prefilterOrdersBasedOnOrigin();
      this.loadAdvancedFilter();
      this.loadSelectBoxOptions();
    }
  }

  selectCurrentUserData(): void {
    this.customerFacade.getAuth0CustomerFromStore().pipe(take(1)).subscribe(userData => {
      if (userData) {
        this.currentUser.email = userData.email;
      }
    });

    this.customerFacade.getCustomerCompanyRoles().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((companyRoles: EUserRoles[]) => {
      this.currentUser.isApprover = (companyRoles.includes(EUserRoles.Approver) || companyRoles.includes(EUserRoles.Admin) || companyRoles.includes(EUserRoles.BusinessPartnerApprover));
    });
  }

  initializeForm(): void {
    this.advancedFiltersForm = this.formBuilder.group({
      soldToAddress: '',
      shipToAddress: '',
      orderDateFrom: '',
      orderDateTo: '',
      orderStatus: '',
      flNumber: '',
      materialNumber: '',
      orderNumber: '',
      purchaseOrder: '',
      rmaNumber: '',
    });
  }

  initializeAdvancedFilter(): void {
    this.advancedFilters.forEach((advancedFilter: AdvancedFilter) => {
      advancedFilter.options = [];
      advancedFilter.selected = [];
    });
  }

  updateForm(): void {
    this.advancedFilters.forEach((filter: AdvancedFilter) => {
      this.advancedFiltersForm.patchValue({
        [filter.name]:
          SparePartOrderHistoryComponent.isDateFilter(filter.name)
            ? this.datepickerRange[filter.name]
            : filter.selected[0],
      });
    });
  }

  setFormValue(event: ISelectEvent): void {
    this.advancedFiltersForm.patchValue({
      [event.key]: event.value,
    });
  }

  loadAdvancedFilter(): void {
    let filter: string[];
    this.advancedFilters.forEach((advancedFilter: AdvancedFilter) => {
      advancedFilter.options = [...new Set(
        // Initialize selectbox options for respective filters from values in all fetched orders (not just current page)
        this.allLoadedOrders.map(order => {
          if (advancedFilter.secondAdditionalAttribute) {
            filter = order?.[advancedFilter.attribute]?.[advancedFilter.firstAdditionalAttribute]?.[advancedFilter.secondAdditionalAttribute].toString();
          } else if (advancedFilter.firstAdditionalAttribute) {
            filter = this.parseDataFromObject(order, advancedFilter.attribute, advancedFilter.firstAdditionalAttribute);
          } else {
            filter = order?.[advancedFilter.attribute].toString();
          }

          if (filter && filter[0] !== '' && filter[0] !== 'empty') {
            return filter;
          }
        }).reduce((acc: string[], val: string[]) => acc.concat(val), []),
      )].filter(item => item !== undefined);
    });
  }

  loadSelectBoxOptions(): void {
    this.selectBoxOptions.forEach(selectBox => {
      selectBox.options = this.createSelectBoxOptions(selectBox.name);
    });
  }

  getOptionsForSelectBox(selectBoxName: string): Array<{name: string; value: any}> {
    return this.selectBoxOptions.find(selectBox =>
      selectBox.name === selectBoxName,
    ).options;
  }

  applySelectedFiltersOptions(): void {
    this.advancedFilters = this.advancedFilters.map((filter: AdvancedFilter) => {
      if (SparePartOrderHistoryComponent.isDateFilter(filter.name)) {
        this.datepickerRange[filter.name] = this.advancedFiltersForm.value[filter.name];
      }
      if (this.advancedFiltersForm.value[filter.name]) {
        filter.selected = SparePartOrderHistoryComponent.isDateFilter(filter.name)
          ? this.calculateOptionsFromDatepicker(filter)
          : [this.advancedFiltersForm.value[filter.name]];
      } else {
        filter.selected = [];
      }

      this.advancedFiltersForm.patchValue({
        [filter.name]:
          SparePartOrderHistoryComponent.isDateFilter(filter.name)
            ? this.datepickerRange[filter.name]
            : filter.selected[0],
      });
      return filter;
    });
    this.openOrCloseDropdown();
  }

  clearSelectedFiltersOptions(): void {
    this.advancedFilters.forEach((filter: AdvancedFilter) => {
      this.advancedFilters[filter.name] = SparePartOrderHistoryComponent.isDateFilter(filter.name)
        ? [this.datepickerRange[filter.name]]
        : [...filter.selected];
    });
    this.openOrCloseDropdown();
  }

  resetFilters(): void {
    this.initializeForm();
  }

  openOrCloseDropdown(): void {
    if (this.advancedSearchDropdownIsOpened) {
      this.updateForm();
    }
    this.advancedSearchDropdownIsOpened = !this.advancedSearchDropdownIsOpened;
  }

  generateOrdersHistoryExcel(): void {
    this.excelGenerationInProgress = true;

    //prevent calling facade function when someone try to enable disabled button
    if (this.getFilteredOrders().length === 0) {
      this.excelGenerationInProgress = false;
      return;
    }

    this.ordersFacade.postOrdersHistoryExcelFile(this.getFilteredOrders())
      .pipe(take(1))
      .subscribe((response: HttpResponse<Blob>) => {
        const contentDispositionHeader = response.headers.get('Content-Disposition');
        const filename = FileUtils.extractFilenameFromContentDisposition(contentDispositionHeader, 'OrderHistory' + new Date().toString());
        FileUtils.saveAndOpenFile(response.body, FileType.EXCEL, filename, undefined, false);
        this.excelGenerationInProgress = false;
      });
  }

  selectOnlyCurrentUserOrders(): void {
    this.onlyCurrentUserOrders = !this.onlyCurrentUserOrders;
    this.prefilterOrdersBasedOnOrigin();
  }

  getFilteredOrders(): any[] {
    let activeOrders = this.searchPipe.transform(
      this.filterPipe.transform(this.orders, this.advancedFilters),
      this.searchOrders,
    );

    let activeOrdersIds = [];
    activeOrders.forEach(order => {
      activeOrdersIds.push(order.id);
    });

    return activeOrdersIds;
  }

  private static isDateFilter(filterName: string): boolean {
    return filterName.includes('orderDate');
  }

  private parseDataFromObject(order: any, attribute: string, additionalAttribute: string): string[] {
    if (additionalAttribute === 'shippingAddress' || additionalAttribute === 'soldToAddress') {
      return [this.addressToString(order?.[attribute]?.[additionalAttribute])];
    } else if (additionalAttribute === 'items') {
      return SparePartOrderHistoryComponent.getMaterialNumbersOfItems(order);
    } else {
      return [order?.[attribute]?.[additionalAttribute].toString()];
    }
  }

  private addressToString(address: any): string {
    if (!address) {
      return '';
    }

    const addressDetails: string = [
      address.address1,
      address.city,
      address.zipCode,
    ].filter(addressField => !SparePartOrderHistoryComponent.isEmptyAddress(addressField)).join(', ');

    return SparePartOrderHistoryComponent.formatEmptyAddress(address.company) + (addressDetails.length > 0 ? ` (${addressDetails})` : '');
  }

  private static getMaterialNumbersOfItems(order: any): string[] {
    return [...new Set(
      order.attributes.items.map(item => item.materialNumber),
    )] as string[];
  }

  private static isEmptyAddress(address: string): boolean {
    return address === '' || address === 'empty';
  }

  private static formatEmptyAddress(address: string): string {
    if (address === '' || address === 'empty') {
      return '';
    }

    return address;
  }

  private createSelectBoxOptions(filterName: string): Array<{name: string; value: any}> {
    let optionsForSelectBox: Array<{name: string; value: any}> = [];
    this.advancedFilters.find(item => item.name === filterName)?.options.forEach(
      option => {
        optionsForSelectBox.push({
          name: option,
          value: option,
        });
      });
    return optionsForSelectBox;
  }

  private calculateOptionsFromDatepicker(filter: AdvancedFilter): string[] {
    let dateOptions: string[] = filter.options;
    dateOptions = dateOptions.filter(option => {
      if (filter.name === 'orderDateFrom') {
        return new Date(this.advancedFiltersForm.value[filter.name]) <= new Date(option);
      } else {
        let endOfTheDay = new Date(this.advancedFiltersForm.value[filter.name]);
        endOfTheDay.setHours(23, 59, 59, 999);
        return endOfTheDay >= new Date(option);
      }
    });
    return dateOptions.length !== 0 ? dateOptions : ['no-match'];
  }

  private prefilterOrdersBasedOnOrigin(): void {
    this.orders = this.onlyCurrentUserOrders
      ? this.getCurrentUserOrders(this.originalOrders)
      : this.originalOrders;
  }

  private getCurrentUserOrders(orders: any[]): any[] {
    const filteredOrders = orders.filter(
      order => order.attributes.customer.email === this.currentUser.email,
    );
    return filteredOrders ? filteredOrders : [];
  }
}
