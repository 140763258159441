<div class="product-page" [ngClass]="'cpq-store'">
  <app-access-control [notForViewer]="true" [description]="false">
    <ng-container ngProjectAs="[slot][nonViewerContent]">
      <div class="row">
        <app-access-control [notForViewer]="true" [description]="false">
          <ng-container ngProjectAs="[slot][nonViewerContent]">
            <ng-container *ngIf="(isMyInstalledBaseFlowEnabled$ | async)">
              <app-equipment-selection
                  class="col-xl-8"
                  [currentCart]="currentCart"
                  [systemDetails]="systemDetails"
                  [loading]="loadingCartDataInProgress || wasRedirectedFromEquipmentPage"
                  (updatedPricesAfterChangeSoldTo)="updatedPricesAfterChangeSoldTo($event)">
              </app-equipment-selection>
            </ng-container>
          </ng-container>
        </app-access-control>
        <div class="col-xl-4">
          <ng-container *ngIf="isSapP40Enabled && equipmentFlNumber && pricesLoaded && loadedSuccessfully">
            <ng-container *ngIf="!isEligible; else EligibleModal">
              <div class="notification">
                <div class="notification__header">
                  <span class="icon-warning icon-warning__circle"></span>
                  <p class="notification__title">{{ 'spare-parts.order' | translate }}</p>
                </div>
              </div>
            </ng-container>
            <ng-template #EligibleModal>
              <div class="notification">
                <div class="notification__header">
                  <span class="icon-check icon-check__circle"></span>
                  <h5 class="notification__title">{{ 'spare-parts.eligible-equipment' | translate }}</h5>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="isProductWithMaterialMasterNumber()">
            <app-product-information-notification/>
          </ng-container>
          <ng-container *ngIf="(isProductDiscontinuedStatusEnabled$ | async) && isProductDiscontinued()">
            <ng-container *ngIf="isSubsequentMaterialAvailable(); else noSubsequentMaterial">
              <div class="notification" [attr.data-test]="'warning-discontinued-part-with-sub'">
                <div class="notification__header">
                  <span class="icon-warning icon-warning__circle"></span>
                  <h5 class="notification__title">
                    {{ 'app-product-page.discontinued-part-with-subsequent-material-1' | translate }}
                    (<span *ngFor="let subsequentMaterial of getSubsequentMaterial(); let last = last">
                      <a [routerLink]="['/product/', 'A_' + subsequentMaterial]">{{ subsequentMaterial }}</a>
                      <span *ngIf="!last">, </span>
                    </span>){{ 'app-product-page.discontinued-part-with-subsequent-material-2' | translate }}
                  </h5>
                </div>
              </div>
            </ng-container>
            <ng-template #noSubsequentMaterial>
              <div class="notification" [attr.data-test]="'warning-discontinued-part'">
                <div class="notification__header">
                  <span class="icon-warning icon-warning__circle"></span>
                  <h5 class="notification__title">{{ 'app-product-page.discontinued-part' | translate }}</h5>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </app-access-control>
  <div class="loader__overlay" *ngIf="!productInfoLoaded; else body">
    <div class="loader__content">
      <div class="loading-animation"></div>
    </div>
  </div>
  <ng-template #body>
    <h1 class="product__title mobile" [attr.data-test]="'title-productTitle'">{{ abstractProduct.name }}</h1>
    <div class="row justify-content-center">
      <div class="col-xl-2 product-image__wrapper no-padding">
        <div [appLoadingAnimation]="!productInfoLoaded">
          <div class="carousel-thumbnail col-sm-12 col-md-12 product-image__thumbnails">
            <div
                class="image__thumbnail"
                *ngFor="let small of smallUrls; let indexOfElement = index;"
                (click)="setLargeUrl(indexOfElement)">
              <img [src]="small" [alt]="productInfoLoaded ? abstractProduct.name : ''"
                   [attr.data-test]="'img-productImageThumbnail'"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-5 product-image__wrapper no-padding">
        <div class="col-sm-12 col-md-11 product-image__main" [ngClass]="{'configurable pt-xl-2': isConfigurable()}">
          <app-slick-carousel class="carousel-grid"
                              [config]="{'dots': true}"
                              (afterChange)="setLargeUrl($event.currentSlide)">
            <div
                appSlickItem
                *ngFor="let large of largeUrls;">
              <ng-container>
                <img class="image__main" alt=""
                     (click)="openZoomPicture()"
                     [src]="large"
                     [attr.data-test]="'img-productImage'"/>
              </ng-container>
            </div>
          </app-slick-carousel>
          <app-access-control>
            <ng-container ngProjectAs="[slot][content]">
              <app-sticker
                  [product]="abstractProduct"
                  [labels]="labels"
                  [prices]="[yourPrice, listPrice]">
              </app-sticker>
            </ng-container>
          </app-access-control>
        </div>
        <ng-container *ngIf="openZoomedPicture">
          <div class="modal-dialog is-open"
               tabindex="-1"
               id="modal-zoom-picture"
               role="dialog"
               aria-modal="true"
               aria-labelledby="modal-confirm-decline_title"
               aria-describedby="modal-confirm-decline_desc"
               aria-hidden="true"
               data-linked="#modal-zoom-picture" (click)="openZoomPicture()">
            <div class="modal-dialog__container" role="document">
              <img [src]="mainPicture"
                   style="cursor: zoom-out;"
                   [alt]="abstractProduct.name"/>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-xl-3">
        <ng-container *ngIf="productInfoLoaded">
          <app-access-control [notForViewer]="true">
            <ng-container ngProjectAs="[slot][nonViewerContent]">
              <app-add-to-wishlist
                  [wishlists]="wishlists"
                  [sku]="concreteSku"
                  [productName]="abstractProduct.name"
                  [productKeywords]="abstractProduct.attributes.mcm_keywords"
                  [addNewModalActive]="addNewModalActive"
                  [textNextToIcon]="true"
                  (createNew)="addToWishlist()">
              </app-add-to-wishlist>
            </ng-container>
          </app-access-control>
          <h1 class="product__title desktop" [attr.data-test]="'title-productTitle'">{{ abstractProduct.name }}</h1>
          <div class="product__short-details">
            <div class="material_number"
                 [attr.data-test]="'desc-productMaterialNumber'">
              {{ abstractProduct.attributes.material_number }}
            </div>
            <span *ngIf="isSapP40Enabled && equipmentFlNumber && pricesLoaded && loadedSuccessfully && isExchangeable"
                  class="label exchangeable-item">
              {{ 'spare-parts.exchangeable-item' | translate }}
            </span>
            <span *ngIf="abstractProduct.attributes.heavyweight && !companyRoles.includes(guestConst)"
                  class="label heavyweight-item">
              {{ 'spare-parts.heavyweight-item' | translate }}
            </span>
          </div>
          <p [innerHTML]="abstractProduct.description" [attr.data-test]="'desc-productDescription'"
             class="basic-product-link"></p>
          <app-access-control>
            <ng-container ngProjectAs="[slot][content]">
              <div class="price-and-stock"
                   *ngIf="equipmentFlNumber && checkIfProductIsAvailableForOnlinePurchase(); else notShowPriceAndAvailability">
                <ng-container *ngIf="isSapP40Enabled; else notVisibleForSapP40">
                  <div class="price-container">
                    <div class="price-container__section-left">
                      <div class="product__description__list-price-text" [attr.data-test]="'label-listPrice'">
                        {{ 'spare-parts.list-price' | translate }}
                      </div>
                      <div class="product__description__your-price" [attr.data-test]="'label-yourPrice'">
                        {{ 'spare-parts.your-price' | translate }}
                      </div>
                    </div>
                    <div class="price-container__section-right">
                      <div class="product__description__list-price" *ngIf="pricesLoaded; else loadingPriceInformation"
                           [attr.data-test]="'desc-listPrice'">
                        <ng-container *ngIf="![null, undefined].includes(listPrice)">
                          {{ priceUtils.getPriceValue(listPrice) | currency: priceUtils.getCurrencyCode(listPrice) }}
                        </ng-container>
                      </div>
                      <div class="product__description__your-price" *ngIf="pricesLoaded; else loadingPriceInformation"
                           [attr.data-test]="'desc-yourPrice'">
                        <ng-container
                            *ngIf="loadedSuccessfully; else noYourPrice">
                          {{ priceUtils.getPriceValue(yourPrice) | currency: priceUtils.getCurrencyCode(yourPrice) }}
                        </ng-container>
                        <ng-template #noYourPrice>
                          {{ 'spare-parts.your-price-not-loaded' | translate }}
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #notVisibleForSapP40>
                  <div class="price-container">
                    <div class="price-container__section-left">
                      <div class="product__description__list-price-text">
                        {{ 'spare-parts.list-price' | translate }}
                      </div>
                    </div>
                    <div class="price-container__section-right">
                      <div class="product__description__your-price">
                        {{ priceUtils.getPriceValue(yourPrice) | currency: priceUtils.getCurrencyCode(yourPrice) }}*
                      </div>
                    </div>
                  </div>
                  <div class="spare-parts-price-disclaimer">
                    {{ 'spare-parts.spare-parts-price-disclaimer' | translate }}
                  </div>
                </ng-template>
                <ng-container *ngIf="isSapP40Enabled">
                  <div *ngIf="pricesLoaded; else loadingPriceInformation">
                    <span *ngIf="currentlyInStock; else outOfStock"
                          [attr.data-test]="'desc-productAvailability'">
                      {{ 'spare-parts.in-stock' | translate }}
                    </span>
                    <ng-template #outOfStock>
                      <span [attr.data-test]="'desc-productAvailability'">
                        {{ 'spare-parts.out-of-stock' | translate }}
                      </span>
                      <app-tooltip
                          class="accordion__category-details__info-icon"
                          link="icon"
                          text="{{ 'spare-parts.out-of-stock-tooltip' | translate }}"></app-tooltip>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
              <ng-template #notShowPriceAndAvailability>
                <div *ngIf="!equipmentFlNumber" class="price-message">
                  {{ 'spare-parts.not-selected-equipment-message' | translate }}
                </div>
              </ng-template>
              <app-access-control [notForViewer]="true">
                <ng-container ngProjectAs="[slot][nonViewerContent]">
                  <form class="button-container" (submit)="addProductToCart()">
                    <ng-container
                        *ngIf="checkIfProductIsAvailableForOnlinePurchase(); else productIsNotAvailableForOnlinePurchase">
                      <label id="label__quantity">
                        <input name="productQuantity"
                               type="number"
                               class="spinbox-quantity"
                               min="1"
                               step="1"
                               [(ngModel)]="productQuantity"
                               required
                               [disabled]="isAddToCartInProgress$ | async"
                               (change)="changeProductQuantity(productQuantity)"/>
                      </label>
                      <button
                          class="button button--primary icon-basket button--cart icon__mobile"
                          [disabled]="(isAddToCartInProgress$ | async) || isAddToCartButtonDisabled() || canCartItemsBeChanged(currentCart, isBusinessPartner)"
                          [attr.data-test]="'button-addToCart'"
                          type="submit">
                        {{ 'app-product-page.add-to-cart' | translate }}
                      </button>
                    </ng-container>
                    <ng-template #productIsNotAvailableForOnlinePurchase>
                      <div class="notification__header"
                           [attr.data-test]="'desc-productIsNotAvailable'">
                        <span class="icon-info icon-info__circle icon-orange-white"></span>
                        <p class="font-weight-bold product-not-available">
                          {{ 'app-product-page.product-is-not-available-for-online-purchase' | translate }}
                        </p>
                      </div>
                    </ng-template>
                    <ng-container *ngIf="!isQuantityValid">
                      <small class="textfield__hint">{{ 'app-product-page.enter-quantity' | translate }}</small>
                    </ng-container>
                  </form>
                  <span class="equipment-buttons">
                    <div class="additional-options">
                      <a *ngIf="isUserSparePartsViewer()"
                         class="spare-parts-viewer"
                         (click)="openSparePartsViewer()"
                         [attr.data-test]="'link-sparePartsViewer'">
                        <span class="icon-arrow-long-right"></span>
                        <span>{{ 'my-equipment.select-spare-parts-viewer' | translate }}</span>
                      </a>
                    </div>
                  </span>
                </ng-container>
              </app-access-control>
            </ng-container>
          </app-access-control>
        </ng-container>
      </div>
      <div class="col-xl-10">
        <div class="product-details">
          <ng-container *ngIf="productInfoLoaded">
            <div class="accordion" role="presentation">
              <app-accordion *ngIf="displayProductDetail(productDetailsEnum.OVERVIEW)"
                             (panelOpened)="tracking(productDetailsEnum.OVERVIEW)" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 id="panel-overview-button"
                      class="accordion__title icon-arrow-down" aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-overview'">
                    {{ 'app-product-page.overview' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <p class="accordion__panel__text" [attr.data-test]="'desc-overview'"
                       [innerHTML]="abstractProduct.attributes.mcm_long_description">
                    </p>
                  </div>
                </ng-container>
              </app-accordion>
              <app-accordion
                  *ngIf="displayProductDetail(productDetailsEnum.DIMENSIONS)"
                  (panelOpened)="tracking(productDetailsEnum.DIMENSIONS)" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 (click)="tracking(productDetailsEnum.DIMENSIONS)" id="panel-dimensions-button"
                      class="accordion__title icon-arrow-down" aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-dimensions'">
                    {{ 'app-product-page.dimensions' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <ng-container *ngIf="abstractProduct?.attributeNames">
                      <div class="row accordion__panel__text">
                        <ng-container
                            *ngIf="toggleAttribute('sap_p40_uom_mapped') && abstractProduct.attributeNames.sap_p40_uom_mapped">
                          <p class="accordion__panel__text__category col-4 col-lg-2"
                             [attr.data-test]="'label_unitOfMeasure'">
                            {{ abstractProduct.attributeNames.sap_p40_uom_mapped }}
                          </p>
                          <div class="col-8 col-lg-10">
                            <p class="accordion__panel__text__category spec accordion__panel__dimension"
                               [attr.data-test]="'desc-unitOfMeasure'">
                              {{ abstractProduct.attributes.sap_p40_uom_mapped || emptyAttribute }}
                            </p>
                          </div>
                        </ng-container>
                        <ng-container
                            *ngIf="toggleAttribute('sap_length') && abstractProduct.attributeNames.sap_length">
                          <p class="accordion__panel__text__category col-4 col-lg-2" [attr.data-test]="'label_length'">
                            {{ abstractProduct.attributeNames.sap_length }}
                          </p>
                          <div class="col-8 col-lg-10">
                            <p class="accordion__panel__text__category spec accordion__panel__dimension"
                               [attr.data-test]="'desc-length'">
                              {{ abstractProduct.attributes.sap_length }} cm
                            </p>
                          </div>
                        </ng-container>
                        <ng-container
                            *ngIf="toggleAttribute('sap_height') && abstractProduct.attributeNames.sap_height">
                          <p class="accordion__panel__text__category col-4 col-lg-2" [attr.data-test]="'label-height'">
                            {{ abstractProduct.attributeNames.sap_height }}
                          </p>
                          <div class="col-8 col-lg-10">
                            <p class="accordion__panel__text__category spec accordion__panel__dimension"
                               [attr.data-test]="'desc-height'">
                              {{ abstractProduct.attributes.sap_height }} cm
                            </p>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="toggleAttribute('sap_width') && abstractProduct.attributeNames.sap_width">
                          <p class="accordion__panel__text__category col-4 col-lg-2" [attr.data-test]="'label-width'">
                            {{ abstractProduct.attributeNames.sap_width }}
                          </p>
                          <div class="col-8 col-lg-10">
                            <p class="accordion__panel__text__category spec accordion__panel__dimension"
                               [attr.data-test]="'desc-width'">
                              {{ abstractProduct.attributes.sap_width }} cm
                            </p>
                          </div>
                        </ng-container>
                        <ng-container
                            *ngIf="toggleAttribute('sap_p40_gross_weight') && abstractProduct.attributeNames.sap_p40_gross_weight">
                          <p class="accordion__panel__text__category col-4 col-lg-2" [attr.data-test]="'label-weight'">
                            {{ abstractProduct.attributeNames.sap_p40_gross_weight }}
                          </p>
                          <div class="col-8 col-lg-10">
                            <p class="accordion__panel__text__category spec accordion__panel__dimension"
                               [attr.data-test]="'desc-weight'">
                              {{ getWeightDetails() }}
                            </p>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </app-accordion>
              <app-accordion *ngIf="displayProductDetail(productDetailsEnum.DETAILS)"
                             (panelOpened)="tracking(productDetailsEnum.DETAILS)" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 id="panel-details-button"
                      class="accordion__title icon-arrow-down"
                      aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-details'">
                    {{ 'app-product-page.details' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <ng-container *ngIf="abstractProduct?.attributeNames">
                      <div class="row accordion__panel__text">
                        <ng-container *ngFor="let attribute of sparePartsDetails">
                          <ng-container *ngIf="toggleAttribute(attribute) && abstractProduct.attributeNames[attribute]">
                            <p class="accordion__panel__text__category col-4 col-lg-2"
                               [attr.data-test]="'label_' + attribute">
                              {{ abstractProduct.attributeNames[attribute] }}
                            </p>
                            <div class="col-8 col-lg-10">
                              <p class="accordion__panel__text__category spec accordion__panel__dimension"
                                 [attr.data-test]="'desc-unitOfMeasure' + attribute">
                                {{ abstractProduct.attributes[attribute] || emptyAttribute }}
                              </p>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </app-accordion>
              <app-accordion
                  *ngIf="displayProductDetail(productDetailsEnum.SUITABLE_FOR)
                    && installBaseProducts
                    && installBaseProducts.length > 0"
                  (panelOpened)="tracking(productDetailsEnum.SUITABLE_FOR)" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 id="panel-suitable-button"
                      class="accordion__title icon-arrow-down" aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-suitableFor'">
                    {{ 'app-product-page.suitable-for' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <div class="accordion__panel__text install-base-pointer">
                      <ng-container *ngFor="let installedBase of installBaseProducts">
                        <p>
                          <a [routerLink]="['/catalog/parts']"
                             [queryParams]="{'rel-product-sysivk': installedBase.attributes.materialNumber}"
                             (click)="trackInstalledBase(installedBase)"
                             [attr.data-test]="'desc-' + installedBase.attributes.nameEnUs">
                            {{ installedBase.attributes.nameEnUs }}
                          </a>
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </app-accordion>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #loadingPriceInformation>
    <div class="sap-data loading-animation"></div>
  </ng-template>
</div>
