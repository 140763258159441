import { Pipe, PipeTransform } from '@angular/core';
import { FormatDatePipe } from './format-date.pipe';

@Pipe({
  name: 'searchFilter',
  pure: false
})
export class SearchFieldPipe implements PipeTransform{

  constructor(private formatDatePipe: FormatDatePipe) {}

  static checkInside(item: any, term: string, toCompare: string, excludes: any, formatDatePipe: FormatDatePipe): boolean {

    if (typeof item === 'string' && item.toString().toLowerCase().includes(toCompare)) {
      return true;
    }

    for (const property in item) {
      if (item[property] === null || item[property] === undefined || excludes.includes(property)) {
        continue;
      }
      if (typeof item[property] === 'object') {
        if (this.checkInside(item[property], term, toCompare, excludes, formatDatePipe)) {
          return true;
        }
      } else {
        let valueToCompare = item[property].toString().toLowerCase();
        if (!isNaN(Date.parse(item[property]))) {
          valueToCompare = formatDatePipe.transform(item[property]).toLowerCase();
        }
        if (valueToCompare.includes(toCompare)) {
          return true;
        }
      }

    }
    return false;
  }

  static filter(items: Array<{ [key: string]: any }>, term: string, excludes: any, formatDatePipe: FormatDatePipe): Array<{ [key: string]: any }> {

  const toCompare = term.toLowerCase();

  return items.filter( (item) => {
      return this.checkInside(item, term, toCompare, excludes, formatDatePipe);
    });
  }

  transform(items: any, term: string, excludes: any = []): any {
    if (!term || !items) { return items; }

    return SearchFieldPipe.filter(items, term, excludes, this.formatDatePipe);
  }
}
