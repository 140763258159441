import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerFacade } from '../../facades/customer.facade';

@Component({
  selector: 'app-remove-item-from-order-modal',
  templateUrl: './remove-item-from-order-modal.component.html',
  styleUrls: ['./remove-item-from-order-modal.component.scss'],
})
export class RemoveItemFromOrderModalComponent {
  @Input() showModalConfirmRemove: boolean = false;
  @Input() deleteLastItemAndDeclineOrderFlag: boolean = false;
  @Input() clickedItemDataName: string;
  @Output() restartShowModal = new EventEmitter<object>();
  @Output() removeItemFromCart = new EventEmitter();
  @Output() lastItemDeclineOrder = new EventEmitter();

  setButtonDisabledAttribute: boolean = false;
  isApprover$: Observable<boolean> = this.customerFacade.isApprover();

  constructor(
    private customerFacade: CustomerFacade,
  ) {
  }

  crossClick(): void {
    this.restartShowModal.emit({showModalConfirmRemove: false, deleteLastItemAndDeclineOrderFlag: false});
  }

  clickRemoveItemFromCart(): void {
    this.removeItemFromCart.emit();
  }

  clickDeclineOrder(): void {
    this.lastItemDeclineOrder.emit();
    this.setButtonDisabledAttribute = true;
  }
}
