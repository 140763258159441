import { CanMatchFn, Route } from '@angular/router';
import { inject } from '@angular/core';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { EFeatureToggles } from '../configurations/common';
import { Observable, of } from 'rxjs';

export function harmonizedFeatureGuard(): CanMatchFn {
  return (route: Route): Observable<boolean> => {
    const configurationFacade = inject(ConfigurationFacade);
    switch (route?.path) {
      case ':lang/delivery-details':
      case ':lang/sap-delivery-details':
      case ':lang/request-quote':
        return configurationFacade.isFeatureEnabled(EFeatureToggles.HARMONIZED_DELIVERY_DETAILS_PAGE);
      case ':lang/order-review/:orderId':
      case ':lang/order-approval/:orderId':
        return configurationFacade.isFeatureEnabled(EFeatureToggles.HARMONIZED_ORDER_REVIEW_PAGE);
      case ':lang/order-thank-you-page/:orderId':
      case ':lang/summary-success/:orderId':
        return configurationFacade.isFeatureEnabled(EFeatureToggles.HARMONIZED_ORDER_THANK_YOU_PAGE);
      case ':lang/purchase-activity':
        return configurationFacade.isFeatureEnabled(EFeatureToggles.HARMONIZED_PURCHASE_ACTIVITY_PAGE);
      default:
        return of(false);
    }
  };
}
