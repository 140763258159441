import { EHybrisOrderStatus, EOrderStatus, EQuoteRequestStatus } from '../configurations/order-status';
import { StringUtils } from './string.utils';

export class IconUtils {

  static getStatusIcon(status: string): string {
    if (!status) {
      return '';
    }

    switch (status.toLowerCase()) {
      case EOrderStatus.DECLINED:
      case EOrderStatus.CANCELED:
      case EQuoteRequestStatus.CANCELED:
        return 'icon-close icon-close__circle';
      case 'warning':
      case EOrderStatus.PAYMENT_CANCELED:
      case EOrderStatus.PAYMENT_FAILED:
      case EOrderStatus.ISSUE:
      case EOrderStatus.SEE_DETAILS:
        return 'icon-warning icon-warning__circle';
      default:
        return 'icon-check icon-check__circle';
    }
  }

  static getHybrisStatusIcon(status: string): string {
    if (!status) {
      return '';
    }

    switch (StringUtils.replaceUnderscores(status.toLowerCase(), ' ')) {
      case EHybrisOrderStatus.REJECTED:
        return 'icon-close icon-close__circle';
      case EHybrisOrderStatus.ORDER_CREATED:
      case EHybrisOrderStatus.ON_HOLD:
      case EHybrisOrderStatus.BACKORDERED:
      case EHybrisOrderStatus.SCHEDULED_FOR_SHIPMENT:
      case EHybrisOrderStatus.IN_PROCESS:
      case EHybrisOrderStatus.PARTIALLY_SHIPPED:
      case EHybrisOrderStatus.SHIPPED:
        return 'icon-check icon-check__circle';
      default:
        return 'icon-warning icon-warning__circle';
    }
  }
}
