import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { Iiso2Codes } from '../../models/enviroment-delivery-details.model';
import * as ValidationPatterns from '../../configurations/validations';
import { AddressUtils } from '../../utils/address.utils';
import { Subject } from 'rxjs';
import { CustomerFacade } from '../../facades/customer.facade';
import { takeUntil } from 'rxjs/operators';
import { EGlueResource, EStoreFeatures, EStoreTypes } from '../../configurations/common';
import { AppUtils } from '../../utils/app.utils';
import { layout } from './layout';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-new-delivery-address-modal',
  templateUrl: './new-delivery-address-modal.component.html',
  styleUrls: ['./new-delivery-address-modal.component.scss'],
})
export class NewDeliveryAddressModalComponent implements OnInit, OnChanges, OnDestroy {
  isSparepartsStore: boolean = false;
  isCaStore: boolean = false;
  isUsStore: boolean = false;
  currentStoreId: string;
  addressForm: UntypedFormGroup;
  iso2Codes: Iiso2Codes[] = AddressUtils.getCountriesForDeliveryAddress();
  data: any;
  maxLengthName: number = 50;
  maxLengthZipcode: number = 15;
  maxLengthCity: number = 35;
  maxLengthState: number = 35;
  maxLengthStreetAndNumber: number = 50;
  minLengthState: number = 2;
  minLengthCity: number = 3;
  auth0UserId: any;
  addNewAddressIsInProgress: boolean = false;
  saveAddressForLater: boolean = false;

  private unsubscribe$ = new Subject<void>();

  @Input() showModal: boolean;

  @Output() closeModal = new EventEmitter<any>();
  @Output() newAddress: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    private formBuilder: UntypedFormBuilder,
    private checkoutFacade: CheckoutFacade,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.currentStoreId = AppUtils.getCurrentStore().storeId;
    this.isSparepartsStore = this.configurationFacade.isFeatureAvailable(EStoreFeatures.SPARE_PARTS);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.setMaxLengthForInputBasedOnCountry();
    this.initializeForm();
    this.selectAuth0User();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal.currentValue) {
      this.initializeForm();
    }
  }

  initializeForm(): void {
    this.addressForm = this.formBuilder.group({
      country: this.iso2Codes.find(i => i.isDefault) || '',
      name: ['', [
        Validators.required,
        Validators.maxLength(this.maxLengthName),
        ValidationPatterns.noEmptySpaceOnTheBeginning,
      ]],
      streetAndNumber: ['', [
        Validators.required,
        Validators.maxLength(this.maxLengthName),
        ValidationPatterns.streetAndNumberAndZipCodePattern,
      ]],
      zipCode: ['', [
        Validators.required,
        Validators.maxLength(this.maxLengthZipcode),
        ValidationPatterns.zipCodeValidation(
          new RegExp(
            this.isCaStore
              ? ValidationPatterns.canadaZipCodeRegex
              : ValidationPatterns.streetAndNumberAndZipCodeRegex),
          'generalZipCode',
        ),
      ]],
      city: ['', [
        Validators.required,
        Validators.minLength(this.minLengthCity),
        Validators.maxLength(this.maxLengthCity),
        ValidationPatterns.cityStatePattern,
      ]],
      state: ['', [
        Validators.required,
        Validators.minLength(this.minLengthState),
        Validators.maxLength(this.maxLengthState),
        ValidationPatterns.cityStatePattern,
        ...(this.isSparepartsStore ?
          [ValidationPatterns.uppercase] : []),
      ]],
    });
  }

  resetForm(): void {
    this.closeModal.emit();
    this.addressForm.reset();
  }

  formIsValid(): boolean {
    return this.addressForm.status === 'VALID';
  }

  /**
   * Save new address from modal:
   * - if checkbox is checked, address will be saved via POST (postCustomerAddresses).
   * - if checkbox is unchecked, address will be saved only for current order flow.
   */
  saveAddress(): void {
    this.addNewAddressIsInProgress = true;
    this.createDataForPostNewAddress();

    if (this.saveAddressForLater) {
      this.customerFacade.beginAddCustomerAddressAction(`${this.auth0UserId}`, this.data);
    }

    this.constructNewAddress(this.data);
    this.addNewAddressIsInProgress = false;
    this.resetForm();
    this.saveAddressForLater = false;
  }

  createDataForPostNewAddress(): void {
    this.data = {
      data: {
        type: EGlueResource.ADDRESSES,
        attributes: {
          salutation: 'Ms',
          firstName: this.addressForm.value.name,
          lastName: 'empty',
          address1: this.addressForm.value.streetAndNumber,
          address2: this.addressForm.value.state,
          address3: '',
          company: this.isSparepartsStore ? this.addressForm.value.name : '',
          country: this.isSparepartsStore ? this.addressForm.value.country : this.addressForm.value.country.name,
          zipCode: this.addressForm.value.zipCode,
          city: this.addressForm.value.city,
          iso2Code: this.addressForm.value.country.value,
          isDefaultShipping: true,
          isDefaultBilling: false,
        },
      },
    };
  }

  setCountry(event: any): void {
    this.addressForm.patchValue({
      [event.key]: event,
    });
  }

  constructNewAddress(data: any): void {
    if (data?.data?.attributes) {
      const newAddress = {
        name: AddressUtils.createAddressString(data.data.attributes),
        value: {...data.data.attributes, isCustom: true},
      };

      this.newAddress.emit(newAddress);
    }
  }

  selectAuth0User(): void {
    this.customerFacade.getAuth0CustomerFromStore().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(user => {
      if (user) {
        this.auth0UserId = user['https://login-shs.siemens-healthineers.com/gid']?.replace('"', '');
      }
    });
  }

  setMaxLengthForInputBasedOnCountry(): void {
    if (this.isSparepartsStore) {
      this.maxLengthName = 35;
      this.maxLengthZipcode = 10;
      this.maxLengthCity = 30;
      this.maxLengthState = 2;
      this.minLengthCity = 2;
      this.maxLengthStreetAndNumber = 35;
    }

    if (this.isCaStore) {
      this.maxLengthZipcode = 7;
    }
  }

  getColumn(index: number): string {
    if (layout.hasOwnProperty(this.currentStoreId)) {
      return layout[this.currentStoreId][index].class;
    } else {
      return layout.default[index].class;
    }
  }

  getOrder(index: number): string {
    if (layout.hasOwnProperty(this.currentStoreId)) {
      return layout[this.currentStoreId][index].order;
    } else {
      return layout.default[index].order;
    }
  }

  getCustomZipCodeValidator(): void {
    const generalZipCodeValidator: ValidatorFn = ValidationPatterns.zipCodeValidation(
      new RegExp(
        this.isCaStore
          ? ValidationPatterns.canadaZipCodeRegex
          : ValidationPatterns.streetAndNumberAndZipCodeRegex),
      'generalZipCode',
    );

    const puertoRicoZipCodeValidator = ValidationPatterns.zipCodeValidation(
      new RegExp(ValidationPatterns.puertoRicoZipCodeRegex), 'puertoRicoZipCode',
    );

    if (this.isUsStore && this.addressForm?.value?.state == ValidationPatterns.puertoRicoState) {
      this.addressForm.controls['zipCode'].setValidators([puertoRicoZipCodeValidator, Validators.required]);
    } else {
      this.addressForm.controls['zipCode'].setValidators([generalZipCodeValidator, Validators.required]);
    }

    this.addressForm.controls['zipCode'].setValue(this.addressForm?.value?.zipCode);
  }
}
