export class StringUtils {
  static toLowerCaseWithoutWhiteSpaceOrSlash(text: string, replaceString: string): string {
    return text.toLowerCase().replace(/\s|\/|&/g, replaceString);
  }

  static capitalize(text: string): string {
    if (typeof text !== 'string') {
      return '';
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  static removeTags(str: string): string {
    if (str) {
      return str.replace(/<[^>]*>/ig, ''); //NOSONAR
    }
    return '';
  }

  static replaceUnderscores(text: string, replaceString: string): string {
    if (text) {
      return text.replace(/_/g, replaceString);
    }
    return '';
  }

  static substringFromChar(str: string, char: string): string {
    if (!str) {
      return '';
    }
    const indexOfChar = str.indexOf(char);
    return str.substr(0, indexOfChar > -1 ? indexOfChar : str.length);
  }

  static updatePathname(str: string): string {
    if (!str) {
      return '';
    }
    const indexOfChar = str.lastIndexOf('/');
    return indexOfChar > 0 ? str : `${str}/`;
  }

  /**
   * Replaces spaces in string with another string.
   *
   * @static
   *
   * @param {string} originalString
   * @param {string} replaceString
   * @returns {string}
   */
  static replaceSpaces(originalString: string, replaceString: string): string {
    if (!originalString || !replaceString) {
      return '';
    }

    return originalString.replace(/ /g, replaceString);
  }

  /**
   * Converts a camelCase string to kebab-case string.
   * @static
   * @param  {string} originalString
   * @returns {string}
   */
  static camelToKebab(originalString: string): string {
    if (!originalString) {
      return '';
    }
    return originalString.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  /**
   * Converts a kebab-case string to camelCase string.
   * @static
   * @param {string} originalString
   * @returns {string}
   */
  static kebabToCamel(originalString: string): string {
    if (!originalString) {
      return '';
    }
    return originalString.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
  }

  /**
   * Returns a translation string based on the amount.
   * @static
   * @param {number} amount
   * @returns {string}
   */
  static getItemTranslationBasedOnAmount(amount: number): string {
    return amount === 1 ? 'item-singular' : 'item-plural';
  }
}
