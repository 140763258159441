<div class="container order-details">
  <div [appLoadingAnimation]="!isLoaded" class="app-loading">
    <div class="row">
      <div class="col-lg-1 hidden-md-down"></div>
      <div class="col-12 col-lg-10">
        <ng-container *ngIf="hybrisOrderDetails">
          <div class="page-header">
            <h3 class="page-title" [attr.data-test]="'title-orderDetails'">
              {{ 'order-tracking-history.order-details' | translate }}
            </h3>
            <div class="page-row">
              <h6 class="order-id" [attr.data-test]="'title-orderStatus'">
                {{ 'order-tracking-history.status-of-order' | translate }} {{ hybrisOrderDetails.id }}
              </h6>
              <div class="action-link">
                <a class="button button--link button--back-to-purchase-activity"
                   (click)="redirectBackToPurchaseActivity()" [attr.data-test]="'btn-backToPurchaseActivity'">
                  <span class="icon-arrow-left color-orange"></span>
                  {{ 'order-details.back-to-purchase-activity' | translate }}
                </a>
              </div>
            </div>
          </div>
          <div class="order-accordion">
            <app-accordion [manualOpen]="orderDetailsAccordion" [attr.data-test]="'orderDetail-' + hybrisOrderDetails.id">
              <ng-container ngProjectAs="[slot][header]">
                <div class="accordion__header accordion__title icon-arrow-down"
                    (click)="toggleOrderDetailsAccordion()"
                    [attr.data-test]="'desc-toggleOrderedItemsAccordion'">
                  <div class="accordion__header--table">
                    <table class="table table--ordered-items" aria-label="Order-details">
                      <thead>
                        <tr>
                          <th [attr.data-test]="'label-orderId'">{{ 'order-thank-you-page.order' | translate }}</th>
                          <th [attr.data-test]="'label-orderedOn'">{{ 'order-thank-you-page.ordered-on' | translate }}</th>
                          <th [attr.data-test]="'label-totalSum'">{{ 'order-thank-you-page.total-sum' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="hybrisOrderDetails">
                          <td [attr.data-test]="'desc-orderId'">{{ hybrisOrderDetails.id }}</td>
                          <td [attr.data-test]="'desc-orderedOn'">{{ hybrisOrderDetails.attributes?.orderCreationDate | formatDate }}</td>
                          <td [attr.data-test]="'desc-totalSum'">{{ hybrisOrderDetails.attributes?.orderTotal | currency: 'USD' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-container>
              <ng-container ngProjectAs="[slot][panel]">
                <div class="accordion__content">
                  <div class="row margin-0">
                    <div class="item-section col-12 col-md-7 col-lg-8">
                      <ng-container *ngFor="let item of hybrisOrderDetails.attributes?.items">
                        <div class="item-details--row">
                          <div class="item-details--content" [attr.data-test]="'order-item-' + item.productName">
                            <div class="content-column">
                              <h5 class="item-name">{{ item.productName }}</h5>
                              <p class="item-material-number">
                                {{ item.productNumber }}
                              </p>
                            </div>
                            <div class="content-row">
                              <div class="column">
                                <div class="item-quantity">
                                  <span>{{ 'order-thank-you-page.quantity' | translate }}</span>
                                  <span class="quantity">{{ item.quantity }}</span>
                                </div>
                                <div class="item-price">
                                  <span>{{ 'order-thank-you-page.total-price' | translate }}</span>
                                  <span class="price">{{ item.itemPrice | currency: 'USD' }}</span>
                                </div>
                              </div>
                              <div class="column">
                                <div class="item-status">
                                  <span>{{ 'spare-parts-order-details-page.item-shipping-status' | translate }}</span>
                                  <span class="status">{{ getFormattedStatus(item.status) }}</span>
                                </div>
                                <div class="item-tracking-number">
                                  <span>{{ 'spare-parts-order-details-page.item-tracking-number' | translate }}</span>
                                  <div class="tracking-number">
                                    <ng-container *ngIf="getTrackingNumbers(item)?.length > 0; else noTrackingNumber">
                                      <ng-container *ngFor="let trackingNumber of getTrackingNumbers(item)">
                                        <a href="{{ ('order-tracking-history.tracking-number-sp-link' | translate) + trackingNumber }}" class="tracking-number--link"
                                            target="_blank" rel="noopener" [attr.data-test]="'link-trackingNumber'">
                                          <span class="icon-arrow-right color-orange"></span>
                                          <span>{{ trackingNumber }}</span>
                                        </a>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noTrackingNumber>
                                      <span>-</span>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="address-section col-12 col-md-5 col-lg-4">
                      <div class="address-section--delivery-address">
                        <h5 class="title">
                          {{ 'order-thank-you-page.delivery-address' | translate }}
                        </h5>
                        <p>
                          {{ hybrisOrderDetails.attributes.shipToName }}<br>
                          {{ hybrisOrderDetails.attributes.shipToAddress }}<br>
                          {{ hybrisOrderDetails.attributes.shipToTown }} {{ hybrisOrderDetails.attributes.shipToPostalOffice }}
                        </p>
                      </div>
                      <div class="address-section--shipping-method" *ngIf="hybrisOrderDetails.attributes?.items[0]?.shippingMethod">
                        <h5 class="title">
                          {{ 'order-thank-you-page.shipping-method' | translate }}
                        </h5>
                        <p>{{ hybrisOrderDetails.attributes.items[0].shippingMethod }}</p>
                      </div>
                      <div class="address-section--purchase-order">
                        <strong class="text-uppercase">
                          {{ 'order-thank-you-page.purchase-order' | translate }}
                        </strong>
                        <span>{{ hybrisOrderDetails.attributes?.poNumber }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </app-accordion>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
