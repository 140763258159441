<ng-container>
  <app-modal
      [open]="showModal"
      [id]="'addItemToCart'"
      class="add-item-modal"
      title="{{'add-item-modal.title' | translate}}"
      (crossClick)="close()"
      (outsideClick)="close()">
    <ng-container ngProjectAs="[slot][body]">
      <h4 [attr.data-test]="'title-itemModal'" class="head-title">
        {{ 'add-item-modal.add-to-existing-cart-title' | translate }}
      </h4>
      <p>{{ 'add-item-modal.add-to-existing-cart-text' | translate }}</p>
      <div *ngIf="!isLoadingCarts else loadingCarts" class="existing-cart">
        <div class="accordion__title desktop">
          <div class="cart__selector"></div>
          <div [attr.data-test]="'desc-cartId'" class="cart__id">
            {{ 'add-item-modal.cart-id' | translate }}
          </div>
          <div [attr.data-test]="'desc-cartNickname'" class="cart__nickname">
            {{ 'add-item-modal.cart-nickname' | translate }}
          </div>
          <div [attr.data-test]="'desc-cartFunctionalLocation'" class="cart__fl">
            {{ 'add-item-modal.cart-functional-location' | translate }}
          </div>
          <div [attr.data-test]="'desc-cartEquipment'" class="cart__equipment">
            {{ 'add-item-modal.cart-equipment' | translate }}
          </div>
        </div>
        <div class="accordion__title mobile">
          <div class="cart__selector"></div>
          <div>
            {{ 'add-item-modal.cart-nickname' | translate }}
          </div>
        </div>
        <div class="agreements__table">
          <div class="table-items">
            <app-accordion
                *ngFor="let cart of carts"
                [manualOpen]="tabsOpened.includes(cart?.id)">
              <ng-container ngProjectAs="[slot][header]">
                <div class="accordion__title icon-arrow-down"
                     [ngClass]="{'disabled': isContractInCart(cart)}"
                     (click)="toggleTab(cart?.id)">
                    <span class="cart__selector">
                      <input
                          [attr.data-test]="'input-selectedCart'"
                          id="radio-{{cart?.id}}"
                          type="radio"
                          name="cartSelected"
                          [value]="cart.id"
                          [(ngModel)]="radioInputReset"
                          [disabled]="isContractInCart(cart) || (isSapStore ? !isCartAvailableForItem(cart) : false)"
                          class="radio"
                          (change)="selectCart(cart)">
                      <label for="radio-{{cart?.id}}"></label>
                    </span>
                  <span [attr.data-test]="'desc-cartId'" class="agreement-info cart__id desktop">{{ cart?.id }}</span>
                  <span [attr.data-test]="'desc-cartName'"
                        class="agreement-info cart__nickname desktop">{{ cartName(cart) }}</span>
                  <span [attr.data-test]="'desc-cartNameMobil'"
                        class="agreement-info cart__nickname mobile">{{ cartName(cart) }}</span>
                  <span [attr.data-test]="'desc-cartEquipmentId'" class="agreement-info cart__fl desktop">
                    {{ cart.attributes?.systemDetails?.siemensEquipmentId }}
                  </span>
                  <span [attr.data-test]="'desc-cartSystemName'" class="agreement-info cart__equipment desktop">
                    {{ cart.attributes?.systemDetails?.nameEnUs }}
                  </span>
                </div>
              </ng-container>
              <ng-container ngProjectAs="[slot][panel]">
                <div class="container accordion__content" *ngIf="tabsOpened.includes(cart?.id)">
                  <div *ngIf="cart?.attributes?.totalItemsQuantity > 0 else emptyCart">
                    <div *ngIf="!isContractInCart(cart) else hasContract">
                      <table aria-label="Cart-information">
                        <thead>
                        <tr>
                          <th scope="col">{{ 'add-item-modal.item' | translate }}</th>
                          <th scope="col">{{ 'add-item-modal.material-number' | translate }}</th>
                          <th scope="col">{{ 'add-item-modal.quantity' | translate }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of cart?.items">
                          <td class="width-60" [attr.data-test]="'desc-itemAttributeName'">
                            {{ item?.details?.attributes?.name }}
                          </td>
                          <td [attr.data-test]="'desc-itemAttributeMaterialNumber'">
                            {{ item?.details?.attributes?.attributes?.material_number }}
                          </td>
                          <td [attr.data-test]="'desc-itemQuantity'">{{ item?.quantity }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <ng-template #hasContract>
                    <p class="cart__message">
                      <span class="icon icon-close icon-close__circle"></span>
                      <span class="text">{{ 'add-item-modal.configurable-items' | translate }}</span>
                    </p>
                  </ng-template>
                  <ng-template #emptyCart>
                    <p class="cart__message">{{ 'add-item-modal.no-items' | translate }}</p>
                  </ng-template>
                </div>
              </ng-container>
            </app-accordion>
          </div>
        </div>
        <div class="modal-btn">
          <button
              [attr.data-test]="'button-addItemToCart'"
              *ngIf="!isAddToExistingCartBtn && hasSelectedCartSystemDetails(); else loadingBtn"
              type="submit"
              class="button button--primary add-item-to-cart-btn"
              [disabled]="isReorderButtonDisabled()"
              (click)="addItemToExistingCart()">
            {{ 'add-item-modal.add-to-existing-cart-button' | translate }}
          </button>
        </div>
      </div>
      <ng-template #loadingCarts>
        <div class="loader-carts">
          <div class="loading-animation"></div>
        </div>
      </ng-template>
      <div class="new-cart">
        <h4 [attr.data-test]="'title-addToNewCart'">{{ 'add-item-modal.add-to-new-cart-title' | translate }}</h4>
        <p class="margin-bottom-0">{{ 'add-item-modal.add-to-new-cart-text' | translate }}</p>
        <div class="modal-btn">
          <button
              [attr.data-test]="'button-addItemToNewCart'"
              *ngIf="!isAddToNewCartBtn else loadingBtn"
              type="submit"
              class="button button--primary"
              [disabled]="isAddToCartInProgress || !currentCartId || !isSapP40Enable"
              (click)="addItemToNewCart()">
            {{ 'add-item-modal.add-to-new-cart-button' | translate }}
          </button>
          <ng-template #loadingBtn>
            <div class="loader-btn">
              <div class="loading-animation"></div>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </app-modal>
  <app-modal-confirm
      [type]="iconType.WARNING"
      modalTitle="{{ 'catalog.select-equipment-modal.title' | translate }}"
      notifyText="{{ 'catalog.select-equipment-modal.text' | translate }}"
      cancelBtnText="{{ 'catalog.select-equipment-modal.cancel-btn' | translate }}"
      primaryBtnText="{{ 'catalog.select-equipment-modal.submit-btn' | translate }}"
      [showModalConfirm]="showModalSelectEquipment"
      (closeModalConfirm)="showModalSelectEquipment = false"
      (primaryActionConfirm)="selectEquipment()">
  </app-modal-confirm>
</ng-container>
