<ng-container *ngIf="isMultiCartActive; else shopCart">
  <app-multi-cart
      [isExportCartActive]="isExportCartActive$ | async">
  </app-multi-cart>
</ng-container>
<ng-template #shopCart>
  <app-shop-cart
      [isExportCartActive]="isExportCartActive$ | async">
  </app-shop-cart>
</ng-template>
