import { IShsEquipmentData, IShsEquipmentDataAttributes } from '../models/installedbase.models';
import { IAddress, ISystemDetails } from '../models/common.models';
import { EGlueResource } from '../configurations/common';
import { notFoundValue, notInstallBaseRelated } from '../shared/ivk-selection-form/IvkSelectionFormConstants';
import { ICPQAddress } from '../models/cpq.models';

export class EquipmentUtils {

  static getAttribute(
    agreement: IShsEquipmentData,
    attribute: string,
    nestedAttribute?: string,
  ): string {
    if (nestedAttribute) {
      if (agreement.attributes[attribute]) {
        return agreement.attributes[attribute][nestedAttribute] ? agreement.attributes[attribute][nestedAttribute] : '-';
      } else return '-';
    } else {
      return agreement.attributes[attribute] ? agreement.attributes[attribute] : '-';
    }
  }

  /**
   * Converts selected system/equipment from ivk selection to system details object
   * for setting to the current cart.
   *
   * NOTE: This function is intended mainly for non-SAP store workflows.
   *
   * @param ivkSelectionFormValue
   * @returns {ISystemDetails}
   */
  static convertIvkSelectionToSystemDetails = (ivkSelectionFormValue: any): ISystemDetails => {
    if ([notFoundValue, notInstallBaseRelated].includes(ivkSelectionFormValue.system)) {
      return {
        serialNumber: ivkSelectionFormValue.serialNumber,
        siemensEquipmentId: ivkSelectionFormValue.siemensEquipmentId,
        materialNumber: ivkSelectionFormValue.materialNumber,
        namePtBr: ivkSelectionFormValue.namePtBr,
        nameEnUs: ivkSelectionFormValue.nameEnUs,
        company: ivkSelectionFormValue.company,
        companyBusinessUnit: ivkSelectionFormValue.companyBusinessUnit,
        companyBusinessUnitNumber: ivkSelectionFormValue.companyBusinessUnitNumber,
        isSparePartsAvailable: false,
      };
    } else {
      // if none of the "special input options" of an ivk form were selected,
      // it's assumed that the user selected some equipment returned from Glue
      const equipment = {type: EGlueResource.SHS_EQUIPMENTS, id: '', links: {self: ''}};
      return EquipmentUtils.convertEquipmentToSystemDetails(
        {...equipment, attributes: ivkSelectionFormValue?.system?.additional},
      );
    }
  };

  /**
   * Convert shs-equipment resource to cart-compatible SystemDetails object.
   *
   * @param {IShsEquipmentData} equipment
   * @returns {ISystemDetails}
   */
  static convertEquipmentToSystemDetails = (equipment: IShsEquipmentData): ISystemDetails => {
    const att: IShsEquipmentDataAttributes = equipment.attributes;
    return {
      functionalLocation: att.functionalLocation,
      department: att.department,
      serialNumber: att.serialNumber,
      siemensEquipmentId: att.siemensEquipmentId,
      materialNumber: att.materialNumber,
      namePtBr: att.materialName ?? att.namePtBr,
      nameEnUs: att.materialName ?? att.nameEnUs,
      company: att.company,
      companyBusinessUnit: att.companyBusinessUnit,
      companyBusinessUnitNumber: att.companyBusinessUnitNumber,
      contractStartDate: att.contractStartDate,
      contractExpirationDate: att.contractExpirationDate,
      endOfSupport: att.endOfSupport,
      myEquipmentName: att.myEquipmentName,
      customerDesc: att.customerDesc,
      externalUrlSmall: att.imageUrlSmall ?? att.externalUrlSmall,
      contractName: att.contractName,
      contractNumber: att.contractNumber,
      isSparePartsAvailable: att.isSparePartsAvailable,
      // as the shipToAddress property is never returned as null from Glue,
      // we can skip null-checks or creating an empty default object
      shipToAddress: EquipmentUtils.convertEquipmentShipToAddressToSystemDetailsShipToAddress(att.shipToAddress),
    };
  };

  private static convertEquipmentShipToAddressToSystemDetailsShipToAddress = (address: IAddress): ICPQAddress => {
    return {
      name: address.company,
      address1: address.address1,
      address2: address.address2,
      address3: address.address3,
      city: address.city,
      zipCode: address.zipCode,
      state: address.state,
      sapId: address.sapId,
      iso2Code: address.iso2Code,
      companyUnitAddressUuid: null,
      comment: null,
      phone: address.phone,
    };
  }
}
