<div class="mini-cart"
     [ngClass]="{'is-open': isOpen, 'alert alert--success': showNewItemAddedNotification}">
  <div class="mini-cart__content" *ngIf="isMinicartNotAccessible()">
    <div class="mini-cart__no-access">
      <app-access-control [description]="true">
      </app-access-control>
    </div>
  </div>
  <div class="mini-cart__content" *ngIf="isMinicartAccessible()">
    <div class="mini-cart__header" *ngIf="showNewItemAddedNotification">
      <div class="mini-cart__indicator">
        <span class="icon alert__indicator" aria-hidden="true"></span>
        <h5 class="mini-cart__title" id="addedToCartAlertTitle">
          {{ 'shop-cart.item-added-title' | translate }}
        </h5>
      </div>
      <p class="mini-cart-item-name" *ngIf="isLastAddedItemLoaded">
        {{ 'mini-cart.item-added-message' | translate: {name: itemName} }}
      </p>
    </div>
    <div class="mini-cart__body">
      <app-configurable-mini-cart
          *ngIf="isContractInCart()"
          [currency]="currency"
          [currentCartId]="currentCart?.id"
          [currentCartName]="currentCartName"
          [usersCarts]="usersCarts"
          [itemsInCart]="itemsInCart"
          [hasNickname]="hasNickname"
          (showAddNicknameModal)="addNicknameModalActive = $event"
          (showDeleteQuoteModal)="deleteQuoteModalActive = $event"
          (redirectToHomepage)="redirectToHomepageOnCartOperationFinish = true">
      </app-configurable-mini-cart>
      <app-multi-mini-cart
          *ngIf="!isContractInCart()"
          [currency]="currency"
          [currentCartId]="currentCart?.id"
          [currentCartName]="currentCartName"
          [usersCarts]="usersCarts"
          [itemsInCart]="itemsInCart"
          [hasNickname]="hasNickname"
          [isApprover]="isApprover()"
          [checkWorkflowInformation]="checkWorkflowInformation()"
          [isExcludeTaxActive]="isExcludeTaxActive"
          (showAddNicknameModal)="addNicknameModalActive = $event"
          (showDeleteCartModal)="deleteCartModalActive = $event"
          (updateCartItemQuantity)="updateCartItemQuantity($event)"
          (redirectToHomepage)="redirectToHomepageOnCartOperationFinish = true">
      </app-multi-mini-cart>
    </div>
    <div class="mini-cart__min-order-value" *ngIf="!isMinimumOrderValue()">
      <strong>
        {{ 'shop-cart.minimum-order-value' | translate }}
      </strong>
    </div>
    <div class="mini-cart__total" *ngIf="!!totalPrice && checkWorkflowInformation()">
      <div class="total-title">
        {{ 'mini-cart.total' | translate }}
        <ng-container *ngIf="showPriceDisclaimer">
          ({{ 'shop-cart.excludes-vat' | translate }})
        </ng-container>
      </div>
      <div class="total-price" [attr.data-test]="'desc-minicartTotalPrice'">
        {{ totalPrice | currency:currency }}
        <span *ngIf="isExcludeTaxActive" class="tax-disclaimer">({{ 'app-product-page.ex_gst' | translate }})</span>
      </div>
    </div>
    <div class="mini-cart__discounts" *ngIf="cartRules && cartRules?.length > 0 && !rfqActive">
      <app-cart-rules [cartRules]="cartRules" [priceToPay]="priceToPay" [currency]="currency"></app-cart-rules>
    </div>
    <div class="mini-cart__footer" *ngIf="itemsInCart?.length; else noItemsInCartTemplate">
      <button type="button" class="button" (click)="closeMiniCart()" [attr.data-test]="'button-continueShopping'">
        {{ 'mini-cart.continue-shopping' | translate }}
      </button>
      <button type="button" class="button button--primary"
              [routerLink]="['/shop-cart']"
              (click)="closeMiniCart()"
              [attr.data-test]="'button-goToCart'">
        {{ getLabelForCartButton() | translate }}
      </button>
    </div>
    <ng-template #noItemsInCartTemplate>
      <div class="mini-cart__no-items">
        <p class="message">
          {{ 'shop-cart.empty-cart-message' | translate }}
        </p>
        <button type="button" class="button"
                (click)="closeMiniCart()"
                data-dismiss="alert"
                [attr.data-test]="'button-continueShopping'">
          {{ 'mini-cart.continue-shopping' | translate }}
        </button>
      </div>
    </ng-template>
    <div class="loader__overlay" *ngIf="isCartOperationInProgress">
      <span class="loading-animation"></span>
    </div>
  </div>
</div>
<app-modal-add-cart-name
    *ngIf="currentCart"
    [cartId]="currentCart.id"
    [hasNickname]="hasNickname"
    [newNameChange]="currentCartName$"
    [showAddNicknameModal]="addNicknameModalActive"
    [isContractInCart]="isContractInCart()"
    (closeModal)="addNicknameModalActive = false">
</app-modal-add-cart-name>
<app-modal-confirm
    [type]="iconType.WARNING"
    [showModalConfirm]="deleteQuoteModalActive"
    [modalTitle]="'mini-cart.confirm-quote-delete-title' | translate"
    [notifyText]="'mini-cart.quote-delete-msg' | translate"
    [primaryBtnText]="'mini-cart.btn-delete' | translate"
    [cancelBtnText]="'mini-cart.btn-cancel' | translate"
    (primaryActionConfirm)="deleteQuote()"
    (closeModalConfirm)="deleteQuoteModalActive = false">
</app-modal-confirm>
<app-modal-confirm
    [type]="iconType.WARNING"
    [showModalConfirm]="deleteCartModalActive"
    [modalTitle]="'mini-cart.confirm-cart-delete-title' | translate"
    [notifyText]="'mini-cart.cart-delete-msg' | translate"
    [primaryBtnText]="'mini-cart.btn-delete' | translate"
    [cancelBtnText]="'mini-cart.btn-cancel' | translate"
    (primaryActionConfirm)="deleteCart()"
    (closeModalConfirm)="deleteCartModalActive = false">
</app-modal-confirm>
