import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, forkJoin, skipWhile } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ECheckoutFlows, EGlueResource, ETermsAndConsentsType } from '../../../../configurations/common';
import { ISoldToAddressesResponse, ISoldToIncludedResource } from '../../../../models/soldTo-selection.models';
import { IAddress } from '../../../../models/common.models';
import { AddressUtils } from '../../../../utils/address.utils';
import {
  ICompanyBusinessUnitAddresses,
  ICustomerCheckoutData,
  ICustomerPreferences,
} from '../../../../models/customer.models';
import { CustomerFacade } from '../../../../facades/customer.facade';
import { SoldToAccountsFacade } from '../../../../facades/sold-to.facade';
import { MarketingFacade } from '../../../../facades/marketing.facade';
import { ICartAttributes, ICartItemWithDetail, ICartVoucher } from '../../../../models/cart.models';
import { CheckoutActions } from '../../../../actions';
import { GlueUtils } from '../../../../utils/glue.utils';
import { IConsentCheckbox } from '../../../../models/checkout.models';
import { ConfigurationFacade } from '../../../../facades/configuration.facade';

@Injectable({
  providedIn: 'root',
})

//TODO: Rename class, e.q. "DeliveryDetailsBusinessService"
export class DeliveryDetailsService {
  userData: ICustomerCheckoutData;
  cartAttributes: ICartAttributes;
  customerPreferences: ICustomerPreferences;
  preferredShipToAddress: IAddress;
  availableShipToAddresses: IAddress[] = [];
  availableBillToAddresses: IAddress[] = [];
  prefilledBillToAddressId: string | null;
  prefilledShipToAddressId: string | null;
  isBusinessPartner: boolean = false;

  constructor(
    private customerFacade: CustomerFacade,
    private soldToFacade: SoldToAccountsFacade,
    private marketingFacade: MarketingFacade,
    private configurationFacade: ConfigurationFacade,
    private store: Store,
  ) {
  }

  /**
   * Resolve checkout flow based on the feature toggles.
   *
   * @param {boolean} useRfqFlow
   * @param {boolean} useGenerateQuoteFlow
   * @return {ECheckoutFlows}
   */
  _resolveRfqFlowConfig(useRfqFlow: boolean, useGenerateQuoteFlow: boolean): ECheckoutFlows {
    if (useRfqFlow && useGenerateQuoteFlow) {
      return ECheckoutFlows.GENERATE_QUOTE_FLOW;
    } else if (useRfqFlow && !useGenerateQuoteFlow) {
      return ECheckoutFlows.REQUEST_QUOTE_FLOW;
    } else {
      return ECheckoutFlows.REGULAR_FLOW;
    }
  }

  /**
   * Begin the process of updating delivery details data.
   */
  updateDeliveryDetailsData(): void {
    this.store.dispatch(CheckoutActions.setLoadingDeliveryDetailsData({loadingDeliveryDetailsData: true}));
    combineLatest([
      this.customerFacade.selectCustomerData(),
      this.customerFacade.isBusinessPartner(),
      this.customerFacade.selectCustomerPreferences(),
      this.marketingFacade.getCurrentCartItems(),
    ]).pipe(
      skipWhile(([, , customerPreferences]) => customerPreferences === null),
      take(1),
    ).subscribe(([userData, isBusinessPartner, customerPreferences, cartData]) => {
      this.userData = userData;
      this.isBusinessPartner = isBusinessPartner;
      this.customerPreferences = customerPreferences;
      this.cartAttributes = cartData.data.attributes;
      this._selectShipToAndBillToAddresses();
      this.store.dispatch(CheckoutActions.setCartVouchers({
        cartVouchers: GlueUtils.filterResourcesByType(cartData.included, EGlueResource.VOUCHERS) as ICartVoucher[],
      }));
    });
  }

  /**
   * Select specific address resources based on the workflow.
   *
   * @private
   */
  private _selectShipToAndBillToAddresses(): void {
    combineLatest([
      this.isBusinessPartner
        ? this.soldToFacade.getSoldToAccountsById(this.cartAttributes?.soldToAddress?.sapId, [EGlueResource.SHIP_TO_ADDRESSES, EGlueResource.BILL_TO_ADDRESSES])
        : this.customerFacade.selectCustomerShipToAddresses(),
      this.customerFacade.selectCustomCustomerShipToAddress(),
      this.customerFacade.selectCustomerAddresses(),
    ]).pipe(
      skipWhile(([addresses, , customerAddresses]) => !addresses || !customerAddresses),
      take(1),
    ).subscribe(([addresses, customAddress, customerAddresses]) => {
      this.processShipToAndBillToAddresses(addresses, customAddress, customerAddresses);
    });
  }


  /**
   * Process shipTo address and billTo addresses.
   *
   * @param {ISoldToAddressesResponse | IAddress[]} addresses
   * @param {IAddress} customAddress
   * @param {IAddress[]} customerAddresses
   */
  processShipToAndBillToAddresses(
    addresses: ISoldToAddressesResponse | IAddress[],
    customAddress: IAddress,
    customerAddresses: IAddress[],
  ): void {
    let shipToAddress: IAddress;
    if (!this.isBusinessPartner) {
      this.availableShipToAddresses = this._mergeShipToAddresses(addresses, customAddress, customerAddresses);
      this.preferredShipToAddress = this.getPreferredShipToAddress(addresses as IAddress[], customerAddresses);
      const quoteShipToAddress: IAddress = {
        ...this.cartAttributes?.shippingAddress,
        attentionTo: this.cartAttributes?.pointOfContact?.attentionTo ?? this.cartAttributes?.shippingAddress?.attentionTo,
      };

      if (AddressUtils.isAddressValid(customAddress)) {
        shipToAddress = customAddress;
      } else if (AddressUtils.isAddressValid(this.preferredShipToAddress)) {
        shipToAddress = this.preferredShipToAddress;
      } else if (AddressUtils.isAddressValid(quoteShipToAddress)) {
        shipToAddress = quoteShipToAddress;
      }
    } else {
      const shipToAddresses = this.splitBPAddressesIntoShipToAndBillTo(addresses as ISoldToAddressesResponse);
      this.preferredShipToAddress = this.getPreferredShipToAddress(
        AddressUtils.unifyAddresses(shipToAddresses.map(address => address.attributes), this.userData),
        customerAddresses,
      );

      shipToAddress = this.getBpShipToAddress(shipToAddresses, customerAddresses, customAddress);
    }
    //check if current cart haa shipping address, in case YES, set this address as preselected
    if (this.cartAttributes.shippingAddress && !this.preferredShipToAddress) {
      this._setShipToAddress(this.cartAttributes.shippingAddress);
    } else {
      this._setShipToAddress(shipToAddress);
    }
  }

  /**
   * Create consent and terms checkboxes based on configuration of visibility and required fields.
   * @param termsAndConsentsType
   * @param {number} taxDisclaimer
   * @returns {IConsentCheckbox[]}
   */
  createTermsAndConsentsCheckboxes(termsAndConsentsType: ETermsAndConsentsType, taxDisclaimer: number): IConsentCheckbox[] {
    let consentsCheckboxes: IConsentCheckbox[] = [];
    forkJoin([
      this.configurationFacade.getDynamicConsentCheckboxes(termsAndConsentsType),
      this.configurationFacade.getDynamicRequiredCheckboxes(termsAndConsentsType),
    ]).pipe(
      map(([consentsConfig, requiredFields]) => {
        return consentsConfig.map(consent => ({
          name: consent,
          label: consent + `${((consent === 'tax_disclaimer') && taxDisclaimer) ? '_' + taxDisclaimer : ''}`,
          isChecked: false,
          isRequired: requiredFields.includes(consent),
        }));
      }),
      take(1),
    ).subscribe((consentsConfig) => {
      consentsCheckboxes = consentsConfig;
    });
    return consentsCheckboxes;
  }

  /**
   * Merge shipTo addresses from different sources
   *
   * @private
   *
   * @param {ISoldToAddressesResponse | IAddress[]} addresses
   * @param {IAddress} customAddress
   * @param {IAddress[]} customerAddresses
   * @return {IAddress[]}
   */
  private _mergeShipToAddresses(
    addresses: ISoldToAddressesResponse | IAddress[],
    customAddress: IAddress,
    customerAddresses: IAddress[],
  ): IAddress[] {
    const shipToAddresses = [
      ...customerAddresses,
      ...AddressUtils.unifyAddresses(addresses as IAddress[], this.userData),
    ];

    if (customAddress && !this._checkAddressDuplicity(shipToAddresses, customAddress)) {
      shipToAddresses.push(customAddress);
    }

    return shipToAddresses;
  }

  /**
   * Set shipTo address data to the state.
   *
   * @private
   *
   * @param {IAddress} shipToAddress
   */
  private _setShipToAddress(shipToAddress: IAddress): void {
    this.customerFacade.getBusinessUnitById('mine').subscribe(businessUnit => {
      const businessUnitAddress = AddressUtils.createShipToAddressFromBusinessUnitAddress(
        businessUnit,
        businessUnit?.included?.find(item => item.type === 'company-business-unit-addresses') as ICompanyBusinessUnitAddresses,
        this.userData,
        businessUnit?.data?.[0]?.attributes?.businessUnitNumber,
      );

      this.availableShipToAddresses.push(businessUnitAddress);

      if (shipToAddress) {
        this.store.dispatch(CheckoutActions.setPreselectedShipToAddress({preselectedShipToAddress: shipToAddress}));
      } else {
        this.store.dispatch(CheckoutActions.setPreselectedShipToAddress({preselectedShipToAddress: businessUnitAddress}));
      }

      this.store.dispatch(CheckoutActions.setAvailableShipToAddresses({availableShipToAddresses: this.availableShipToAddresses}));
      this.store.dispatch(CheckoutActions.setLoadingDeliveryDetailsData({loadingDeliveryDetailsData: false}));
    });
  }

  /**
   * Get preferred shipTo address.
   *
   * @param {IAddress[]} addresses
   * @param {IAddress} customerAddresses
   * @return {IAddress}
   */
  getPreferredShipToAddress(addresses: IAddress[], customerAddresses: IAddress[]): IAddress {
    let preferredAddress: IAddress = customerAddresses?.find(
      address => address?.id === this.customerPreferences?.preferredShipToId,
    );

    if (preferredAddress) {
      preferredAddress = {
        ...preferredAddress,
        sapId: this.cartAttributes?.soldToAddress?.sapId,
      };
    } else {
      preferredAddress = addresses?.find(address => address?.sapId === this.customerPreferences?.preferredShipToId);
    }

    return {...preferredAddress, company: preferredAddress?.name};
  }

  /**
   * Split BP addresses into shipTo and billTo addresses.
   *
   * @param {ISoldToAddressesResponse} addresses
   * @return {ISoldToIncludedResource[]}
   */
  splitBPAddressesIntoShipToAndBillTo(addresses: ISoldToAddressesResponse): ISoldToIncludedResource[] {
    const shipToAddresses: ISoldToIncludedResource[] = [];
    const billToAddresses: ISoldToIncludedResource[] = [];
    addresses?.included?.forEach(customerAddress => {
      if (customerAddress.type === EGlueResource.SHIP_TO_ADDRESSES) {
        shipToAddresses.push(customerAddress);
      }
      if (customerAddress.type === EGlueResource.BILL_TO_ADDRESSES) {
        billToAddresses.push(customerAddress);
      }
    });

    this.setBillToAddresses(billToAddresses);
    return shipToAddresses;
  }

  /**
   * Get business partner's shipTo addresses based on the business logic.
   *
   * @param {ISoldToIncludedResource[]} addresses
   * @param {IAddress[]} customerAddresses
   * @param {IAddress} customAddress
   * @return {IAddress}
   */
  getBpShipToAddress(addresses: ISoldToIncludedResource[], customerAddresses: IAddress[], customAddress: IAddress): IAddress {
    this.availableShipToAddresses = [];

    if (addresses?.length > 0) {
      addresses.forEach(address => {
        this.availableShipToAddresses.push(AddressUtils.unifyAddress(address?.attributes, this.userData));
      });
    }

    this.availableShipToAddresses = this.availableShipToAddresses.concat(customerAddresses);

    const preferredShipToAddress: IAddress = this.availableShipToAddresses?.find(address =>
      this.preferredShipToAddress?.isCustom
        ? address?.id && address?.id === this.preferredShipToAddress?.id
        : address?.sapId && address?.sapId === this.preferredShipToAddress?.sapId,
    );

    this._selectPrefilledShipToAddressId();
    let prefilledShipToAddress: IAddress;
    if (this.prefilledShipToAddressId) {
      prefilledShipToAddress = addresses?.find(address => address?.id === this.prefilledShipToAddressId)
        ? AddressUtils.unifyAddress(addresses?.find(address => address?.id === this.prefilledShipToAddressId)?.attributes, this.userData)
        : customerAddresses.find(address => address?.id === this.prefilledShipToAddressId);
    }

    const defaultShipToAddress = AddressUtils.unifyAddress(this.cartAttributes?.systemDetails?.shipToAddress, this.userData);
    this.availableShipToAddresses.push(defaultShipToAddress);

    if (customAddress) {
      this.availableShipToAddresses.push(customAddress);
      return customAddress;
    } else if (preferredShipToAddress) {
      return preferredShipToAddress;
    } else if (prefilledShipToAddress) {
      return prefilledShipToAddress;
    }

    return defaultShipToAddress;
  }

  /**
   * Find default bill to address based on the preselected soldTo address and set it to the state.
   *
   * @param {ISoldToIncludedResource} addresses
   */
  setBillToAddresses(addresses: ISoldToIncludedResource[]): void {
    this.availableBillToAddresses = [];
    if (addresses) {
      if (addresses.length > 0) {
        addresses.forEach((address) => {
          address.type === EGlueResource.BILL_TO_ADDRESSES
          && this.availableBillToAddresses.push(AddressUtils.unifyAddress(address, this.userData));
        });
        this._selectPrefilledBillToAddressId();
      }

      const billToAddress: IAddress = AddressUtils.unifyAddress(
        this.availableBillToAddresses?.find(address => address.id === this.prefilledBillToAddressId) ?? addresses[0],
        this.userData,
      );

      this.store.dispatch(CheckoutActions.setPreselectedBillToAddress({preselectedBillToAddress: billToAddress}));
      this.store.dispatch(CheckoutActions.setAvailableBillToAddresses({availableBillToAddresses: this.availableBillToAddresses}));
    }
  }

  private _selectPrefilledShipToAddressId(): void {
    this.marketingFacade.selectPrefilledShipToAddressId().subscribe(shipToAddressId => {
      this.prefilledShipToAddressId = shipToAddressId;
    });
  }

  private _selectPrefilledBillToAddressId(): void {
    this.marketingFacade.selectPrefilledBillToAddressId().subscribe(billToAddressId => {
      this.prefilledBillToAddressId = billToAddressId;
    });
  }

  /**
   * Delivery derail 2 section
   * Calculate total weight (for all items in cart)
   *
   * @return {number}
   */
  calculateTotalWeight(cartItemsWithDetails: ICartItemWithDetail[]): number {
    return cartItemsWithDetails.reduce((sum, item) => {
      return sum + item?.attributes?.quantity * (item?.attributes?.attributes?.sap_p40_gross_weight || 0);
    }, 0);
  }

  /**
   * Check if custom address is already included in addresses
   *
   * @private
   *
   * @param {IAddress[]} addresses
   * @param {IAddress} customAddress
   * @returns {boolean}
   */
  private _checkAddressDuplicity(addresses: IAddress[], customAddress: IAddress): boolean {
    return addresses.some(
      address => AddressUtils.createAddressString(address) === AddressUtils.createAddressString(customAddress),
    );
  }
}
