// This file contains default values that are imported to the other env files

import { EUserRoles } from '../app/configurations/common';

export const environment = {
  name: 'defaults',
  production: false,
  glueApiUrl: [],
  auth0Config: {
    domain: '',
    client_id: '',
    useRefreshTokens: true,
    audience: '',
  },
  features: {
    // feature names are defined in EFeatures enum
    quoteRequest: ['BR', 'GB', 'IN', 'JP', 'AU'],
    cpq: ['US'],
    spareParts: ['US', 'CA'],
    myProfile: ['US', 'CA', 'BR', 'GB', 'IN', 'JP', 'AU'],
    multiCart: ['US', 'CA', 'BR', 'GB', 'IN', 'JP', 'AU'],
    excludeTax: ['AU'],
    shopByCategory: ['US', 'AU', 'JP', 'GB', 'CA', 'BR', 'IN'],
  },
  protectedEndpoints: [
    {name: 'abstract-products', methods: ['get'], mode: 'GuestAndLogged'},
    {name: 'addresses-report-wrong', methods: ['post']},
    {name: 'carts', methods: ['get', 'post', 'patch', 'delete']},
    {name: 'carts-approval', methods: ['get']},
    {name: 'item-lists', methods: ['post', 'delete']},
    {name: 'catalog-search', methods: ['get'], mode: 'GuestAndLogged'},
    {name: 'checkout-update', methods: ['post']},
    {name: 'checkout-data', methods: ['post']},
    {name: 'checkout', methods: ['post']},
    {name: 'company-approvers', methods: ['get']},
    {name: 'company-users', methods: ['get']},
    {name: 'company-business-units', methods: ['get']},
    {name: 'concrete-products', methods: ['get']},
    {name: 'contact-us', methods: ['post']},
    {name: 'contact-us-general', methods: ['post']},
    {name: 'content-product-abstract-lists', methods: ['get'], mode: 'GuestAndLogged'},
    {name: 'cpq-accept', methods: ['post']},
    {name: 'cpq-cart-items-commit', methods: ['post']},
    {name: 'cpq-commit', methods: ['post']},
    {name: 'cpq-configuration-download', methods: ['get']},
    {name: 'cpq-contract-details-defaults', methods: ['post']},
    {name: 'cpq-guided-initialize', methods: ['post']},
    {name: 'cpq-initialize', methods: ['post']},
    {name: 'cpq-reconfigure', methods: ['post']},
    {name: 'cpq-uncommit', methods: ['post']},
    {name: 'cpq-quote', methods: ['get', 'post', 'delete']},
    {name: 'cpq-recalculate', methods: ['post']},
    {name: 'cpq-reconfigure', methods: ['post']},
    {name: 'cpq-set-step', methods: ['post']},
    {name: 'cpq-quote-items', methods: ['get', 'delete']},
    {name: 'cpq-configuration-download', methods: ['get']},
    {name: 'cpq-quote-download', methods: ['get']},
    {name: 'customer-orders-history', methods: ['get']},
    {name: 'customers', methods: ['post', 'get', 'delete', 'patch']},
    {name: 'customer-orders-history-export-to-excel', methods: ['post']},
    {name: 'customer-preferences', methods: ['patch']},
    {name: 'shs-equipment-materials', methods: ['get']},
    {name: 'shs-equipments', methods: ['get']},
    {name: 'shs-equipments-installed-base', methods: ['get']},
    {name: 'orders', methods: ['get']},
    {name: 'orders-cancel', methods: ['post']},
    {name: 'orders-export-to-excel', methods: ['post']},
    {name: 'quotes-export-to-excel', methods: ['get']},
    {name: 'quote-decline', methods: ['post']},
    {name: 'quote-requests', methods: ['post', 'get']},
    {name: 'shared-carts', methods: ['post', 'delete']},
    {name: 'terms', methods: ['post']},
    {name: 'sold-to-addresses', methods: ['get']},
    {name: 'shopping-lists', methods: ['post', 'get', 'delete', 'patch']},
    {name: 'cpq-address-report-wrong', methods: ['post', 'get']},
    {name: 'export-to-pdf', methods: ['get']},
  ],
  allowedEndpoints: ['i18n', 'stores', 'toggles', 'config', 'content-banners-by-key', 'assets'],
  contactSettings: {
    phoneNumber: '08000554838',
    email: 'fast.track.br.team@siemens-healthineers.com',
  },
  defaultImg: 'https://digitalmedianp.shop.siemens-healthineers.com/catalog/no_image_available.jpg',
  defaultPageTitle: 'Siemens Healthineers Shop',
  homePageBannerKey: 'HOME_PAGE_BANNER',
  storeSelectionBannerKey: 'STORE_SELECTION_BANNER',
  featuredProductsKey: 'FEATURED_PRODUCTS',
  blackFridaySticker: 'https://digitalmedianp.shop.siemens-healthineers.com/banner/9989_CS_eCommerce_BlackFriday_Banner_140x140px.jpg',
  showJsErrors: false,
  endpointsToSkipErrorHandling: [
    {name: 'content-banners-by-key', codes: []},
    {name: 'company-users/mine?include=company-roles,customers', codes: ['001', '003']},
    {name: 'carts', codes: ['403', '002']},
    {name: 'shopping-lists', codes: []},
    {name: 'cpq-quote', codes: ['404']},
    {name: 'cpq-address-report-wrong', codes: ['403']},
    {name: 'opal-systems-search', codes: []},
    {name: 'shs-equipment-materials', codes: ['301']},
    {name: 'shs-equipments-installed-base', codes: ['301']},
    {name: 'shs-equipments', codes: []},
    {name: 'checkout', codes: ['1101']},
  ],
  defaultBannerImg: '/assets/img/top_banner.jpg',
  defaultNoImg: '/assets/img/no_image_available.jpg',
  hideConsoleLogs: false,
  discountStickers: ['BF', 'STICKER_DISCOUNT'],
  statuesTreatedAsDeclined: ['canceled', 'declined', 'order declined'],
  helpMeChooseImg: 'https://digitalmedianp.shop.siemens-healthineers.com/catalog/help_me_choose.jpg',
  cpqItemAttributesToHide: [
    'available',
    'second_description',
    'expensiveness',
    'pdp_overview_description',
  ],
  searchExcludedAttributes: [
    'serialNumber',
    'company',
    'namePtBr',
    'myEquipmentName',
    'companyBusinessUnit',
    'companyBusinessUnitNumber',
    'externalUrlLarge',
    'externalUrlSmall',
    'companyBusinessUnitAddress',
    'id',
    'links',
    'self',
    'type',
  ],
  preferredLanguages: [
    {name: 'Bulgarian', value: 'bg'},
    {name: 'Croatian', value: 'hr'},
    {name: 'Czech', value: 'cs'},
    {name: 'Danish', value: 'da'},
    {name: 'Deutsch', value: 'de'},
    {name: 'Dutch', value: 'nl'},
    {name: 'English', value: 'en'},
    {name: 'Estonian', value: 'et'},
    {name: 'Finnish', value: 'fi'},
    {name: 'French', value: 'fr'},
    {name: 'Greek', value: 'el'},
    {name: 'Hungarian', value: 'hu'},
    {name: 'Indonesian', value: 'in'},
    {name: 'Italian', value: 'it'},
    {name: 'Japanese', value: 'ja'},
    {name: 'Korean', value: 'ko'},
    {name: 'Latvian', value: 'lv'},
    {name: 'Lithuanian', value: 'lt'},
    {name: 'Macedonian', value: 'mk'},
    {name: 'Norwegian', value: 'no'},
    {name: 'Polish', value: 'pl'},
    {name: 'Portuguese', value: 'pt'},
    {name: 'Romanian / Moldavian', value: 'ro'},
    {name: 'Russian', value: 'ru'},
    {name: 'Serbian', value: 'sr'},
    {name: 'Simplified Chinese', value: 'zh'},
    {name: 'Slovak', value: 'sk'},
    {name: 'Slovenian', value: 'sl'},
    {name: 'Spanish', value: 'es'},
    {name: 'Swedish', value: 'sv'},
    {name: 'Turkish', value: 'tr'},
    {name: 'Ukrainian', value: 'uk'},
    {name: 'Vietnamese', value: 'vi'},
  ],
  onlineServiceUrl: 'https://my-qa.siemens-healthineers.com/profile',
  teamplayFleetUrl: 'https://quality.fleet-test.siemens-healthineers.com',
  arakhUrl: 'https://ecommerce-arakh-dev-edge.azurewebsites.net',
  sparePartsViewerUrl: 'https://spc.healthcare.siemens.com/OnlineSPC/OciLogin.aspx?HOOK_URL=//shop.siemens-healthineers.com&~target=_top&~OkCode=ADDI',
  sparePartsReturnDocumentUrlKey: 'spare-parts-download-return-document-url',
  sparePartSaturdayDeliveryKey: 'FedEx Saturday Delivery',

  //blacklists for sorting options on the catalog page
  blockedSortOptionsPerRole: [
    {
      role: EUserRoles.Guest,
      options: ['rating', 'price_asc', 'price_desc'],
    },
  ],
  blockedSortOptionsPerStore: [
    {store: 'US', excluded: ['rating', 'price_asc', 'price_desc']},
    {store: 'CA', excluded: ['rating']},
  ],
  maxCachedProducts: 30,
  pricingGroupForConsumables: '111',
};
