import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EMultiCartTabs,
  EUserRoles,
  PurchaseActivityPreviousOrdersTabs,
  PurchaseActivityTabs,
} from '../../configurations/common';
import * as PurchaseActivitySelectors from '../../reducers/purchase-activity.reducer';
import { Store } from '@ngrx/store';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { IPurchaseActivityContent, IPurchaseActivityList } from '../../models/order.models';
import { AnalyticsService } from '../../analytics/analytics.service';
import { OrdersFacade } from '../../facades/orders.facade';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { PurchaseActivityActions } from '../../actions';
import { IPaginationCurrent } from '../../models/settings.model';
import { CustomerFacade } from '../../facades/customer.facade';
import { MarketingFacade } from '../../facades/marketing.facade';

@Component({
  selector: 'app-harmonized-page-purchase-activity',
  templateUrl: './harmonized-page-purchase-activity.component.html',
  styleUrl: './harmonized-page-purchase-activity.component.scss',
})
export class HarmonizedPagePurchaseActivityComponent implements OnInit, OnDestroy {
  protected readonly EMultiCartTabs = EMultiCartTabs;
  protected readonly PurchaseActivityTabs = PurchaseActivityTabs;

  // Tabs navigation
  protected purchaseActivityTabs: PurchaseActivityTabs[] = [];
  protected purchaseActivityPreviousOrdersTabs: PurchaseActivityPreviousOrdersTabs[] = [];
  protected openedTab: string = '';
  protected openedPreviousOrdersTab: string = this.purchaseActivityPreviousOrdersTabs[0];
  protected isPurchaseActivityTabsNavigationVisible: boolean = true;
  protected isPurchaseActivityPreviousOrdersTabsNavigationVisible: boolean = true;

  // Content
  isHistoryLoaded$: Observable<boolean> = this.store.select(PurchaseActivitySelectors.selectPurchaseHistoryStatus);
  purchaseActivityContent: IPurchaseActivityContent = {
    [PurchaseActivityPreviousOrdersTabs.ORDERS]: {itemsPerPage: [], pagination: null},
    [PurchaseActivityPreviousOrdersTabs.ARCHIVE]: {itemsPerPage: [], pagination: null},
    [PurchaseActivityPreviousOrdersTabs.CANCELED]: {itemsPerPage: [], pagination: null},
    [PurchaseActivityTabs.PENDING_ORDERS]: {itemsPerPage: [], pagination: null},
    [PurchaseActivityTabs.PREVIOUS_QUOTES]: {itemsPerPage: [], pagination: null},
    [PurchaseActivityTabs.PREVIOUS_REQUESTS]: {itemsPerPage: [], pagination: null},
  };
  serviceOrdersLoaded: boolean = false; //cpq functionality

  // Other variables
  triggerClearAllFilters$: Subject<void> = new Subject<void>(); // Including Advanced Search, My orders and Quick Search
  private unsubscribe$: Subject<void> = new Subject<void>();
  private pageReadyEventFired: boolean = false; //walkme related

  constructor(
    private store: Store,
    private configurationFacade: ConfigurationFacade,
    private analyticsService: AnalyticsService,
    private orderFacade: OrdersFacade,
    private customerFacade: CustomerFacade,
    private marketingFacade: MarketingFacade,
  ) {
  }

  ngOnInit(): void {
    this._reorderItems();
    this._initializePurchaseActivityContent();
    this._initializeAvailableTabs();
    this.store.dispatch(PurchaseActivityActions.initPurchaseActivity());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // *** Initialization of content ***

  /**
   * Initialize content for purchaseActivity state and also for cpq orders.
   * @private
   */
  private _initializePurchaseActivityContent(): void {
    this._getPreviousOrders();
    this._getArchiveOrders();
    this._getCanceledOrders();
    this._getPendingOrders();
    this._getPreviousQuotes();
    this._getPreviousRequests();
    this._loadCpqOrdersData();
  }

  private _getPreviousOrders(): void {
    this.store.select(PurchaseActivitySelectors.selectPreviousOrdersAtPage)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => {
          return of({ itemsPerPage: [], pagination: null } as IPurchaseActivityList);
        })
      ).subscribe((orders: IPurchaseActivityList) => {
      this.purchaseActivityContent[PurchaseActivityPreviousOrdersTabs.ORDERS] = orders;
    });
  }

  private _getArchiveOrders(): void {
    this.store.select(PurchaseActivitySelectors.selectArchiveOrders)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => {
          return of({ itemsPerPage: [], pagination: null } as IPurchaseActivityList);
        })
      ).subscribe((orders: IPurchaseActivityList) => {
      this.purchaseActivityContent[PurchaseActivityPreviousOrdersTabs.ARCHIVE] = orders;
    });
  }

  private _getCanceledOrders(): void {
    this.store.select(PurchaseActivitySelectors.selectCanceledOrders)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => {
          return of({ itemsPerPage: [], pagination: null } as IPurchaseActivityList);
        })
      ).subscribe((orders: IPurchaseActivityList) => {
      this.purchaseActivityContent[PurchaseActivityPreviousOrdersTabs.CANCELED] = orders;
    });
  }

  /**
   * Fetches pending orders and filters them based on the user's email if the user has the 'Buyer' role.
   * Updates the purchase activity content with the filtered or unfiltered pending orders.
   * @private
   */
  private _getPendingOrders(): void {
    combineLatest([
      this.store.select(PurchaseActivitySelectors.selectPendingOrders),
      this.customerFacade.selectCustomerData()
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => {
          return of([], []);
        })
      ).subscribe(([orders, customerData]) => {
        if (customerData.companyRoles.includes(EUserRoles.Buyer)) {
          orders.itemsPerPage = orders.itemsPerPage.map(page =>
            page?.filter(order => order.attributes.customer.email === customerData.customerData.email)
          );
        }
        this.purchaseActivityContent[PurchaseActivityTabs.PENDING_ORDERS] = orders;
      });
  }

  private _getPreviousQuotes(): void {
    this.store.select(PurchaseActivitySelectors.selectPreviousQuotes)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => {
          return of({ itemsPerPage: [], pagination: null } as IPurchaseActivityList);
        })
      ).subscribe((quotes: IPurchaseActivityList) => {
      this.purchaseActivityContent[PurchaseActivityTabs.PREVIOUS_QUOTES] = quotes;
    });
  }

  private _getPreviousRequests(): void {
    this.store.select(PurchaseActivitySelectors.selectPreviousRequest)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => {
          return of({ itemsPerPage: [], pagination: null } as IPurchaseActivityList);
          })
      ).subscribe((requests: IPurchaseActivityList) => {
      this.purchaseActivityContent[PurchaseActivityTabs.PREVIOUS_REQUESTS] = requests;
    });
  }

  private _loadCpqOrdersData(): void {
    if (this.openedTab === PurchaseActivityTabs.SERVICES) {
      this.orderFacade.loadCpqOrdersFulfilledData();
      this.orderFacade.loadCpqOrdersUnderReviewData();
    }
  }

  // *** Pagination ***

  /**
   * Get pagination data for the currently opened tab.
   */
  getPaginationData(): IPaginationCurrent {
    const tab = this.openedTab === PurchaseActivityTabs.PREVIOUS_ORDERS
      ? this.openedPreviousOrdersTab
      : this.openedTab;
    return this.purchaseActivityContent[tab].pagination;
  }

  /**
   * Changes the current page in the pagination for the specified tab. New offset is calculated as number of page
   * minus one multiplied by limit per page.
   * @param {string} tab - The tab for which the page is being changed.
   *                       It can be one of the PurchaseActivityTabs or PurchaseActivityPreviousOrdersTabs.
   * @param {number} page - The new page number to set in the pagination.
   */
  changePage(tab: string, page: number): void {
    switch (tab) {
      case PurchaseActivityTabs.PREVIOUS_ORDERS:
        if (!this.purchaseActivityContent[PurchaseActivityPreviousOrdersTabs.ORDERS].itemsPerPage[page]) {
          const limitPerPage: number =
                  this.purchaseActivityContent[PurchaseActivityPreviousOrdersTabs.ORDERS].pagination.limitPerPage;
          this.store.dispatch(PurchaseActivityActions.initOrdersLoadingInPreviousOrders((page - 1) * limitPerPage));
        } else {
          this.store.dispatch(PurchaseActivityActions.changePagination({tab, page}));
        }
        break;
      default:
        break;
    }
  }

  // *** Navigation ***

  /**
   * Initializes available tabs based on configurations:
   * - Tabs: config.purchase_activity_tabs
   * - Previous Orders Tabs: config.purchase_activity_previous_orders_tabs
   *
   * If config for PurchaseActivityTabs navigation is empty string or there is only one available tab, then hide
   * PurchaseActivityTabs navigation.
   * If config for PurchaseActivityPreviousOrdersTabs navigation is empty string, then set as default tab 'orders'.
   * Navigation for PurchaseActivityPreviousOrdersTabs is hidden if config is empty string or there is only one
   * available tab.
   * @private
   */
  private _initializeAvailableTabs(): void {
    combineLatest([
      this.configurationFacade.getDynamicPurchaseActivityTabs(),
      this.configurationFacade.getDynamicPurchaseActivityPreviousOrdersTabs(),
    ]).pipe(
      take(1),
      catchError(() => {
        return of([[], []]);
      })
    ).subscribe(([dynamicTabs, dynamicPreviousOrdersTabs]) => {
      // Main navigation
      this.purchaseActivityTabs = Object.values(PurchaseActivityTabs)
        .filter(tab => dynamicTabs.includes(tab));
      this.openedTab = this.purchaseActivityTabs[0] || '';
      this.isPurchaseActivityTabsNavigationVisible = this.purchaseActivityTabs.length > 1;

      // Sub-navigation for Previous Orders
      this.purchaseActivityPreviousOrdersTabs = Object.values(PurchaseActivityPreviousOrdersTabs)
        .filter(tab => dynamicPreviousOrdersTabs.includes(tab));
      this.openedPreviousOrdersTab =
        this.purchaseActivityPreviousOrdersTabs[0] || PurchaseActivityPreviousOrdersTabs.ORDERS;
      this.isPurchaseActivityPreviousOrdersTabsNavigationVisible = this.purchaseActivityPreviousOrdersTabs.length > 1;
    });
  }

  /**
   * Switches the tab in main navigation.
   * @param {string} tab
   */
  changeTab(tab: string): void {
    this.openedTab = tab;
    this.triggerClearAllFilters$.next();
  }

  /**
   * Switches the tab in the sub-navigation of Previous Orders.
   * @param {string} tab
   */
  changePreviousOrdersTab(tab: string): void {
    this.openedPreviousOrdersTab = tab;
    this.triggerClearAllFilters$.next();
  }

  // *** Reorder ***

  /**
   * User reorder all items and is redirected to my install base, because selected cart is empty and has not selected
   * any FL, then after selecting is user redirected back to purchase activity and these items are added to specified
   * cart.
   */
  private _reorderItems(): void {
    this.marketingFacade.isReorderConfirmed().pipe(
      take(1),
    ).subscribe(isReorderConfirmed => {
      if (isReorderConfirmed) {
        this.marketingFacade.getReorderItems().pipe(
          take(1),
        ).subscribe(items => {
          this._addToCart(items);
          this.marketingFacade.completeReorder();
        });
      }
    });
  }

  /**
   * Adds the items to the cart and track this operation
   * @param {any[]} items
   * @private
   */
  private _addToCart(items: any[]): void {
    items.forEach(item => {
      this.analyticsService.setProducts(item.data);
      this.analyticsService.trackCart('cart.add');
      this.marketingFacade.addProductToCart(item.data, item.isConcrete, item.quantity);
    });
  }

  // *** Analytics Service ***

  trackPageReady(): void {
    if (!this.pageReadyEventFired) {
      this.analyticsService.trackPageReady('My Purchase Activity', PageTypes.MY_PURCHASE_ACTIVITY_PAGE);
      this.pageReadyEventFired = true;
    }
  }
}
