import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IPaginationCurrent } from '../models/settings.model';
import { IPurchaseActivityList } from '../models/order.models';
import * as PurchaseActivityActions from '../actions/purchase-activity.actions';
import { GlueUtils } from '../utils/glue.utils';
import { StringUtils } from '../utils/string.utils';

export const purchaseHistoryFeatureKey = 'purchaseHistory';

const initialPagination: IPaginationCurrent = {
  currentPage: 0,
  currentOffset: 0,
  prevPage: 0,
  prevOffset: 0,
  nextPage: 0,
  nextOffset: 0,
  firstPage: 0,
  firstOffset: 0,
  lastPage: 0,
  lastOffset: 0,
  limitPerPage: 0,
};

export interface IPurchaseActivityState {
  previousOrders: IPurchaseActivityList;
  archiveOrders: IPurchaseActivityList;
  pendingOrders: IPurchaseActivityList;
  previousQuotes: IPurchaseActivityList;
  previousRequests: IPurchaseActivityList;
  isLoaded: {
    previousOrders: boolean;
    archiveOrders: boolean;
    pendingOrders: boolean;
    previousQuotes: boolean;
    previousRequests: boolean;
  }
}

export const initialState: IPurchaseActivityState = {
  previousOrders: {
    itemsPerPage: [],
    pagination: {...initialPagination},
  },
  archiveOrders: {
    itemsPerPage: [],
    pagination: {...initialPagination},
  },
  pendingOrders: {
    itemsPerPage: [],
    pagination: {...initialPagination},
  },
  previousQuotes: {
    itemsPerPage: [],
    pagination: {...initialPagination},
  },
  previousRequests: {
    itemsPerPage: [],
    pagination: {...initialPagination},
  },
  isLoaded: {
    previousOrders: false,
    archiveOrders: false,
    pendingOrders: false,
    previousQuotes: false,
    previousRequests: false,
  }
};

const updatePreviousOrders = (state: IPurchaseActivityState, orders: any) => {
  const previousOrdersPagination = GlueUtils.parsePaginationLinks(orders?.links);
  const previousOrdersPerPage = new Array(...state.previousOrders.itemsPerPage);
  previousOrdersPerPage[previousOrdersPagination.currentPage] = orders.data;

  return {
    ...state,
    previousOrders: {
      ...state.previousOrders,
      itemsPerPage: previousOrdersPerPage,
      pagination: previousOrdersPagination,
    },
  };
};

export const reducer = createReducer(
  initialState,

  // Initialize loading while loading new orders/requests/quotes (for pagination).
  on(PurchaseActivityActions.loadOrdersInPreviousOrders, (state) => {
    return {
      ...state,
      isLoaded: {
        ...state.isLoaded,
        previousOrders: false,
      }
    };
  }),
  on(PurchaseActivityActions.loadArchiveInPreviousOrders, (state) => {
    return {
      ...state,
      isLoaded: {
        ...state.isLoaded,
        archiveOrders: false,
      }
    };
  }),
  on(PurchaseActivityActions.loadOrdersInPendingOrders, (state) => {
    return {
      ...state,
      isLoaded: {
        ...state.isLoaded,
        pendingOrders: false,
      }
    };
  }),
  on(PurchaseActivityActions.loadQuotesAndRequestsInPreviousQuotesAndRequests, (state) => {
    return {
      ...state,
      isLoaded: {
        ...state.isLoaded,
        previousQuotes: false,
        previousRequests: false,
      }
    };
  }),


  on(PurchaseActivityActions.loadOrdersInPreviousOrdersSuccess, (state, {orders}) => {
    return {
      ...updatePreviousOrders(state, orders),
      isLoaded: {
        ...state.isLoaded,
        previousOrders: true,
      },
    };
  }),
  on(PurchaseActivityActions.loadOrdersInPreviousOrdersFailure, (state) => {
    return {
      ...state,
      isLoaded: {
        ...state.isLoaded,
        previousOrders: true,
      },
    };
  }),

  on(PurchaseActivityActions.loadArchiveInPreviousOrdersSuccess, (state, {archiveOrders}) => {
    return {
      ...state,
      archiveOrders: {
        ...state.archiveOrders,
        itemsPerPage: [
          null,
          archiveOrders.data
        ],
        pagination: {
          ...state.archiveOrders.pagination,
          currentPage: 1,
          prevPage: 1,
          lastPage: 1
        }
      },
      isLoaded: {
        ...state.isLoaded,
        archiveOrders: true,
      },
    };
  }),
  on(PurchaseActivityActions.loadArchiveInPreviousOrdersFailure, (state) => {
    return {
      ...state,
      isLoaded: {
        ...state.isLoaded,
        archiveOrders: true,
      },
    };
  }),

  on(PurchaseActivityActions.loadOrdersInPendingOrdersSuccess, (state, {pendingOrders}) => {
    return {
      ...state,
      pendingOrders: {
        ...state.pendingOrders,
        itemsPerPage: [
          null,
          pendingOrders.data
        ],
        pagination: {
          ...state.pendingOrders.pagination,
          currentPage: 1,
          prevPage: 1,
          lastPage: 1
        }
      },
      isLoaded: {
        ...state.isLoaded,
        pendingOrders: true,
      },
    };
  }),
  on(PurchaseActivityActions.loadOrdersInPendingOrdersFailure, (state) => {
    return {
      ...state,
      isLoaded: {
        ...state.isLoaded,
        pendingOrders: true,
      },
    };
  }),

  on(PurchaseActivityActions.deleteItemFromPendingOrder, (state) => {
    return {
      ...state,
      isLoaded: {
        ...state.isLoaded,
        pendingOrders: false,
      },
    }
  }),

  on(PurchaseActivityActions.loadQuotesAndRequestsInPreviousQuotesAndRequestsSuccess, (state, {previousQuotes, previousRequests}) => {
    return {
      ...state,
      previousQuotes: {
        ...state.previousQuotes,
        itemsPerPage: [
          null,
          previousQuotes
        ],
        pagination: {
          ...state.previousQuotes.pagination,
          currentPage: 1,
          prevPage: 1,
          lastPage: 1
        }
      },
      previousRequests: {
        ...state.previousRequests,
        itemsPerPage: [
          null,
          previousRequests
        ],
        pagination: {
          ...state.previousRequests.pagination,
          currentPage: 1,
          prevPage: 1,
          lastPage: 1
        }
      },
      isLoaded: {
        ...state.isLoaded,
        previousQuotes: true,
        previousRequests: true,
      },
    };
  }),
  on(PurchaseActivityActions.loadQuotesAndRequestsInPreviousQuotesAndRequestsFailure, (state) => {
    return {
      ...state,
      isLoaded: {
        ...state.isLoaded,
        previousQuotes: true,
        previousRequests: true,
      },
    };
  }),

  on(PurchaseActivityActions.changePagination, (state, {tab, page}) => {
    tab = StringUtils.kebabToCamel(tab);
    return {
      ...state,
      [tab]: {
        ...state[tab],
        pagination: {
          ...state[tab].pagination,
          currentPage: page,
        },
      },
    };
  })
);

export function PurchaseActivityReducer(state: IPurchaseActivityState | undefined, action: Action): any {
  return reducer(state, action);
}

export const purchaseActivityBaseState = createFeatureSelector<IPurchaseActivityState>('purchaseActivity');

export const selectArchiveOrders = createSelector(
  purchaseActivityBaseState,
  state => state.archiveOrders
);

export const selectPendingOrders = createSelector(
  purchaseActivityBaseState,
  state => state.pendingOrders
);

export const selectPreviousQuotes = createSelector(
  purchaseActivityBaseState,
  state => state.previousQuotes
);

export const selectPreviousRequest = createSelector(
  purchaseActivityBaseState,
  state => state.previousRequests
);

export const selectPreviousOrdersAtPage = createSelector(
  purchaseActivityBaseState,
  state => state.previousOrders
);

export const selectArchiveOrderById = (id: string) => createSelector(
  purchaseActivityBaseState,
  state => state.archiveOrders?.itemsPerPage
    .reduce((acc, page) => acc.concat(page || []), [])
    .find(order => order?.id === id)
);

export const selectPurchaseHistoryStatus = createSelector(
  purchaseActivityBaseState,
  state => {
    return Object.values(state.isLoaded).every(status => status === true);
  }
);
