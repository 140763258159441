<header class="container">
  <div class="row">
    <div class="col-12">
      <div class="application-bar application-bar--compact">
        <app-notification></app-notification>
        <div class="application-bar__container">
          <div class="application-bar__logo">
            <a *ngIf="!isOnStoreSelection()" [routerLink]="currentLangParam">
              <div class="logo logo--compact" [attr.data-test]="'logo-homepage'"></div>
            </a>
            <div *ngIf="isOnStoreSelection()" class="logo logo--compact"></div>
          </div>
          <div *ngIf="!isOnStoreSelection()" class="application-bar__app-identifier">
            <a [routerLink]="currentLangParam" [attr.data-test]="'link-shop'">{{ 'app-bar.app-name' | translate }}</a>
          </div>
          <div *ngIf="isOnStoreSelection()" class="application-bar__app-identifier">
            {{ 'app-bar.app-name' | translate }}
          </div>
          <div *ngIf="!hideSearchAndActions() && (isExternalQuoteCheckoutEnabled$ | async) === false" class="search-bar">
            <app-search-bar></app-search-bar>
          </div>
          <div *ngIf="!hideSearchAndActions()" class="icons-wrapper">
            <app-select-language [isExternalQuoteCheckoutEnabled]="isExternalQuoteCheckoutEnabled$ | async">
            </app-select-language>
            @if ((isExternalQuoteCheckoutEnabled$ | async) === false) {
              <div class="application-bar__additional-functions cart mini-cart__icon">
                @if (userLoggedIn$ | async) {
                  <a class="icon-shopping-cart hidden-md-up" routerLink="/shop-cart">
                    @if (itemsInCart && !isCartLoading()) {
                      <span class="label label--superscript cart-label mini-cart__toggler"
                            [ngClass]="{'hide-counter': hasContractInCart}">
                        {{ hasContractInCart ? '&nbsp;' : itemsInCart }}
                      </span>
                    }
                    <span *ngIf="isCartLoading()" class="loading-animation"></span>
                  </a>
                  <span class="icon-shopping-cart mini-cart__toggler hidden-sm-down"
                        [ngClass]="{'disabled': isCartLoading()}"
                        (click)="toggleMiniCart()"
                        [attr.data-test]="'button-toggleMiniCart'">
                    @if (itemsInCart && !isCartLoading()) {
                      <span class="label label--superscript cart-label mini-cart__toggler"
                            [ngClass]="{'hide-counter': hasContractInCart}">
                        {{ hasContractInCart ? '&nbsp;' : itemsInCart }}
                      </span>
                    }
                    <span *ngIf="isCartLoading()" class="loading-animation"></span>
                  </span>
                  <app-mini-cart
                      [haveAccess]="!isGuest && !isViewer() && areToUAccepted"
                      [rfqActive]="rfqActive"
                      [isRfqOnly]="isRfqOnly"
                      [isSapP40Enable]="isSapP40Enable">
                  </app-mini-cart>
                } @else {
                  <span class="icon-shopping-cart disabled"></span>
                }
              </div>
              <div class="application-bar__user-identifier user-account">
                <app-dropdown
                    [cssStyles]="{dropdown: 'user-identifier', toggle: 'user-identifier__toggle-button'}"
                    [dropDownName]="'user'">
                  <ng-container ngProjectAs="[slot][toggle]">
                    <span class="user-identifier__avatar">
                      <span *ngIf="(userLoggedIn$ | async) === false" class="user-identifier__initials">
                        <span class="icon-user"></span>
                      </span>
                      <span *ngIf="user$ | async as profile" class="user-identifier__initials">
                        <img alt="User's avatar" [src]="profile.picture">
                      </span>
                    </span>
                    <span *ngIf="(userLoggedIn$ | async) === false" class="user-identifier__details">
                      <span class="user-identifier__name">{{ 'app-bar.login-register' | translate }}</span>
                    </span>
                    <span *ngIf="user$ | async as profile" class="user-identifier__details">
                      <span class="user-identifier__name">{{ profile.given_name }} {{ profile.family_name }}</span>
                      <span class="user-identifier__company">{{ profile.email }}</span>
                    </span>
                  </ng-container>
                  <ng-container ngProjectAs="[slot][panel]">
                    <ng-container *ngIf="(userLoggedIn$ | async) === false">
                      <a class="menu__item login-btn"
                         (click)="login()"
                         [attr.data-test]="'link-login'">
                        {{ 'app-bar.login' | translate }}
                      </a>
                      <a class="menu__item"
                         (click)="register()"
                         [attr.data-test]="'link-register'">
                        {{ 'app-bar.register' | translate }}
                      </a>
                    </ng-container>
                    <ng-container *ngIf="(userLoggedIn$ | async)">
                      <a *ngIf="isMenuItemVisible('shop-cart')"
                         class="menu__item"
                         routerLink="/shop-cart"
                         [attr.data-test]="'menu-item-myCart'">
                        {{ 'app-bar.my-cart' | translate }}
                      </a>
                      <a *ngIf="!areToUAccepted && currentBusinessUnitName"
                         class="menu__item"
                         [attr.data-test]="'menu-item-myProfile'">
                        {{ 'app-bar.my-profile' | translate }}
                      </a>
                      <a *ngIf="areToUAccepted && !isMyProfileActive && currentBusinessUnitName"
                         class="menu__item"
                         href="{{ onlineServiceUrl }}"
                         target="_blank"
                         rel="noopener"
                         [attr.data-test]="'menu-item-myProfile'">
                        {{ 'app-bar.my-profile' | translate }}
                      </a>
                      <a *ngIf="areToUAccepted && isMyProfileActive"
                         class="menu__item"
                         [attr.data-test]="'menu-item-myProfile'"
                         [routerLink]="['/my-profile']">
                        {{ 'app-bar.my-profile' | translate }}
                      </a>
                      <a *ngIf="isMenuItemVisible('wishlists')"
                         class="menu__item"
                         routerLink="/wishlists"
                         [attr.data-test]="'menu-item-wishlists'">
                        {{ 'wishlists.navigation' | translate }}
                      </a>
                      <a class="menu__item" (click)="logout()" [attr.data-test]="'menu-item-logout'">
                        {{ 'app-bar.logout' | translate }}
                      </a>
                    </ng-container>
                  </ng-container>
                </app-dropdown>
                <ng-container>
                  <app-bar-alert
                      [alertDialogActive]="alertDialogActive"
                      [userLoggedIn$]="userLoggedIn$"
                      [isGuest]="isGuest"
                      [isCustomerDataLoaded]="isCustomerDataLoaded"
                      [alertType]="getDialogType()"
                      [message]="getRestrictedMessage()"
                  ></app-bar-alert>
                </ng-container>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<app-cart-notification-modal *ngIf="areToUAccepted"></app-cart-notification-modal>
