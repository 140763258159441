<app-accordion [manualOpen]="tabsOpened.includes(order.id)">
  <ng-container ngProjectAs="[slot][header]">
    <div class="accordion__title icon-arrow-down" (click)="toggleItemDetailsVisibility(order.id)"
         [attr.data-test]="'orderId-' + order.id">
      <div class="accordion-columns">
        <div class="accordion-column">
          <strong [attr.data-test]="'desc-orderNumber'">
            @if (currentTab === PurchaseActivityTabs.PENDING_ORDERS) {
              #{{ order.id.substring(0, order.id.indexOf('-')) }}
            } @else {
              {{ order.id }}
            }
          </strong>
        </div>
        <div class="accordion-column">
          <span [attr.data-test]="'desc-orderDate'">{{ order.attributes.createdAt | formatDate }}</span><br>
          <span [attr.data-test]="'desc-userName'" class="greyed-out created-by-field">
            {{ order.attributes.userName }}
          </span>
        </div>
        @if (currentTab === PurchaseActivityTabs.PREVIOUS_ORDERS) {
          <div class="accordion-column">
            <span [attr.data-test]="'desc-equipment'">{{ order.attributes.equipment }}</span><br>
            @if (order.attributes?.equipmentName) {
              <span [attr.data-test]="'desc-equipmentName'" class="greyed-out">
                {{ order.attributes.equipmentName }}
              </span>
            }
          </div>
        }
        @if (currentTab === PurchaseActivityTabs.PENDING_ORDERS) {
          <div class="accordion-column">
            <span class="greyed-out" [attr.data-test]="'desc-approverName'">
                {{ order.attributes.approverDetails.firstName }} {{ order.attributes.approverDetails.lastName }}
            </span>
          </div>
        }
        <div class="accordion-column">
          <div>
            <span class="status__icon"
                  [attr.data-test]="'icon-orderStatus'"
                  [ngClass]="getIcon(order.attributes?.stateDisplayName)">
            </span>
            <span class="status__name" [attr.data-test]="'desc-orderStatus'">
              {{ mappedStatus(order.attributes?.stateDisplayName) }}
            </span>
            @if (order.attributes?.stateDisplayName !== EOrderStatus.WAITING) {
              <br>
              <span class="greyed-out" [attr.data-test]="'desc-approverName'">
                 {{ order.attributes?.approverDetails?.firstName }} {{ order.attributes?.approverDetails?.lastName }}
              </span>
            }
          </div>
        </div>
          <div class="accordion-column">
            @if (order.attributes?.items && order.attributes?.sumPrice !== undefined) {
              @if (currentTab !== PurchaseActivityTabs.PREVIOUS_REQUESTS) {
                <strong [attr.data-test]="'desc-sumPrice'">
                  {{ order.attributes.totals.grandTotal | currency: priceUtils.getStoreCurrencyCode() }}
                  @if (currentTab !== PurchaseActivityTabs.PENDING_ORDERS && isExcludeTaxActive) {
                    <span class="tax-disclaimer" [attr.data-test]="'desc-tax'">
                    ({{ 'purchase-activity.incl_gst' | translate }})
                  </span>
                  }
                </strong><br>
              }
              <span [attr.data-test]="'desc-quantity'">
                @if (currentTab !== PurchaseActivityTabs.PENDING_ORDERS) {
                  {{ getQuantityTranslationByAmount(getTotalQuantity(order.attributes?.items)) }}
                } @else {
                  {{ getQuantityTranslationByAmount(order.attributes.totalItemsQuantity) }}
                }
              </span>
            }
          </div>
      </div>
    </div>
  </ng-container>
  <ng-container ngProjectAs="[slot][panel]">
    <div class="container accordion__content">
      @if (!isPendingOrderLoaded && (currentTab === PurchaseActivityTabs.PENDING_ORDERS)) {
        <div class="loader__overlay">
          <div class="loader__content">
            <div class="loading-animation"></div>
          </div>
        </div>
      } @else {
        @if (currentTab === PurchaseActivityTabs.PENDING_ORDERS) {
          <div class="pending-orders-container">
            @if (!isPendingOrderLoaded) {
              <div class="loader__overlay">
                <div class="loader__content">
                  <div class="loading-animation"></div>
                </div>
              </div>
            } @else {
              <div class="pending-order-details">
                <div class="delivery-and-system-details">
                  @if ((poNumberVisibilityByTabs$ | async)?.includes(currentTab) && order.attributes.approverDetails.poNumber) {
                    <div class="po-number">
                      <span class="po-number-title m-t-0" [attr.data-test]="'title-poNumber'">
                        {{ 'purchase-activity.purchase-order' | translate }}:
                      </span>
                      <span [attr.data-test]="'desc-poNumber'">
                        {{ order.attributes.approverDetails.poNumber }}
                      </span>
                    </div>
                  }
                  @if (order.attributes.shippingAddress) {
                    <div class="delivery-address">
                      <h5 [attr.data-test]="'title-address'" [ngClass]="{'m-t-0': !order.attributes.approverDetails.poNumber}">
                        {{ 'purchase-activity.delivery-address' | translate }}
                      </h5>
                      @if (!isFieldEmpty(order.attributes.shippingAddress.firstName)) {
                        <span [attr.data-test]="'desc-firstName'">
                          {{ formatIfMissingValue(order.attributes.shippingAddress.firstName) }}
                        </span>
                      }
                      <span [attr.data-test]="'desc-address1'">
                        {{ formatIfMissingValue(order.attributes.shippingAddress.address1) }}
                      </span>
                      <div class="address-container">
                        @if (!isFieldEmpty(order.attributes.shippingAddress.zipCode)) {
                          <span [attr.data-test]="'desc-zipCode'">
                            {{ formatIfMissingValue(order.attributes.shippingAddress.zipCode) }}&nbsp;
                          </span>
                        }
                        @if (!isFieldEmpty(order.attributes.shippingAddress.city)) {
                          <span [attr.data-test]="'desc-city'">
                            {{ formatIfMissingValue(order.attributes.shippingAddress.city) }},
                          </span>
                        }
                        @if (!isFieldEmpty(order.attributes.shippingAddress.address2)) {
                          <span [attr.data-test]="'desc-address2'">
                            {{ formatIfMissingValue(order.attributes.shippingAddress.address2) }}
                          </span>
                        }
                      </div>
                    </div>
                    <div class="system-details">
                      <h5 class="cart-system-details__title" [attr.data-test]="'title-systemDetails'">
                        {{ 'purchase-activity.system-details' | translate }}
                      </h5>
                      <div [attr.data-test]="'label-selectedSystem'">
                        {{ "purchase-activity.selected-system" | translate }}:
                      </div>
                      @if (order.attributes.systemDetails) {
                        <div [attr.data-test]="'desc-selectedSystem'">
                          {{ order.attributes.systemDetails | selectedSystem | async }}
                        </div>
                      }
                      <div [attr.data-test]="'label-companyDetails'">
                        {{ "purchase-activity.company" | translate }}:
                      </div>
                      <div>
                        @if(order.attributes.systemDetails) {
                          <div [attr.data-test]="'desc-companyDetails'">
                            {{ order.attributes.systemDetails | companyDetails }}
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
                <div class="pending-order-approver-container">
                  <div class="pending-order-approver-details">
                    <div class="user-container">
                      <div class="user-avatar" [attr.data-test]="'desc-userInitials'">
                        <span class="user-avatar-content">
                          {{ getUserInitials(order.attributes.customer.firstName, order.attributes.customer.lastName) }}
                        </span>
                      </div>
                      <div class="user-information">
                      <span class="user-identifier__name" [attr.data-test]="'desc-userFullname'">
                        {{ order.attributes.customer.firstName }} {{ order.attributes.customer.lastName }}
                      </span>
                        <span class="user-details__email" [attr.data-test]="'desc-userEmail'">
                          {{ order.attributes.customer.email }}
                        </span>
                        @if (order.attributes.approverDetails.comment) {
                          <div class="user-information-comment greyed-out">
                            <span [attr.data-test]="'desc-userComment'">
                              {{ order.attributes.approverDetails.comment }}
                            </span>
                          </div>
                        }
                        @if (isApprover$ | async) {
                          <div class="request-button">
                            <a href="mailto:{{order.attributes.customer.email}}"
                               class="button button--link button--small icon-email"
                               [attr.data-test]="'link-userEmail'">
                              {{ 'purchase-activity.request-more-info' | translate }}
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div>
                    @if (itemsList.length > 0 && order.attributes.shippingAddress) {
                      <div class="order-buttons" [ngClass]="{'download-button-container': AppUtils.isStoreActive(EStoreTypes.JP)}">
                        @if (AppUtils.isStoreActive(EStoreTypes.JP)) {
                          <a class="download-button" [attr.data-test]="'button-downloadQuoteDocumentation'" (click)="generateQuotePdf()">
                            @if (isQuotePdfGenerationInProgress) {
                              <div>
                                <div class="spinner">
                                  <div class="loading-animation"></div>
                                </div>
                              </div>
                            } @else {
                              <div>
                                <span class="icon-arrow-right"></span>
                                <span [attr.data-test]="'desc-downloadQuoteDocumentation'">
                                  {{ 'purchase-activity.download-quote-documentation' | translate }}
                                </span>
                              </div>
                            }
                          </a>
                        }
                        @if (order.attributes.stateDisplayName === EOrderStatus.WAITING) {
                          <div class="pending-order-buttons-container">
                            @if (isApprover$ | async) {
                              <button class="button" [attr.data-test]="'button-declineOrder'" (click)="openDeclinePendingOrderModal()">
                                {{ 'purchase-activity.decline-order' | translate }}
                              </button>
                              <button class="button button--primary"
                                      [attr.data-test]="'button-approveOrder'"
                                      (click)="approveOrder()">
                                {{ 'purchase-activity.go-to-approve' | translate }}
                              </button>
                            } @else {
                              <button class="button" [attr.data-test]="'button-cancelOrder'" (click)="openDeclinePendingOrderModal()">
                                {{ 'purchase-activity.cancel-order' | translate }}
                              </button>
                            }
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        } @else {
          <div class="order-details">
            <div class="order-details__header">
              <div class="column">
                @if ((poNumberVisibilityByTabs$ | async)?.includes(currentTab)) {
                  <div>
                    <span class="base-info__label" [attr.data-test]="'label-purchaseOrder'">
                      {{ 'purchase-activity.purchase-order' | translate }}:
                    </span>
                    <span class="text" [attr.data-test]="'desc-purchaseOrder'">
                      @if (currentTab === PurchaseActivityTabs.PREVIOUS_ORDERS) {
                        {{ order.attributes.sapPoNumber?.trim() || 'purchase-activity.empty-purchase-order-number' | translate }}
                      } @else {
                        {{ order.id }}
                      }
                    </span>
                  </div>
                }
                @if (this.currentSubTab === PurchaseActivityPreviousOrdersTabs.ORDERS && this.isEligibleForReturn) {
                  <div>
                    <span class="base-info__label" [attr.data-test]="'label-returnOrderNumber'">
                      {{ 'purchase-activity.return-order-number' | translate }}:
                    </span>
                    <span class="text" [attr.data-test]="'desc-returnOrderNumber'">
                      {{ order.attributes?.sapDetails?.returnAuthorizationNumber }}
                    </span>
                  </div>
                  <a class="download-return-document__button" [attr.data-test]="'link-downloadReturnDocument'"
                          (click)="downloadReturnDocument()">
                    <span class="icon-arrow-right"></span>
                    <span [attr.data-test]="'desc-downloadReturnDocument'">
                      {{ 'purchase-activity.download-return-doc' | translate }}
                    </span>
                  </a>
                }
              </div>
              <div class="column">
                <div class="arrow-buttons">
                  @if (isExportAsPdfEnabled$ | async) {
                    @if (isPdfGenerationAvailable()) {
                      @if (!isExportAsPdfInProgress) {
                        <a (click)="generateOrderDetailPdf()" [attr.data-test]="'link-pdfExport'">
                          <span class="icon-arrow-right"></span>
                          <span [attr.data-test]="'desc-pdfExport'">
                            {{ 'purchase-activity.pdf-export' | translate }}
                          </span>
                        </a>
                      } @else {
                        <div class="btn-spinner">
                          <div class="loading-animation"></div>
                        </div>
                      }
                    }
                  }
                  @if ((isReorderAllItemsEnabled$ | async) && (isBusinessPartner$ | async) !== true
                  && currentSubTab !== PurchaseActivityPreviousOrdersTabs.ARCHIVE) {
                    <a [attr.data-test]="'link-reorderAllItems'" (click)="addItemsToCart(order.attributes.items)">
                      <span class="icon-arrow-right"></span>
                      <span [attr.data-test]="'desc-reorderAllItems'">
                        {{ 'purchase-activity.' + currentTab + '-reorder-all-items' | translate }}
                      </span>
                    </a>
                  }
                  @if (isShowOrderDetailsEnabled$ | async) {
                    <a (click)="redirectToOrderDetails(order.id)" [attr.data-test]="'link-showOrderDetails'">
                      <span class="icon-arrow-right"></span>
                      <span [attr.data-test]="'desc-showOrderDetails'">
                        {{ 'purchase-activity.show-' + currentTab + '-details' | translate }}
                      </span>
                    </a>
                  }
                </div>
              </div>
            </div>
            <div class="address-information">
              <p [attr.data-test]="'link-addressInformation'" class="address__label">
                {{ 'purchase-activity.address-information' | translate }}:
              </p>
              <div class="address-table">
                <div class="address-column" [attr.data-test]="'div-orderedBy'">
                  <span class="address-header greyed-out" [attr.data-test]="'label-orderedBy'">
                    {{ 'purchase-activity.ordered-by' | translate }}:
                  </span>
                  <span [attr.data-test]="'label-customerName'" class="address-row">
                    {{ order.attributes.pointOfContact?.firstName }} {{ order.attributes.pointOfContact?.lastName }}
                  </span>
                  <span [attr.data-test]="'label-customerEmail'" class="address-row">
                    {{ order.attributes.pointOfContact?.email }}
                  </span>
                  <span [attr.data-test]="'label-customerPhone'" class="address-row">
                    {{ order.attributes.pointOfContact?.phone }}
                  </span>
                </div>
                @for (address of addresses; track address.type) {
                  <div class="address-column" [attr.data-test]="'div-' + address.type">
                    @if (checkoutAddresses.includes(address.arakhKey)) {
                      <span class="address-header greyed-out" [attr.data-test]="'label-address'">
                        {{ 'purchase-activity.' + address.translationKey | translate }}:
                      </span>
                      @if (!order.attributes[address.type]) {
                        <span>-</span>
                      }
                      @if (address.type === 'shippingAddress') {
                        <span class="address-row" [attr.data-test]="'desc-attentionTo'">
                          {{ formatIfMissingValue(order.attributes.pointOfContact?.attentionTo) }}
                        </span>
                      }
                      <span [attr.data-test]="'desc-addressName'" class="address-row">
                        @if (!order.attributes[address.type]?.isCustom && order.attributes[address.type]?.sapId) {
                          {{ order.attributes[address.type]?.sapId }} -
                        }
                        {{ formatIfMissingValue(order.attributes[address.type]?.company) }}
                      </span>
                      <span [attr.data-test]="'desc-addressStreet'" class="address-row">
                        {{ formatIfMissingValue(order.attributes[address.type]?.address1) }}
                      </span>
                      <span [attr.data-test]="'desc-addressCity'" class="address-row">
                        {{
                          formatIfMissingValue(order.attributes[address.type]?.city) + ' ' +
                          formatIfMissingValue(order.attributes[address.type]?.zipCode)
                        }}
                      </span>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        }
      }
      <div class="order-details">
        <div class="ordered-items">
          <p class="items__label" [attr.data-test]="'desc-orderedItems'">{{ 'purchase-activity.ordered-items' | translate }}:</p>
          @for (item of order.attributes.items; track item) {
            <div class="product" [attr.data-test]="'orderedItem-' + item.name">
              <img class="product__image" [attr.data-test]="'img-productImage'" src="{{item.metadata?.image}}" alt="{{item.name}}">
              <div class="product__details">
                <div class="details-header">
                  <h5 class="product__name" [attr.data-test]="'desc-productName'">{{ item.name }}</h5>
                  @if (currentTab === PurchaseActivityTabs.PENDING_ORDERS && order.attributes.stateDisplayName === EOrderStatus.WAITING) {
                    <div class="button-container">
                      <button
                          type="button"
                          class="remove-item-label"
                          (click)="openRemoveItemFromCartModal(item.sku, order.id)"
                          [attr.data-test]="'button-removeItem'">
                        <span class="icon-close"></span>
                        <span [attr.data-test]="'desc-removeItem'">
                          {{ 'purchase-activity.remove-item' | translate }}
                        </span>
                      </button>
                    </div>
                  }
                </div>
                <p class="material-number" [attr.data-test]="'desc-productMaterialNumber'">
                  @if (currentTab !== PurchaseActivityTabs.PREVIOUS_REQUESTS) {
                    {{ item.materialNumber }}
                  } @else {
                    {{ item.sku }}
                  }
                </p>
                <div class="product__info">
                  <div>
                    <span [attr.data-test]="'label-quantity'">{{ 'purchase-activity.quantity' | translate }}:</span>
                    <span class="product__qty" [attr.data-test]="'desc-quantity'">{{ item.quantity }}</span>
                  </div>
                  @if (currentTab !== PurchaseActivityTabs.PREVIOUS_REQUESTS) {
                    <div>
                      <span [attr.data-test]="'label-price'">{{ 'purchase-activity.price-per-unit' | translate }}:</span>
                      <span class="product__price" [attr.data-test]="'desc-price'">
                              {{ (item?.unitPrice) | currency: priceUtils.getStoreCurrencyCode() }}
                            </span>
                    </div>
                  }
                  @if (currentTab !== PurchaseActivityTabs.PREVIOUS_REQUESTS) {
                    <div>
                      <span [attr.data-test]="'label-totalPrice'">{{ 'purchase-activity.total-price' | translate }}:</span>
                      <span class="product__price" [attr.data-test]="'desc-totalPrice'">
                        {{ item?.sumPrice | currency: priceUtils.getStoreCurrencyCode() }}
                      </span>
                    </div>
                  }
                  @if (
                      currentTab === PurchaseActivityTabs.PREVIOUS_QUOTES ||
                      currentTab === PurchaseActivityTabs.PREVIOUS_REQUESTS ||
                      (currentTab === PurchaseActivityTabs.PREVIOUS_ORDERS &&
                          currentSubTab === PurchaseActivityPreviousOrdersTabs.ORDERS)
                      ) {
                    <div class="request-again__btn">
                      <button
                          class="button button--small button--primary buy-again-button"
                          appDebounceClick
                          [debounceTime]="300"
                          (debounceClick)="addItemsToCart([item])"
                          [attr.data-test]="'button-buyAgain'">
                        <span class="buy-again__icon icon-replay"></span>
                        <span [attr.data-test]="'desc-buyAgain'" class="buy-again__label">
                          @if (
                              currentTab === PurchaseActivityTabs.PREVIOUS_ORDERS &&
                              currentSubTab === PurchaseActivityPreviousOrdersTabs.ORDERS
                              ) {
                            {{ 'purchase-activity.buy-again' | translate }}
                          } @else {
                            {{ 'purchase-activity.request-again' | translate }}
                          }
                        </span>
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </ng-container>
</app-accordion>
@if (isDeclinePendingOrderModalVisible) {
  <div class="modal-dialog modal-dialog--warning is-open"
       tabindex="-1"
       id="modal-confirm-decline"
       role="dialog"
       aria-modal="true"
       aria-labelledby="modal-confirm-decline_title"
       aria-describedby="modal-confirm-decline_desc"
       aria-hidden="true"
       data-linked="#modal-confirm-decline">
    <div class="modal-dialog__container" role="document">
      @if (isDeclinePendingOrderInProgress) {
        <div class="loader__overlay">
          <div class="loader__content">
            <div class="loading-animation"></div>
          </div>
        </div>
      }
      <div class="modal-dialog__header">
        <i aria-hidden="true" class="modal-dialog__indicator"></i>
        <h5 class="modal-dialog__title" id="modal-confirm-decline_title" [attr.data-test]="'title-modal-dialog'">
          {{ (isApprover$ | async)
            ? ('purchase-activity.decline-order-modal-title' | translate)
            : ('purchase-activity.cancel-order-modal-title' | translate)
          }}
        </h5>
        <button
            type="button"
            class="modal-dialog__close"
            data-dismiss="modal-dialog"
            aria-label="Close"
            (click)="closeDeclinePendingOrderModal()"
            [attr.data-test]="'button-close-modal-dialog'">
          <span aria-hidden="true" class="icon-close"></span>
        </button>
      </div>
      <div id="modal-confirm-decline_desc" class="modal-dialog__body">
        <p [attr.data-test]="'desc-modal-message'">
          @if (isApprover$ | async) {
            {{ 'purchase-activity.decline-order-modal-message' | translate }}
          } @else {
            {{ 'purchase-activity.cancel-order-modal-message' | translate }}
          }
        </p>
      </div>
      <div class="modal-dialog__footer">
        <button
            type="button"
            class="button button"
            data-dismiss="modal-dialog"
            (click)="closeDeclinePendingOrderModal()"
            [attr.data-test]="'button-modal-cancel'">
          @if (isApprover$ | async) {
            {{ 'purchase-activity.cancel-button' | translate }}
          } @else {
            {{ 'purchase-activity.no-button' | translate }}
          }
        </button>
        <button
            type="button"
            class="button button--primary"
            data-dismiss="modal-dialog"
            appDebounceClick
            [debounceTime]="300"
            (debounceClick)="declinePendingOrder(order)"
            [attr.data-test]="'button-modal-cancel'">
          @if (isApprover$ | async) {
            {{ 'purchase-activity.decline-button' | translate }}
          } @else {
            {{ 'purchase-activity.yes-button' | translate }}
          }
        </button>
      </div>
    </div>
  </div>
}
<app-remove-item-from-order-modal
    [showModalConfirmRemove]="isConfirmRemoveItemModalVisible"
    [deleteLastItemAndDeclineOrderFlag]="isLastItemInPendingOrder"
    [clickedItemDataName]="clickedItemName"
    (restartShowModal)="restartShowModal()"
    (removeItemFromCart)="removeItemFromCart()"
    (lastItemDeclineOrder)="declinePendingOrder(order)"
/>
