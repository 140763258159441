import { Pipe, PipeTransform } from '@angular/core';
import { AdvancedFilter } from '../../models/advanced-filter';
import { EEquipmentFilterAttributes, EEquipmentProductGroup } from '../../configurations/equipments';
import { RemoveLeadingZeroPipe } from './remove-leading-zero.pipe';

@Pipe({
  name: 'advancedFiltering',
  standalone: true
})
export class AdvancedFilteringPipe implements PipeTransform {
  constructor(private removeLeadingZero: RemoveLeadingZeroPipe) {}

  /**
   * Filters the items by the given filters.
   *
   * @param {any[]} items
   * @param {AdvancedFilter[]} filters
   * @returns {any[]}
   */
  transform(items: any[], filters: AdvancedFilter[]): any[] {
    if (!this.isAnyFilterApplied(filters)) {
      return items;
    }
    const arrayOfFilterResults: any[][] = [];
    filters.forEach(filter => {
      const filterResults = this.filterByAttribute(items, filter);
      if (filter.selected.length !== 0) {
        arrayOfFilterResults.push(filterResults);
      }
    });
    return this.intersect(...arrayOfFilterResults);
  }

  /**
   * Checks if any filter is applied.
   *
   * @param {AdvancedFilter[]} filters
   * @returns {boolean}
   * @private
   */
  private isAnyFilterApplied(filters: AdvancedFilter[]): boolean {
    return filters.some(filter => filter.selected.length > 0);
  }

  /**
   * Filters the items by the given filter.
   *
   * @param {any[]} items
   * @param {AdvancedFilter} filter
   * @returns {any[]}
   */
  filterByAttribute(items: any[], filter: AdvancedFilter): any[] {
    if (items && filter.selected) {
      return items.filter((item) => {
        let attribute: string[];
        if (filter.secondAdditionalAttribute) {
          attribute = [item?.attributes?.[filter.attribute]?.[filter.firstAdditionalAttribute]?.[filter.secondAdditionalAttribute].toString()];
        } else if (filter.firstAdditionalAttribute) {
          attribute = [item?.attributes?.[filter.attribute]?.[filter.firstAdditionalAttribute].toString()];
        } else {
          switch (filter.attribute) {
            case 'id':
              attribute = [this.removeLeadingZero.transform(item?.attributes?.[filter.attribute])];
              break;
            case 'shippingAddress':
            case 'soldToAddress':
            case 'payerAddress':
            case 'billingAddress':
              attribute = [this.addressToString(item?.attributes?.[filter.attribute])];
              break;
            case 'shipTo':
            case 'soldTo':
            case 'billTo':
              attribute = [this.hybrisAddressToString(item?.attributes?.[filter.attribute], filter.attribute)];
              break;
            case 'items':
              attribute = this.getMaterialNumbersOfItems(item);
              break;
            default:
              attribute = [item?.attributes?.[filter.attribute]?.toString()];
              break;
          }
        }
        return this.checkForMatch((filter.name === EEquipmentFilterAttributes.PRODUCT_CATEGORY
            ? this.editSelectedFilterForConsumables(filter.selected) : filter.selected), attribute);
      });
    }
    return [];
  }

  /**
   * Edit the selected filter for consumables.
   *
   * @param {string[]} selected
   * @returns {string[]}
   */
  editSelectedFilterForConsumables(selected: string[]): string[] {
    return selected.map(s => (s.toLowerCase() !== EEquipmentProductGroup.CONSUMABLES.toLowerCase()).toString());
  }

  /**
   * Checks if the selected filter matches the values.
   *
   * @param {string[]} selectedFilter
   * @param {string[]} values
   * @returns {boolean}
   */
  checkForMatch(selectedFilter: string[], values: string[]): boolean {
    return values.some(value => selectedFilter.includes(value));
  }

  /**
   * converts the given address object to a string.
   *
   * @param address
   * @returns {string}
   */
  addressToString(address: any): string {
    if (!address) {
      return '';
    }

    const addressDetails = [
      address.address1,
      address.city,
      address.zipCode,
    ].filter(addressField => !this.isEmptyAddress(addressField)).join(', ');

    return this.formatEmptyAddress(address.company) + (addressDetails.length > 0 ? ` (${addressDetails})` : '');
  }

  /**
   * Converts the given address object to a string.
   *
   * @param attributes
   * @param {string} addressType
   * @returns {string}
   */
  hybrisAddressToString(attributes: any, addressType: string): string {
    if (!attributes) {
      return '';
    }

    const addressDetails = [
      attributes[`${addressType}Address`],
      attributes[`${addressType}Town`],
      attributes[`${addressType}PostalOffice`],
    ].filter(addressField => !this.isEmptyAddress(addressField)).join(', ');

    return this.formatEmptyAddress(attributes[`${addressType}Name`]) +
      (addressDetails.length > 0 ? ` (${addressDetails})` : '');
  }

  /**
   * Retrieves the material numbers of the items in the order.
   *
   * @param order
   * @returns {string[]}
   */
  getMaterialNumbersOfItems(order: any): string[] {
    return [
      ...new Set(
        order?.attributes?.items.map(item =>
          item?.materialNumber ||
          item?.productNumber?.toString() ||
          item?.sku
        )
      )
    ] as string[];
  }

  /**
   * Checks if the given address is empty.
   *
   * @param {string} address
   * @returns {boolean}
   */
  isEmptyAddress(address: string): boolean {
    return address === '' || address === 'empty';
  }

  /**
   * Formats the given address if it is empty.
   *
   * @param {string} address
   * @returns {string}
   */
  formatEmptyAddress(address: string): string {
    return this.isEmptyAddress(address) ? '' : address;
  }

  /**
   * Returns the intersection of the given arrays.
   *
   * @param {any[]} first
   * @param rest
   * @returns {any[]}
   */
  intersect(first = [], ...rest): any[] {
    rest = rest.map(array => new Set(array));
    return first.filter(item => rest.every(set => set.has(item)));
  }
}
