import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services';
import { map } from 'rxjs/operators';
import { EFeatureToggles } from '../configurations/common';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { combineLatest } from 'rxjs';

export const guestCartAuthGuard: CanActivateFn = (route, _) => {
  const router = inject(Router);
  const configurationFacade = inject(ConfigurationFacade);
  const authService = inject(AuthService);

  return combineLatest([
    configurationFacade.isFeatureEnabled(EFeatureToggles.EXTERNAL_QUOTE_CHECKOUT),
    authService.isAuthenticated$
  ]).pipe(
    map(([isFeatureEnabled, isAuthenticated]) => {
      if (isFeatureEnabled) {
        return true;
      } else {
        if (!isAuthenticated) {
          router.navigate(['/']);
          return false;
        }
        return true;
      }
    })
  );
};
