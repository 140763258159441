<div class="delivery-details container" [appLoadingAnimation]="isUserDataLoading || isCartDataLoading">
  <app-delivery-details-1-section
      *ngIf="!isUserDataLoading && !isCartDataLoading"
      [ngClass]="{'hidden': isOnDeliveryDetails2Section}"
      [isSectionDisplayed]="!isOnDeliveryDetails2Section"
      [cartId]="cartId"
      [cartAttributes]="cartAttributes"
      [cartItemsWithDetails]="cartItemsWithDetails"
      [cartRules]="cartRules"
      [systemDetails]="systemDetails"
      [userRoles]="userData.companyRoles"
      [userData]="userData"
      [isPreselectedShipToAddressCustom]="isPreselectedShipToAddressCustom"
      [isBusinessPartner]="isBusinessPartner"
      [isSaturdayShipment]="isSaturdayShipment"
      [isPriceDisputingActive]="isPriceDisputingActive"
      [isPriceDisputingSetInCart]="isPriceDisputingSetInCart"
      [priceDisputingPerItem]="priceDisputingPerItem"
      [useRfqFlow]="useRfqFlow"
      [useGenerateQuoteFlow]="useGenerateQuoteFlow"
      (proceedToDeliveryDetails2Section)="isOnDeliveryDetails2Section = $event">
  </app-delivery-details-1-section>
  <app-delivery-details-2-section
      *ngIf="!isUserDataLoading && !isCartDataLoading"
      [ngClass]="{'hidden': !isOnDeliveryDetails2Section}"
      [isSectionDisplayed]="isOnDeliveryDetails2Section"
      [cartId]="cartId"
      [cartAttributes]="cartAttributes"
      [cartItemsWithDetails]="cartItemsWithDetails"
      [cartRules]="cartRules"
      [systemDetails]="systemDetails"
      [userRoles]="userData.companyRoles"
      [userData]="userData"
      [shipToAddress]="orderDetailsData?.shipToAddress"
      [isPreselectedShipToAddressCustom]="isPreselectedShipToAddressCustom"
      [isBusinessPartner]="isBusinessPartner"
      [isSaturdayShipment]="isSaturdayShipment"
      [isPriceDisputingActive]="isPriceDisputingActive"
      [isPriceDisputingSetInCart]="isPriceDisputingSetInCart"
      [priceDisputingPerItem]="priceDisputingPerItem"
      [creatingOrderInProgress]="isCreatingOrderInProgress"
      [isApproverBuyerWorkflowEnabled]="isApproverBuyerWorkflowEnabled"
      [isCheckoutQuoteApprovalMandatory]="isCheckoutQuoteApprovalMandatory"
      [hasApproverAccess]="hasApproverAccess"
      [useRfqFlow]="useRfqFlow"
      [useGenerateQuoteFlow]="useGenerateQuoteFlow"
      (backToDeliveryDetails1Section)="proceedToDeliveryDetails1Section()"
      (createOrder)="createOrder()">
  </app-delivery-details-2-section>
  <app-modal
      [open]="showApprovalModal"
      title="{{ getModalTitle() | translate }}"
      (crossClick)="backToHomepage()"
      (outsideClick)="backToHomepage()">
    <ng-container ngProjectAs="[slot][body]">
      @if (useRfqFlow) {
        <h5>{{ getModalBody() | translate }}</h5>
      } @else {
        <h5 [innerHTML]="approvalMsg"></h5>
      }
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <a type="submit" class="button button--primary" data-dismiss="modal-dialog" (click)="backToHomepage()">
        {{ 'alert.ok' | translate }}
      </a>
    </ng-container>
  </app-modal>
</div>
