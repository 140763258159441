import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeliveryDetailsService } from '../shared/services/delivery-details.service';
import { combineLatest, Observable, skipWhile, Subject, takeUntil } from 'rxjs';
import { EFeatureToggles, EGlueResource, EUserRoles } from '../../../configurations/common';
import { CustomerFacade } from '../../../facades/customer.facade';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from '../../../services';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { take } from 'rxjs/operators';
import { PageTypes } from '../../../analytics/enums/pageTypes';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { ICartAttributes, ICartItemWithDetail, ICartRule } from '../../../models/cart.models';
import {
  IAddress,
  IMaterialMasterNumbersPerItem,
  IPointOfContact,
  IPriceDisputingPerItem,
  ISystemDetails,
} from '../../../models/common.models';
import { CartUtils } from '../../../utils/cart.utils';
import { AddressUtils } from '../../../utils/address.utils';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { ICheckoutUpdate, IConsentCheckbox } from '../../../models/checkout.models';
import { IApproverData, ICustomerCheckoutData } from '../../../models/customer.models';
import { FileType, FileUtils } from '../../../utils/file.utils';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { StoreConfigurationFacade } from '../../../facades/store-configuration.facade';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ICreateRfQ } from '../../../models/rfq.models';

@Component({
  selector: 'app-harmonized-page-delivery-details',
  templateUrl: './harmonized-page-delivery-details.component.html',
})
export class HarmonizedPageDeliveryDetailsComponent implements OnInit, OnDestroy {
  cartId: string;
  inProcessCartId: string;
  cartAttributes: ICartAttributes;
  cartItemsWithDetails: ICartItemWithDetail[];
  cartRules: ICartRule[];
  systemDetails: ISystemDetails;
  priceDisputingPerItem: IPriceDisputingPerItem[];
  materialMasterNumbersPerItem: IMaterialMasterNumbersPerItem[] = [];
  userData: ICustomerCheckoutData;
  orderDetailsData: {shipToAddress: IAddress, billToAddress: IAddress};
  deliveryDetails2FormInputs$: Observable<string[]> = new Observable<string[]>();

  isBusinessPartner: boolean = false;
  isCartDataLoading: boolean = true;
  isCreatingOrderInProgress: boolean = false;
  isOnDeliveryDetails2Section: boolean = false;
  isPreselectedShipToAddressCustom: boolean = false;
  isPriceDisputingActive: boolean = false;
  isSaturdayShipment: boolean = false;
  isUserDataLoading: boolean = true;
  isPriceDisputingSetInCart: boolean = false;

  //RFQ workflow related flags
  useRfqFlow: boolean = false;
  useGenerateQuoteFlow: boolean = false;

  isApproverBuyerWorkflowEnabled: boolean = false;
  hasApproverAccess: boolean = false;
  isCheckoutQuoteApprovalMandatory: boolean = false;
  companyUserId: string;
  showApprovalModal: boolean = false;
  approvalMsg: string;

  termsAccessTime: Date;
  quoteRequestReference: string;
  quoteCreatedAt: string;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private customerFacade: CustomerFacade,
    private marketingFacade: MarketingFacade,
    private checkoutFacade: CheckoutFacade,
    private configurationFacade: ConfigurationFacade,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private i18nService: I18nService,
    private analyticsService: AnalyticsService,
    private deliveryDetailsService: DeliveryDetailsService,
    private storeConfigurationFacade: StoreConfigurationFacade,
  ) {
  }

  /**
   * Initializes the component by loading necessary data.
   */
  ngOnInit(): void {
    this.deliveryDetailsService.updateDeliveryDetailsData();
    this._selectCustomerData();
    this._selectCurrentCartId();
    this._selectIsApproverBuyerFlowEnabled();
    this._selectIsCheckoutQuoteApprovalMandatory();
    this._selectIsPreselectedShipToAddressCustom();
    this._selectSectionWithRequestedInput();
    if (this.useGenerateQuoteFlow) {
      this.termsAccessTime = new Date();
    }
  }

  /**
   * Cleans up resources when the component is destroyed.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.customerFacade.clearAttentionTo();
  }

  private _resolveRfqFlowConfig(isRfqEnabled: boolean, isRfqOnlyEnabled: boolean): void {
    //in case the RFQ ONLY is enabled or RFQ is enabled and cart has product with zero price the RFQ flow should be used
    this.useRfqFlow = isRfqOnlyEnabled || (isRfqEnabled && !CartUtils.isSetPricesForAllItems(this.cartItemsWithDetails));
    //in case the RFQ ONLY flow is enabled and cart doesn't have any product with zero price the generate quote flow should be used
    this.useGenerateQuoteFlow = isRfqOnlyEnabled && CartUtils.isSetPricesForAllItems(this.cartItemsWithDetails);

    const workflowType = this.deliveryDetailsService._resolveRfqFlowConfig(this.useRfqFlow, this.useGenerateQuoteFlow);
    this.deliveryDetails2FormInputs$ = this.configurationFacade.getDynamicDeliveryDetailsFormConfiguration(workflowType, 2);
  }

  private _initializeRfqFlowConfig(): void {
    combineLatest([
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.RFQ_FLOW),
      this.storeConfigurationFacade.selectIsRfqOnly(),
    ]).pipe(
      take(1),
    ).subscribe(([isRfqEnabled, isRfqOnlyEnabled]) => {
      this._resolveRfqFlowConfig(isRfqEnabled, isRfqOnlyEnabled);
    });
  }

  /**
   * Selects the section with requested input.
   * @private
   */
  private _selectSectionWithRequestedInput(): void {
    if (this.activatedRoute.snapshot.queryParamMap.has('redirect-to')) {
      this.deliveryDetails2FormInputs$.pipe(take(1)).subscribe(input => {
        if (input.includes(this.activatedRoute.snapshot.queryParamMap.get('redirect-to'))) {
          this.isOnDeliveryDetails2Section = true;
        }
      });
    }
  }

  /**
   * Proceeds to the delivery details 1 section.
   */
  proceedToDeliveryDetails1Section(): void {
    this.isOnDeliveryDetails2Section = false;
  }

  /**
   * Get title for modal
   * @returns {string}
   */
  getModalTitle(): string {
    if (!this.useRfqFlow) {
      return 'checkout-delivery-details.approval-success';
    } else {
      return this.useGenerateQuoteFlow
        ? 'request-quote.success-modal-title-rfq-only'
        : 'request-quote.success-modal-title';
    }
  }

  /**
   * Creates pointOfContact and calls either createDeliveryDetailsOrder(), or createQuoteRequestOrder()
   * based on RFQ flow.
   */
  createOrder(): void {
    this.isCreatingOrderInProgress = true;

    combineLatest([
      this.checkoutFacade.selectPreselectedShipToAddress(),
      this.checkoutFacade.selectPreselectedBillToAddress(),
      this.checkoutFacade.selectDeliveryDetailsFormData(),
      this.checkoutFacade.selectDeliveryDetailsApproverFormData(),
      this.checkoutFacade.selectTaxNumberFormData(),
      this.checkoutFacade.selectTermsAndConsentsData(),
    ]).pipe(take(1)).subscribe(([preselectedShipTo, preselectedBillTo, checkoutFormData, checkoutApproverFormData, checkoutTaxNumberData, termsAndConditionData]) => {
      const pointOfContact = {
        pointOfContactId: 'pointOfContact',
        attentionTo: checkoutFormData?.attentionTo ?? '',
        department: checkoutFormData?.department ?? '',
        firstName: checkoutFormData?.firstName ?? '',
        lastName: checkoutFormData?.lastName ?? '',
        email: checkoutFormData?.email ?? '',
        phone: checkoutFormData?.phone ?? '',
        comment: checkoutFormData?.comment ?? '',
        deliveryTime: checkoutFormData?.deliveryTime ?? '',
        deliveryDate: checkoutFormData?.deliveryDate ?? '',
        shipmentMethod: checkoutFormData?.shipmentMethod ?? '',
        floorOrRoom: checkoutFormData?.floorOrRoom ?? '',
      };

      if (!this.useRfqFlow) {
        this.createDeliveryDetailsOrder(pointOfContact, preselectedBillTo, preselectedShipTo, checkoutApproverFormData);
      } else {
        this.createQuoteRequestOrder(pointOfContact, preselectedShipTo, checkoutTaxNumberData, termsAndConditionData);
      }
    });
  }


  /**
   * Creates a regular order with necessary data.
   * @param {IPointOfContact} pointOfContact
   * @param {IAddress} preselectedBillTo
   * @param {IAddress} preselectedShipTo
   * @param {IApproverData} checkoutApproverFormData
   */
  createDeliveryDetailsOrder(pointOfContact: IPointOfContact, preselectedBillTo: IAddress, preselectedShipTo: IAddress, checkoutApproverFormData: IApproverData) {
    let checkoutUpdate: ICheckoutUpdate = {
      data: {
        type: EGlueResource.CHECKOUT_UPDATE,
        attributes: {
          idCart: this.cartId,
          isAddressSavingSkipped: true,
          pointOfContact: pointOfContact,
          priceDisputingPerItem: this.priceDisputingPerItem,
          materialMasterNumbers: this.materialMasterNumbersPerItem,
        },
      },
    };

    if (preselectedShipTo) {
      checkoutUpdate = this._extendCheckoutUpdateWithShipTo(checkoutUpdate, preselectedShipTo);
    }

    if (preselectedBillTo) {
      checkoutUpdate = this._extendCheckoutUpdateWithBillTo(checkoutUpdate, preselectedBillTo);
    }

    if (this.isApproverBuyerWorkflowEnabled) {
      checkoutUpdate = this._extendCheckoutUpdateWithApproverDetails(checkoutUpdate, checkoutApproverFormData);
    }

    this.checkoutFacade.postCheckoutUpdate(checkoutUpdate).pipe(
      take(1),
    ).subscribe({
      next: () => {
        this.isCreatingOrderInProgress = false;
        this.inProcessCartId = this.cartId;

        if (this.isApproverBuyerWorkflowEnabled) {
          this.showApprovalModal = true;
          this.generateApprovalMsg();

          if (this.isCheckoutQuoteApprovalMandatory) {
            this._generateQuotePdf();
          }

          if (this.userData.companyRoles.includes(EUserRoles.Buyer) || this.isCheckoutQuoteApprovalMandatory) {
            this.analyticsService.trackCart('order.placed');
            this.marketingFacade.createEmptyCart();
            return;
          }
        }

        this.checkoutFacade.actionPutCartIdOrderApprove(this.inProcessCartId);
        this.router.navigate(['/order-review/', this.inProcessCartId]).then();
      },
      error: () => {
        this.isCreatingOrderInProgress = false;
      },
    });
  }

  /**
   * Creates an RFQ order with necessary data.
   * @param {IPointOfContact} pointOfContact
   * @param {IAddress} preselectedShipTo
   * @param {string} checkoutTaxNumberData
   * @param termsAndConditions
   */
  createQuoteRequestOrder(
    pointOfContact: IPointOfContact,
    preselectedShipTo: IAddress,
    checkoutTaxNumberData: string,
    termsAndConditions: IConsentCheckbox[],
  ) {
    const quoteRequestData: ICreateRfQ = {
      data: {
        type: EGlueResource.QUOTE_REQUESTS,
        attributes: {
          cartUuid: this.cartId,
          meta: {
            purchase_order_number: '',
            delivery_date: '',
            note: '',
          },
          systemDetails: this.systemDetails,
          systemDetailsPerItem: [],
          pointOfContact: pointOfContact,
          shippingAddress: preselectedShipTo,
          taxNumber: checkoutTaxNumberData,
          termsAccessTime: this.termsAccessTime,
          consentedTerms: termsAndConditions
            ? termsAndConditions.filter(checkbox => checkbox.isChecked)
              .map(checkbox => checkbox.name)
            : [],
        },
      },
    };

    this.checkoutFacade.createRfq(quoteRequestData).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
        next: response => {
          if (response) {
            this.quoteRequestReference = response.data.attributes.quoteRequestReference;
            this.quoteCreatedAt = response.data.attributes.createdAt;
          }

          this.isCreatingOrderInProgress = false;
          this.showApprovalModal = true;

          const previousCartId = this.cartId;
          this.marketingFacade.createEmptyCart();
          this.marketingFacade.deleteCart(previousCartId);
          this.analyticsService.trackCart('rfq.placed');

          this._getQuotePdfFile();
        },
        error: (error) => {
          this.isCreatingOrderInProgress = false;
          this._handleCheckoutError(error);
        },
      },
    );
  }

  private _getQuotePdfFile(): void {
    if (this.useGenerateQuoteFlow && !!this.quoteRequestReference) {
      this.checkoutFacade.getQuotePdfFile(this.quoteRequestReference).pipe(
        take(1),
      ).subscribe((response: HttpResponse<Blob>) => {
        const contentDispositionHeader = response.headers.get('Content-Disposition');
        const filename = FileUtils.extractFilenameFromContentDisposition(
          contentDispositionHeader, 'Quote_details_' + this.quoteRequestReference,
        );

        FileUtils.saveAndOpenFile(response.body, FileType.PDF, filename);
      });
    }
  }

  private _handleCheckoutError(httpError: HttpErrorResponse): void {
    if (httpError.status === 422) {
      this.configurationFacade.getAlert().pipe(
        take(1),
      ).subscribe(alert => {
        if (alert && alert.type === 'error' && alert.message) {
          this.configurationFacade.setAlert({
            ...alert,
            type: 'warning',
          });
        }
      });

      this.termsAccessTime = new Date();
    }
  }

  /**
   * Extends the checkout update object with the shipping address.
   *
   * @private
   *
   * @param {ICheckoutUpdate} checkoutUpdate
   * @param {IAddress} shipTo
   * @returns {ICheckoutUpdate}
   */
  private _extendCheckoutUpdateWithShipTo(checkoutUpdate: ICheckoutUpdate, shipTo: IAddress): ICheckoutUpdate {
    return {
      ...checkoutUpdate,
      data: {
        ...checkoutUpdate.data,
        attributes: {
          ...checkoutUpdate.data.attributes,
          shippingAddress: AddressUtils.updateEmptyAddressFields(shipTo),
        },
      },
    };
  }

  /**
   * Extends the checkout update object with the billing address.
   *
   * @private
   *
   * @param {ICheckoutUpdate} checkoutUpdate
   * @param {IAddress} billTo
   * @returns {ICheckoutUpdate}
   */
  private _extendCheckoutUpdateWithBillTo(checkoutUpdate: ICheckoutUpdate, billTo: IAddress): ICheckoutUpdate {
    return {
      ...checkoutUpdate,
      data: {
        ...checkoutUpdate.data,
        attributes: {
          ...checkoutUpdate.data.attributes,
          billingAddress: AddressUtils.updateEmptyAddressFields(billTo),
        },
      },
    };
  }

  /**
   * Extends the checkout update object with the approver details.
   *
   * @private
   *
   * @param {ICheckoutUpdate} checkoutUpdate
   * @param {IApproverData} approverDetails
   * @returns {ICheckoutUpdate}
   */
  private _extendCheckoutUpdateWithApproverDetails(checkoutUpdate: ICheckoutUpdate, approverDetails: IApproverData): ICheckoutUpdate {
    return {
      ...checkoutUpdate,
      data: {
        ...checkoutUpdate.data,
        attributes: {
          ...checkoutUpdate.data.attributes,
          approverDetails: !this.hasApproverAccess
            ? approverDetails
            : {approverId: this.companyUserId, comment: '', dueDate: null},
        },
      },
    };
  }

  /**
   * Selects customer related data.
   *
   * @private
   */
  private _selectCustomerData(): void {
    combineLatest([
      this.customerFacade.selectCustomerData(),
      this.customerFacade.selectCustomerDataLoading(),
      this.customerFacade.isSaturdayShipmentSelected(),
      this.customerFacade.isBusinessPartner(),
      this.customerFacade.isPriceDisputingActive(),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([data, isDataLoading, isSaturdayShipment, isBusinessPartner, isPriceDisputingActive]) => {
      this.userData = data;
      this.isUserDataLoading = isDataLoading;
      this.isSaturdayShipment = isSaturdayShipment;
      this.isBusinessPartner = isBusinessPartner;
      this.isPriceDisputingActive = isPriceDisputingActive;

      if (this.userData.companyRoles.includes(EUserRoles.Viewer)) {
        this.router.navigate([this.i18nService.getCurrentLocale()]).then();
      }

      this.hasApproverAccess = [
        EUserRoles.Approver,
        EUserRoles.Admin,
      ].some(role => this.userData.companyRoles.includes(role));
    });
  }

  /**
   * Selects the current cart ID.
   *
   * @private
   */
  private _selectCurrentCartId(): void {
    this.marketingFacade.selectCartId()
      .pipe(
        skipWhile(cartId => !cartId),
        take(1),
      ).subscribe(cartId => {
      this.cartId = cartId;
      this._selectCartItemAdditionalData(cartId);
    });
  }

  /**
   * Selects additional data for the current cart.
   *
   * @private
   *
   * @param {string} cartId
   */
  private _selectCartItemAdditionalData(cartId: string): void {
    combineLatest([
      this.marketingFacade.getCurrentCartItems(),
      this.marketingFacade.selectCartItemsWithDetails(),
      this.marketingFacade.selectPriceDisputing(),
      this.marketingFacade.selectIsPriceDisputingSetInCart(),
      this.marketingFacade.selectAllMaterialMasterNumbersInCart(cartId),
    ]).pipe(
      skipWhile(([cartData]) => !cartData),
      takeUntil(this.unsubscribe$),
    ).subscribe(
      ([cartData, cartItemsWithDetails, priceDisputingPerItem, isPriceDisputingSetInCart, materialMasterNumbers]) => {
        if (cartData) {
          this.cartAttributes = cartData.data.attributes;
          this.cartRules = cartData.included.filter(include => include.type === 'cart-rules');
          this.systemDetails = cartData.data.attributes.systemDetails;

          if (cartItemsWithDetails?.length > 0) {
            this.cartItemsWithDetails = CartUtils.mapAvailabilitiesToCartItems(
              cartItemsWithDetails, cartData.included,
            );
          }

          this._initializeRfqFlowConfig();

          this.priceDisputingPerItem = priceDisputingPerItem;
          this.isPriceDisputingSetInCart = isPriceDisputingSetInCart;
          this.materialMasterNumbersPerItem = materialMasterNumbers;
          this.isCartDataLoading = false;

          this._setDataForAnalyticsTracking(this.cartItemsWithDetails);
        }
      });
  }

  /**
   * Sets data for analytics tracking.
   *
   * @private
   *
   * @param {ICustomerCheckoutData} cartData
   */
  private _setDataForAnalyticsTracking(cartData: ICartItemWithDetail[]): void {
    this.analyticsService.setCartId(this.cartId);
    this.analyticsService.setProducts(cartData);
    this.analyticsService.trackPageReady('spare part - delivery details', PageTypes.DELIVERY_DETAILS_PAGE, 'concrete-products');
  }

  /**
   * Selects whether the approver-buyer flow is enabled (based on Arakh feature toggle).
   *
   * If approver-buyer flow is enabled, selects additional needed data (company user id).
   *
   * @private
   */
  private _selectIsApproverBuyerFlowEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.APPROVER_BUYER_FLOW).pipe(take(1)).subscribe(value => {
      this.isApproverBuyerWorkflowEnabled = value;

      if (this.isApproverBuyerWorkflowEnabled) {
        this._selectCompanyUserId();
      }
    });
  }

  /**
   * Selects if special checkout quote approval workflow is enabled.
   *
   * The workflow where even approvers need to approve their quote explicitly.
   * It was initially introduced with JP store.
   *
   * @private
   */
  private _selectIsCheckoutQuoteApprovalMandatory(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CHECKOUT_QUOTE_APPROVAL_MANDATORY).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isCheckoutQuoteApprovalMandatory = state;
    });
  }

  /**
   * Selects the company user ID.
   *
   * @private
   */
  private _selectCompanyUserId(): void {
    this.customerFacade.selectCompanyUserId().pipe(take(1)).subscribe(companyUserId => this.companyUserId = companyUserId);
  }

  /**
   * Selects whether the preselected ship-to address is custom (manually created by customer).
   *
   * @private
   */
  private _selectIsPreselectedShipToAddressCustom(): void {
    this.checkoutFacade.selectIsPreselectedShipToAddressCustom$()
      .pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(isPreselectedShipToAddressCustom => {
      this.isPreselectedShipToAddressCustom = isPreselectedShipToAddressCustom;
    });
  }

  /**
   * Generates a quote PDF for the current cart.
   *
   * @private
   */
  private _generateQuotePdf(): void {
    if (this.cartId) {
      this.checkoutFacade.getCartGenerateQuotePdfFile(this.cartId, false).pipe(
        take(1),
      ).subscribe((response: HttpResponse<Blob>) => {
        const contentDispositionHeader = response.headers.get('Content-Disposition');
        const filename = FileUtils.extractFilenameFromContentDisposition(
          contentDispositionHeader, 'Cart_details_' + this.cartId,
        );

        FileUtils.saveAndOpenFile(response.body, FileType.PDF, filename);
      });
    }
  }

  /**
   * Generates an approval message based on feature toggle and user role.
   */
  generateApprovalMsg(): void {
    const messageUrl: string = this.i18nService.getCurrentLocale() + '/order-approval/' + this.cartId;
    let messageKey: string = 'checkout-delivery-details.approval-success-message';
    let messageArgs: object = {};
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.APPROVER_HYPERLINK).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((value: boolean) => {
      if (value && this.userData.companyRoles.includes(EUserRoles.Approver)) {
        messageKey = 'checkout-delivery-details.approval-success-message-approver';
        messageArgs = {navigationToHospitalOrder: messageUrl};
      }
    });

    this.i18nService.getTranslationByKey(messageKey, messageArgs).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((translation: string) => this.approvalMsg = translation);
  }

  /**
   * Navigates back to the homepage and then resets cached checkout data.
   */
  backToHomepage(): void {
    this.router.navigate(
      [this.i18nService.getCurrentLocale()],
      {queryParams: {lastCartId: this.inProcessCartId}},
    ).then(() => this.marketingFacade.resetCachedCheckoutData());
  }

  /**
   * Get body of modal after finishing order
   * @returns {string}
   */
  getModalBody(): string {
    return this.useGenerateQuoteFlow ? 'request-quote.success-modal-body-rfq-only' : 'request-quote.success-modal-body';
  }
}
