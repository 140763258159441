import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { WishlistActions } from '../../../actions';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { PageTypes } from '../../../analytics/enums/pageTypes';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { StoreConfigurationFacade } from '../../../facades/store-configuration.facade';
import { ICartRule } from '../../../models/cart.models';
import { State } from '../../../reducers';
import * as ShopCartSelectors from '../../../reducers/shop-cart.reducer';
import * as WishlistSelector from '../../../reducers/wishlist.reducer';
import { AppUtils } from '../../../utils/app.utils';
import { CartUtils } from '../../../utils/cart.utils';
import { FileType, FileUtils } from '../../../utils/file.utils';
import { FormatDatePipe } from '../../../shared/pipes/format-date.pipe';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-shop-cart',
  templateUrl: './shop-cart.component.html',
  styleUrls: ['./shop-cart.component.scss'],
})
export class ShopCartComponent implements OnInit, OnDestroy {
  @Input() isExportCartActive: boolean;

  rfqActive = false;
  isRfqOnly = false;

  currency: string;
  currentQuantity = true;
  cartId: string;
  currentCart: any;
  isCartEmpty = true;
  cartRules = [] as Array<ICartRule>;
  items: any;
  totalItemsQuantity: number;
  updateItemInCartData: {data: {type: string; attributes: {quantity: number}}};
  updateCartOperationInProgress$ = this.store.select(ShopCartSelectors.isAddItemToCartOperationInProgress);
  loadingCartDataInProgress: boolean;
  addItemToCartOperationInProgress: boolean;

  addNewModalActive = false;
  wishlists = [];
  minimumOrderValue = 0;
  showPriceDisclaimer = false;

  private activeTriggerAdlytic: boolean = false;
  private unsubscribe$ = new Subject<void>();
  private pageReadyEventFired: boolean = false;

  constructor(
    private storeConfigurationFacade: StoreConfigurationFacade,
    private store: Store<State>,
    private router: Router,
    private analyticsService: AnalyticsService,
    private checkoutFacade: CheckoutFacade,
    private configurationFacade: ConfigurationFacade,
    private marketingFacade: MarketingFacade,
    private formatDatePipe: FormatDatePipe
  ) {
  }

  ngOnInit(): void {
    this.selectIsCartEmpty();
    this.selectCartItemsWithDetails();
    this.shopCartPageDataSubscription();
    this.marketingFacade.loadCurrentCart();
    this.store.dispatch(WishlistActions.retrieveWishlists());
    this.getWishlistsFromStore();
    this.selectRfqActive();
    this.selectIsRfqOnly();
    this.selectMinimumOrderValue();
    this.selectCartRules();
    this.showPriceDisclaimer = AppUtils.getCurrentStore().showPriceDisclaimer;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectRfqActive(): void {
    this.marketingFacade.selectRfqActive().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.rfqActive = state;
    });
  }

  selectIsRfqOnly(): void {
    this.storeConfigurationFacade.selectIsRfqOnly().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isRfqOnly = state;
    });
  }

  selectMinimumOrderValue(): void {
    this.storeConfigurationFacade.selectMinimumOrderValue().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.minimumOrderValue = state;
    });
  }

  getWishlistsFromStore(): void {
    this.store.select(WishlistSelector.getWishlists).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      wishlists => {
        this.wishlists = wishlists;
      },
    );
  }

  getQuantityFromItem(quantity): void {
    this.currentQuantity = quantity;
  }

  selectIsCartEmpty(): void {
    this.marketingFacade.selectIsCartEmpty().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isCartEmpty = state;
    });
  }

  selectCartItemsWithDetails(): void {
    this.marketingFacade.selectCartItemsWithDetails().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(items => {
      this.items = items;
      this.analyticsService.setProducts(this.items);
    });
  }

  selectCartRules(): void {
    this.marketingFacade.selectCartRules().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartRules => {
      this.cartRules = cartRules;
    });
  }

  shopCartPageDataSubscription(): void {
    this.marketingFacade.shopCartPageDataSelector().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      if (!state) {
        this.currency = AppUtils.getCurrentStore().storeDefaultCurrency;
        return;
      }

      this.loadingCartDataInProgress = state.loadingCartDataInProgress;

      if (!state.cartItems || !state.cartId) {
        return;
      }

      this.currentCart = state.cartItems.data.attributes;
      this.cartId = state.cartId;
      this.currency = this.currentCart.currency;

      if (!this.activeTriggerAdlytic) {
        this.activeTriggerAdlytic = true;
        this.analyticsService.setCartId(this.cartId);
        this.analyticsService.trackPageReady('cart detail', PageTypes.CART_PAGE, 'concrete-products');
      }
      this.trackPageReady();
      this.addItemToCartOperationInProgress = state.addItemToCartOperationInProgress;
      this.totalItemsQuantity = state.cartItems.data.attributes.totalItemsQuantity;
    });
  }

  redirectToProductLists(): void {
    this.router.navigate(['/catalog']);
  }

  proceedToNextStep(): void {
    const requestQuoteOrGenerateQuoteFlow = this.rfqActive || (this.isRfqOnly && this.isSetPricesForAllItems());
    const nextPage = requestQuoteOrGenerateQuoteFlow ? '/request-quote' : '/delivery-details';
    if (requestQuoteOrGenerateQuoteFlow) {
      this.analyticsService.trackCart('rfq.checkout');
    } else {
      this.marketingFacade.getCurrentCartItems().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(cartItems => {
        this.analyticsService.setProducts(cartItems);
        this.analyticsService.trackCart('cart.checkout');
      })
    }
    this.router.navigate([nextPage]).then();
  }

  proceedBtnLabel(): string {
    return this.isRfqOnly && this.isSetPricesForAllItems() ? 'shop-cart.proceed-to-generate-quote' : this.getButtonNameForNoRfqOnly();
  }

  getTotalPrice(): number {
    return CartUtils.getTotalPrice(this.isCartEmpty, this.currentCart);
  }

  isSetPricesForAllItems(): boolean {
    return CartUtils.isSetPricesForAllItems(this.items);
  }

  isMinimumOrderValue(): boolean {
    return CartUtils.getIsMinimumOrderValue(this.minimumOrderValue, this.isCartEmpty, this.currentCart, this.items);
  }

  disableBtnProceedToNextStep(): boolean {
    return !this.isMinimumOrderValue() || this.addItemToCartOperationInProgress || this.totalItemsQuantity === 0 || !this.currentQuantity;
  }

  generatePdfFile(): void {
    this.checkoutFacade.getCartDetailPdfFile(this.cartId).pipe(take(1))
      .subscribe((response: HttpResponse<Blob>) => {
        const contentDispositionHeader = response.headers.get('Content-Disposition');
        const filename = FileUtils.extractFilenameFromContentDisposition(contentDispositionHeader, 'Cart_details_' + this.cartId);
        FileUtils.saveAndOpenFile(response.body, FileType.PDF, filename);
      });
  }

  private getButtonNameForNoRfqOnly(): string {
    return this.rfqActive ? 'shop-cart.proceed-to-request-details' : 'shop-cart.proceed-to-delivery-details';
  }

  private trackPageReady(): void {
    if (!this.pageReadyEventFired && !this.loadingCartDataInProgress) {
      this.analyticsService.trackPageReady('Shopping Cart Page', PageTypes.SHOPPING_CART_PAGE, 'shopping-cart');
      this.pageReadyEventFired = true;
    }
  }
}
