import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { ImageUtils } from '../../../utils/image.utils';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { PayloadUtils } from '../../../utils/payload.utils';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { IGuestData } from '../../../models/guest.models';

@Component({
  selector: 'app-guest-cart',
  templateUrl: './guest-cart.component.html',
  styleUrl: './guest-cart.component.scss',
})
export class GuestCartComponent implements OnInit {

  protected readonly ImageUtils = ImageUtils;

  guestCart: IGuestData;
  guestCartItems: any[]; //any type used for now because of expected changes (comment on the line 42)

  guestUserId: string;
  guestCartId: string;
  guestCartLoading: boolean = true;

  constructor(
    private marketingFacade: MarketingFacade,
    private router: Router,
    private route: ActivatedRoute,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  /**
   * Initializes the guest cart data.
   */
  ngOnInit(): void {
    this.guestUserId = this.route.snapshot.paramMap.get('guestUserId');
    this.guestCartId = this.route.snapshot.paramMap.get('guestCartId');

    //BE call will be moved to REDUX (own state, reducer, actions, effects) in the task #123834
    this.marketingFacade.getGuestCartById(this.guestUserId, this.guestCartId).pipe(
      take(1),
    ).subscribe({
      next: cart => {
        if (cart) {
          this.guestCart = cart.data;
          this.guestCartItems = PayloadUtils.getItemsWithDetailsFromInclude(
            cart.included,
            false,
            ['concrete-products', 'concrete-product-image-sets', 'guest-cart-items'],
            'guest-cart-items',
          );
          this.guestCartLoading = false;
        }
      },
      error: () => {
        this.configurationFacade.setAlert({
          type: 'error',
          message: 'error.guest-cart-not-found',
        });
      },
    });
  }

  /**
   * Navigates to the guest delivery details page.
   */
  proceedToNextStep(): void {
    this.router.navigate([`/delivery-details/${this.guestUserId}/${this.guestCartId}`]);
  }
}
