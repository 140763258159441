import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { authFeatureKey, State as authState } from '../../auth/reducers';
import { authStatusFeatureKey } from '../../auth/reducers/auth.reducers';
import { environment } from '../../../environments/environment';
import { AppUtils } from '../../utils/app.utils';
import { EFeatureToggles, EStoreFeatures, EStoreTypes } from '../../configurations/common';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CustomerFacade } from '../../facades/customer.facade';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean;
  checkUserLoginSubscription: Subscription;
  homePageBannerKey = environment.homePageBannerKey;
  featuredProductsKey = environment.featuredProductsKey;
  currentStore: string;
  homeInstallBase$: Observable<boolean> = this.configurationFacade.isFeatureEnabled(EFeatureToggles.HOME_INSTALL_BASE);
  isShopByCategoryActive: boolean = false;
  isAnyTileAvailable$: Observable<boolean> = new Observable<boolean>();
  isUsStore: boolean = false;
  isBusinessPartnerRole$: Observable<boolean> = new Observable<boolean>();

  constructor(
    private authStore: Store<authState>,
    private analyticsService: AnalyticsService,
    private configurationFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade) {
  }

  ngOnInit(): void {
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isBusinessPartnerRole$ = this.customerFacade.isBusinessPartner();
    this.currentStore = AppUtils.getCurrentStore().storeId;
    this.isShopByCategoryActive = this.configurationFacade.isFeatureAvailable(EStoreFeatures.SHOP_BY_CATEGORY);
    this.isAnyTileAvailable$ = this.configurationFacade.selectShopCategoryIsAnyTileActive();
    this.checkUserLoginSubscription = this.checkUserLogin().subscribe(data => {
      this.isLoggedIn = data;
    });
    this.adlyticTrackPage();
  }

  ngOnDestroy(): void {
    this.checkUserLoginSubscription.unsubscribe();
  }

  checkUserLogin(): Observable<boolean> {
    return this.authStore.select(s => s[authFeatureKey][authStatusFeatureKey].isAuthenticated);
  }

  private adlyticTrackPage(): void {
    this.analyticsService.setProducts(null);
    this.analyticsService.setCartId(null);
    this.analyticsService.trackPageReady('Home', PageTypes.HOME_PAGE);
  }
}
