import { Injectable, Injector } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { debounceTime, map, take } from 'rxjs/operators';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { State } from '../reducers';
import { CheckoutActions, ShopCartActions, SparePartActions } from '../actions';
import * as SparePartSelectors from '../reducers/spare-part.reducer';
import * as ShopCartSelectors from '../reducers/shop-cart.reducer';
import { ProductsService } from '../services';
import {
  IAddToCartItem,
  ICart,
  ICartBaseInformation,
  ICartItemListError,
  ICartItemListsRequest,
  ICartPayload,
  ICartRule,
  ICartUpdateRequest,
  ICartUpdateRequestAttributes,
} from '../models/cart.models';
import { ICPQStartConfigRequest } from '../models/cpq.models';
import { CatalogFacade } from './catalog.facade';
import { ISapMessage } from '../models/sap.model';
import {
  IMaterialMasterNumbersPerItem, IMaterialMasterNumbersToggles,
  IPriceDisputingPerItem,
  ISystemDetails,
} from '../models/common.models';
import { EGlueResource } from '../configurations/common';
import { IShsEquipmentDataAttributes } from '../models/installedbase.models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MarketingFacade {
  currentCartId$ = this.store.select(ShopCartSelectors.selectCurrentCartId);

  private pShoppingCartService: ShoppingCartService;
  private pProductService: ProductsService;

  public getShoppingCartService(): ShoppingCartService {
    if (!this.pShoppingCartService) {
      this.pShoppingCartService = this.injector.get(ShoppingCartService);
    }
    return this.pShoppingCartService;
  }

  public get productService(): ProductsService {
    if (!this.pProductService) {
      this.pProductService = this.injector.get(ProductsService);
    }
    return this.pProductService;
  }

  constructor(
    private injector: Injector,
    private store: Store<State>,
    private catalogFacade: CatalogFacade,
    private translateService: TranslateService,
  ) {
  }

  postCart(attributes: ICartUpdateRequestAttributes = {}): Observable<ICartPayload> {
    return this.getShoppingCartService().postCart(attributes);
  }

  /**
   * Create new cart for the user.
   *
   * Can also set attributes to the cart using the optional {@param attributes}.
   *
   * As the cart id is not known until cart creation, the recommended method for checking
   * if the cart has been created and is now the current default is to subscribe to the
   * current cart id via {@see selectCartId()} and check until the value has changed relative
   * to the previous cart ID value (or null if no current cart ID was set previously).
   *
   * @param {ICartUpdateRequestAttributes} attributes
   */
  createEmptyCart(attributes?: ICartUpdateRequestAttributes): void {
    this.resetCachedCheckoutData();
    this.store.dispatch(ShopCartActions.createEmptyCart({attributes}));
  }

  addItemToNewCart(requestBody: any, product: any, redirectUrl: string): void {
    this.store.dispatch(ShopCartActions.postNewCartWithItem({requestBody, product, redirectUrl}));
  }

  addItemsFromCsvToCart(cartId: string, items: IAddToCartItem[]): void {
    const requestBody: ICartItemListsRequest = {
      data: {
        type: EGlueResource.ITEM_LISTS,
        attributes: {
          items,
        },
      },
    };
    this.store.dispatch(ShopCartActions.addToCartFromCsvFile({cartId, requestBody}));
  }

  isAddingItemsViaCsvInProgress(): Observable<boolean> {
    return this.store.select(ShopCartSelectors.selectAddToCartViaCsvProgress);
  }

  selectAddToCartViaCsvErrors(): Observable<Array<ICartItemListError>> {
    return this.store.select(ShopCartSelectors.selectAddToCartViaCsvErrors);
  }

  reorderItem(item: any): void {
    this.store.dispatch(SparePartActions.addItem({item}));
  }

  reorderAllItems(items: any[]): void {
    this.store.dispatch(SparePartActions.addAllItems({items}));
  }

  updateReorderWithSystemDetails(updatedSystemDetails: ISystemDetails): void {
    this.store.dispatch(SparePartActions.updateReorderWithSystemDetails({updatedSystemDetails}));
  }

  updateReorderWithSystemDetailsForFunctionalLocation(updatedSystemDetails: IShsEquipmentDataAttributes): void {
    this.store.dispatch(SparePartActions.updateReorderWithSystemDetailsForFunctionalLocation({updatedSystemDetails}));
  }

  getReorderItems(): Observable<any> {
    return this.store.select(SparePartSelectors.getReorderItems);
  }

  isReorderPending(): Observable<boolean> {
    return this.store.select(SparePartSelectors.isReorderPending);
  }

  isReorderConfirmed(): Observable<boolean> {
    return this.store.select(SparePartSelectors.isReorderConfirmed);
  }

  confirmReorder(): void {
    this.store.dispatch(SparePartActions.confirmReorder());
  }

  completeReorder(): void {
    this.store.dispatch(SparePartActions.completeReorder());
  }

  cancelReorder(): void {
    this.store.dispatch(SparePartActions.cancelReorder());
  }

  getCarts(): Observable<any> {
    return this.getShoppingCartService().getCarts();
  }

  getCartById(cartId: string): Observable<ICartPayload> {
    return this.getShoppingCartService().getCartById(cartId);
  }

  addItemToCart(cartId: string, itemData: any): Observable<ICartPayload> {
    return this.getShoppingCartService().addItemToCart(cartId, itemData);
  }

  deleteItemsFromCartAndUpdateSystemDetails(cartId: string, systemDetails: ISystemDetails): void {
    this.store.dispatch(ShopCartActions.deleteItemsFromCart({cartId: cartId, systemDetails: systemDetails}));
  }

  deleteItemsFromCart(cartId: string): Observable<ICartPayload> {
    return this.getShoppingCartService().deleteItemsFromCart(cartId);
  }

  addItemsToCartFromCsvFile(cartId: string, requestBody: ICartItemListsRequest): Observable<ICartPayload> {
    return this.getShoppingCartService().postProductsFromCsvToCart(cartId, requestBody);
  }

  addItemToExistingCart(cartId: string, requestBody: any, product: any, redirectUrl: string): void {
    this.store.dispatch(ShopCartActions.checkIsCartDefault({cartId, requestBody, product, redirectUrl}));
  }

  getCartItems(cartId: string): Observable<ICartPayload> {
    return this.getShoppingCartService().getCartItems(cartId);
  }

  getCartShipments(cartId: string): Observable<ICartPayload> {
    return this.getShoppingCartService().getCartShippingMethods(cartId);
  }

  loadCurrentCart(): void {
    this.store.dispatch(ShopCartActions.loadCurrentCartAndCartItems());
  }

  selectLastAddedItemName(): Observable<any> {
    return this.store.select(ShopCartSelectors.lastAddedItemName);
  }

  shopCartPageDataSelector(): Observable<any> {
    return this.store.select(ShopCartSelectors.getShopCartPageData);
  }

  getCurrentCartItems(): Observable<any> {
    return this.store.select(ShopCartSelectors.selectCartItems);
  }


  deleteItemFromCart(cartId: string, itemId: string): Observable<{}> {
    return this.getShoppingCartService().deleteItemFromCart(cartId, itemId);
  }

  sequentialRequestPatchCartItemGetCartItems(cartId: string, itemId: string, itemData: any): Observable<ICartPayload> {
    return this.getShoppingCartService().sequentialRequestPatchCartItemGetCartItems(cartId, itemId, itemData);
  }

  selectCart(): Observable<ICart> {
    return this.store.select(ShopCartSelectors.selectCurrentCart);
  }

  selectCartById(cartId: string): Observable<ICart | undefined> {
    return this.store.select(ShopCartSelectors.getCarts).pipe(
      take(1),
      map(carts => carts.find((cart) => cart.id === cartId)),
    );
  }

  /**
   * Triggers actions to switch the current/default cart to the one identified by {@param newDefaultCartId}.
   *
   * Optionally also allows updating some additional fields of the cart being switched to using the
   * optional {@param attributes}.
   *
   * To check if the new cart has been set as default, subscribe to the current cart or cart ID value
   * using {@see selectCartId}, {@see getCurrentCartItems} or {@see selectCart}.
   *
   * @param {string} newDefaultCartId
   * @param {ICartUpdateRequestAttributes|undefined} attributes
   */
  switchDefaultCart(newDefaultCartId: string, attributes?: ICartUpdateRequestAttributes) {
    this.resetCachedCheckoutData();
    this.store.dispatch(ShopCartActions.switchDefaultCart({cartId: newDefaultCartId, attributes}));
  }

  resetCachedCheckoutData(): void {
    this.store.dispatch(CheckoutActions.resetDeliveryDetailsFormData());
    this.store.dispatch(CheckoutActions.resetDeliveryDetailsApproverFormData());
    this.store.dispatch(CheckoutActions.resetTaxNumberFormData());
  }

  selectCarts(): Observable<Array<ICart>> {
    return this.store.select(ShopCartSelectors.getCarts);
  }

  selectCartsBaseInfo(): Observable<ICartBaseInformation[]> {
    return this.store.select(ShopCartSelectors.selectCartsBaseInfo);
  }

  selectCartsItems(): Observable<any> {
    return this.store.select(ShopCartSelectors.getCartsItems);
  }

  selectAllCartsItems(): Observable<any> {
    return this.store.select(ShopCartSelectors.getAllCartsItems);
  }

  selectMiniCartState(): Observable<any> {
    return this.store.select(ShopCartSelectors.selectMiniCartState);
  }

  toggleMiniCart(isOpen: boolean): void {
    return this.store.dispatch(ShopCartActions.toggleMiniCart({isOpen}));
  }

  isAddItemOperationInProgress(): Observable<any> {
    return this.store.select(ShopCartSelectors.isAddItemToCartOperationInProgress);
  }

  selectItemsInQueueToCart(): Observable<any> {
    return this.store.select(ShopCartSelectors.itemsInQueueToCart);
  }

  postVoucher(cartId: string, code: any): Observable<ICartPayload> {
    return this.getShoppingCartService().postVoucher(cartId, code);
  }

  removeVoucher(cartId: string, voucherId: string): Observable<ICartPayload> {
    return this.getShoppingCartService().removeVoucher(cartId, voucherId);
  }

  getProductBySku(sku: string): Observable<any> {
    return this.productService.getProductBySku(sku);
  }

  selectCurrency(): Observable<string> {
    return this.store.select(ShopCartSelectors.selectCurrentCartCurrency);
  }

  selectIsCartEmpty(): Observable<boolean> {
    return this.store.select(ShopCartSelectors.isCartEmpty);
  }

  selectCartId(): Observable<string | null> {
    return this.store.select(ShopCartSelectors.selectCurrentCartId);
  }

  selectCartItemsWithDetails(): Observable<any[]> {
    return this.store.select(ShopCartSelectors.cartItemsWithDetails);
  }

  selectCartItemsTotalWeight$(): Observable<number> {
    return this.store.select(ShopCartSelectors.selectCartItemsTotalWeight);
  }

  selectHasCartContract(): Observable<boolean> {
    return this.selectCartItemsWithDetails().pipe(map(items => {
      return items ? items.some(item => !!item.attributes.cpqConfigId) : false;
    }));
  }

  addProductToCart(product, isConcrete = false, quantity = 1, redirectUrl = ''): void {
    this.addItemToQueue(product.sku);
    this.currentCartId$.pipe(take(1), debounceTime(0)).subscribe((cartId) => {
      if (isConcrete) {
        const requestBody = this.getAddToCartRequestBody(
          product,
          product.sku,
          quantity,
        );
        this.addToCartDependingOnWhetherItExists(cartId, requestBody, product, redirectUrl);
      } else {
        this.getProductBySku(product.sku).pipe(take(1)).subscribe({
          next: products => {
            const requestBody = this.getAddToCartRequestBody(
              product,
              products.data.attributes.attributeMap.product_concrete_ids[0],
              quantity,
            );
            this.addToCartDependingOnWhetherItExists(cartId, requestBody, product, redirectUrl);
          },
          error: error => {
            this.removeItemFromQueue(product.sku, error);
          },
        });
      }
    });
  }

  getAddToCartRequestBody(product: any, sku: string, quantity: number): any {
    const body = {
      data: {
        type: 'items',
        attributes: {
          ...product,
          quantity,
          sku,
        },
      },
    };
    if (product.type === 'contract') {
      body.data.attributes.systemDetailsPerItem[0].itemId = sku;
    }
    return body;
  }

  addToCartDependingOnWhetherItExists(cartId, requestBody, product, redirectUrl): void {
    if (cartId !== null) {
      this.addItemToExistingCart(cartId, requestBody, product, redirectUrl);
    } else {
      this.addItemToNewCart(requestBody, product, redirectUrl);
    }
  }

  startContractConfiguration(sku: string, siemensEquipmentId: string, materialNumber: string): void {
    this.addItemToQueue(sku);
    const url = `/service-configuration?selectedService=${sku}&fl-number=${siemensEquipmentId}&rel-product-sysivk=${materialNumber}`;
    this.catalogFacade.getShsEquipmentInstalledBaseSystemByFunctionalLocation(materialNumber, siemensEquipmentId)
      .pipe(take(1)).subscribe(
      selectedSystems => {
        const selectedSystem: IShsEquipmentDataAttributes = selectedSystems.data.find(
          installBase =>
            installBase.attributes.materialNumber === materialNumber
            && installBase.attributes.siemensEquipmentId === siemensEquipmentId)?.attributes;

        if (selectedSystem &&
          selectedSystem.siemensEquipmentId === siemensEquipmentId &&
          selectedSystem.materialNumber === materialNumber) {
          const systemDetails = {
            serialNumber: selectedSystem.serialNumber,
            company: selectedSystem.company,
            materialNumber,
            siemensEquipmentId,
            companyBusinessUnit: selectedSystem.companyBusinessUnit,
            companyBusinessUnitNumber: selectedSystem.companyBusinessUnitNumber,
            nameEnUs: selectedSystem.materialName,
            namePtBr: selectedSystem.materialName,
          };
          const product: ICPQStartConfigRequest = {
            sku,
            quantity: 1,
            name: '',
            type: 'contract',
            productConfigurationInstance: {
              isComplete: true,
              displayData: `{"Functional Location Number": "${siemensEquipmentId}"}`,
              configuration: `{"functional_location_number": "${siemensEquipmentId}"}`,
              configuratorKey: 'CPQ_CONFIGURATOR',
              availableQuantity: '1',
            },
            systemDetails,
            systemDetailsPerItem: [{
              itemId: sku,
              systemDetails,
            }],
          } as ICPQStartConfigRequest;
          this.addProductToCart(product, false, 1, url);
        }
      },
    );
  }

  addItemToQueue(sku: string): void {
    this.store.dispatch(ShopCartActions.addItemToQueue({sku}));
  }

  removeItemFromQueue(sku: string, error: any): void {
    this.store.dispatch(ShopCartActions.removeItemFromQueue({sku, error}));
  }

  selectRfqActive(): Observable<boolean> {
    return this.store.select(ShopCartSelectors.selectRfqActive);
  }

  selectCartRules(): Observable<Array<ICartRule>> {
    return this.store.select(ShopCartSelectors.selectCartRules);
  }

  updateCartData(cartId: string, cartData: ICartUpdateRequest, eTag: string): Observable<ICartPayload> {
    return this.getShoppingCartService().updateCart(cartId, cartData, eTag);
  }

  updateCartById(cartId: string, attributes: ICartUpdateRequestAttributes = {}): void {
    this.store.dispatch(ShopCartActions.startCartOperation());
    this.store.select(ShopCartSelectors.selectDefaultCartNameAndETag)
      .pipe(take(1))
      .subscribe(cartData => {
        if (cartData) {
          if (Object.keys(attributes).length === 0) {
            // in case of empty attributes object, just set the current cart name
            // use case: changing the default cart to the one identified by cartId
            attributes = {
              name: cartData.name,
            };
          }

          const payload: ICartUpdateRequest = {
            data: {
              type: EGlueResource.CARTS,
              attributes,
            },
          };
          this.store.dispatch(ShopCartActions.updateCartData({cartId, cartData: payload, eTag: cartData.eTag}));
        }
      });
  }

  deleteCartById(cartId: string): Observable<any> {
    this.resetCachedCheckoutData();
    return this.getShoppingCartService().deleteCartById(cartId);
  }

  deleteCart(cartId: string, redirectUrl: string = ''): void {
    this.store.dispatch(ShopCartActions.deleteCart({cartId, redirectUrl}));
  }

  deleteCpqCart(cartId: string, redirectUrl: string = ''): void {
    this.store.dispatch(ShopCartActions.deleteCpqCart({cartId, redirectUrl}));
  }

  refreshCart(cartId: string): void {
    this.store.dispatch(ShopCartActions.loadCartItems({cartId}));
  }

  selectCartOperationInProgress(): Observable<boolean> {
    return this.store.select(ShopCartSelectors.selectCartOperationInProgress);
  }

  selectLoadingCartDataInProgress(): Observable<any> {
    return this.store.select(ShopCartSelectors.selectLoadingCartDataInProgress);
  }

  quoteSummaryPageDeleteQuote(cartId: string, redirectUrl: string): void {
    this.store.dispatch(ShopCartActions.quoteSummaryPageDeleteQuote({cartId, redirectUrl}));
  }

  selectSapMessages(): Observable<Array<ISapMessage>> {
    return this.store.select(ShopCartSelectors.selectSapMessages);
  }

  selectPrefilledShipToAddressId(): Observable<string> {
    return this.store.select(ShopCartSelectors.selectPrefilledShipToAddressId);
  }

  selectPriceDisputing(): Observable<IPriceDisputingPerItem[]> {
    return this.store.select(ShopCartSelectors.selectPriceDisputingPerItem);
  }

  selectIsPriceDisputingSetInCart(): Observable<boolean> {
    return this.store.select(ShopCartSelectors.selectIsPriceDisputingSetInCart);
  }

  selectPrefilledBillToAddressId(): Observable<string> {
    return this.store.select(ShopCartSelectors.selectPrefilledBillToAddressId);
  }

  setMaterialMasterNumbers(materialMasterNumbers: IMaterialMasterNumbersPerItem, cartId: string): void {
    this.store.dispatch(ShopCartActions.addOrUpdateMaterialMasterNumbersPerItemAction({
      materialMasterNumbers: materialMasterNumbers,
      cartId: cartId,
    }));
  }

  selectMaterialMasterNumbersByItemId(cartId: string, itemId: string): Observable<IMaterialMasterNumbersPerItem> {
    return this.store.select(ShopCartSelectors.selectMaterialMasterNumbersByItemId(cartId, itemId));
  }

  selectAllMaterialMasterNumbersInCart(cartId: string): Observable<IMaterialMasterNumbersPerItem[]> {
    return this.store.select(ShopCartSelectors.selectAllMaterialMasterNumbersInCart(cartId));
  }

  /**
   * @Description Get toggles for material master numbers inputs showing
   * @returns {Observable<IMaterialMasterNumbersToggles>}
   */
  getMaterialMasterNumbersToggles(): Observable<IMaterialMasterNumbersToggles> {
    return combineLatest([
      this.translateService.get('feature_toggle.p40_show_PEAK_number'),
      this.translateService.get('feature_toggle.p40_Notification_number'),
      this.translateService.get('feature_toggle.p40_Software_version'),
      this.translateService.get('feature_toggle.p40_Serial_number'),
    ]).pipe(
      map(([peakNumber, notificationNumber, softwareVersion, serialNumber]) => {
        return {
          peakNumber: peakNumber,
          notificationNumber: notificationNumber,
          softwareVersion: softwareVersion,
          serialNumber: serialNumber,
        };
      }),
    );
  }
}
