import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { CheckoutActions } from '../actions';
import { IAddress, IPointOfContact, ITotals } from '../models/common.models';
import { EUserRoles } from '../configurations/common';
import { IRfqBaseInfo } from '../models/rfq.models';
import { IApproverData } from '../models/customer.models';
import { ICartVoucher } from '../models/cart.models';
import { IConsentCheckbox } from '../models/checkout.models';

export interface CheckoutState {
  selectedDeliveryAddress: any;
  pointOfContacts: any;
  selectedApprover: any;
  approverComment: string;
  postCheckoutData: any;
  responseFromPostCheckout: any;
  cartId: string;
  Approvers: [];
  ApproversLoaded: boolean;
  NewCustomerAddressData: IAddress;
  userId: string;
  ApproversLoadedError: Error;
  loggedUserRoles: EUserRoles[];
  rfqHistoryInfo: IRfqBaseInfo[] | undefined;
  sapPoNumber: string;
  orderEmailCopy: string;
  //new state variables for harmonized checkout
  loadingDeliveryDetailsData: boolean;
  availableShipToAddresses: IAddress[];
  availableBillToAddresses: IAddress[];
  preselectedShipToAddress: IAddress;
  preselectedBillToAddress: IAddress;
  deliveryDetailsFormData: IPointOfContact;
  deliveryDetailsApproverFormData: IApproverData;
  deliveryDetailsTotalPrices: ITotals;
  deliveryDetailsTotalPricesLoading: boolean;
  cartVouchers: ICartVoucher[];
  taxNumber: string;
  termsAndConditions: IConsentCheckbox[];
}

export const initializeState = (): CheckoutState => {
  return {
    responseFromPostCheckout: undefined,
    cartId: '',
    postCheckoutData: undefined,
    approverComment: undefined,
    pointOfContacts: undefined,
    selectedApprover: undefined,
    selectedDeliveryAddress: undefined,
    Approvers: undefined,
    ApproversLoaded: false,
    userId: undefined,
    NewCustomerAddressData: undefined,
    ApproversLoadedError: undefined,
    loggedUserRoles: [EUserRoles.Buyer],
    rfqHistoryInfo: undefined,
    sapPoNumber: '',
    orderEmailCopy: undefined,
    loadingDeliveryDetailsData: true,
    availableShipToAddresses: undefined,
    preselectedShipToAddress: undefined,
    availableBillToAddresses: undefined,
    preselectedBillToAddress: undefined,
    deliveryDetailsFormData: undefined,
    deliveryDetailsApproverFormData: undefined,
    deliveryDetailsTotalPrices: undefined,
    deliveryDetailsTotalPricesLoading: true,
    cartVouchers: [],
    taxNumber: null,
    termsAndConditions: [],
  };
};

const getCheckoutFeatureState = createFeatureSelector<CheckoutState>('checkout');

export const initialState = initializeState();

export const selectRfqHistoryData = createSelector(
  getCheckoutFeatureState,
  state => state.rfqHistoryInfo,
);

export const getSapPoNumber = createSelector(
  getCheckoutFeatureState,
  state => state.sapPoNumber,
);

export const getOrderCopyEmail = createSelector(
  getCheckoutFeatureState,
  state => state.orderEmailCopy,
);

export const selectLoadingDeliveryDetailsData = createSelector(
  getCheckoutFeatureState,
  state => state.loadingDeliveryDetailsData,
);

export const selectAvailableShipToAddresses = createSelector(
  getCheckoutFeatureState,
  state => state.availableShipToAddresses,
);

export const selectPreselectedShipToAddress = createSelector(
  getCheckoutFeatureState,
  state => state.preselectedShipToAddress,
);

export const selectIsPreselectedShipToAddressCustom = createSelector(
  getCheckoutFeatureState,
  state => state.preselectedShipToAddress?.isCustom,
);

export const selectAvailableBillToAddresses = createSelector(
  getCheckoutFeatureState,
  state => state.availableBillToAddresses,
);

export const selectPreselectedBillToAddress = createSelector(
  getCheckoutFeatureState,
  state => state.preselectedBillToAddress,
);

export const selectSelectedShipmentMethod = createSelector(
  getCheckoutFeatureState,
  state => state.deliveryDetailsFormData?.shipmentMethod,
);

export const selectDeliveryDetailsFormData = createSelector(
  getCheckoutFeatureState,
  state => state.deliveryDetailsFormData,
);

export const selectDeliveryDetailsApproverFormData = createSelector(
  getCheckoutFeatureState,
  state => state.deliveryDetailsApproverFormData,
);

export const selectTaxNumberFormData = createSelector(
  getCheckoutFeatureState,
  state => state.taxNumber,
);

export const selectTermsAndConsentsData = createSelector(
  getCheckoutFeatureState,
  state => state.termsAndConditions,
);

export const selectTotalPrices = createSelector(
  getCheckoutFeatureState,
  state => state.deliveryDetailsTotalPrices,
);

export const selectTotalPricesLoading = createSelector(
  getCheckoutFeatureState,
  state => state.deliveryDetailsTotalPricesLoading,
);

export const selectCartVouchers = createSelector(
  getCheckoutFeatureState,
  state => state.cartVouchers,
);

const reducer = createReducer(
  initialState,

  on(CheckoutActions.UpdateCheckoutData, (state: CheckoutState, {
    deliveryAddress,
    pointOfContact,
    approver,
    approverComment,
  }) => {
    return {
      ...state,
      selectedDeliveryAddress: deliveryAddress,
      selectedApprover: approver,
      approverComment,
      pointOfContacts: pointOfContact,
    };
  }),
  on(CheckoutActions.UpdatePOCData, (state: CheckoutState, {payload}) => {
    return {...state, pointOfContacts: payload};
  }),
  on(CheckoutActions.UpdateSelectedDeliveryAddress, (state: CheckoutState, {payload}) => {
    return {...state, selectedDeliveryAddress: payload};
  }),
  on(CheckoutActions.PostCheckoutData, (state, action): CheckoutState => {
    return {
      ...state,
      postCheckoutData: action.postCheckoutData,
      cartId: action.cartId,
    };
  }),
  on(CheckoutActions.SuccessPostCheckoutDataAction, (state, action): CheckoutState => {
    return {
      ...state,
      responseFromPostCheckout: action.responseFromPostCheckout,
    };
  }),
  on(CheckoutActions.BeginApproversListAction, (state: CheckoutState) => {
    return {...state};
  }),
  on(CheckoutActions.SuccessGetApproversListAction, (state: CheckoutState, {payload}) => {
    return {...state, Approvers: payload, ApproversLoaded: true};
  }),
  on(CheckoutActions.ErrorGetApproversListAction, (state: CheckoutState, error: Error) => {
    return {...state, ApproversLoadedError: error};
  }),
  on(CheckoutActions.SuccessGetQuoteRfqHistoryDataAction, (state: CheckoutState, {rfqBaseInfo}) => {
    return {
      ...state,
      rfqHistoryInfo: rfqBaseInfo?.data as IRfqBaseInfo[],
    };
  }),
  on(CheckoutActions.setSapPoNumber, (state, {sapPoNumber}) => {
    return {
      ...state,
      sapPoNumber,
    };
  }),
  on(CheckoutActions.setOrderEmailCopy, (state, {orderEmailCopy}) => {
    return {
      ...state,
      orderEmailCopy,
    };
  }),
  on(CheckoutActions.clearPurchaseOrderNumber, (state) => {
    return {
      ...state,
      sapPoNumber: '',
    };
  }),
  on(CheckoutActions.clearOrderEmailCopy, (state) => {
    return {
      ...state,
      orderEmailCopy: undefined,
    };
  }),
  on(CheckoutActions.setLoadingDeliveryDetailsData, (state, {loadingDeliveryDetailsData}) => {
    return {
      ...state,
      loadingDeliveryDetailsData: loadingDeliveryDetailsData,
    };
  }),
  on(CheckoutActions.setAvailableShipToAddresses, (state, {availableShipToAddresses}) => {
    return {
      ...state,
      availableShipToAddresses: availableShipToAddresses,
    };
  }),
  on(CheckoutActions.addShipToAddressToAvailableShipToAddresses, (state, {shipToAddress}) => {
    return {
      ...state,
      availableShipToAddresses: [...state.availableShipToAddresses, shipToAddress],
    };
  }),
  on(CheckoutActions.setPreselectedShipToAddress, (state, {preselectedShipToAddress}) => {
    return {
      ...state,
      preselectedShipToAddress: preselectedShipToAddress,
    };
  }),
  on(CheckoutActions.setAvailableBillToAddresses, (state, {availableBillToAddresses}) => {
    return {
      ...state,
      availableBillToAddresses: availableBillToAddresses,
    };
  }),
  on(CheckoutActions.setPreselectedBillToAddress, (state, {preselectedBillToAddress}) => {
    return {
      ...state,
      preselectedBillToAddress: preselectedBillToAddress,
    };
  }),
  on(CheckoutActions.setDeliveryDetailsFormData, (state, {deliveryDetailsFormData}) => {
    return {
      ...state,
      deliveryDetailsFormData: {
        ...state.deliveryDetailsFormData,
        ...deliveryDetailsFormData,
      },
    };
  }),
  on(CheckoutActions.setDeliveryDetailsApproverFormData, (state, {deliveryDetailsApproverFormData}) => {
    return {
      ...state,
      deliveryDetailsApproverFormData: deliveryDetailsApproverFormData,
    };
  }),
  on(CheckoutActions.setTaxNumberFormData, (state, {taxNumber}) => {
    return {
      ...state,
      taxNumber: taxNumber,
    };
  }),
  on(CheckoutActions.setTermsAndConsentsData, (state, {terms}) => {
    return {
      ...state,
      termsAndConditions: terms,
    };
  }),
  on(CheckoutActions.resetDeliveryDetailsFormData, (state) => {
    return {
      ...state,
      deliveryDetailsFormData: undefined,
    };
  }),
  on(CheckoutActions.resetTaxNumberFormData, (state) => {
    return {
      ...state,
      taxNumber: null,
    };
  }),
  on(CheckoutActions.resetDeliveryDetailsApproverFormData, (state) => {
    return {
      ...state,
      deliveryDetailsApproverFormData: undefined,
    };
  }),
  on(CheckoutActions.setTotalPrices, (state, {totalPrices}) => {
    return {
      ...state,
      deliveryDetailsTotalPrices: totalPrices,
    };
  }),
  on(CheckoutActions.setTotalPricesLoading, (state, {totalPricesLoading}) => {
    return {
      ...state,
      deliveryDetailsTotalPricesLoading: totalPricesLoading,
    };
  }),
  on(CheckoutActions.setCartVouchers, (state, {cartVouchers}) => {
    return {
      ...state,
      cartVouchers,
    };
  }),
);


export function CheckoutReducers(state: CheckoutState | undefined, action: Action): any {
  return reducer(state, action);
}
