import { Component, Input } from '@angular/core';
import { ImageUtils } from '../../../../../utils/image.utils';
import { IPointOfContact, IPriceDisputingPerItem } from '../../../../../models/common.models';
import { OrderReviewService } from '../../../shared/services/order-review.service';
import { IShipmentMethod } from '../../../../../models/checkout.models';
import { ICartItemWithDetail } from '../../../../../models/cart.models';
import { Observable } from 'rxjs';
import { EDeliveryInputNames, EFeatureToggles } from '../../../../../configurations/common';
import { ConfigurationFacade } from '../../../../../facades/configuration.facade';
import { CustomerFacade } from '../../../../../facades/customer.facade';
import { AppUtils } from '../../../../../utils/app.utils';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrl: './order-summary.component.scss',
})
export class OrderSummaryComponent {

  @Input() pointOfContact: IPointOfContact;
  @Input() selectedShipmentMethod: IShipmentMethod;
  @Input() cartItemsWithDetails: ICartItemWithDetail[];
  @Input() currency: string;
  @Input() cartId: string;
  @Input() priceDisputingPerItems: IPriceDisputingPerItem[];
  @Input() isCartStatusWaiting: boolean = false;

  protected isProductDiscontinuedStatusEnabled$: Observable<boolean> =
              this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
  protected isPriceDisputingActive$: Observable<boolean> = this.customerFacade.isPriceDisputingActive();

  protected readonly ImageUtils = ImageUtils;
  protected readonly isSapStore: boolean = AppUtils.isSapStore();

  constructor(
    protected orderReviewService: OrderReviewService,
    private configurationFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade,
  ) {
  }

  /**
   * Determine if price disputing is set for given item.
   * @param {string} itemId
   * @return {boolean}
   */
  isPriceDisputingSetForItem(itemId: string): boolean {
    return this.priceDisputingPerItems?.find(itemPriceDisputing => {
      return itemPriceDisputing.itemId === itemId;
    })?.priceDisputing?.isSet;
  }

  /**
   * Enum for delivery input names.
   * @protected
   */
  protected readonly EDeliveryInputNames = EDeliveryInputNames;
}
