import { Component, OnInit } from '@angular/core';
import { EFeatureToggles, EStoreTypes } from '../../configurations/common';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { Observable } from 'rxjs';
import { AppUtils } from '../../utils/app.utils';

@Component({
  selector: 'app-page-shop-cart',
  templateUrl: './page-shop-cart.component.html',
  styleUrls: [],
})
export class PageShopCartComponent implements OnInit {
  protected readonly AppUtils = AppUtils;
  protected readonly EStoreTypes = EStoreTypes;
  isExportCartActive$: Observable<boolean> = new Observable<boolean>;
  isExternalQuoteCheckoutEnabled$: Observable<boolean> = new Observable<boolean>;

  constructor(private configurationFacade: ConfigurationFacade) {}

  ngOnInit(): void {
    this.isExportCartActive$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.CART_EXPORT_AS_PDF);
    this.isExternalQuoteCheckoutEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.EXTERNAL_QUOTE_CHECKOUT);
  }
}
