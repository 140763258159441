<div
    class="datepicker textfield width-100" [attr.data-test]="'desc-datepicker'"
    [ngClass]="{
    'is-active':isActive || modelValue,
    'is-invalid': isInvalid,
    'read-only': readOnly,
    'has-value': modelValue !== '',
    'disabled': disabled,
    'icon-calendar': !isActive && !showCalendarIcon && !modelValue
    }">
  <input
      class="textfield__input date-time-picker__input flatpickr-input form-control"
      type="text"
      mwlFlatpickr
      [(ngModel)]="modelValue"
      (flatpickrOpen)="setOpenCloseDatepickr()"
      (flatpickrClose)="setOpenCloseDatepickr()"
      (flatpickrValueUpdate)="setShowCalendarIcon()"
      [altInput]="true"
      [static]="true"
      [altFormat]=dateFormat
      [defaultValue]="modelValue"
      [monthSelectorType]=monthSelectorType
      [minDate]=minDateFormat
      [maxDate]=maxDateFormat
      [convertModelValue]="true"
      [clickOpens]="true"
      (ngModelChange)="update()"
      [mode]=mode>
  <span *ngIf="modelValue" class="reset-button icon-close" (click)="resetDatepicker()">
  </span>
  <label *ngIf="!!label" class="textfield__label" [for]="'textfield'+inputName">
    {{ getLabel() }}
  </label>
  <div *ngIf="form" [formGroup]="form">
    <input formControlName="{{ inputName }}" [value]="modelValue" [name]="inputName" hidden [required]="isRequired">
  </div>
  <div class="disable-overlay" *ngIf="disabled"></div>
</div>
