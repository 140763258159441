import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { CustomerFacade } from '../../../facades/customer.facade';
import { AdvancedFilter } from '../../../models/advanced-filter';
import { catchError, takeUntil, take } from 'rxjs/operators';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { OrdersFacade } from '../../../facades/orders.facade';
import { SearchFieldPipe } from '../../../shared/pipes/search-field.pipe';
import { PurchaseActivityPreviousOrdersTabs, PurchaseActivityTabs } from '../../../configurations/common';
import { IOrderItem, IPurchaseActivityItem } from '../../../models/order.models';
import { AdvancedFilteringPipe } from '../../../shared/pipes/advanced-filtering.pipe';
import { FileType, FileUtils } from '../../../utils/file.utils';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrl: './purchase-history.component.scss',
})
export class PurchaseHistoryComponent implements OnInit, OnDestroy {
  protected readonly PurchaseActivityTabs = PurchaseActivityTabs;

  @Input() orders: IPurchaseActivityItem[] = [];
  @Input() currentTab: string;
  @Input() triggerClearAllFilters$: Subject<void>;
  @Input() currentSubTab: string;

  // Configurations and data initialization
  isMyOrdersFilterAvailable$: Observable<boolean> = this.customerFacade.isApprover();
  currentCartId$: Observable<string> = this.marketingFacade.selectCartId();

  // Filtering
  showOnlyMyOrders: boolean = false;
  quickSearch: string = '';
  appliedAdvancedFilters: AdvancedFilter[] = [];

  // View toggles
  isAdvancedSearchOpened: boolean = false;
  isModalInProgress: boolean = false;
  isLoadingOrders: boolean = false;
  isExcelGenerationInProgress: boolean = false;
  isAddItemToCartModalVisible: boolean = false;

  // Other variables
  flNumberOfReorder: string = '';
  orderId: string = '';
  itemsToAdd: {data: IOrderItem, isConcrete: boolean, quantity: number}[] = [];
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private customerFacade: CustomerFacade,
    private ordersFacade: OrdersFacade,
    private filterPipe: AdvancedFilteringPipe,
    private searchPipe: SearchFieldPipe,
    private marketingFacade: MarketingFacade,
  ) {}

  ngOnInit(): void {
    this.triggerClearAllFilters$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.showOnlyMyOrders = false;
      this.quickSearch = '';
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // *** Filtering ***

  /**
   * Toggles the visibility of orders belonging to the current user.
   * When `showOnlyMyOrders` is set to true, it adds a filter to display only the orders associated with the current
   * user's email. When set to false, it removes this filter.
   */
  toggleShowingOnlyMyOrders(): void {
    this.showOnlyMyOrders = !this.showOnlyMyOrders;
    if (this.showOnlyMyOrders) {
      this.customerFacade.selectCustomerData()
        .pipe(
          take(1),
          catchError(() => {
            return of({ customerData: { email: '' } });
          }),
        ).subscribe(customerCheckoutData => {
          const customerEmail = customerCheckoutData.customerData.email;
          const customerFilter: AdvancedFilter = {
            name: 'customer',
            selected: [customerEmail],
            options: [customerEmail],
            attribute: 'customer',
            firstAdditionalAttribute: 'email',
          };
          /* Cannot be used .push() because it does not change reference and reactivity is not triggered. In this case
          * the filtering would not work. */
          this.appliedAdvancedFilters = [...this.appliedAdvancedFilters, customerFilter];
        }
      );
    } else {
      this.appliedAdvancedFilters = this.appliedAdvancedFilters.filter(filter => filter.name !== 'customer');
    }
  }

  closeAdvancedSearch(): void {
    this.isAdvancedSearchOpened = false;
  }

  /**
   * Applies the given advanced filters to the current list of filters.
   * If a customer filter is already applied, it retains that filter.
   * @param {AdvancedFilter[]} advancedFilters - The list of advanced filters to apply.
   */
  applyAdvancedFilters(advancedFilters: AdvancedFilter[]): void {
    const appliedMyOrdersFilter = this.appliedAdvancedFilters
      .find(filter => filter.name === 'customer');
    this.appliedAdvancedFilters = advancedFilters;
    if (appliedMyOrdersFilter) {
      this.appliedAdvancedFilters = [...this.appliedAdvancedFilters, appliedMyOrdersFilter];
    }
  }

  /**
   * @returns {boolean}
   */
  isAppliedFilter(): boolean {
    return this.appliedAdvancedFilters.length > 0 || this.quickSearch != '';
  }

  /**
   * Retrieves the list of orders filtered by the applied advanced filters and quick search.
   * @returns {IPurchaseActivityItem[]}
   */
  getFilteredListOfOrders(): IPurchaseActivityItem[] {
    const currentPageOrders: IPurchaseActivityItem[] =
            this.orders['itemsPerPage'][this.orders['pagination']['currentPage']] ?? [];
    return this.searchPipe.transform(
      this.filterPipe.transform(currentPageOrders, this.appliedAdvancedFilters),
      this.quickSearch,
    );
  }

  /**
   * Retrieves the IDs of the filtered orders.
   * @returns {string[]} An array of IDs of the orders that match the current filters.
   */
  getFilteredOrdersIds(): string[] {
    return this.getFilteredListOfOrders().map(order => order.id);
  }

  /**
   * Checks if list of orders if empty after applying advanced filters or/and quick search filtering.
   * @returns {boolean}
   */
  isFilteredListOfOrdersEmpty(): boolean {
    return this.getFilteredListOfOrders().length === 0;
  }

  // *** Downloading of content ***

  /**
   * Downloads the order list as an Excel file.
   * Checks if the filtered list of orders is empty and prevents the download if it is.
   * Depending on the current tab and sub-tab, it requests the appropriate Excel file from the server
   * and saves it using the FileUtils utility.
   */
  downloadOrderListExcel(): void {
    this.isExcelGenerationInProgress = true;
    //prevent calling facade function when someone try to enable disabled button
    if (this.isFilteredListOfOrdersEmpty()) {
      this.isExcelGenerationInProgress = false;
      return;
    }

    switch (this.currentTab) {
      case PurchaseActivityTabs.PREVIOUS_ORDERS: {
        if (this.currentSubTab === PurchaseActivityPreviousOrdersTabs.ORDERS) {
          this.ordersFacade.postOrdersHistoryExcelFile(this.getFilteredOrdersIds())
            .pipe(
              take(1),
              catchError(() => {
                this.isExcelGenerationInProgress = false;
                return of();
              })
            ).subscribe((file: Blob) => {
              FileUtils.saveAndOpenFile(file, FileType.EXCEL, `OrderHistory`, new Date().toString(), false);
              this.isExcelGenerationInProgress = false;
            });
        } else {
          this.ordersFacade.postHybrisOrdersHistoryExcelFile(this.getFilteredOrdersIds())
            .pipe(
              take(1),
              catchError(() => {
                this.isExcelGenerationInProgress = false;
                return of();
              })
            ).subscribe((file: Blob) => {
              FileUtils.saveAndOpenFile(file, FileType.EXCEL, `Archive_OrderHistory`, new Date().toString(), false);
              this.isExcelGenerationInProgress = false;
            });
        }
        break;
      }
      case PurchaseActivityTabs.PREVIOUS_REQUESTS:
      case PurchaseActivityTabs.PREVIOUS_QUOTES: {
        this.ordersFacade.postQuoteHistoryExcelFile(this.getFilteredOrdersIds())
          .pipe(
            take(1),
            catchError(() => {
              this.isExcelGenerationInProgress = false;
              return of();
            })
          ).subscribe((file: Blob) => {
            FileUtils.saveAndOpenFile(file, FileType.EXCEL, `OrderHistory`, new Date().toString(), false);
            this.isExcelGenerationInProgress = false;
          });
      }
    }
  }
}
