import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ISelectEvent } from '../../../../models/common.models';
import { sparePartHybrisOrderHistoryFilters } from './spare-part-hybris-order-history.filters';
import { AdvancedFilter } from '../../../../models/advanced-filter';
import { FilterByAttributesPipe } from '../../../../shared/pipes/filter-by-attributes.pipe';
import { SearchFieldPipe } from '../../../../shared/pipes/search-field.pipe';
import { IArchiveOrder, IHybrisOrderItem } from '../../../../models/order.models';
import { take } from 'rxjs/operators';
import { FileType, FileUtils } from '../../../../utils/file.utils';
import { OrdersFacade } from '../../../../facades/orders.facade';
import { StringUtils } from '../../../../utils/string.utils';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-spare-part-hybris-order-history',
  templateUrl: './spare-part-hybris-order-history.component.html',
  styleUrls: ['./spare-part-hybris-order-history.component.scss']
})
export class SparePartHybrisOrderHistoryComponent implements OnChanges {
  @Input() hybrisOrders: IArchiveOrder[];
  @Input() hybrisOrdersLoading: boolean = true;

  searchOrders: string;
  advancedSearchDropdownIsOpened: boolean = false;
  advancedFiltersForm: UntypedFormGroup;
  advancedFilters: AdvancedFilter[] = sparePartHybrisOrderHistoryFilters;

  selectBoxOptions: any[] = [
    {
      name: 'soldToAddress',
      options: []
    },
    {
      name: 'shipToAddress',
      options: []
    },
    {
      name: 'flNumber',
      options: []
    },
    {
      name: 'orderStatus',
      options: []
    }
  ];

  datepickerRange: any = {
    'orderDateFrom': '',
    'orderDateTo': ''
  };

  excelGenerationInProgress: boolean = false;

  constructor(
    private ordersFacade: OrdersFacade,
    private formBuilder: UntypedFormBuilder,
    private filterPipe: FilterByAttributesPipe,
    private searchPipe: SearchFieldPipe
  ) {
  }

  ngOnChanges(): void {
    if (!this.hybrisOrdersLoading) {
      this.initializeForm();
      this.initializeAdvancedFilter();
      this.loadAdvancedFilter();
      this.loadSelectBoxOptions();
    }
  }

  initializeForm(): void {
    this.advancedFiltersForm = this.formBuilder.group({
      soldToAddress: '',
      shipToAddress: '',
      orderDateFrom: '',
      orderDateTo: '',
      orderStatus: '',
      flNumber: '',
      materialNumber: '',
      orderNumber: '',
      purchaseOrder: ''
    });
  }

  initializeAdvancedFilter(): void {
    this.advancedFilters.forEach((advancedFilter: AdvancedFilter) => {
      advancedFilter.options = [];
      advancedFilter.selected = [];
    });
  }

  updateForm(): void {
    this.advancedFilters.forEach((filter: AdvancedFilter) => {
      this.advancedFiltersForm.patchValue({
        [filter.name]:
          this.isDateFilter(filter.name)
            ? this.datepickerRange[filter.name]
            : filter.selected[0]
      });
    });
  }

  setFormValue(event: ISelectEvent): void {
    this.advancedFiltersForm.patchValue({
      [event.key]: event.value
    });
  }

  loadAdvancedFilter(): void {
    let filter: string[];
    this.advancedFilters.forEach((advancedFilter: AdvancedFilter) => {
      advancedFilter.options = [...new Set(
        this.hybrisOrders.map((order: IArchiveOrder) => {
          if (advancedFilter.secondAdditionalAttribute) {
            filter = order?.[advancedFilter.attribute]?.[advancedFilter.firstAdditionalAttribute]?.[advancedFilter.secondAdditionalAttribute];
          } else if (advancedFilter.firstAdditionalAttribute) {
            filter = this.parseDataFromObject(order, advancedFilter.attribute, advancedFilter.firstAdditionalAttribute);
          } else {
            filter = order?.[advancedFilter.attribute];
          }

          if (filter && filter[0] !== '' && filter[0] !== 'empty') {
            return filter;
          }
        }).reduce((acc: string[], val: string[]) => acc.concat(val), [])
      )].filter(item => item !== undefined);
    });
  }

  loadSelectBoxOptions(): void {
    this.selectBoxOptions.forEach(selectBox => {
      selectBox.options = this.createSelectBoxOptions(selectBox.name);
    });
  }

  getOptionsForSelectBox(selectBoxName: string): Array<{name: string; value: any}> {
    return this.selectBoxOptions.find(selectBox =>
      selectBox.name === selectBoxName
    ).options;
  }

  applySelectedFiltersOptions(): void {
    this.advancedFilters = this.advancedFilters.map((filter: AdvancedFilter) => {
      if (this.isDateFilter(filter.name)) {
        this.datepickerRange[filter.name] = this.advancedFiltersForm.value[filter.name];
      }
      if (this.advancedFiltersForm.value[filter.name]) {
        filter.selected = this.isDateFilter(filter.name)
          ? this.calculateOptionsFromDatepicker(filter)
          : [this.advancedFiltersForm.value[filter.name]];
      } else {
        filter.selected = [];
      }
      return filter;
    });
    this.openOrCloseDropdown();
  }

  clearSelectedFiltersOptions(): void {
    this.advancedFilters.forEach((filter: AdvancedFilter) => {
      this.advancedFilters[filter.name] = this.isDateFilter(filter.name)
        ? [this.datepickerRange[filter.name]]
        : [...filter.selected];
    });
    this.openOrCloseDropdown();
  }

  resetFilters(): void {
    this.initializeForm();
  }

  openOrCloseDropdown(): void {
    if (this.advancedSearchDropdownIsOpened) {
      this.updateForm();
    }
    this.advancedSearchDropdownIsOpened = !this.advancedSearchDropdownIsOpened;
  }

  generateOrdersHistoryExcel(): void {
    this.excelGenerationInProgress = true;
    this.ordersFacade.postHybrisOrdersHistoryExcelFile(this.getFilteredOrders())
      .pipe(take(1))
      .subscribe((response: HttpResponse<Blob>)  => {
        const contentDispositionHeader = response.headers.get('Content-Disposition');
        const filename = FileUtils.extractFilenameFromContentDisposition(contentDispositionHeader, 'Archive_OrderHistory' + new Date().toString());
        FileUtils.saveAndOpenFile(response.body, FileType.EXCEL, filename, undefined, false);
        this.excelGenerationInProgress = false;
      });
  }

  getFilteredOrders(): any[] {
    const activeOrders = this.searchPipe.transform(
      this.filterPipe.transform(this.hybrisOrders, this.advancedFilters),
      this.searchOrders
    );

    const activeOrdersIds = [];
    activeOrders.forEach(order => {
      activeOrdersIds.push(order.id);
    });

    return activeOrdersIds;
  }

  private isDateFilter(filterName: string): boolean {
    return filterName.includes('orderDate');
  }

  private parseDataFromObject(order: any, attribute: string, additionalAttribute: string): string[] {
    if (additionalAttribute === 'shipTo' || additionalAttribute === 'soldTo') {
      return [this.hybrisAddressToString(order?.[attribute], additionalAttribute)];
    } else if(additionalAttribute === 'items') {
      return this.getMaterialNumbersOfItems(order);
    } else {
      return [order?.[attribute]?.[additionalAttribute]];
    }
  }

  private hybrisAddressToString(attributes: any, addressType: string): string {
    if (!attributes) {
      return '';
    }

    const addressDetails: string = [
      attributes[`${addressType}Address`],
      attributes[`${addressType}Town`],
      attributes[`${addressType}PostalOffice`]
    ].filter(addressField => !this.isEmptyAddress(addressField)).join(', ');

    return this.formatEmptyAddress(attributes[`${addressType}Name`]) + (addressDetails.length > 0 ? ` (${addressDetails})` : '');
  }

  private getMaterialNumbersOfItems(order: IArchiveOrder): string[] {
    return [...new Set(
      order.attributes.items.map((item: IHybrisOrderItem) => item.productNumber.toString())
    )];
  }

  private isEmptyAddress(address: string): boolean {
    return address === '' || address === 'empty';
  }

  private formatEmptyAddress(address: string): string {
    if (address === '' || address === 'empty') {
      return '';
    }

    return address;
  }

  private createSelectBoxOptions(filterName: string): Array<{name: string; value: any}> {
    const optionsForSelectBox: Array<{name: string; value: any}> = [];
    this.advancedFilters.find((item: AdvancedFilter) => item.name === filterName)?.options.forEach(
      option => {
        optionsForSelectBox.push({
          name: filterName === 'orderStatus' ? this.getFormattedStatus(option) : option,
          value: option
        });
      });
    return optionsForSelectBox;
  }

  private getFormattedStatus(status: string): string {
    return StringUtils.replaceUnderscores(status, ' ').split(' ').map(statusPart => {
      return statusPart.charAt(0).toUpperCase() + statusPart.slice(1).toLowerCase();
    }).join(' ');
  }

  private calculateOptionsFromDatepicker(filter: AdvancedFilter): string[] {
    let dateOptions: string[] = filter.options;
    dateOptions = dateOptions.filter(option => {
      if (filter.name === 'orderDateFrom') {
        return new Date(this.advancedFiltersForm.value[filter.name]) <= new Date(option);
      } else {
        const endOfTheDay: Date = new Date(this.advancedFiltersForm.value[filter.name]);
        endOfTheDay.setHours(23, 59, 59, 999);
        return endOfTheDay >= new Date(option);
      }
    });
    return dateOptions.length !== 0 ? dateOptions : ['no-match'];
  }
}
