<div class="container order-tracking-history">
  <div [appLoadingAnimation]="!isLoaded" class="app-loading">
    <ng-container *ngIf="orderDetails">
      <div class="col-12 col-lg-10">
        <div class="row">
          <div class="col-12">
            <h3>{{ 'order-tracking-history.order-details' | translate }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-9">
            <h6 class="no-margin">{{ 'order-tracking-history.status-of-order' | translate }} {{ orderDetails.id }}</h6>
          </div>
          <div class="back-to-cart col-12 col-lg-3" (click)="backToOrders()">
            <span class="icon-arrow-left"></span>
            @if (isHarmonizedPurchaseActivityEnabled$ | async) {
                {{ 'order-details.back-to-purchase-activity' | translate }}
            } @else {
              <ng-container *ngIf="!isApprover(); else approverBlock">
                {{ 'order-tracking-history.back-to-my-orders' | translate }}
              </ng-container>
              <ng-template #approverBlock>
                {{ 'order-tracking-history.back-to-hospital' | translate }}
              </ng-template>
            }
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-1 order-icon">
            <span [ngClass]="orderIcon()"></span>
          </div>
          <div class="col-12 col-lg-8 collapsing-div">
            <div>
              <label>
                <strong>
                  {{
                    'order-tracking-history.status-bar-order-' +
                    getStatus(orderDetails.attributes.stateDisplayName) +
                    '-title' | translate: {number: orderDetails.id}
                  }}
                </strong>
              </label>
              <span *ngIf="!isAuStore() && (!isJpStore() || showStatusForJpStore())">
                {{
                  'order-tracking-history.status-bar-order-' +
                  getStatus(orderDetails.attributes.stateDisplayName) +
                  '-subtitle' | translate: {name: 'Approver Name'}
                }}
              </span>
              <span *ngIf="showStatusBarEstimatedArrival()">
                {{ 'order-tracking-history.status-bar-estimated-arrival' | translate }}
              </span>
            </div>
          </div>
          @if (!isViewer() && !isOnlinePaymentFailed()) {
            <div class="col-12 col-lg-3 cancel-order-button"
                 [ngClass]="{'back-to-cart': orderDetails.attributes.stateDisplayName === 'new',
                                         'inactive': orderDetails.attributes.stateDisplayName !== 'new'}"
                 (click)="cancelOrderModal()">
              <strong
                  class="cancel-order-button--padding">{{ 'order-tracking-history.cancel-order' | translate }}</strong>
            </div>
          } @else if (!isViewer() && isOnlinePaymentFailed()) {
            <div class="col-12 col-lg-3 resume-payment-button"
                 [ngClass]="{'inactive': !isPaymentRetryAvailable || isPaymentRetryLoading}"
                 (click)="retryPayment()">
              <strong class="resume-order-button--padding">
                {{ 'order-tracking-history.retry-payment' | translate }}
              </strong>
            </div>
          }
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <app-steps-bar
              [currentStatus]="orderDetails.attributes.stateDisplayName"
              [comment]="orderDetails.attributes.stateComment"
              [payment]="orderDetails.attributes.payments[0]">
          </app-steps-bar>
        </div>
      </div>
      <div class="row">
        <div class="col-12 order-item">
          <h3>{{ 'order-tracking-history.ordered-items' | translate }}</h3>
        </div>
      </div>
      <div class="row justify-content-between table-header-colors">
        <div class="col-12 col-lg-2">
          <div class="col-12 col-lg-10 padding-styling">{{ 'order-tracking-history.order' | translate }}</div>
          <div class="col-12 col-lg-10">
            <strong>{{ orderDetails.id }}</strong>
          </div>
        </div>
        <div class="col-12 col-lg-2">
          <div class="col-12 col-lg-10 padding-styling">{{ 'order-tracking-history.ordered-on' | translate }}</div>
          <div class="col-12 col-lg-10 created-date">
            <strong>{{ orderDetails.attributes.createdAt | formatDate }}</strong>
          </div>
        </div>
        <div class="col-12 col-lg-2 total-qty">
          <div class="col-12 padding-styling">
            {{ 'order-tracking-history.total-quantity' | translate }}
          </div>
          <div class="col-12">
            <strong>{{ totalQuantity }} {{ 'order-tracking-history.items' | translate }}</strong>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div *ngIf="!isAuStore(); else shippingCost" class="col-12 no-margin padding-styling total-sum">
            {{ 'order-tracking-history.total-sum' | translate }}
          </div>
          <ng-template #shippingCost>
            <div class="row no-margin no-padding">
              <div class="col-6 no-margin no-padding text-right">
                {{ 'order-tracking-history.shipping-cost' | translate }}
              </div>
              <div class="col-6 no-margin no-padding total-sum">
                {{ 'order-tracking-history.total-sum' | translate }}
              </div>
            </div>
          </ng-template>
          <ng-container
              *ngIf="orderDetails.attributes.totals.subtotal !== orderDetails.attributes.totals.grandTotal &&
              !isAuStore()">
            <div class="row no-margin no-padding">
              <div class="col-6 no-padding text-left">
                {{ 'order-tracking-history.subtotal' | translate }}
              </div>
              <div class="col-6 no-padding text-right">
                {{ orderDetails.attributes.totals.subtotal | currency: orderDetails.attributes.currencyIsoCode }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="orderDetails.attributes.totals.discountTotal">
            <div class="row no-margin no-padding promotional-code">
              <div class="col-8 no-padding text-left">
                {{ 'order-tracking-history.promotional-code' | translate }} ({{ getVoucherDiscount() }}%)
              </div>
              <div class="col-4 no-padding text-right">
                -{{ orderDetails.attributes.totals.discountTotal | currency: orderDetails.attributes.currencyIsoCode }}
              </div>
            </div>
          </ng-container>
          <div class="row no-margin no-padding">
            <div *ngIf="!isAuStore(); else shippingCostValue" class="col-6 no-padding text-left">
              <strong>{{ 'order-tracking-history.order-total' | translate }}</strong>
            </div>
            <ng-template #shippingCostValue>
              <div class="col-6 no-padding text-right">
                <strong>
                  {{
                    (taxUtils.isExcludeTaxActive() ?
                        orderDetails.attributes.shipments[0].defaultNetPrice :
                        orderDetails.attributes.shipments[0].defaultGrossPrice)
                        | currency: orderDetails.attributes.currencyIsoCode
                  }}
                </strong>
              </div>
            </ng-template>
            <div class="col-6 no-padding text-right">
              <strong>
                {{ orderDetails.attributes.totals.grandTotal | currency: orderDetails.attributes.currencyIsoCode }}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-12 col-lg-8 items-border">
          <ng-container *ngFor="let item of orderDetails.attributes.items">
            <div class="item-border">
              <div class="row item" [attr.data-test]="'order-item-' + item.name">
                <div class="col-12 col-lg-2">
                  <img src="{{ item.metadata.image }}" alt="n/a">
                </div>
                <div class="col-12" [ngClass]="isAuStore() || isInStore() ? 'col-lg-10' : 'col-lg-8'">
                  <div class="row">
                    <div class="col-12">
                      <h6 class="item--name">{{ item.name }}</h6>
                    </div>
                    <div *ngIf="!isAuStore() && !isInStore()" class="col-12">
                      <div>
                        <h5 class="item--price">
                          {{ item.unitPrice | currency: orderDetails.attributes.currencyIsoCode }}
                        </h5>
                      </div>
                    </div>
                    <div class="col-12 padding-styling" *ngIf="item.systemDetails">
                      <div>
                        <span class="item-system-details-span-styling">
                          {{ 'orders-page.selected-system' | translate }}
                        </span>
                        <span class="item-system-details-styling">
                          <span class="item-system-details-span-styling" *ngIf="item.systemDetails">
                            {{ item.systemDetails | selectedSystem | async }}
                          </span>
                        </span>
                      </div>
                      <div>
                        <span class="item-system-details-span-styling">
                          {{ 'orders-page.company' | translate }}
                        </span>
                        <span class="item-system-details-styling">
                          <span class="item-system-details-span-styling" *ngIf="item.systemDetails">
                            {{ item.systemDetails | companyDetails }}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div *ngIf="!isAuStore() && !isInStore()" class="col-12">
                      <span class="currently-in-stock" *ngIf="item.deliveryAvailability">
                        {{ item.deliveryAvailability }}
                      </span>
                    </div>
                    <div *ngIf="!isAuStore() && !isInStore()" class="col-12">
                      <span class="estimated-arrival-time" *ngIf="item.deliveryTime">
                         {{ 'shop-cart.estimatedArrivalLabel' | translate }}{{ item.deliveryTime }}
                      </span>
                    </div>
                    <div *ngIf="isAuStore()" class="col-12 order-info">
                      <div class="row">
                        <label class="col-12 sku">
                          {{ item.sku }}
                        </label>
                        <div class="col-6">
                          <div class="row">
                            <label class="col-6">{{ 'order-approve.quantity' | translate }}:</label>
                            <label class="col-6 text-right"
                                   [attr.data-test]="'label-itemQuantity'">{{ item.quantity }}</label>
                          </div>
                          <div class="row">
                            <label class="col-6">{{ 'order-tracking-history.item-price' | translate }}:</label>
                            <label class="col-6 text-right"
                                   [attr.data-test]="'label-itemPrice'">
                              {{ item.sumPrice | currency: orderDetails.attributes.currencyIsoCode }}
                            </label>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <label class="col-6">{{ 'order-approve.tracking-number' | translate }}:</label>
                            <label class="col-6 text-right" [attr.data-test]="'label-trackingNumber'">
                              {{ orderDetails.attributes.trackingNumber ?? ('order-approve.no-tracking-number' | translate) }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="isInStore()" class="col-12">
                      <div class="row">
                        <h5 class="col-8 item--price">
                          {{ item.unitPrice | currency: orderDetails.attributes.currencyIsoCode }}
                        </h5>
                        <div class="col-4">
                          {{ item.quantity }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <span class="currently-in-stock" *ngIf="item.deliveryAvailability">
                            {{ item.deliveryAvailability }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">
                          <span class="estimated-arrival-time" *ngIf="item.deliveryTime">
                             {{ 'shop-cart.estimatedArrivalLabel' | translate }}{{ item.deliveryTime }}
                          </span>
                        </div>
                        <div class="col-4">
                          <span class="tracking-number">
                            <span>
                              {{ 'order-approve.tracking-number' | translate }}:
                            </span>
                            <a href="{{ ('order-tracking-history.tracking-link' | translate) + orderDetails.attributes.trackingNumber }}"
                               target="_blank" rel="noopener" [attr.data-test]="'link-trackingNumber'"
                               *ngIf="orderDetails.attributes.trackingNumber; else noTrackingNo">
                              {{ orderDetails.attributes.trackingNumber }}
                            </a>
                            <ng-template #noTrackingNo>
                              <span>
                                {{ 'order-approve.no-tracking-number' | translate }}
                              </span>
                            </ng-template>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!isAuStore() && !isInStore()" class="col-12 col-lg-2">
                  <br/>
                  <div>{{ item.quantity }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-12 col-lg-4 address">
          <strong>{{ 'order-tracking-history.delivery-address' | translate }}</strong>
          <div>
            <div *ngIf="orderDetails.attributes.shippingAddress.firstName !== 'empty'">
              {{ orderDetails.attributes.shippingAddress.firstName }}
            </div>
            <div>{{ orderDetails.attributes.shippingAddress.address1 }}</div>
            <div *ngIf="orderDetails.attributes.shippingAddress.address3 !== 'empty'">
              {{ orderDetails.attributes.shippingAddress.address3 }}
            </div>
            <div class="address--inline">
              <div *ngIf="orderDetails.attributes.shippingAddress.zipCode !== 'empty'">
                {{ orderDetails.attributes.shippingAddress.zipCode }}&nbsp;
              </div>
              <div *ngIf="orderDetails.attributes.shippingAddress.city !== 'empty'">
                {{ orderDetails.attributes.shippingAddress.city }}
              </div>
              <div *ngIf="orderDetails.attributes.shippingAddress.address2 !== 'empty'">
                , {{ orderDetails.attributes.shippingAddress.address2 }}
              </div>
            </div>
            <div *ngIf="orderDetails.attributes.shippingAddress.country !== 'empty'">
              {{ orderDetails.attributes.shippingAddress.country }}
            </div>
          </div>
          <div *ngIf="orderDetails.attributes.taxNumber" class="cart-tax-number">
            <ng-container>
              <strong>{{ 'order-approve.billing-tax-number' | translate }}</strong>
              <div>
                <span>{{ orderDetails.attributes.taxNumber }}</span>
              </div>
            </ng-container>
          </div>
          <div class="cart-system-details">
            <ng-container *ngIf="orderDetails.attributes.systemDetails && !isAuStore()">
              <strong>{{ 'orders-page.system-details' | translate }}</strong>
              <div class="padding-styling">
                <div>
                  <span>
                    {{ 'orders-page.selected-system' | translate }}
                  </span>
                  <div class="cart-system-details--line">
                    <span>
                      {{ orderDetails.attributes.systemDetails | selectedSystem | async }}
                    </span>
                  </div>
                </div>
                <div>
                  <span>
                    {{ 'orders-page.company' | translate }}
                  </span>
                  <div class="cart-system-details--line">
                    <span>
                      {{ orderDetails.attributes.systemDetails | companyDetails }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="isAuStore()">
              <strong>{{ 'order-tracking-history.shipping-method' | translate }}</strong>
              <div class="padding-styling">
                <div>
                  <span>
                    {{ orderDetails.attributes.shipments[0].shipmentMethodName }}
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="isInStore()">
            <strong>{{ 'orders-page.payment-method' | translate }}</strong>
            <div class="padding-styling">
              {{ OrdersUtils.getPaymentTranslationKey(orderDetails?.attributes?.payments?.[0]?.paymentProvider) | translate }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-modal
    [open]="showModalCancelOrder"
    title="{{ 'order-tracking-history.cancel-order-modal-title' | translate:{number: orderId} }}"
    (crossClick)="restartShowModal()">
  <ng-container ngProjectAs="[slot][body]">
    @if (isOrderCanceling) {
      <div class="loader__overlay">
        <div class="loader__content">
          <div class="loading-animation"></div>
        </div>
      </div>
    }
    <h5 class="cancel-order-modal-title">
      {{ 'order-tracking-history.cancel-order-modal-content' | translate:{number: orderId} }}
    </h5>
  </ng-container>
  <ng-container ngProjectAs="[slot][footer]">
    <button type="button" (click)="restartShowModal()" class="button button" data-dismiss="modal-dialog">
      {{ 'order-tracking-history.keep-order'  | translate }}
    </button>
    <button type="button" (click)="cancelOrder(orderId)"
            class="button button--primary"
            data-dismiss="modal-dialog">
      {{ 'order-tracking-history.cancel-order' | translate }}
    </button>
  </ng-container>
</app-modal>
