import { EOrderStatus } from '../configurations/order-status';

export class UiUtils {

  static getStatusIcon(currentStatus: string): string {
    if (!currentStatus) {
      return '';
    }

    let icon: string;
    switch (currentStatus.toLowerCase()) {
      case EOrderStatus.DECLINED:
      case EOrderStatus.CANCELED:
        icon = 'close';
        break;
      case EOrderStatus.ISSUE:
        icon = 'warning';
        break;
      default:
        icon = 'check';
    }

    return `icon-${icon} icon-${icon}__circle icon-check-styling`;
  }

  static singularOrPlural(value: number, translateKey: string): string {
    return value > 1 ? `${translateKey}-plural` : `${translateKey}-singular`;
  }

  static scrollToTop(): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.scrollY;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 5);
  }
}
