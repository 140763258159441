import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { AnalyticsService } from '../../../analytics/analytics.service';

import { IPendingOrder } from '../../../models/order.models';
import { OrdersFacade } from '../../../facades/orders.facade';
import { Subject, Subscription } from 'rxjs';
import { CustomerFacade } from '../../../facades/customer.facade';
import { Router } from '@angular/router';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { take, takeUntil } from 'rxjs/operators';
import { ObjectUtils } from '../../../utils/object.utils';
import { EStoreTypes, EUserRoles } from '../../../configurations/common';
import { AppUtils } from '../../../utils/app.utils';
import { FileType, FileUtils } from '../../../utils/file.utils';
import { environment } from '../../../../environments/environment';
import { IStore } from '../../../models/settings.model';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-my-order-detail',
  templateUrl: './my-order-detail.component.html',
  styleUrls: ['./my-order-detail.component.scss'],
})
export class MyOrderDetailComponent implements OnInit, OnChanges, OnDestroy {
  isJpStore: boolean = false;
  itemsDetails: any;
  itemsList: any[] = [];
  openModal: boolean = false;
  loaded: boolean = false;
  companyRoles: EUserRoles[];
  userSubscription$: Subscription;
  showModalConfirmRemove: boolean;
  deleteLastItemAndDeclineOrderFlag: boolean;
  cartId: string;
  clickedItemDataId: string;
  clickedItemDataName: string;
  pendingOrder: any;
  actionInProgress: boolean = false;
  isPdfGenerationInProgress: boolean = false;
  @Input() cartDetails: IPendingOrder;
  @Input() isOpen: boolean;
  @Input() allowApprove: boolean;
  @Input() pendingOrdersLoading: boolean;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private ordersFacade: OrdersFacade,
    private analyticsService: AnalyticsService,
    private customerFacade: CustomerFacade,
    private router: Router,
    private checkoutFacade: CheckoutFacade,
  ) {
  }

  ngOnInit(): void {
    this.userSubscription$ = this.getUserData();
    this.isJpStore = AppUtils.isStoreActive(EStoreTypes.JP);
  }

  ngOnDestroy(): void {
    this.userSubscription$.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen?.currentValue && !this.loaded) {
      this.retrieveItemsForOrderToApprove(this.cartDetails.id);
    }
  }

  updateDetails(): void {
    this.updated.emit();
  }

  getUserData(): Subscription {
    return this.customerFacade.getCustomerCompanyRoles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(companyRoles => {
        this.companyRoles = companyRoles;
      });
  }

  retrieveItemsForOrderToApprove(id: string): void {
    this.ordersFacade.getCartForApprovalData(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.loaded = true;
        if (data) {
          this.itemsDetails = data;
          if (!ObjectUtils.isEmpty(this.itemsDetails.data.relationships)) {
            this.itemsDetails.data.relationships.items.data.forEach((item) => {
              if (this.itemsList.indexOf(item.id) === -1) {
                this.itemsList.push(item.id);
              }
            });
          }
        }
      });
  }

  buildQuoteDeclineData(data: IPendingOrder): object {
    const {
            approverId,
            firstName: approverFirstName,
            lastName: approverLastName,
            comment,
            dueDate,
          } = data.attributes.approverDetails;
    const {email, salutation, firstName, lastName} = data.attributes.customer;
    return {
      data: {
        type: 'quote-decline',
        attributes: {
          idCart: data.id,
          approverDetails: {
            approverId,
            approverFirstName,
            approverLastName,
            comment,
            dueDate,
          },
          customer: {
            email,
            salutation,
            firstName,
            lastName,
          },
        },
      },
    };
  }

  onDeclineOrderClick(order: IPendingOrder): void {
    this.actionInProgress = true;
    const data = this.buildQuoteDeclineData(order);
    this.closeModalDialog();
    this.ordersFacade.postDeclinePendingOrder(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.analyticsService.setProducts(this.itemsDetails);
        this.analyticsService.trackOrderRejected();
        this.updateDetails();
        this.actionInProgress = false;
      });
  }

  getItemPrice(id: string): number {
    return this.itemsDetails.included?.filter(tmp => tmp.type === 'items' && tmp.id === id)[0].attributes.calculations.unitPrice;
  }

  getItemQuantity(id: string): number {
    return this.itemsDetails.included?.filter(tmp => tmp.type === 'items' && tmp.id === id)[0].attributes.quantity;
  }

  getItemName(id: string): string {
    return this.itemsDetails.included?.filter(tmp => tmp.type === 'concrete-products' && tmp.id === id)[0].attributes.name;
  }

  getItemPicture(id: string): string {
    return this.itemsDetails.included?.filter(tmp => tmp.type === 'concrete-product-image-sets' && tmp.id === id)[0]
      .attributes.imageSets.find(
        img => img.name === 'default')
      .images.find(smallImage => smallImage.externalUrlSmall)
      .externalUrlSmall;
  }

  getItemSystemDetails(id: string): any {
    return this.itemsDetails?.included?.filter(tmp => tmp.type === 'items' && tmp.id === id)[0]
      .attributes.systemDetails;
  }

  openModalDialog(): void {
    this.openModal = true;
  }

  closeModalDialog(): void {
    this.openModal = false;
  }

  getUserInitials(firstName: string, lastName: string): string {
    return firstName.substr(0, 1) + lastName.substr(0, 1);
  }

  getUserFullName(firstName: string, lastName: string): string {
    return `${firstName} ${lastName}`;
  }

  getTranslation(part: string): string {
    if (this.isApproverCheck()) {
      return `${part}-approver`;
    }
    return `${part}-buyer`;

  }

  isApproverCheck(): boolean {
    return this.companyRoles.includes(EUserRoles.Approver) || this.companyRoles.includes(EUserRoles.Admin);
  }

  renderEmptyAddressField(field: string): string {
    return field === 'empty' ? '' : field;
  }

  isAddressFieldEmpty(field: string): boolean {
    return field === 'empty' || field === '' || !field;
  }

  approveOrder(): void {
    this.checkoutFacade.actionPutCartIdOrderApprove(this.cartDetails.id);
    this.router.navigate(['/order-approval/', this.cartDetails.id]).then();
  }

  removeItemFromCartModal(itemId: any, id: any): void {
    this.cartId = id;
    this.clickedItemDataId = itemId;
    this.clickedItemDataName = this.getItemName(itemId);
    this.showModalConfirmRemove = true;
    (this.itemsList.length > 1) ? this.deleteLastItemAndDeclineOrderFlag = false : this.deleteLastItemAndDeclineOrderFlag = true;
  }

  restartShowModal(): void {
    this.showModalConfirmRemove = false;
    this.deleteLastItemAndDeclineOrderFlag = false;
  }

  deleteItemFromOrder(): void {
    this.ordersFacade.deleteItemFromOrder(this.cartId, this.clickedItemDataId)
      .pipe(take(1)).subscribe(() => {
      this.deleteLastItemAndDeclineOrderFlag = false;
      this.updateDetails();
      this.showModalConfirmRemove = false;
    });
  }

  deleteLastItemAndDeclineOrder(): void {
    this.pendingOrder = {
      data: {
        type: 'quote-decline',
        attributes: {
          idCart: this.cartDetails.id,
          approverDetails: {
            approverId: this.cartDetails.attributes.approverDetails.approverId,
            approverFirstName: this.cartDetails.attributes.approverDetails.firstName,
            approverLastName: this.cartDetails.attributes.approverDetails.lastName,
            comment: this.cartDetails.attributes.approverDetails.comment,
            dueDate: this.cartDetails.attributes.approverDetails.dueDate,
          },
          customer: {
            email: this.cartDetails.attributes.customer.email,
            customersalutation: this.cartDetails.attributes.customer.salutation,
            customerfirstName: this.cartDetails.attributes.customer.firstName,
            customerlastName: this.cartDetails.attributes.customer.lastName,
          },
        },
      },
    };
    this.actionInProgress = true;
    this.ordersFacade.postDeclinePendingOrder(this.pendingOrder)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.showModalConfirmRemove = false;
        this.deleteLastItemAndDeclineOrderFlag = false;
        this.updateDetails();
        this.actionInProgress = false;
      });
  }

  generateQuotePdf(): void {
    if (this.isJpStore && this.cartDetails?.id) {
      this.isPdfGenerationInProgress = true;
      this.checkoutFacade.getCartGenerateQuotePdfFile(this.cartDetails.id, false).pipe(take(1))
        .subscribe((response: HttpResponse<Blob>)  => {
          const contentDispositionHeader = response.headers.get('Content-Disposition');
          const filename = FileUtils.extractFilenameFromContentDisposition(contentDispositionHeader, 'Cart_details_' + this.cartDetails?.id);
          FileUtils.saveAndOpenFile(response.body, FileType.PDF, filename);
          this.isPdfGenerationInProgress = false;
        });
    }
  }

  isExcludeTaxActive(): boolean {
    const currentStore: IStore = AppUtils.getCurrentStore();
    return environment.features.excludeTax.includes(currentStore.storeId);
  }
}
