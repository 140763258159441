@if (guestCartLoading) {
  <div class="loading-animation"></div>
} @else {
  <h3 [attr.data-test]="'title-page'">{{ 'guest-cart.title' | translate }}</h3>
  <h5 [attr.data-test]="'title-cart'">{{ 'guest-cart.subtitle' | translate }}</h5>
  <div class="page">
    <div class="guest-cart">
      <div class="guest-cart__header">
        <h6 [attr.data-test]="'title-guestCartId'">{{ guestCart.id }}</h6>
        <div class="system-details">
          <p [attr.data-test]="'desc-systemName'">{{ guestCart.attributes.customerRequestData?.systemName }}</p>
          <p [attr.data-test]="'desc-fl'">FL: {{ guestCart.attributes.customerRequestData?.functionalLocation }}</p>
        </div>
      </div>
      <div class="guest-cart__body">
        <div class="desktop-item__header">
          <div class="item__image"></div> <!-- BLANK SPACE FOR ALIGNMENT -->
          <div class="item__info"></div>  <!-- BLANK SPACE FOR ALIGNMENT -->
          <div class="item__price" [attr.data-test]="'label-itemUnitPrice'">
            {{ 'guest-cart.item.price-per-unit' | translate }}
          </div>
          <div class="item__quantity" [attr.data-test]="'label-itemQuantity'">
            {{ 'guest-cart.item.quantity' | translate }}
          </div>
          <div class="item__total-price" [attr.data-test]="'label-itemTotalPrice'">
            {{ 'guest-cart.item.total-price' | translate }}
          </div>
        </div>
        @for (item of guestCartItems; track item) {
          <div class="item" [attr.data-test]="'cart-item-' + item.attributes.name">
            <div class="item__image">
              <img [src]="ImageUtils.getProductImageUrl(item)"
                   [alt]="item.attributes.name"
                   [attr.data-test]="'img-itemImage'">
            </div>
            <div class="item__info">
              <h6 class="item__info--name" [attr.data-test]="'title-itemName'">{{ item.attributes.name }}</h6>
              <p class="item__info--sku" [attr.data-test]="'desc-itemSku'">{{ item.attributes.sku }}</p>
            </div>
            <div class="item__price" [attr.data-test]="'desc-itemUnitPrice'">
              {{ item.attributes.calculations.unitPrice | currency: guestCart.attributes.currency }}
            </div>
            <div class="item__quantity">
              <input type="number"
                     [value]="item.attributes.quantity"
                     [disabled]="true"
                     [attr.data-test]="'desc-itemQuantity'"/>
            </div>
            <div class="item__total-price" [attr.data-test]="'desc-itemTotalPrice'">
              {{ item.attributes.calculations.sumPrice | currency: guestCart.attributes.currency }}
            </div>
            <div class="mobile-item__header">
              <div>{{ 'guest-cart.item.price-per-unit' | translate }}</div>
              <div>{{ 'guest-cart.item.quantity' | translate }}</div>
              <div>{{ 'guest-cart.item.total-price' | translate }}</div>
            </div>
            <div class="mobile-item__content">
              <div class="item__price">
                {{ item.attributes.calculations.unitPrice | currency: guestCart.attributes.currency }}
              </div>
              <input class="item__quantity" type="number" [value]="item.attributes.quantity" [disabled]="true"/>
              <div class="item__total-price">
                {{ item.attributes.calculations.sumPrice | currency: guestCart.attributes.currency }}
              </div>
            </div>
          </div>
          <hr>
        }
      </div>
    </div>
    <div class="summary">
      <button class="button button--primary"
              [disabled]="guestCartItems.length === 0"
              (click)="proceedToNextStep()"
              [attr.data-test]="'button-continueToCheckout'">
        {{ 'guest-cart.continue-to-checkout' | translate }}
      </button>
      <div class="summary__footer">
        <div>
          <p class="total-price" [attr.data-test]="'label-totalPrice'">{{ 'guest-cart.total-price' | translate }}</p>
          <p [attr.data-test]="'label-excludeTax'">{{ 'guest-cart.exclude-tax' | translate }}</p>
        </div>
        <p class="total-price" [attr.data-test]="'desc-totalPrice'">
          {{ guestCart.attributes.totals.subtotal | currency: guestCart.attributes.currency }}
        </p>
      </div>
    </div>
  </div>
}
