import { Component, OnDestroy, OnInit } from '@angular/core';
import { State } from '../../reducers';
import { Store } from '@ngrx/store';
import { IOrder, IOrderById, IOrderItem } from '../../models/order.models';
import * as OrderApproveActions from '../../actions/order-approve.actions';
import { OrdersFacade } from '../../facades/orders.facade';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayUtils } from '../../utils/array.utils';
import { MathUtils } from '../../utils/math.utils';
import { EOrderStatus } from '../../configurations/order-status';
import { Observable, Subscription } from 'rxjs';
import { CustomerFacade } from '../../facades/customer.facade';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { EFeatureToggles, EStoreTypes, EUserRoles, HospitalOrdersTabs } from '../../configurations/common';
import { UiUtils } from '../../utils/ui.utils';
import { AppUtils } from '../../utils/app.utils';
import { IStore } from '../../models/settings.model';
import { TaxUtils } from '../../utils/tax.utils';
import { take } from 'rxjs/operators';
import { OrdersUtils } from '../../utils/orders.utils';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '../../utils/date.utils';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-order-tracking-history',
  templateUrl: './order-tracking-history.component.html',
  styleUrls: ['./order-tracking-history.component.scss'],
})
export class OrderTrackingHistoryComponent implements OnInit, OnDestroy {

  protected readonly OrdersUtils = OrdersUtils;

  orderDetails: IOrder;
  totalQuantity: number = 0;
  estimatedArrivalDate: string;
  isLoaded: boolean;
  companyRoles: EUserRoles[];
  companyRolesSubscription$: Subscription;
  statusCompletedFinal: boolean = false;
  showModalCancelOrder: boolean;
  orderId: string;
  actualStore: IStore;
  taxUtils = TaxUtils;
  isPaymentRetryAvailable: boolean = false;
  isPaymentRetryLoading: boolean = false;
  isOrderCanceling: boolean = false;
  isHarmonizedPurchaseActivityEnabled: boolean = false;

  //Feature toggles
  isHarmonizedPurchaseActivityEnabled$: Observable<boolean> =
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.HARMONIZED_PURCHASE_ACTIVITY_PAGE);

  constructor(private store: Store<State>,
              private ordersFacade: OrdersFacade,
              private route: ActivatedRoute,
              private router: Router,
              private customerFacade: CustomerFacade,
              private analyticsService: AnalyticsService,
              private checkoutFacade: CheckoutFacade,
              private translateService: TranslateService,
              private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.companyRolesSubscription$ = this.selectCompanyRole();
    this.retrieveOrderDetails(this.route.snapshot.paramMap.get('orderId'));
    this.actualStore = AppUtils.getCurrentStore();
    this.isHarmonizedPurchaseActivityEnabled$.subscribe(result => this.isHarmonizedPurchaseActivityEnabled = result);
  }

  ngOnDestroy(): void {
    this.companyRolesSubscription$.unsubscribe();
  }

  /**
   * Retrieve order details by id
   * @param {string} id
   */
  retrieveOrderDetails(id: string): void {
    this.isLoaded = false;
    this.ordersFacade.getOrderById(id).subscribe({
        next: (data: IOrderById) => {
          if (data) {
            this._storeOrderDetails(data.data);
            this.analyticsService.setProducts(this.orderDetails.attributes.items);
            this.analyticsService.setCartId(this.orderDetails.id);
            this.analyticsService.trackOrderApproved('Order Details', PageTypes.ORDER_DETAILS);
            this.isPaymentRetryAvailable = this.getIsPaymentRetryAvailable(data.data);
          }
          this.store.dispatch(OrderApproveActions.getOrderDetailsSuccess({orderDetails: this.orderDetails}));
        },
        complete: (): void => {
          this.isLoaded = true;
        },
      },
    );
  }

  /**
   * Parse and store retrieved order details to related global variables
   *
   * @param {IOrder} order
   */
  private _storeOrderDetails(order: IOrder): void {
    this.orderDetails = order;
    const date: number = Date.parse(this.orderDetails.attributes.createdAt);
    const resultDate: Date = new Date(date);
    resultDate.setDate(resultDate.getDate() + 2);
    this.estimatedArrivalDate = resultDate.toString();
    const items: IOrderItem[] = this.orderDetails.attributes.items;
    this.orderDetails.attributes.items = this.combineSameItems(items);
    this.totalQuantity = items.reduce((prev: number, curr: IOrderItem) => {
      return prev + curr.quantity;
    }, 0);
    this.orderId = this.orderDetails.id;
  }

  /**
   * @param {IOrderItem[]} items
   * @returns {IOrderItem[]}
   */
  combineSameItems(items: IOrderItem[]): IOrderItem[] {
    return ArrayUtils.uniqueObjects(items, 'sku');
  }

  /**
   * @returns {number}
   */
  getVoucherDiscount(): number {
    return MathUtils.calculateDiscount(this.orderDetails.attributes.totals.discountTotal, this.orderDetails.attributes.totals.subtotal);
  }

  /**
   * navigate back to Orders
   */
  backToOrders(): void {
    this.isHarmonizedPurchaseActivityEnabled$.subscribe(
      isHarmonizedPurchaseActivityPageEnabled => {
        if (isHarmonizedPurchaseActivityPageEnabled) {
          this.router.navigate(
            ['/purchase-activity']
          );
        } else {
          this.router.navigate(
            ['/my-orders'],
            {queryParams: {tab: HospitalOrdersTabs.PREVIOUS_ORDERS}},
          );
        }
      }
    )
  }

  /**
   * @param {string} status
   * @returns {string}
   */
  getStatus(status: string): string {
    switch (status) {
      case null:
      case '':
        return '';
      case EOrderStatus.DECLINED:
        return EOrderStatus.DECLINED;
      case EOrderStatus.CANCELED:
        return EOrderStatus.CANCELED;
      case EOrderStatus.COMPLETED:
        this.statusCompletedFinal = true;
        return 'completed-final';
      default:
        this.statusCompletedFinal = false;
        const values = Object.keys(EOrderStatus)
          .map(key => EOrderStatus[key])
          .filter(k => !(parseInt(k, 10) >= 0));
        return values[values.findIndex(i => i === status)].replace(' ', '_');
    }
  }

  /**
   * @returns {string}
   */
  orderIcon(): string {
    let status: string = this.orderDetails.attributes?.stateDisplayName;
    return UiUtils.getStatusIcon(this.isOnlinePaymentFailed() ? EOrderStatus.ISSUE : status);
  }

  /**
   * @returns {Subscription}
   */
  selectCompanyRole(): Subscription {
    return this.customerFacade.getCustomerCompanyRoles().subscribe((companyRoles: EUserRoles[]) => {
      this.companyRoles = companyRoles;
    });
  }

  /**
   * @returns {boolean}
   */
  isApprover(): boolean {
    return this.companyRoles.includes(EUserRoles.Approver) || this.companyRoles.includes(EUserRoles.Admin);
  }

  /**
   * @returns {boolean}
   */
  isViewer(): boolean {
    return this.companyRoles.includes(EUserRoles.Viewer);
  }

  /**
   * Checks if online payment failed.
   *
   * @returns {boolean}
   */
  isOnlinePaymentFailed(): boolean {
    switch (this.orderDetails.attributes.stateDisplayName) {
      case EOrderStatus.PAYMENT_FAILED:
      case EOrderStatus.PAYMENT_CANCELED:
        return true;
      default:
        return false;
    }
  }

  /**
   * Cancel order modal
   */
  cancelOrderModal(): void {
    if (this.orderDetails.attributes.stateDisplayName === 'new') {
      this.showModalCancelOrder = true;
    }
  }

  /**
   * Checks if payment retry option is available based on the order state and the time difference
   * between the order creation and the current time.
   *
   * The payment retry option is available for orders with failed/cancelled payment for 5 days.
   *
   * @returns {boolean}
   */
  getIsPaymentRetryAvailable(order: IOrder): boolean {
    if (order.attributes.stateDisplayName !== EOrderStatus.PAYMENT_FAILED
      && order.attributes.stateDisplayName !== EOrderStatus.PAYMENT_CANCELED
    ) {
      return false;
    }

    const createdAt = DateUtils.convertStringDateToDate(order.attributes.payments[0]?.createdAt);

    if (!createdAt) {
      return false;
    }

    //60 seconds (as default Spryker timeout) are subtracted from 5 days to avoid edge case scenario
    //where the call from FE is made right in time, but the OMS validation on BE side is executed over this time
    return (new Date().getTime() - createdAt.getTime()) <= ((5 * 24 * 60 * 60 * 1000) - (60 * 1000));
  }

  /**
   * Retry payment to CCAvenue
   */
  retryPayment(): void {
    this.isPaymentRetryLoading = true;
    if (!this.isPaymentRetryAvailable) {
      this.isPaymentRetryLoading = false;
      return;
    }

    this.ordersFacade.getOrderById(this.orderId).pipe(
      take(1),
    ).subscribe({
        next: (data: IOrderById) => {
          if (data) {
            this._storeOrderDetails(data.data);
            if (this.getIsPaymentRetryAvailable(data.data)) {
              this.checkoutFacade.getOnlinePaymentData(data.data.id).pipe(
                take(1),
              ).subscribe({
                next: data => {
                  this.isPaymentRetryLoading = false;
                  this.isPaymentRetryAvailable = false;
                  OrdersUtils.postToCCAvenue(data.data[0].attributes, this.translateService.instant('config.ccavenue_url'));
                },
                error: () => {
                  this._showPaymentRetryFailedAlert();
                },
              });
              return;
            }
            this.isPaymentRetryAvailable = false;
          }
          this._showPaymentRetryFailedAlert();
        },
        error: () => {
          this._showPaymentRetryFailedAlert();
        },
      },
    );
  }

  /**
   * Show payment retry failed alert modal
   */
  private _showPaymentRetryFailedAlert(): void {
    this.configurationFacade.setAlert({
      type: 'error',
      message: this.translateService.instant('order-tracking-history.retry-payment-failed'),
    });
    this.isPaymentRetryLoading = false;
  }

  /**
   * @param {string} orderId
   */
  cancelOrder(orderId: string): void {
    this.isOrderCanceling = true;
    const data = {
      data: {
        type: 'orders-cancel',
        attributes: {
          orderReference: orderId,
        },
      },
    };
    this.ordersFacade.postCancelOrder(orderId, data).subscribe((): void => {
      this.showModalCancelOrder = false;
      if (this.isHarmonizedPurchaseActivityEnabled) {
        this.router.navigate(
          ['/purchase-activity']
        );
      } else {
        this.router.navigate(
          ['/my-orders'],
        );
      }
    });
  }

  /**
   * restart show modal
   */
  restartShowModal(): void {
    this.showModalCancelOrder = false;
  }

  /**
   * @returns {boolean}
   */
  showStatusBarEstimatedArrival(): boolean {
    return this.orderDetails.attributes.stateDisplayName !== 'declined'
      && this.orderDetails.attributes.stateDisplayName !== 'canceled'
      && !this.statusCompletedFinal && !this.isAuStore()
      && (!this.isJpStore() || this.showStatusForJpStore());
  }

  /**
   * @returns {boolean}
   */
  isAuStore(): boolean {
    return this.actualStore.storeId === EStoreTypes.AU;
  }

  /**
   * @returns {boolean}
   */
  isJpStore(): boolean {
    return this.actualStore.storeId === EStoreTypes.JP;
  }

  /**
   * @returns {boolean}
   */
  isInStore(): boolean {
    return this.actualStore.storeId === EStoreTypes.IN;
  }

  /**
   * @returns {boolean}
   */
  showStatusForJpStore(): boolean {
    return this.isJpStore() && this.getStatus(this.orderDetails.attributes.stateDisplayName) !== EOrderStatus.INVOICE_SENT.replace(' ', '_');
  }
}
