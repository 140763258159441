import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DeliveryDetailsService } from '../services/delivery-details.service';
import * as OrderApproveSelectors from '../reducers/order-approve.reducer';
import { State } from '../reducers';
import * as OrderApproveActions from '../actions/order-approve.actions';
import {
  IAddressData,
  IAddressesDataIncluded,
  ICCPaymentResponse,
  ICheckoutData,
  ICheckoutUpdate,
  IConsentCheckbox,
  ICustomAddressData,
} from '../models/checkout.models';
import { CustomerService, RfqService } from '../services';
import {
  ICreateRfQ,
  IRfqBaseInfo,
  IRfqCancelDetails,
  IRfqCreateResponse,
  IRfqDetails,
  IRfqHistory,
} from '../models/rfq.models';
import { OrdersService } from '../services/orders.service';
import { CheckoutActions } from '../actions/';
import * as CheckoutSelectors from '../reducers/checkout.reducer';
import { IAddress, IPointOfContact, ITotals } from '../models/common.models';
import { ICartVoucher } from '../models/cart.models';
import { IApproverData } from '../models/customer.models';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CheckoutFacade {

  private pCustomerService: CustomerService;
  private pDeliveryDetailsService: DeliveryDetailsService;
  private pRfqService: RfqService;
  private pOrderService: OrdersService;

  public get customerService(): CustomerService {
    if (!this.pCustomerService) {
      this.pCustomerService = this.injector.get(CustomerService);
    }
    return this.pCustomerService;
  }

  public get deliveryDetailsService(): DeliveryDetailsService {
    if (!this.pDeliveryDetailsService) {
      this.pDeliveryDetailsService = this.injector.get(DeliveryDetailsService);
    }
    return this.pDeliveryDetailsService;
  }

  public get ordersService(): OrdersService {
    if (!this.pOrderService) {
      this.pOrderService = this.injector.get(OrdersService);
    }
    return this.pOrderService;
  }

  public get rfqService(): RfqService {
    if (!this.pRfqService) {
      this.pRfqService = this.injector.get(RfqService);
    }
    return this.pRfqService;
  }

  constructor(
    private injector: Injector,
    private store: Store<State>,
  ) {
  }

  getOnlinePaymentData(orderId: string): Observable<ICCPaymentResponse> {
    return this.deliveryDetailsService.getOnlinePaymentData(orderId);
  }

  postCustomerAddresses(customerId: string, body: any): Observable<any> {
    return this.customerService.addCustomerAddress(customerId, body);
  }

  postCheckoutUpdate(data: ICheckoutUpdate): Observable<any> {
    return this.deliveryDetailsService.postCheckoutUpdate(data);
  }

  postCheckoutData(data: ICheckoutData): Observable<any> {
    return this.deliveryDetailsService.postCheckoutData(data);
  }

  getShipmentMethodPrice(cartId: string): Observable<any> {
    return this.deliveryDetailsService.getShipmentMethodPrice(cartId);
  }

  getCompanyUsers(): Observable<any> {
    return this.deliveryDetailsService.getCompanyUsers();
  }

  getCompanyUsersForApprove(): Observable<any> {
    return this.deliveryDetailsService.getCompanyUsersForApprove();
  }

  postCheckout(data: any): Observable<any> {
    return this.deliveryDetailsService.postCheckout(data);
  }

  successSummaryPageData(): Observable<any> {
    return this.store.select(OrderApproveSelectors.getOrderSuccessPageData);
  }

  successSummaryPageClearData(): void {
    this.store.dispatch(OrderApproveActions.successSummaryPageClearData());
  }

  actionPutCartIdOrderApprove(cartId: string): void {
    this.store.dispatch(OrderApproveActions.PutCartId({cartId}));
  }

  actionLoadCartItemsOrderApprove(cartId: string): void {
    this.store.dispatch(OrderApproveActions.loadCartItems({cartId}));
  }

  selectCartItemsOrderApprove(): Observable<any> {
    return this.store.select(OrderApproveSelectors.getCartItems);
  }

  selectCartItemsLoadFail(): Observable<any> {
    return this.store.select(OrderApproveSelectors.getCartItemsLoadFail);
  }

  convertDataToDisplayAddresses(addressToAddArray: Array<IAddressesDataIncluded> | Array<ICustomAddressData>,
                                addressesList: Array<IAddressData>): IAddressData[] {
    return this.deliveryDetailsService.getAddressesFromData(addressToAddArray, addressesList);
  }

  createRfq(quote: ICreateRfQ): Observable<IRfqCreateResponse> {
    return this.rfqService.createRequestForQuote(quote);
  }

  getQuoteHistory(): Observable<IRfqHistory> {
    return this.rfqService.getRfqHistory();
  }

  loadQuoteHistory() {
    this.store.dispatch(CheckoutActions.GetQuoteRfqHistoryStartDataAction());
  }

  selectQuoteHistory(): Observable<IRfqBaseInfo[]> {
    return this.store.select(CheckoutSelectors.selectRfqHistoryData);
  }

  selectQuoteDetails(quoteId: string): Observable<IRfqDetails> {
    return this.rfqService.getRfqDetails(quoteId);
  }

  cancelRequest(quoteId: string, quote: IRfqCancelDetails): Observable<any> {
    return this.rfqService.cancelRfq(quoteId, quote);
  }

  getResponseCheckoutData(): Observable<any> {
    return this.store.select(OrderApproveSelectors.getResponseCheckoutData);
  }

  getQuotePdfFile(quoteId: string): Observable<HttpResponse<Blob>> {
    return this.rfqService.getRfqPdfFile(quoteId);
  }

  getCartDetailPdfFile(cartId: string): Observable<HttpResponse<Blob>> {
    return this.ordersService.getCartDetailPdfFile(cartId);
  }

  getCartGenerateQuotePdfFile(cartId: string, isPreview: boolean): Observable<any> {
    return this.ordersService.getCartGenerateQuotePdfFile(cartId, isPreview);
  }

  setSapPoNumber(sapPoNumber: string): void {
    this.store.dispatch(CheckoutActions.setSapPoNumber({sapPoNumber: sapPoNumber}));
  }

  setOrderEmailCopy(orderEmailCopy: string): void {
    this.store.dispatch(CheckoutActions.setOrderEmailCopy({orderEmailCopy: orderEmailCopy}));
  }

  selectSapPoNumber(): Observable<string> {
    return this.store.select(CheckoutSelectors.getSapPoNumber);
  }

  selectOrderCopyEmail(): Observable<string> {
    return this.store.select(CheckoutSelectors.getOrderCopyEmail);
  }

  selectLoadingDeliveryDetailsData(): Observable<boolean> {
    return this.store.select(CheckoutSelectors.selectLoadingDeliveryDetailsData);
  }

  selectAvailableShipToAddresses(): Observable<IAddress[]> {
    return this.store.select(CheckoutSelectors.selectAvailableShipToAddresses);
  }

  selectPreselectedShipToAddress(): Observable<IAddress> {
    return this.store.select(CheckoutSelectors.selectPreselectedShipToAddress);
  }

  selectIsPreselectedShipToAddressCustom$(): Observable<boolean> {
    return this.store.select(CheckoutSelectors.selectIsPreselectedShipToAddressCustom);
  }

  selectAvailableBillToAddresses(): Observable<IAddress[]> {
    return this.store.select(CheckoutSelectors.selectAvailableBillToAddresses);
  }

  selectPreselectedBillToAddress(): Observable<IAddress> {
    return this.store.select(CheckoutSelectors.selectPreselectedBillToAddress);
  }

  selectSelectedShipmentMethod(): Observable<string> {
    return this.store.select(CheckoutSelectors.selectSelectedShipmentMethod);
  }

  selectDeliveryDetailsFormData(): Observable<IPointOfContact> {
    return this.store.select(CheckoutSelectors.selectDeliveryDetailsFormData);
  }

  selectDeliveryDetailsApproverFormData(): Observable<IApproverData> {
    return this.store.select(CheckoutSelectors.selectDeliveryDetailsApproverFormData);
  }

  selectTaxNumberFormData(): Observable<string> {
    return this.store.select(CheckoutSelectors.selectTaxNumberFormData);
  }

  selectTermsAndConsentsData(): Observable<IConsentCheckbox[]> {
    return this.store.select(CheckoutSelectors.selectTermsAndConsentsData);
  }

  selectTotalPrices(): Observable<ITotals> {
    return this.store.select(CheckoutSelectors.selectTotalPrices);
  }

  selectTotalPricesLoading(): Observable<boolean> {
    return this.store.select(CheckoutSelectors.selectTotalPricesLoading);
  }

  selectCartVouchers(): Observable<ICartVoucher[]> {
    return this.store.select(CheckoutSelectors.selectCartVouchers);
  }

  setCheckoutFormData(data: IPointOfContact): void {
    this.store.dispatch(CheckoutActions.setDeliveryDetailsFormData({deliveryDetailsFormData: data}));
  }

  setPreselectedShipToAddress(preselectedShipToAddress: IAddress): void {
    this.store.dispatch(CheckoutActions.setPreselectedShipToAddress({preselectedShipToAddress: preselectedShipToAddress}));
  }

  setPreselectedBillToAddress(preselectedBillToAddress: IAddress): void {
    this.store.dispatch(CheckoutActions.setPreselectedBillToAddress({preselectedBillToAddress: preselectedBillToAddress}));
  }

  addShipToAddressToAvailableShipToAddresses(customShipToAddress: IAddress): void {
    this.store.dispatch(CheckoutActions.addShipToAddressToAvailableShipToAddresses({shipToAddress: customShipToAddress}));
  }

  setDeliveryDetailsApproverFormData(data: IApproverData): void {
    this.store.dispatch(CheckoutActions.setDeliveryDetailsApproverFormData({deliveryDetailsApproverFormData: data}));
  }

  setTaxNumberFormData(data: string): void {
    this.store.dispatch(CheckoutActions.setTaxNumberFormData({taxNumber: data}));
  }

  setTermsAndConsentsData(data: IConsentCheckbox[]): void {
    this.store.dispatch(CheckoutActions.setTermsAndConsentsData({terms: data}));
  }

  setCheckoutTotalPrices(data: ITotals): void {
    this.store.dispatch(CheckoutActions.setTotalPrices({totalPrices: data}));
  }

  setCheckoutTotalPricesLoading(loading: boolean): void {
    this.store.dispatch(CheckoutActions.setTotalPricesLoading({totalPricesLoading: loading}));
  }

  setCartVouchers(cartVouchers: ICartVoucher[]): void {
    this.store.dispatch(CheckoutActions.setCartVouchers({cartVouchers}));
  }
}
