<div [appLoadingAnimation]="isUserRolesLoading || isCartEmpty" class="container">
  <app-order-details-section
      [ngClass]="isOnContactDetailsSection ? 'hidden' : ''"
      [cartItemsWithDetails]="cartItemsWithDetails"
      [loggedUserRoles]="userRoles"
      [cartRules]="cartRules"
      [cartItems]="cartItems"
      [cartId]="cartId"
      [isCartEmpty]="isCartEmpty"
      [addresses]="addresses"
      [businessAddresses]="businessAddresses"
      [customerAddresses]="customerAddresses"
      [excludeTax]="excludeTax"
      [requestQuoteFeatureActive]="requestQuoteFeatureActive()"
      (formSubmitted)="proceedToContactDetails($event)">
  </app-order-details-section>
  <app-order-contact-details-section
      [isJpStore]="isJpStore"
      [isAuStore]="isAuStore"
      [ngClass]="!isOnContactDetailsSection ? 'hidden' : ''"
      [creatingOrderInProgress]="creatingOrderInProgress"
      [creatingPreviewInProgress]="creatingPreviewInProgress"
      [cartItems]="cartItems"
      [cartItemsWithDetails]="cartItemsWithDetails"
      [cartRules]="cartRules"
      [isCartEmpty]="isCartEmpty"
      [cartId]="cartId"
      [hasApproverAccess]="hasApproverAccess"
      (backToDetailsClicked)="proceedToOrderDetails()"
      (previewSubmitted)="getPreviewQuotePdf()"
      (formSubmitted)="createOrder($event)">
  </app-order-contact-details-section>
</div>
<app-modal
    [open]="showApprovalModal"
    title="{{ 'checkout-delivery-details.approval-success' | translate }}"
    (crossClick)="backToHomepage()"
    (outsideClick)="backToHomepage()">
    <ng-container ngProjectAs="[slot][body]">
      <h5 [innerHTML]="successModalMessage"></h5>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
        <a type="submit" class="button button--primary" data-dismiss="modal-dialog"
           (click)="backToHomepage()">Ok</a>
    </ng-container>
</app-modal>
