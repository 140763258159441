import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from '../../analytics/analytics.service';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { StoreSelectionFacade } from '../../facades/store-selection.facade';
import { UtilsService } from '../../services/utils.service';
import { EFeatureToggles, EUserRoles } from '../../configurations/common';
import { AppUtils } from '../../utils/app.utils';
import { INavigationResponse } from '../../models/settings.model';
import { AuthorizationFacade } from '../../facades/authorization.facade';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  @ViewChild('navigationBarListElem') navigationBarListElem: ElementRef;
  @ViewChild('navigationHamburgerElem') navigationHamburgerElem: ElementRef;

  isGuest: boolean = true;
  navigationBarList: any;
  navigationBarListLoaded = false;
  active = false;
  companyRoles: EUserRoles[];
  isSapStore: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private configFacade: ConfigurationFacade,
    private renderer: Renderer2,
    private analyticsService: AnalyticsService,
    private customerFacade: CustomerFacade,
    private authFacade: AuthorizationFacade,
    private storeSelectionFacade: StoreSelectionFacade,
    private utils: UtilsService,
  ) {
  }

  ngOnInit(): void {
    this.isSapStore = AppUtils.isSapStore();
    this.renderer.listen('window', 'click', (e) => {
      if (!this.navigationBarListElem?.nativeElement.contains(e.target)) {
        this.active = false;
      }
    });
    this.selectCompanyRole();
    this.selectIsGuest();
    this.getNavigationBarList();
    this.utils.getCustomRouteData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectCompanyRole(): void {
    this.customerFacade.getCustomerCompanyRoles()?.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(companyRoles => {
      this.companyRoles = companyRoles;
    });
  }

  /**
   * Check if user is guest. This needs to be called after company roles are loaded in case, user is logged in.
   */
  selectIsGuest(): void {
    this.customerFacade.selectCustomerCompanyRoles().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((roles: EUserRoles[]) => {
      this.isGuest = roles.includes(EUserRoles.Guest);
    });
  }

  /**
   * Proceed logic only in two cases:
   * 1. User is not logged in (login process is not in progress and user is not authenticated)
   * 2. User is logged in and customer data is loaded
   * After this condition, check if user is guest and get navigation bar list based on his role.
   * Also check if harmonization for purchase activity page is enabled.
   */
  getNavigationBarList(): void {
    combineLatest([
      this.customerFacade.selectIsCustomerLoaded(),
      this.authFacade.selectIsLoginInProcess$,
      this.authFacade.isAuthenticated(),
      this.configFacade.isFeatureEnabled(EFeatureToggles.HARMONIZED_PURCHASE_ACTIVITY_PAGE)
    ]).pipe(
      filter(([isCustomerLoaded, isLoginInProcess, isAuthenticated]) =>
        (!isLoginInProcess && !isAuthenticated) || (!isLoginInProcess && isAuthenticated && isCustomerLoaded)),
      takeUntil(this.unsubscribe$)
    ).subscribe(([,,, isHarmonizedPurchaseActivityPageEnabled]) => {
      this.selectIsGuest();
      this.configFacade.getNavigationBar(this.isGuest)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (navigationResponse: INavigationResponse) => {
            const { nodes } = navigationResponse.data.attributes;
            if (nodes && nodes.length > 0) {
              this.navigationBarList = nodes.filter(node => node.isActive);
              this.navigationBarListLoaded = true;
            }
            if (!(this.companyRoles.includes(EUserRoles.Guest))) {
              this.navigationBarList = AppUtils.filterNodesByRole(this.navigationBarList, this.companyRoles);
            }
            if (!this.isSapStore) {
              if (isHarmonizedPurchaseActivityPageEnabled) {
                this.navigationBarList = this.navigationBarList.filter(navItem => !navItem.url.includes('my-orders'));
              } else {
                this.navigationBarList = this.navigationBarList.filter(navItem => !navItem.url.includes('purchase-activity'));
              }
            }
            this.configFacade.navBarLoaded();
          },
          error: () => {
            this.configFacade.navBarLoaded();
          }
        });
    });
  }

  activeDropdown(): void {
    this.active = !this.active;
  }

  tracking(navitem: string): void {
    this.analyticsService.trackNavigation(navitem);
  }

  hideNavigation(): boolean {
    return !!this.utils.customData['hideSearchAndActions'] || this.storeSelectionFacade.isOnStoreSelectionPage();
  }
}
