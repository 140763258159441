import { AdvancedFilter } from '../../models/advanced-filter';

export const purchaseActivityFilters: AdvancedFilter[] = [
  {
    name: 'soldToAddress',
    attribute: 'soldToAddress',
    options: [],
    selected: []
  },
  {
    name: 'shipToAddress',
    attribute: 'shippingAddress',
    options: [],
    selected: []
  },
  {
    name: 'orderDateFrom',
    attribute: 'createdAt',
    options: [],
    selected: []
  },
  {
    name: 'orderDateTo',
    attribute: 'createdAt',
    options: [],
    selected: []
  },
  {
    name: 'orderStatus',
    attribute: 'stateDisplayName',
    options: [],
    selected: []
  },
  {
    name: 'flNumber',
    attribute: 'systemDetails',
    firstAdditionalAttribute: 'siemensEquipmentId',
    options: [],
    selected: []
  },
  {
    name: 'materialNumber',
    attribute: 'items',
    options: [],
    selected: []
  },
  {
    name: 'orderNumber',
    attribute: 'id',
    options: [],
    selected: []
  },
  {
    name: 'purchaseOrder',
    attribute: 'sapPoNumber',
    options: [],
    selected: []
  },
  {
    name: 'rmaNumber',
    attribute: 'sapDetails',
    firstAdditionalAttribute: 'returnAuthorizationNumber',
    options: [],
    selected: []
  }
];
