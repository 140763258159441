import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IConsentCheckbox } from '../../../../../models/checkout.models';

@Component({
  selector: 'app-terms-and-consents',
  templateUrl: './terms-and-consents.component.html',
  styleUrl: './terms-and-consents.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class TermsAndConsentsComponent {
  @Input() termsAndConsentsCheckboxes: IConsentCheckbox[];
}
