import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IArchiveOrder } from '../models/order.models';
import { HybrisOrdersActions } from '../actions';

export interface HybrisOrdersState {
  hybrisOrders: IArchiveOrder[]
}

export const initialState: HybrisOrdersState = {
  hybrisOrders: null
};

export const hybrisOrdersBaseState = createFeatureSelector<HybrisOrdersState>('hybrisOrders');

export const selectHybrisOrders = createSelector(
  hybrisOrdersBaseState,
  state => state.hybrisOrders
);

export const selectHybrisOrderById = (id: string) => createSelector(
  hybrisOrdersBaseState,
  state => state.hybrisOrders?.find(order => order?.id === id)
);

export const HybrisOrdersReducer = createReducer(
  initialState,
  on(HybrisOrdersActions.retrieveHybrisOrdersSuccess, (state: HybrisOrdersState, {hybrisOrders}) => {
    return {
      ...state,
      hybrisOrders: hybrisOrders
    };
  })
);
