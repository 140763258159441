import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CatalogSearch } from '../models/catalog.models';
import { catchError } from 'rxjs/operators';
import { GlueConfigurationService } from './glue-configuration.service';
import {
  IArchiveOrderResponse,
  IOrderById,
  IOrders,
  IOrderSearchFilter,
  IPendingOrder,
  ICartsApprovalResponse,
} from '../models/order.models';
import { EGlueResource, EQueryParams } from '../configurations/common';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private mainUrl = this.glueConfiguration.getEndpointUrl();
  private cartsUrl = '/carts';
  private quoteRequestsUrl = '/quote-requests';

  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private static handleOrderError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getCarts(): Observable<any> {
    return this.http.get<CatalogSearch>(`${this.mainUrl}/carts`).pipe(
      catchError(OrdersService.handleOrderError),
    );
  }

  getCartsApproval(): Observable<ICartsApprovalResponse> {
    return this.http.get<IPendingOrder[]>(`${this.mainUrl}/carts-approval`).pipe(
      catchError(OrdersService.handleOrderError),
    );
  }

  getCartForApprovalData(id: string): Observable<any> {
    const httpParamsForGetCartItems = {
      params: new HttpParams().set(
        'include',
        'items,concrete-products,concrete-product-image-sets,vouchers',
      ),
    };

    return this.http.get<any>(`${this.mainUrl}/carts-approval/${id}`, httpParamsForGetCartItems).pipe(
      catchError(OrdersService.handleOrderError),
    );
  }

  getOrderById(id: string): Observable<IOrderById> {
    return this.http.get<IOrderById>(`${this.mainUrl}/orders/${id}`).pipe(
      catchError(OrdersService.handleOrderError),
    );
  }

  getHybrisOrderById(id: string): Observable<IArchiveOrderResponse> {
    return this.http.get<IArchiveOrderResponse>(`${this.mainUrl}/customer-orders-history/${id}`).pipe(
      catchError(OrdersService.handleOrderError),
    );
  }

  postDeclinePendingOrder(data: any): Observable<any> {
    return this.http.post<any>(`${this.mainUrl}/quote-decline`, data).pipe(
      catchError(OrdersService.handleOrderError));
  }

  deleteCart(cartId: string): Observable<any> {
    return this.http.delete<any>(`${this.mainUrl}${this.cartsUrl}/${cartId}`).pipe(
      catchError(OrdersService.handleOrderError));
  }

  getOrders(
    offset: number                           = undefined,
    limit: number                            = undefined,
    orderFilter: Partial<IOrderSearchFilter> = {},
  ): Observable<any> {
    let httpParams = new HttpParams();

    if (orderFilter.includeApproverOrders) {
      httpParams = httpParams
        .set('orderFilter[includeApproverOrders]', true);
    }

    if (orderFilter.isFinalized !== undefined) {
      httpParams = httpParams.set('orderFilter[isFinalized]', orderFilter.isFinalized);
    }

    if (orderFilter.workflow) {
      httpParams = httpParams.set('orderFilter[workflow]', orderFilter.workflow);
    }

    if ((offset === 0 || offset) && limit) {
      httpParams = httpParams
        .set(EQueryParams.OFFSET, offset)
        .set(EQueryParams.LIMIT, limit);
    }

    httpParams = httpParams.set(EQueryParams.SORT, '-created_at'); // minus sign means DESC direction (newest first)

    return this.http.get<IOrders>(`${this.mainUrl}/orders`, {params: httpParams});
  }

  getArchiveOrders(): Observable<IArchiveOrderResponse> {
    return this.http.get<IArchiveOrderResponse>(`${this.mainUrl}/customer-orders-history`).pipe(
      catchError(OrdersService.handleOrderError),
    );
  }

  deleteItemFromOrder(cartId: any, itemId: any): Observable<any> {
    const url = `${this.mainUrl + this.cartsUrl}/${cartId}/items/${itemId}`;
    return this.http.delete<any>(url).pipe(
      catchError(OrdersService.handleOrderError),
    );
  }

  postCancelOrder(_orderId: string, data: any): Observable<any> {
    const url = `${this.mainUrl}/orders-cancel`;
    return this.http.post<any>(url, data).pipe(
      catchError(OrdersService.handleOrderError));
  }

  getCartDetailPdfFile(cartId: string): Observable<any> {
    return this.http.get(this.mainUrl + `/carts/${cartId}/${EGlueResource.CARTS_EXPORT_PDF}`, {
      responseType: 'blob',
      observe: 'response',
    }).pipe(catchError(OrdersService.handleOrderError));
  }

  getCartGenerateQuotePdfFile(cartId: string, isPreview: boolean): Observable<any> {
    return this.http.get(
      this.mainUrl + `/carts/${cartId}/generate-quote`,
      {
        responseType: 'blob',
        params: new HttpParams().set(
          'preview',
          isPreview ? 1 : 0,
        ),
        observe: 'response',
      },
    ).pipe(catchError(OrdersService.handleOrderError));
  }

  getOrderDetailPdfFile(orderReference: string): Observable<any> {
    return this.http.get(`${this.mainUrl}/orders/${orderReference}/${EGlueResource.CARTS_EXPORT_PDF}`, {
      responseType: 'blob',
      observe: 'response',
    }).pipe(catchError(OrdersService.handleOrderError));
  }

  postOrdersHistoryExcelFile(activeOrdersReferences: string[]): Observable<any> {
    const body = {
      'data': {
        'type': 'orders-export-to-excel',
        'attributes': {
          'orderReferences': activeOrdersReferences,
        },
      },
    };
    return this.http.post<any>(`${this.mainUrl}/orders-export-to-excel`, body, {
      responseType: 'blob' as 'json',
      observe: 'response',
    }).pipe(catchError(OrdersService.handleOrderError));
  }

  postHybrisOrdersHistoryExcelFile(activeOrdersReferences: string[]): Observable<any> {
    const body = {
      'data': {
        'type': 'customer-orders-history-export-to-excel',
        'attributes': {
          'orderReferences': activeOrdersReferences,
        },
      },
    };
    return this.http.post<any>(`${this.mainUrl}/customer-orders-history-export-to-excel`, body, {
      responseType: 'blob' as 'json',
      observe: 'response',
    }).pipe(catchError(OrdersService.handleOrderError));
  }

  getQuoteHistoryExcelFile(activeQuoteReferences: string[]): Observable<any> {
    return this.http.get<any>(`${this.mainUrl + this.quoteRequestsUrl}/export/excel/?quoteReferences=` + activeQuoteReferences.join(','), {
      responseType: 'blob' as 'json',
      observe: 'response',
    }).pipe(catchError(OrdersService.handleOrderError));
  }
}
