import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, skipWhile, Subject, takeWhile } from 'rxjs';

import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { OrdersFacade } from '../../facades/orders.facade';
import { IOrderHistory, IPendingOrder } from '../../models/order.models';
import { ObjectUtils } from '../../utils/object.utils';
import { CustomerFacade } from '../../facades/customer.facade';
import { EUserRoles, HospitalOrdersTabs } from '../../configurations/common';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { IRfqBaseInfo } from '../../models/rfq.models';
import { CartUtils } from '../../utils/cart.utils';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { StoreConfigurationFacade } from '../../facades/store-configuration.facade';
import { IOrdersHistoryPage } from '../../reducers/orders.reducer';
import { ICustomerAccessControlData } from '../../models/customer.models';

@Component({
  selector: 'app-page-my-orders',
  templateUrl: './page-my-orders.component.html',
  styleUrls: ['./page-my-orders.component.scss'],
})
export class PageMyOrdersComponent implements OnInit, OnDestroy {
  pendingOrdersLoading: boolean= true;
  pendingOrders: IPendingOrder[] = [] as IPendingOrder[];
  ordersHistoryList: IOrderHistory[] = [] as IOrderHistory[];
  originalOrdersHistoryList: IOrderHistory[];
  previousRequestsAll: IRfqBaseInfo[] = [] as IRfqBaseInfo[];
  previousRequests: IRfqBaseInfo[] = [] as IRfqBaseInfo[];
  previousQuotes: IRfqBaseInfo[] = [] as IRfqBaseInfo[];
  orderHistoryListLoading: boolean = true;
  orderHistoryListAtPageLoading: boolean = true;
  onlyMineOrders: boolean = false;
  searchPreviousOrder: string;
  searchPreviousRequest: string;
  searchPreviousQuotes: string;
  companyRoles: EUserRoles[];
  userId: string;
  userNameKeyFromAuth: string = 'https://login-shs.siemens-healthineers.com/given_name';
  userLastNameKeyFromAuth: string = 'https://login-shs.siemens-healthineers.com/family_name';
  userEmailKeyFromAuth: string = 'https://login-shs.siemens-healthineers.com/email';
  isGuest: boolean;
  filteringHistoryInProgress: boolean = false;
  userName: string;
  userLastName: string;
  userEmail: string;
  previousRequestsLoading: boolean = true;
  HospitalOrderTabs = HospitalOrdersTabs;
  currentTab: string = '';
  ordersCurrentPage: number = 1;
  ordersNumPages: number = 1;
  openedTabsForPendingOrders: Array<string> = [];
  isRfqOnly: boolean = true;
  isAnyOrderTab: boolean = false;
  customerState$: Observable<ICustomerAccessControlData> = new Observable<ICustomerAccessControlData>();
  hospitalOrdersTabsMap: Map<string, boolean> = new Map<string, boolean>(
    Object.values(HospitalOrdersTabs).map((tabKey) => [tabKey, false]),
  );
  // originalOrderOfTabs -> only for not sorting items when using keyvalue pipe
  originalOrderOfTabs = (): number => {
    return 0;
  }

  private route: ActivatedRoute;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private ordersFacade: OrdersFacade,
    private analyticsService: AnalyticsService,
    private customerFacade: CustomerFacade,
    private checkoutFacade: CheckoutFacade,
    private storeConfigurationFacade: StoreConfigurationFacade,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.customerState$ = this.customerFacade.selectAccessControlData();
    this.initialOrdersDataLoading();
    this.retrieveRequestsHistory();
    this.selectUserId();
    this.selectUserData();
    this.analyticsResetProducts();
    this.retrieveIsRfq();
    this.resolveCurrentTab();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  retrieveIsRfq(): void {
    this.storeConfigurationFacade.selectIsRfqOnly().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isRfqOnly = state;
    });
  }

  changeTab(tab: string): void {
    this.currentTab = tab;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {tab: tab},
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  selectUserId(): void{
    this.customerFacade.selectUserId().pipe(takeUntil(this.unsubscribe$)).subscribe(state => {
      this.userId = state;
    });
  }

  selectUserData(): void {
    this.customerFacade.getAuth0CustomerFromStore().pipe(takeUntil(this.unsubscribe$)).subscribe(userData => {
      if (userData) {
        this.userName = userData[this.userNameKeyFromAuth];
        this.userLastName = userData[this.userLastNameKeyFromAuth];
        this.userEmail = userData[this.userEmailKeyFromAuth];
      }
    });
  }

  isApprover(): boolean {
    return this.companyRoles.includes(EUserRoles.Approver);
  }

  isBuyer(): boolean {
    return this.companyRoles.includes(EUserRoles.Buyer);
  }

  checkRolesForAccess(): boolean {
    return this.companyRoles.includes(EUserRoles.Admin) || this.companyRoles.includes(EUserRoles.Approver);
  }

  retrievePendingCarts(reloadedAfterChanges: boolean = false): void {
    this.ordersFacade.selectPendingCarts().pipe(
      skipWhile(res => res === undefined),
      takeUntil(this.unsubscribe$))
      .subscribe( {
        next: (pendingOrders: IPendingOrder[]) => {
          this.loadCartData(pendingOrders, !reloadedAfterChanges);
          reloadedAfterChanges = false;
          if (this.checkRolesForAccess()) {
            const currentApproverPendingOrder =
                    this.pendingOrders.filter((order) => this.userId === order.attributes.approverDetails.approverId);
            this.pendingOrders.unshift(...currentApproverPendingOrder);
            this.pendingOrders = Array.from(new Set(this.pendingOrders));
          }
          if (this.pendingOrders.length > 0) {
            this.hospitalOrdersTabsMap.set(this.HospitalOrderTabs.PENDING_ORDERS, true);
            this.isAnyOrderTab = true;
          } else if (this.hospitalOrdersTabsMap.get(this.HospitalOrderTabs.PENDING_ORDERS)) {
            this.hospitalOrdersTabsMap.set(this.HospitalOrderTabs.PENDING_ORDERS, false);
            this.changeTab(this.isRfqOnly ? HospitalOrdersTabs.PREVIOUS_QUOTES : HospitalOrdersTabs.PREVIOUS_ORDERS);
          }
        },
        error: err => {
          //to be added later
        }
      });
  }

  retrieveRequestsHistory(): void {
    this.checkoutFacade.selectQuoteHistory().pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (!data) {
        return;
      }
      this.previousRequests = [];
      this.previousQuotes = [];
      this.previousRequestsAll = ObjectUtils.deepClone(data as IRfqBaseInfo[]);
      for (const previousReq of this.previousRequestsAll) {
        previousReq.itemsTotalNumber = this.selectTotalQuantityForRfqItems(previousReq);
      }
      this.sortByDate(this.previousRequestsAll);

      this.previousRequestsAll.forEach(requests => {
        if (requests?.attributes) {
          if (CartUtils.isSetPricesForAllItems(requests.attributes.shownVersion.cart.items)) {
            this.previousQuotes.push(requests);
          } else {
            this.previousRequests.push(requests);
          }
        }
      });
      if (this.previousQuotes.length > 0) {
        this.hospitalOrdersTabsMap.set(this.HospitalOrderTabs.PREVIOUS_QUOTES, true);
        this.isAnyOrderTab = true;
      }
      if (this.previousRequests.length > 0) {
        this.hospitalOrdersTabsMap.set(this.HospitalOrderTabs.PREVIOUS_REQUESTS, true);
        this.isAnyOrderTab = true;
      }
      this.previousRequestsLoading = false;
    });
  }

  goToOrdersHistoryPage(pageNum: number): void {
    this.orderHistoryListAtPageLoading = true;
    this.ordersCurrentPage = pageNum;
    this.ordersFacade.selectOrdersHistoryAtPage(pageNum).pipe(
      skipWhile(ordersHistoryPage => !ordersHistoryPage.numPages),
      take(1),
    ).subscribe((ordersHistoryPage) => {
      if (ordersHistoryPage.pageLoaded) {
        this.updateOrdersHistory(ordersHistoryPage);
      } else if (pageNum !== 1) {
        this.triggerPageLoad(pageNum, ordersHistoryPage.limitPerPage);
      }
    });
  }

  private triggerPageLoad(pageNum: number, limit: number): void {
    const offset = limit ? (pageNum - 1) * limit : undefined;
    this.ordersFacade.loadOrdersHistory(offset, limit);
    this.ordersFacade.selectOrdersHistoryAtPage(pageNum).pipe(
      skipWhile(ordersHistoryPage => !ordersHistoryPage.pageLoaded),
      take(1),
    ).subscribe(ordersHistoryPage => {
      this.updateOrdersHistory(ordersHistoryPage);
    });
  }

  updateOrdersHistory(ordersHistoryPage: IOrdersHistoryPage): void {
    if (!ordersHistoryPage.orders) {
      return;
    }

    if (this.isApprover) {
      this.ordersHistoryList = [...ordersHistoryPage.orders];
    } else {
      this.ordersHistoryList = this.filterOrdersHistory(ordersHistoryPage.orders) as IOrderHistory[];
    }

    this.ordersHistoryList = this.ordersHistoryList.reduce((orders, order) => {
      orders.push({
        ...order,
        attributes: {
          ...order.attributes,
          stateDisplayName: order.attributes.stateDisplayName === 'new' ? 'approved' : order.attributes.stateDisplayName,
        },
      });
      return orders;
    }, []);
    this.originalOrdersHistoryList = this.ordersHistoryList;
    this.orderHistoryListLoading = false;
    this.orderHistoryListAtPageLoading = false;

    if (this.ordersHistoryList.length > 0) {
      this.hospitalOrdersTabsMap.set(this.HospitalOrderTabs.PREVIOUS_ORDERS, true);
      this.isAnyOrderTab = true;
    }

    this.ordersNumPages = ordersHistoryPage.numPages;
  }

  sortByDate(orderArray: IPendingOrder[] | IOrderHistory[] | IRfqBaseInfo[]): void {
    if (orderArray.length > 0) {
      orderArray.sort((a, b) =>
        new Date(b.attributes.createdAt).getTime() - new Date(a.attributes.createdAt).getTime());
    }
  }

  retrievePendingOrderOpenedTabId(openedTabId: string): void {
    const exists = this.openedTabsForPendingOrders.findIndex(tab => tab === openedTabId);
    if (exists !== -1) {
      this.openedTabsForPendingOrders.splice(exists, 1);
    } else {
      this.openedTabsForPendingOrders.push(openedTabId);
    }
  }

  selectOnlyUserOrders(): void {
    this.filteringHistoryInProgress = true;
    this.onlyMineOrders = !this.onlyMineOrders;
    if (this.onlyMineOrders) {
      this.ordersHistoryList = this.filterOrdersHistory(this.originalOrdersHistoryList);
    } else {
      this.ordersHistoryList = this.originalOrdersHistoryList;
    }
    this.filteringHistoryInProgress = false;
  }

  filterOrdersHistory(orders: Array<any>): Array<any> {
    const filteredOrders = orders.filter(
      order =>
        order.attributes.customer.firstName === this.userName && order.attributes.customer.lastName === this.userLastName,
    );

    return filteredOrders ? filteredOrders : [];
  }

  showOrdersOrRequests(): boolean {
    return !this.orderHistoryListLoading
      && !this.previousRequestsLoading
      && !this.pendingOrdersLoading
      && this.ordersHistoryList.length === 0
      && this.pendingOrders.length === 0
      && this.previousRequestsAll.length === 0;
  }

  showInitialLoader(): boolean {
    return this.orderHistoryListLoading || this.pendingOrdersLoading || this.previousRequestsLoading;
  }

  selectTotalQuantityForRfqItems(rfqBaseInfo: IRfqBaseInfo): number {
    let itemTotalCount = 0;
    if (rfqBaseInfo.attributes.shownVersion.cart.items) {
      let items = rfqBaseInfo.attributes.shownVersion.cart.items;
      if (!Array.isArray(items)) {
        // safety check as some RfQs are returned with items as an object of items
        // indexed by numbers, e.g. {"1": item1, "2": item2}
        items = Object.values(items);
      }
      for (const rfqItem of items) {
        itemTotalCount += rfqItem.quantity;
      }
    }
    return itemTotalCount;
  }

  initialOrdersDataLoading(): void {
    this.pendingOrdersLoading = true;
    this.customerFacade.getMyOrderPageData().pipe(take(1)).subscribe(state => {
      this.isGuest = state.isGuest;
      this.companyRoles = state.companyRoles;
      if (!this.isGuest) {
        if (this.checkRolesForAccess()) {
          this.ordersFacade.loadCartsApproval();
        } else {
          this.ordersFacade.loadCarts();
        }
        this.ordersFacade.loadOrdersHistory();
        // TODO: remove after harmonization
        this.checkoutFacade.loadQuoteHistory();
        this.ordersFacade.loadCartsApproval();
        this.ordersFacade.loadPreviousQuotesAndRequests();
        this.retrievePendingCarts();
        this.goToOrdersHistoryPage(1);
      }
    });
  }

  reloadDataAfterDeletingItemFromPendingOrder(): void {
    this.customerFacade.getMyOrderPageData().pipe(take(1)).subscribe(state => {
      this.isGuest = state.isGuest;
      this.companyRoles = state.companyRoles;
      if (!this.isGuest) {
        if (this.checkRolesForAccess()) {
          this.ordersFacade.loadCartsApproval();
        } else {
          this.ordersFacade.loadCarts();
        }
        this.retrievePendingCarts(true);
        this.ordersFacade.loadOrdersHistory(undefined, undefined, true);
        this.goToOrdersHistoryPage(1)
      }
    });
  }

  private loadCartData(data: any, stopLoading = true): void {
    if (stopLoading) {
      this.pendingOrdersLoading = false;
    }

    this.pendingOrders = data?.filter(cart => cart.attributes.approverDetails?.status === 'waiting') as IPendingOrder[];
    //buyer can not see orders created by another buyer
    if (this.isBuyer()) {
      this.pendingOrders = this.pendingOrders.filter(order => order.attributes.customer.email === this.userEmail);
    }
    this.sortByDate(this.pendingOrders);

    this.analyticsService.trackPageReady('Orders', PageTypes.ORDER_APPROVAL_CONFIRMATION_PAGE);
  }

  private analyticsResetProducts(): void {
    this.analyticsService.setProducts(null);
    this.analyticsService.setCartId(null);
  }

  private resolveCurrentTab(): void {
    this.activatedRoute.queryParams.pipe(
      takeWhile(() => this.currentTab === ''),
    ).subscribe((params) => {
      let resolvedTab: string;
      const selectedTab = params['tab'];
      if (selectedTab && Object.values(HospitalOrdersTabs).includes(selectedTab)) {
        resolvedTab = selectedTab;
      } else {
        resolvedTab = this.isRfqOnly ? HospitalOrdersTabs.PREVIOUS_QUOTES : HospitalOrdersTabs.PREVIOUS_ORDERS;
      }
      this.changeTab(resolvedTab);
    });
  }
}
