import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, skipWhile, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { CustomerFacade } from '../../../facades/customer.facade';
import { IAddress, ISelectEvent } from '../../../models/common.models';
import { I18nService } from '../../../services';
import { notFoundValue, notInstallBaseRelated } from '../../../shared/ivk-selection-form/IvkSelectionFormConstants';
import { CustomerCurrencyPipe } from '../../../shared/pipes/customer-currency.pipe';
import { AddressUtils } from '../../../utils/address.utils';
import { AppUtils } from '../../../utils/app.utils';
import { ArrayUtils } from '../../../utils/array.utils';
import { CartUtils } from '../../../utils/cart.utils';
import { InstallBaseFacade } from '../../../facades/install-base.facade';
import { EDeliveryInputNames, EUserRoles } from '../../../configurations/common';
import { IShsEquipmentData } from '../../../models/installedbase.models';
import { EquipmentUtils } from '../../../utils/equipment.utils';
import { IAddressReduced } from '../../../models/customer.models';
import { IBaseConfig } from '../../../models/enviroment-delivery-details.model';
import { ConfigurationFacade } from '../../../facades/configuration.facade';

@Component({
  selector: 'app-request-details-section',
  templateUrl: 'request-details-section.component.html',
  styleUrls: ['request-details-section.component.scss'],
})
export class RequestDetailsSectionComponent implements OnInit, OnDestroy {
  @Input() loggedUserRoles: EUserRoles[];
  @Input() cartItems;
  @Input() isCartEmpty;
  @Input() cartId;
  @Input() isRfqOnly;
  @Input() minimumOrderValue;
  @Input() currency;
  @Input() currentCart;

  @Output() formSubmitted = new EventEmitter<any>();

  customerAddresses: any;
  businessAddresses: any;
  ivkForm: UntypedFormGroup;
  deliveryForm: UntypedFormGroup;
  businessUnitLoading = false;
  businessAddressesLoading = false;
  systems = [];
  businessUnits = [] as Array<{name: string, value: string}>;
  addresses: Array<{name: string, value: any, addressFromSystem: boolean}> = [];
  formsAreValid = false;
  showModalAddressWrong = false;
  showModalAddress = false;
  showPriceDisclaimer = false;
  additionalItemLevelFields = [] as Array<{
    name: string,
    getValFunc: (item: any) => string,
    shouldDisplayFunc: (item: any) => boolean
  }>;
  installBaseLoading: boolean = false;
  preferredShipToAddress: any = null;
  allAddressesLoaded: boolean = false;
  requiredInputs: Array<string>;
  visibilityOfInputs: Array<string>;
  departments: IBaseConfig[];
  inputsEnum: {[key: string]: string};

  private itemFormMap = new Map();
  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private i18nService: I18nService,
    private catalogFacade: CatalogFacade,
    private customerFacade: CustomerFacade,
    private installBaseFacade: InstallBaseFacade,
    private customerCurrencyPipe: CustomerCurrencyPipe,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.inputsEnum = EDeliveryInputNames;
    this.requiredInputs = this.configurationFacade.getFeatureToggleArrayValues('checkout_page_required_inputs');
    this.visibilityOfInputs = this.configurationFacade.getFeatureToggleArrayValues('delivery_details_1_visibility_of_inputs');

    this.getDepartments();
    this.initializeForm();

    this.getNotFoundLabels();
    this.beginGetBusinessUnitsAction();

    this.selectInstallBaseProducts();
    this.selectBusinessUnitLoading();
    this.selectBusinessAddresses();
    this.selectBusinessUnits();
    this.selectCustomerAddresses();
    this.selectPreferredShipToAddress();

    this.showPriceDisclaimer = AppUtils.getCurrentStore().showPriceDisclaimer;

    this.prepareAdditionalFields();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeForm(): void {
    this.deliveryForm = this.formBuilder.group({
      deliveryAddress: ['', [Validators.required]],
      department: ['', (this.requiredInputs?.includes(EDeliveryInputNames?.DEPARTMENT) && this.visibilityOfInputs?.includes(EDeliveryInputNames?.DEPARTMENT)) ? [Validators.required] : []],
      floorOrRoom: ['', (this.requiredInputs?.includes(EDeliveryInputNames?.FLOOR_OR_ROOM) && this.visibilityOfInputs?.includes(EDeliveryInputNames?.FLOOR_OR_ROOM)) ? [Validators.required] : []],
    });

    this.revalidateForms();
  }

  ivkFormChanged(form: UntypedFormGroup): void {
    this.ivkForm = form;

    this.revalidateForms();
  }

  systemIdValueChanged(event: ISelectEvent): void {
    let addressData = null;
    if (event.additional?.shipToAddress) {
      const addressFromSystemIndex = this.addresses.findIndex(address => address.addressFromSystem);
      addressData = {
        name: AddressUtils.createAddressString(event.additional.shipToAddress),
        value: {
          ...event.additional.shipToAddress,
          idCompanyUnitAddress: null,
          idCustomerAddress: null,
        },
        addressFromSystem: true,
      };

      if (AddressUtils.isAddressValid(addressData?.value)) {
        if (addressFromSystemIndex === -1) {
          this.addresses.push(addressData);
        } else {
          this.addresses[addressFromSystemIndex] = addressData;
        }
      }
    }

    if (addressData && AddressUtils.isAddressValid(addressData?.value) && !this.preferredShipToAddress) {
      this.deliveryForm.patchValue({
        deliveryAddress: addressData,
      });
    }

    this.revalidateForms();
  }

  /**
   * Method for setting delivery address form field.
   *
   * @param {ISelectEvent} event
   */
  setDeliveryAddress(event: ISelectEvent): void {
    this.deliveryForm.patchValue({
      deliveryAddress: event,
    });

    this.revalidateForms();
  }

  showModalNewAddress(): void {
    this.showModalAddress = true;
  }

  showModalWrongAddress(): void {
    this.showModalAddressWrong = true;
  }

  itemFormChanged(itemForm: any): void {
    this.itemFormMap.set(itemForm.itemId, itemForm.formGroup);

    this.revalidateForms();
  }

  itemFormHidden(itemId: any): void {
    this.itemFormMap.delete(itemId);

    this.revalidateForms();
  }

  revalidateForms(): void {
    this.formsAreValid = this.isFormValid(this.ivkForm) &&
      this.isFormValid(this.deliveryForm) &&
      this.areItemFormsValid();
  }

  proceedToNextStep(): void {
    const formData = {
      deliveryAddress: this.deliveryForm.value.deliveryAddress?.value,
      systemDetails: EquipmentUtils.convertIvkSelectionToSystemDetails(this.ivkForm.value),
      floorOrRoom: this.deliveryForm.value.floorOrRoom,
      department: this.deliveryForm.value.department,
      systemDetailsPerItem: [],
    };

    for (const [id, form] of this.itemFormMap) {
      formData.systemDetailsPerItem.push({
        itemId: id,
        systemDetails: EquipmentUtils.convertIvkSelectionToSystemDetails(form.value),
      });
    }

    this.formSubmitted.emit(formData);
  }

  backToCart(): void {
    this.router.navigate(['/shop-cart']).then();
  }

  isFormValid(form: UntypedFormGroup): boolean {
    return form?.status === 'VALID';
  }

  selectAddressesFromInstallBase(sortedSystems: any[]): any[] {
    return ArrayUtils.removeDuplicates(sortedSystems
      .map(installBase => ({...installBase?.attributes?.shipToAddress}))).filter((el) => {
      return el.address1 != null;
    });
  }

  /**
   * This method get all departments.
   */
  getDepartments(): void {
    this.configurationFacade.getTranslationByKey(['departments'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.departments = Object.values(data['departments']).map((department: string) => {
          return {
            name: department,
            value: department,
          };
        });
      });
  }

  /**
   * This method set new value to the form on change.
   * @param {ISelectEvent} event
   */
  setFormValue(event: ISelectEvent): void {
    this.deliveryForm.patchValue({
      [event.key]: event.value,
    });
    this.revalidateForms();
  }

  getTotalPrice(): number {
    return CartUtils.getTotalPrice(this.isCartEmpty, this.currentCart);
  }

  getPageTitle(): string {
    return this.isRfqOnly && this.isSetPricesForAllItems()
      ? 'request-quote.page-title-rfq-only'
      : 'request-quote.page-title';
  }

  isSetPricesForAllItems(): boolean {
    return CartUtils.isSetPricesForAllItems(this.cartItems);
  }

  isMinimumOrderValue(): boolean {
    return CartUtils.getIsMinimumOrderValue(this.minimumOrderValue, this.isCartEmpty, this.currentCart, this.cartItems);
  }

  private getNotFoundLabels(): void {
    this.i18nService.getTranslationByKey([
      'request-details.system-not-found',
      'request-details.unit-not-found',
      'request-details.item-not-install-base-related',
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(labels => {
      this.systems.unshift({value: notFoundValue, name: labels['request-details.system-not-found']});
      this.systems.unshift({
        value: notInstallBaseRelated,
        name: labels['request-details.item-not-install-base-related'],
      });
      this.businessUnits.unshift({value: notFoundValue, name: labels['request-details.unit-not-found']});
    });
  }

  private beginGetBusinessUnitsAction(): void {
    this.customerFacade.beginGetBusinessUnitsAction();
  }

  private selectInstallBaseProducts(): void {
    this.installBaseFacade.selectInstalledBaseSystemData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data: IShsEquipmentData[]) => {
          if (data?.length) {
            const sortedSystems = ArrayUtils.sortByAttribute([...data], 'attributes.materialName');
            this.systems = this.systems.concat(sortedSystems.map((installBase: IShsEquipmentData) => {
              return {
                name: installBase.attributes.materialName
                  + (installBase.attributes.siemensEquipmentId
                      ? ` (${installBase.attributes.siemensEquipmentId})`
                      : ''
                  ),
                value: installBase.attributes.siemensEquipmentId,
                additional: {...installBase.attributes},
              };
            }));
            this.businessUnits = this.businessUnits.concat(sortedSystems.reduce((acc, installBase) => {
              if (installBase?.attributes?.companyBusinessUnit && installBase.attributes.companyBusinessUnitNumber) {
                acc.push({
                  name: installBase.attributes.companyBusinessUnit,
                  value: installBase.attributes.companyBusinessUnitNumber,
                  additional: {
                    institutionName: installBase.attributes.company,
                  },
                });
              }
              return acc;
            }, []));

            AddressUtils.appendAddresses(
              this.addresses,
              this.selectAddressesFromInstallBase(sortedSystems),
              'business',
              true,
            );

            this.prepareBusinessUnits();
          }
        }, error: () => {
        },
      });
  }

  private selectBusinessUnitLoading(): void {
    this.customerFacade.selectBusinessUnitLoading().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isLoading => {
      this.businessUnitLoading = isLoading;
    });
  }

  selectBusinessUnits(): void {
    this.customerFacade.selectBusinessUnits().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(units => {
      if (units) {
        this.businessUnits = this.businessUnits.concat(units.map(unit => {
          return {
            name: unit.name,
            value: unit.businessUnitNumber,
            additional: {
              institutionName: unit.institutionName,
            },
          };
        }));
        this.prepareBusinessUnits();
      }
    });
  }

  private selectBusinessAddresses(): void {
    this.businessAddressesLoading = true;
    this.customerFacade.selectBusinessAddress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
        next: addresses => {
          if (addresses) {
            this.businessAddresses = addresses;
            AddressUtils.appendAddresses(this.addresses, this.businessAddresses, 'business');
            this.businessAddressesLoading = false;
          }
        },
        error: () => {
          this.businessAddressesLoading = false;
        },
      },
    );
  }

  private selectCustomerAddresses(): void {
    this.customerFacade.selectCustomerAddresses().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addresses => {
      if (addresses) {
        this.customerAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, this.customerAddresses, 'customer');
      }
    });
  }

  private prepareBusinessUnits(): void {
    this.businessUnits = ArrayUtils.removeDuplicates(this.businessUnits);
    // Skip first unit as it is not found option
    const sortedUnits = ArrayUtils.sortByAttribute(this.businessUnits.slice(1, this.businessUnits.length), 'name');
    this.businessUnits = [this.businessUnits[0], ...sortedUnits];
  }

  private areItemFormsValid(): boolean {
    for (const form of this.itemFormMap.values()) {
      if (form.status === 'INVALID') {
        return false;
      }
    }

    return true;
  }

  private prepareAdditionalFields(): void {
    this.additionalItemLevelFields = [{
      name: 'shop-cart.quantity',
      getValFunc: (item) => item.attributes.quantity,
      shouldDisplayFunc: (_) => true,
    }];
    if (this.isRfqOnly && this.isSetPricesForAllItems()) {
      this.additionalItemLevelFields.push({
        name: 'shop-cart.item-price',
        getValFunc: (item) => this.customerCurrencyPipe.transform(
          item.attributes.calculations.unitPrice,
          this.currency,
        ),
        shouldDisplayFunc: (item) => !!item.attributes.calculations.unitPrice,
      });
    }
  }

  /**
   * Method for preselecting delivery address if it is set as a preferred shipTo address or if it is the only address.
   * The preselection should start only when all types of addresses have already been loaded.
   *
   * Different types of addresses:
   * - customer addresses
   * - business addresses
   * - tpF addresses
   *
   * @private
   */
  private selectPreferredShipToAddress(): void {
    combineLatest([
      this.customerFacade.selectCustomerAddresses(),
      this.customerFacade.selectBusinessAddress(),
      this.installBaseFacade.selectInstalledBaseSystemData(),
    ]).pipe(
      skipWhile(([customerAddresses, businessAddresses, installBaseSystems]) => {
        return !customerAddresses || !businessAddresses || !installBaseSystems;
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.customerFacade.selectCustomerPreferences().pipe(
        skipWhile(customerPreferences => !customerPreferences),
        takeUntil(this.unsubscribe$),
      ).subscribe(customerPreferences => {
        this.addresses.forEach(address => {
          if (address.value.sapId === customerPreferences.preferredShipToId
            || address.value.id === customerPreferences.preferredShipToId) {
            this.preferredShipToAddress = address
          }
        });

        if (this.preferredShipToAddress) {
          this.deliveryForm.patchValue({deliveryAddress: this.preferredShipToAddress});
        }
        if (this.addresses.length === 1) {
          this.deliveryForm.patchValue({deliveryAddress: this.addresses[0]});
        }

        this.allAddressesLoaded = true;
        this.revalidateForms();
      });
    });
  }

  /**
   * Return the address displayed in deliveryForm if exists, otherwise return the default address.
   * Set resetAddressesList as true for Report Wrong Address modal.
   * Set address id as bussinessAddress id for  Report Wrong Address modal.
   *
   * @returns {IAddress[]|IAddressReduced[]}
   */
  getAddressesListReportWrongAddress(): IAddress[] | IAddressReduced[] {
    if (this.deliveryForm.value.deliveryAddress.value) {
      this.deliveryForm.value.deliveryAddress.value.id = this.businessAddresses[0].id;
    }
    return this.deliveryForm.value.deliveryAddress.value ?
      [this.deliveryForm.value.deliveryAddress.value] : this.businessAddresses;
  }
}
