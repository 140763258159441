import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { ApplicationBarComponent } from './application-bar/application-bar.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { I18nModule } from '../i18n/i18n.module';
import { PaginationComponent } from './pagination/pagination.component';
import { MiniCartComponent } from './mini-cart/mini-cart.component';
import { ConfigurableMiniCartComponent } from './mini-cart/configurable-mini-cart/configurable-mini-cart.component';
import { MultiMiniCartComponent } from './mini-cart/multi-mini-cart/multi-mini-cart.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SortByComponent } from './sort-by/sort-by.component';
import { EquipmentFilterComponent } from './equipment-filter/equipment-filter.component';
import { FiltersComponent } from './filters/filters.component';
import { MarkedFiltersComponent } from './marked-filters/marked-filters.component';
import { AlertComponent } from './alert/alert.component';
import { AccessControlComponent } from './access-control/access-control.component';
import { CartRulesComponent } from './cart-rules/cart-rules.component';
import { ReportWrongAddressModalByTypeComponent } from './report-wrong-address-by-type-modal/report-wrong-address-by-type-modal.component';
import { ReportWrongAddressModalComponent } from './report-wrong-address-modal/report-wrong-address-modal.component';
import { NewDeliveryAddressModalComponent } from './new-delivery-address-modal/new-delivery-address-modal.component';
import { ChangeAddressModalComponent } from './change-address-modal/change-address-modal.component';
import { ConfirmRemoveModalComponent } from './confirm-remove-modal/confirm-remove-modal.component';
import { ContactPointModalComponent } from './contact-point-modal/contact-point-modal.component';
import { DeliveryInstructionsModalComponent } from './delivery-instructions-modal/delivery-instructions-modal.component';
import { ProductLoadingTileComponent } from './product-loading-tile/product-loading-tile.component';
import { LayoutOptionsComponent } from './layout-options/layout-options.component';
import { AddToWishlistComponent } from './add-to-wishlist/add-to-wishlist.component';
import { AddNewWishlistComponent } from './add-new-wishlist/add-new-wishlist.component';
import { StickerComponent } from './sticker/sticker.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ProgressComponent } from './progress/progress.component';
import { ProductBaseInfoComponent } from './product-base-info/product-base-info.component';
import { ConfigurationStepsBarComponent } from './configuration-steps-bar/configuration-steps-bar.component';
import { NotificationComponent } from './notification/notification.component';
import { ModalAddCartNameComponent } from './modal-add-cart-name/modal-add-cart-name.component';
import { IvkSelectionFormComponent } from './ivk-selection-form/ivk-selection-form.component';
import { QuoteItemWithIvkFormComponent } from './quote-item-with-ivk-form/quote-item-with-ivk-form.component';

// Pipes
import { CompanyDetailsPipe } from './pipes/company-details.pipe';
import { CustomerCurrencyPipe } from './pipes/customer-currency.pipe';
import { SearchFieldPipe } from './pipes/search-field.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { ShouldDisplayPricePipe } from './pipes/should-display-price.pipe';
import { SelectBoxFilterPipe } from './pipes/select-box-filter.pipe';
import { SelectedSystemPipe } from './pipes/selected-system.pipe';
import { FilterByAttributesPipe } from './pipes/filter-by-attributes.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { FormatDateTimePipe } from './pipes/format-date-time.pipe';

// MarCom reusable components
import { DropdownComponent } from './dropdown/dropdown.component';
import { AccordionComponent } from './accordion/accordion.component';
import { SelectBoxComponent } from './select-box/select-box.component';
import { TextfieldComponent } from './textfield/textfield.component';
import { TextareaComponent } from './textarea/textarea.component';
import { ModalComponent } from './modal/modal.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { StepsBarComponent } from './steps-bar/steps-bar.component';
import { LoadingAnimationDirective } from './directives/loading-animation.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { TrimDirective } from './directives/trim.directive';
import { AppBarAlertComponent } from './application-bar/app-bar-alert/app-bar-alert.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { CpqBillAndPayerAddressComponent } from '../components/page-quote-review-finalize/quote-summary/cpq-bill-and-payer-address/cpq-bill-and-payer-address.component';
import { CartNotificationModalComponent } from './cart-notification-modal/cart-notification-modal.component';
import { EncodeUriTextPipe } from './pipes/encode-uri-text.pipe';
import { FormatAddressPipe } from './pipes/format-address.pipe';
import { SocialMediaFooterComponent } from './social-media-footer/social-media-footer.component';
import { ConfigurationStatusPipe } from './pipes/configuration-status.pipe';
import { EditConfigurationLabelPipe } from './pipes/edit-configuration-label.pipe';
import { ContactUsFooterComponent } from './contact-us-footer/contact-us-footer.component';
import { EquipmentSelectionComponent } from './equipment-selection/equipment-selection.component';
import { SapMessageControllerComponent } from './sap-message-controller/sap-message-controller.component';
import { ModalSapP40Component } from './modal-sap-p40/modal-sap-p40.component';
import { WalkMeDirective } from './walk-me/walk-me.directive';
import { PaginationLinklessComponent } from './pagination-linkless/pagination-linkless.component';
import { RemoveLeadingZeroPipe } from './pipes/remove-leading-zero.pipe';
import { DragDropFileUploadDirective } from './directives/drag-drop-file-upload.directive';
import { DragDropFileUploadComponent } from './drag-drop-file-upload/drag-drop-file-upload.component';
import { ModalSelectSoldToAccountComponent } from './modal-select-sold-to-account/modal-select-sold-to-account.component';
import { ReportWrongBpAddressModalComponent } from './report-wrong-bp-address-modal/report-wrong-bp-address-modal.component';
import { MessageComponent } from './message/message.component';
import { ModalEosEquipmentComponent } from './modal-eos-equipment/modal-eos-equipment.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { AdvancedProductInformationComponent } from './product-information/advanced-product-information.component';
import { ProductInformationNotificationComponent } from './product-information/product-information-notification/product-information-notification.component';
import { DynamicDeliveryDetailsFormComponent } from '../components/harmonized-checkout/shared/components/dynamic-delivery-details-form/dynamic-delivery-details-form.component';
import { HarmonizedReportWrongAddressModalComponent } from './harmonized-report-wrong-address-modal/harmonized-report-wrong-address-modal.component';
import { RadioButtonsComponent } from './radio-button/radio-buttons.component';
import { EquipmentComponentFilterComponent } from './equipment-component-filter/equipment-component-filter.component';
import { AddressBlockComponent } from './address-block/address-block.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    FlatpickrModule.forRoot(),
  ],
  declarations: [
    ApplicationBarComponent,
    SearchFieldComponent,
    NavigationBarComponent,
    BreadcrumbsComponent,
    FooterComponent,
    SelectLanguageComponent,
    PaginationComponent,
    PaginationLinklessComponent,
    DropdownComponent,
    AccordionComponent,
    MiniCartComponent,
    ConfigurableMiniCartComponent,
    MultiMiniCartComponent,
    CustomerCurrencyPipe,
    SelectBoxComponent,
    TextfieldComponent,
    TextareaComponent,
    ModalComponent,
    ModalConfirmComponent,
    SearchFieldPipe,
    SearchBarComponent,
    SortByComponent,
    PhoneNumberPipe,
    StepsBarComponent,
    LoadingAnimationDirective,
    AlertComponent,
    AccessControlComponent,
    DebounceClickDirective,
    EquipmentFilterComponent,
    FiltersComponent,
    MarkedFiltersComponent,
    CartRulesComponent,
    OnlyNumbersDirective,
    ShouldDisplayPricePipe,
    SelectBoxFilterPipe,
    ImagePreloadDirective,
    TrimDirective,
    ReportWrongAddressModalByTypeComponent,
    ReportWrongAddressModalComponent,
    NewDeliveryAddressModalComponent,
    ChangeAddressModalComponent,
    ConfirmRemoveModalComponent,
    ContactPointModalComponent,
    DeliveryInstructionsModalComponent,
    ProductLoadingTileComponent,
    LayoutOptionsComponent,
    AddToWishlistComponent,
    AddNewWishlistComponent,
    StickerComponent,
    AppBarAlertComponent,
    StickerComponent,
    QuoteItemWithIvkFormComponent,
    IvkSelectionFormComponent,
    SelectedSystemPipe,
    CompanyDetailsPipe,
    TooltipComponent,
    FilterByAttributesPipe,
    ProgressComponent,
    ProductBaseInfoComponent,
    AdvancedProductInformationComponent,
    ConfigurationStepsBarComponent,
    NotificationComponent,
    ModalSapP40Component,
    DatepickerComponent,
    ModalAddCartNameComponent,
    CpqBillAndPayerAddressComponent,
    CartNotificationModalComponent,
    EncodeUriTextPipe,
    FormatAddressPipe,
    SocialMediaFooterComponent,
    ConfigurationStatusPipe,
    EditConfigurationLabelPipe,
    ContactUsFooterComponent,
    EquipmentSelectionComponent,
    SapMessageControllerComponent,
    WalkMeDirective,
    FormatDatePipe,
    FormatTimePipe,
    FormatDateTimePipe,
    RemoveLeadingZeroPipe,
    DragDropFileUploadDirective,
    DragDropFileUploadComponent,
    ModalSelectSoldToAccountComponent,
    ReportWrongBpAddressModalComponent,
    MessageComponent,
    ModalEosEquipmentComponent,
    ProductInformationNotificationComponent,
    DynamicDeliveryDetailsFormComponent,
    HarmonizedReportWrongAddressModalComponent,
    RadioButtonsComponent,
    EquipmentComponentFilterComponent,
    AddressBlockComponent,
  ],
  exports: [
    ApplicationBarComponent,
    BreadcrumbsComponent,
    FooterComponent,
    NavigationBarComponent,
    PaginationComponent,
    PaginationLinklessComponent,
    DropdownComponent,
    AccordionComponent,
    MiniCartComponent,
    ConfigurableMiniCartComponent,
    MultiMiniCartComponent,
    CustomerCurrencyPipe,
    SelectBoxComponent,
    TextfieldComponent,
    TextareaComponent,
    ModalComponent,
    ModalConfirmComponent,
    SearchFieldPipe,
    SearchBarComponent,
    SortByComponent,
    PhoneNumberPipe,
    StepsBarComponent,
    LoadingAnimationDirective,
    AlertComponent,
    AccessControlComponent,
    DebounceClickDirective,
    EquipmentFilterComponent,
    FiltersComponent,
    MarkedFiltersComponent,
    CartRulesComponent,
    OnlyNumbersDirective,
    ShouldDisplayPricePipe,
    SelectBoxFilterPipe,
    ImagePreloadDirective,
    ReportWrongAddressModalByTypeComponent,
    ReportWrongAddressModalComponent,
    NewDeliveryAddressModalComponent,
    ChangeAddressModalComponent,
    ConfirmRemoveModalComponent,
    ContactPointModalComponent,
    DeliveryInstructionsModalComponent,
    ProductLoadingTileComponent,
    LayoutOptionsComponent,
    AddToWishlistComponent,
    AddNewWishlistComponent,
    StickerComponent,
    QuoteItemWithIvkFormComponent,
    IvkSelectionFormComponent,
    SelectedSystemPipe,
    CompanyDetailsPipe,
    TooltipComponent,
    FilterByAttributesPipe,
    ProgressComponent,
    ProductBaseInfoComponent,
    AdvancedProductInformationComponent,
    ConfigurationStepsBarComponent,
    NotificationComponent,
    DatepickerComponent,
    CpqBillAndPayerAddressComponent,
    ModalAddCartNameComponent,
    EncodeUriTextPipe,
    FormatAddressPipe,
    ConfigurationStatusPipe,
    EditConfigurationLabelPipe,
    EquipmentSelectionComponent,
    SapMessageControllerComponent,
    ModalSapP40Component,
    WalkMeDirective,
    FormatDatePipe,
    FormatTimePipe,
    FormatDateTimePipe,
    RemoveLeadingZeroPipe,
    DragDropFileUploadComponent,
    ModalSelectSoldToAccountComponent,
    ReportWrongBpAddressModalComponent,
    MessageComponent,
    ModalEosEquipmentComponent,
    ProductInformationNotificationComponent,
    DynamicDeliveryDetailsFormComponent,
    HarmonizedReportWrongAddressModalComponent,
    RadioButtonsComponent,
    EquipmentComponentFilterComponent,
    AddressBlockComponent,
  ],
  providers: [
    ConfigurationFacade,
    CustomerCurrencyPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
}
