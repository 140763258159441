<div class="order-details">
  <ng-container *ngIf="!orderDetails">
    <div class="col padding-3">
      <div class="loading-animation"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="orderDetails">
    <div class="delivery-address">
      <h5 class="delivery-address__title" [attr.data-test]="'title-address'">{{ 'orders-page.delivery-address' | translate }}</h5>
      <ng-container *ngIf="!isAddressFieldEmpty(orderDetails.attributes.shippingAddress.firstName)">
        <p [attr.data-test]="'desc-firstName'">
          {{ renderEmptyAddressField(orderDetails.attributes.shippingAddress.firstName) }}
        </p>
      </ng-container>
      <p [attr.data-test]="'desc-address1'">
        {{ renderEmptyAddressField(orderDetails.attributes.shippingAddress.address1) }}
      </p>
      <div class="delivery-address--inline">
        <ng-container *ngIf="!isAddressFieldEmpty(orderDetails.attributes.shippingAddress.zipCode)">
          <p [attr.data-test]="'desc-zipCode'">{{ renderEmptyAddressField(orderDetails.attributes.shippingAddress.zipCode) }}&nbsp;</p>
        </ng-container>
        <ng-container *ngIf="!isAddressFieldEmpty(orderDetails.attributes.shippingAddress.city)">
          <p [attr.data-test]="'desc-city'">{{ renderEmptyAddressField(orderDetails.attributes.shippingAddress.city) }}</p>
        </ng-container>
        <ng-container *ngIf="!isAddressFieldEmpty(orderDetails.attributes.shippingAddress.address2)">
          <p [attr.data-test]="'desc-address2'">, {{ renderEmptyAddressField(orderDetails.attributes.shippingAddress.address2) }}</p>
        </ng-container>
      </div>
      <ng-container *ngIf="!isAddressFieldEmpty(orderDetails.attributes.shippingAddress.country)">
        <p [attr.data-test]="'desc-country'">{{ renderEmptyAddressField(orderDetails.attributes.shippingAddress.country) }}</p>
      </ng-container>
    </div>

    <div *ngIf="orderDetails.attributes.taxNumber" class="cart-tax-number">
      <ng-container >
        <h5 class="cart-tax-number__title">{{ 'order-approve.billing-tax-number' | translate }}</h5>
          <div>
            <p [attr.data-test]="'desc-taxNumber'">{{ orderDetails.attributes.taxNumber }}</p>
          </div>
      </ng-container>
    </div>

    <div class="cart-system-details">
      <ng-container *ngIf="orderDetails.attributes.systemDetails">
        <h5 class="cart-system-details__title" [attr.data-test]="'title-systemDetails'">
          {{ 'orders-page.system-details' | translate }}
        </h5>
        <div class="padding-styling">
          <div>
            <p [attr.data-test]="'label-selectedSystem'">
              {{ 'orders-page.selected-system' | translate }}
            </p>
            <div class="cart-system-details--inline">
              <p *ngIf="orderDetails.attributes.systemDetails" [attr.data-test]="'desc-selectedSystem'">
                {{ orderDetails.attributes.systemDetails | selectedSystem | async }}
              </p>
            </div>
          </div>
          <div>
            <p [attr.data-test]="'label-companyDetails'">
              {{ 'orders-page.company' | translate }}
            </p>
            <div class="cart-system-details--inline">
              <p *ngIf="orderDetails.attributes.systemDetails" [attr.data-test]="'desc-companyDetails'">
                {{ orderDetails.attributes.systemDetails | companyDetails }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <ng-container *ngIf="orderDetails.type === 'orders' && orderDetails; else cartsBlock">
          <div class="product__wrapper" [ngClass]="{'in-progress': isItemInProgress(item.sku)}"
               *ngFor="let item of orderDetails.attributes.items" [attr.data-test]="'order-item-' + item.name">
            <div class="product__image">
              <img [src]="item.metadata.image" [alt]="item.name" [attr.data-test]="'img-productImage'">
            </div>
            <div class="product__details">
              <h5 class="product__name" [attr.data-test]="'desc-productName'">{{ item.name }}</h5>
              <div *ngIf="type === 'order'">
                <span class="product__price" [attr.data-test]="'label-productPrice'">
                  {{ 'orders-page.unit-price' | translate }}:
                </span>
                <span class="product__price" [attr.data-test]="'desc-productPrice'">
                  {{ item.unitPrice | currency: orderDetails.attributes.currencyIsoCode }}
                  <span *ngIf="isExcludeTaxActive()" class="tax-disclaimer">({{ 'app-product-page.ex_gst' | translate }})</span>
                </span>
              </div>
              <div class="item-level-details">
                <ng-container *ngIf="item.systemDetails">
                  <div>
                    <span [attr.data-test]="'label-selectedSystem'">
                      {{ 'orders-page.selected-system' | translate }}
                    </span>
                    <div class="item-system-details-styling">
                      <span *ngIf="item.systemDetails" [attr.data-test]="'desc-selectedSystem'">
                        {{ item.systemDetails | selectedSystem | async }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <span [attr.data-test]="'label-companyDetails'">
                      {{ 'orders-page.company' | translate }}
                    </span>
                    <div class="item-system-details-styling">
                      <span *ngIf="item.systemDetails" [attr.data-test]="'desc-companyDetails'">
                        {{ item.systemDetails | companyDetails }}
                      </span>
                    </div>
                  </div>
                </ng-container>
                <div class="product__qty">
                  <span [attr.data-test]="'label-quantity'">{{ 'shop-cart.quantity' | translate }}: </span>
                  <span [attr.data-test]="'desc-quantity'">{{ item.quantity }}</span>
                </div>
              </div>
              <div class="product__btn">
                <button
                    class="button button--small button--primary buy-again-button"
                    appDebounceClick
                    [debounceTime]="300"
                    [disabled]="isItemInProgress(item.sku)"
                    (debounceClick)="addProductToCart(item.sku, item.name)"
                    [attr.data-test]="'button-buyAgain'">
                  <span class="buy-again__icon icon-replay"></span>
                  {{ buyButtonLabel() | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #cartsBlock>
          <ng-container *ngIf="cartItems">
            <div
                class="product__wrapper"
                [ngClass]="{'in-progress': isItemInProgress(item)}"
                *ngFor="let item of cartItems">
              <div class="product__image">
                <img width="130" [src]="getItemPicture(item)" [alt]="getItemName(item)">
              </div>
              <div class="product__details">
                <h5 class="product__name">{{ getItemName(item) }}</h5>
                <span class="product__price">
                  {{ getItemPrice(item) | currency: cartDetails.data.attributes.currency }}
                </span>
                <span class="product__qty">
                  {{ 'shop-cart.quantity' | translate }}:&nbsp;{{ getItemQuantity(item) }}
                </span>
                <div class="product__btn">
                  <button
                      class="button button--small button--primary buy-again-button"
                      appDebounceClick
                      [debounceTime]="300"
                      [disabled]="isItemInProgress(item)"
                      (debounceClick)="addProductToCart(item, getItemName(item))"
                      [attr.data-test]="'button-buyAgain'">
                    <span class="buy-again__icon icon-replay"></span>
                    {{ 'orders-page.buy-again' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
      <div class="col-12 col-lg-6 tracking-text">
        <a class="button button--link"
           *ngIf="orderDetails.type === 'orders'"
           [routerLink]="[type === 'order' ? '/my-orders/' : '/my-request/', orderDetails.id]">
          <span class="icon-arrow-right-small"></span> {{ 'orders-page.tracking-details' | translate }}
        </a>
      </div>
    </div>
  </ng-container>
</div>
