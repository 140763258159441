<app-access-control [notForViewer]="true" [description]="true">
  <ng-container ngProjectAs="[slot][nonViewerContent]">
    <h4 [attr.data-test]="'title-purchaseActivityTitle'">{{ 'purchase-activity.title' | translate }}</h4>
    @if (isHistoryLoaded$ | async) {
      <div class="purchase-activity-content">
        @if (isPurchaseActivityTabsNavigationVisible) {
          <div class="purchase-activity-navigation">
            @if (purchaseActivityTabs) {
              <div class="bare-list tabs__list">
                @for (tab of purchaseActivityTabs; track tab) {
                  <h6 class="tabs__item"
                      [attr.data-test]="'tab-' + tab"
                      [ngClass]="{'is-active is-active__bar': openedTab === tab}"
                      (click)="changeTab(tab)">
                    {{ 'purchase-activity.' + tab | translate }}
                  </h6>
                }
              </div>
            }
            @if (openedTab === PurchaseActivityTabs.PREVIOUS_ORDERS && isPurchaseActivityPreviousOrdersTabsNavigationVisible) {
              <div class="bare-list tabs__list">
                @for (tab of purchaseActivityPreviousOrdersTabs; track tab) {
                  <h6 class="tabs__item"
                      [attr.data-test]="'tab-' + tab"
                      [ngClass]="{'is-active is-active__bar': openedPreviousOrdersTab === tab}"
                      (click)="changePreviousOrdersTab(tab)">
                    {{ 'purchase-activity.' + tab | translate }}
                  </h6>
                }
              </div>
            }
          </div>
        }
        <div class="purchase-activity-history">
          @switch (openedTab) {
            @case (PurchaseActivityTabs.SERVICES) {
              <app-service-purchase-activity
                  (loadServiceOrdersEmit)="serviceOrdersLoaded = $event; trackPageReady()"
              />
            }
            @case (PurchaseActivityTabs.PREVIOUS_ORDERS) {
              <app-purchase-history
                  [orders]="purchaseActivityContent[openedPreviousOrdersTab]"
                  [currentTab]="openedTab"
                  [triggerClearAllFilters$]="triggerClearAllFilters$"
                  [currentSubTab]="openedPreviousOrdersTab"
              />
            }
            @default {
              <app-purchase-history
                  [orders]="purchaseActivityContent[openedTab]"
                  [currentTab]="openedTab"
                  [triggerClearAllFilters$]="triggerClearAllFilters$"
              />
            }
          }
          @if (openedTab !== PurchaseActivityTabs.SERVICES) {
            <app-pagination-linkless
                [pageCount]="getPaginationData().lastPage"
                [currentPage]="getPaginationData().currentPage"
                (pageChange)="changePage(openedTab, $event)"
            />
          }
        </div>
      </div>
    } @else {
      <div class="purchase-activity-loader">
        <div class="loading-animation"></div>
      </div>
    }
  </ng-container>
</app-access-control>
