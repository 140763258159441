<div class="dynamic-checkout-approver">
  <h6>{{ 'checkout-delivery-details.approver-data-title' | translate }}</h6>
  <br>
  @if (approverForm) {
    @for (input of visibleInputs; track input) {
      @switch (input) {
        @case (EApproverInputNames.APPROVER) {
          <app-select-box
              label="{{ 'checkout-delivery-details.select-approver' | translate }}"
              [form]="approverForm"
              [modelValue]="approverForm.value.approver"
              [options]="approversNameList"
              [loading]="approversNameListLoading"
              [disabled]="approversNameListLoading"
              [inputName]="'approver'"
              [isRequired]="requiredInputs.includes(EApproverInputNames.APPROVER)"
              [readOnly]="readOnlyInputs.includes(EApproverInputNames.APPROVER)"
              (selected)="selectApprover($event)">
          </app-select-box>
        }
        @case (EApproverInputNames.COMMENT) {
          <app-textarea
              label="{{ 'checkout-delivery-details.comment' | translate }}"
              [form]="approverForm"
              [modelValue]="approverForm.value.comment"
              [rows]="3"
              [cssStyles]="{wrapper: 'text-area-comment'}"
              [inputName]="'comment'"
              [maxLength]="commentMaxLength"
              [isRequired]="requiredInputs.includes(EApproverInputNames.COMMENT)"
              [readOnly]="readOnlyInputs.includes(EApproverInputNames.COMMENT)"
              (areaFilled)="setApproverFormValue($event)">
          </app-textarea>
          <div class="alert-controls textarea-alert" *ngIf="approverForm.value.comment">
            <small class="alert-text">
              {{ approverForm.value.comment.length }}/{{ commentMaxLength }}
            </small>
          </div>
        }
        @case (EApproverInputNames.PO_NUMBER) {
          <app-textfield
              label="{{ 'checkout-delivery-details.po-number' | translate }}"
              [form]="approverForm"
              [modelValue]="approverForm.value.poNumber"
              [inputName]="'poNumber'"
              [isRequired]="requiredInputs.includes(EApproverInputNames.PO_NUMBER)"
              [readOnly]="readOnlyInputs.includes(EApproverInputNames.PO_NUMBER)"
              (fieldFilled)="setApproverFormValue($event)">
          </app-textfield>
        }
        @case (EApproverInputNames.PROMO_CODE) {
          <div class="row voucher-form">
            <div class="col-12">
              <ng-container *ngIf="!voucherCodeInputVisible; else voucherForm">
                <button class="button margin-bottom-1"
                        (click)="expandVoucherForm()"
                        [disabled]="useRfqFlow && !useGenerateQuoteFlow"
                        [attr.data-test]="'button-addCode'">
                  {{ 'order-approve.add-promotional-code' | translate }}
                </button>
              </ng-container>
            </div>
            <ng-template #voucherForm>
              <label [attr.data-test]="'label-addVoucher'">
                <span class="voucher__label">{{ 'order-approve.insert-voucher' | translate }}</span>
              </label>
              <div class="checkout__field voucher__container">
                <div class="voucher__container--input">
                  <app-textfield
                      class="checkout__textfield"
                      [form]="approverForm"
                      [modelValue]="approverForm.value.promoCode"
                      [markInvalid]="voucherHasError && !addVoucherInProgress"
                      [cssStyles]="{wrapper: 'textfield-with-prefix textfield-promotionCode'}"
                      [inputName]="'promoCode'"
                      label="{{ 'order-approve.code' | translate }}"
                      [readOnly]="readOnlyInputs.includes(EApproverInputNames.PROMO_CODE)"
                      (fieldFilled)="resetVoucherField()"
                      (keydown.enter)="!isVoucherBtnDisabled() && addVoucher()">
                  </app-textfield>
                  <div
                      *ngIf="approverForm.get('promoCode').touched && (this.voucherHasError && !this.voucherListLoaded)"
                      class="alert-controls textarea-alert">
                    <small class="alert-text" [attr.data-test]="'hint-invalidCode'">
                      {{ 'order-approve.invalid-code' | translate }}
                    </small>
                  </div>
                </div>
                <button
                    type="button"
                    class="button button add-voucher__btn"
                    [ngClass]="{'in-progress': addVoucherInProgress}"
                    (click)="addVoucher()"
                    [disabled]="isVoucherBtnDisabled()"
                    [attr.data-test]="'button-applyVoucher'">
                  <ng-container *ngIf="addVoucherInProgress; else applyMsg">
                    <span class="loading-animation"></span>
                  </ng-container>
                  <ng-template #applyMsg>
                    <span>{{ 'order-approve.apply-voucher' | translate }}</span>
                  </ng-template>
                </button>
              </div>
            </ng-template>
          </div>
        }
      }
    }
  }
</div>
