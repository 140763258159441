<div class="lang-selection-container">
  <app-dropdown
      [cssStyles]="{dropdown: 'user-identifier', toggle: 'user-identifier__toggle-button'}"
      [dropDownName]="'language'">
    <ng-container ngProjectAs="[slot][toggle]">
      <button class="meta-menu__link js-dropdown dropdown">
        <span class="icon-globe dropdown__toggle"></span>
      </button>
    </ng-container>
    <ng-container ngProjectAs="[slot][panel]">
      <ng-container>
      <span class="menu__item"
            *ngFor="let lang of languages"
            (click)="useLanguage(lang)">
        {{ lang.name }}
      </span>
        @if (!isExternalQuoteCheckoutEnabled) {
          <span class="divider"></span>
          <span class="menu__item" (click)="goToStoreSelection()">
            {{ 'app-bar.store-selection' | translate }}
          </span>
        }
      </ng-container>
    </ng-container>
  </app-dropdown>
</div>

