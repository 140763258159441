import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationFacade } from '../../../../facades/configuration.facade';
import { IAddressData } from '../../../../models/checkout.models';
import { IAddress, IMessage, IPointOfContact, ITotals } from '../../../../models/common.models';
import { ISapMessage } from '../../../../models/sap.model';
import { ICart, IDiscount } from '../../../../models/cart.models';
import { EMessageType, EAddressType } from '../../../../configurations/common';
import { take } from 'rxjs/operators';
import { FileType, FileUtils } from '../../../../utils/file.utils';
import { CheckoutFacade } from '../../../../facades/checkout.facade';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddressUtils } from '../../../../utils/address.utils';
import { environment } from '../../../../../environments/environment.defaults';
import { I18nService } from '../../../../services';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OrderReviewService {
  fileGenerationInProgress: boolean = false;
  addressesConfig: string[]; // Addresses configuration for visibility and order from Arakh
  checkoutSectionsRequiredFields: string[]; // Required fields for sections from Arakh
  private cartTotalsSubject$: BehaviorSubject<ITotals> = new BehaviorSubject<ITotals>(null);
  private cartDiscountsSubject$: BehaviorSubject<IDiscount[]> = new BehaviorSubject<IDiscount[]>([]);

  cartTotals$: Observable<ITotals> = this.cartTotalsSubject$.asObservable();
  cartDiscounts$: Observable<IDiscount[]> = this.cartDiscountsSubject$.asObservable();

  constructor(
    private configurationFacade: ConfigurationFacade,
    private checkoutFacade: CheckoutFacade,
    private router: Router,
    private i18nService: I18nService,
  ) {
    this.selectCheckoutAddressesConfig();
    this.selectCheckoutSectionsRequiredFields();
  }

  // *** Initialization ***

  /**
   * Get addresses configuration from Arakh.
   */
  selectCheckoutAddressesConfig(): void {
    this.configurationFacade.getDynamicCheckoutAddresses()
      .pipe(take(1)).subscribe((value) => {
      this.addressesConfig = value;
    });
  }

  /**
   * Initialize addresses based on configuration in arakh. If address is not part of cart, then create empty, not valid
   * address and user will not be able to submit order.
   * @param {ICart} cart
   * @returns {IAddressData[]}
   */
  checkoutAddressesInitialization(cart: ICart): IAddressData[] {
    const addresses: IAddressData[] = [];
    this.addressesConfig.forEach(addressType => {
      let addressData: IAddressData;
      switch (addressType) {
        case EAddressType.SHIP_TO:
          addressData = this.createCheckoutAddress(addressType, cart.attributes.shippingAddress);
          addressData = {
            ...addressData,
            name: this.enrichAddressDataNameWithPoCAttentionTo(addressData.name, cart.attributes.pointOfContact)
          };
          break;
        case EAddressType.BILL_TO:
          addressData = this.createCheckoutAddress(addressType, cart.attributes.billingAddress);
          break;
        case EAddressType.SOLD_TO:
          addressData = this.createCheckoutAddress(addressType, cart.attributes.soldToAddress);
          break;
        case EAddressType.PAYER:
          addressData = this.createCheckoutAddress(addressType, cart.attributes.payerAddress);
          break;
        default:
          break;
      }
      addresses.push(addressData);
    });
    return addresses;
  }

  /**
   * Enriches the address data name with the point of contact's attention to information.
   *
   * @param {string} addressDataName - The address data name to be enriched.
   * @param {IPointOfContact} pointOfContact - The point of contact containing the attention to information.
   * @returns {string} The enriched address data name.
   */
  enrichAddressDataNameWithPoCAttentionTo(addressDataName: string, pointOfContact: IPointOfContact): string {
    return `${pointOfContact.attentionTo ? pointOfContact.attentionTo + ' - ' : ''}${addressDataName}`;
  }

  /**
   * Create address data based on address type and address object. If address is falsy (null, undefined), then create
   * empty, not valid address.
   * @param {EAddressType} addressType
   * @param {IAddress} address
   * @returns {IAddressData}
   */
  createCheckoutAddress(addressType: EAddressType, address: IAddress): IAddressData {
    const addressName: string = address
      ? `${address.sapId ? address.sapId + ' - ' : ''}${AddressUtils.formatAddressToString(address)}`
      : null;

    const isAddressValid: boolean = AddressUtils.isAddressValid(address);
    return {
      value: address,
      name: addressName,
      type: addressType,
      isAddressValid: isAddressValid,
    };
  }

  /**
   * Get required fields for sections from Arakh to be checked in "isFormFieldRequired" function.
   */
  selectCheckoutSectionsRequiredFields(): void {
    this.configurationFacade.getDynamicCheckoutSectionsRequiredFields()
      .pipe(take(1)).subscribe((value) => {
      this.checkoutSectionsRequiredFields = value;
    });
  }

  /**
   * Check if form field is required for adding Validators. Required while initializing form.
   * @param {string} fieldName
   * @returns {boolean}
   */
  isFormFieldRequired(fieldName: string): boolean {
    return this.checkoutSectionsRequiredFields.includes(fieldName);
  }

  // *** Cart operations ***

  /**
   * Update totals and discounts in cart (e.g. after add/remove discount).
   * @param {ITotals} totals
   * @param {IDiscount[]} discounts
   */
  updateTotalsAndDiscounts(totals: ITotals, discounts: IDiscount[] = []): void {
    this.cartTotalsSubject$.next(totals);
    this.cartDiscountsSubject$.next(discounts);
  }

  /**
   * Return % of discount for discount based on subtotal value.
   * @param {number} discountAmount
   * @param {number} cartSubtotal
   * @returns {number}
   */
  getDiscountTitle(discountAmount: number, cartSubtotal: number): number {
    if (!cartSubtotal) {
      return 0;
    }

    return Math.round((discountAmount * 100) / cartSubtotal);
  }

  /**
   * Checks if sapMessages array contains message with type 'E'.
   * @param {ISapMessage[]} sapMessages
   * @returns {boolean}
   */
  checkHasNoErrorMessages(sapMessages: ISapMessage[]): boolean {
    return !sapMessages.some(message => message.type === 'E');
  }

  /**
   * Check if the selected shipment method is Saturday shipment.
   * @param {string} selectedShipmentMethodName
   * @returns {boolean}
   */
  isSaturdayShipmentSelected(selectedShipmentMethodName: string): boolean {
    return selectedShipmentMethodName === environment.sparePartSaturdayDeliveryKey;
  }

  // *** Generating of content ***

  /**
   * Generate warning message for price disputing.
   * @returns {IMessage}
   */
  generatePriceDisputingMessage(): IMessage {
    return {
      type: EMessageType.WARNING,
      title: 'cart.disputed-pricing.title',
      description: 'cart.disputed-pricing.description',
    };
  }

  /**
   * Generate warning message "Something is wrong" and redirect to home page.
   */
  showNotificationAndRedirectToHomePage() {
    this.configurationFacade.appendNotification({
      type: 'warning',
      title: 'error-404.title-something-is-wrong',
      messages: [{
        key: 'error-404.something-is-wrong',
      }],
    });
    this.router.navigate([this.i18nService.getCurrentLocale()]);
  }

  /**
   * Get availability icon based on availability status.
   * @param {boolean} isAvailable
   * @returns {string}
   */
  getAvailabilityIcon(isAvailable: boolean): string {
    return isAvailable ? 'icon-check icon-check__circle' : 'icon-exclamationmark icon-exclamationmark__circle';
  }

  /**
   * Set loader overlay until file is generated.
   * @param {ICart} cart
   */
  generatePdfFile(cart: ICart): void {
    this.fileGenerationInProgress = true;
    this.checkoutFacade.getCartDetailPdfFile(cart.id).pipe(take(1))
      .subscribe((response: HttpResponse<Blob>)  => {
        const contentDispositionHeader = response.headers.get('Content-Disposition');
        const filename = FileUtils.extractFilenameFromContentDisposition(contentDispositionHeader, 'Cart_details_' + cart.id);
        FileUtils.saveAndOpenFile(response.body, FileType.PDF, filename);
      });
    this.fileGenerationInProgress = false;
  }
}
